import React from "react";
import { Form } from "react-bootstrap";
import { useCampaign } from "../../../../../hooks/useCampaign";
import DynamicFaq from "../../DynamicFaq";
import DynamicTitleTranslation from "../../DynamicTitleTranslation";
import Select from "react-select";
const DonationOtherDetails = ({organizations,languages}) => {
  const {campaign,handleDynamicDetails,handleChange,handleSelect} = useCampaign()
  return (
    <>
    <div className="d-flex justify-content-center">
        <h4 className="text-dark mt-4 ">Other Details</h4>
      </div>
      <div className="row">
        <div className="form-group col-md-6 col-xs-12">
          <Form.Group className="form-group">
          <Form.Label >Organization</Form.Label>
            <Select
            className="dropdown"
            placeholder="Select Organization"
            name="organization_id"
            value={organizations.filter(obj => obj.value===campaign.organization_id)} // set selected values
            options={organizations} // set list of the data
            onChange={(selected)=>handleSelect('organization_id',selected.value)} // assign onChange function
            />
            {/* {error.organization_id && <span className="text-danger">Please select organization</span>} */}
          </Form.Group>
        </div>
      <div className="form-group col-md-6 col-xs-12 ">
        <Form.Group className="form-group">
          <Form.Label >Deadline</Form.Label>
          <input
            type="date"
            className="form-control"
            id="deadline"
            name="deadline"
            value={campaign.deadline}
            onChange={handleChange}
            placeholder="Deadline"
          />
        </Form.Group>
        </div>
      </div>
      <div className="form-group ">
        <label htmlFor="name">Faqs</label>
        <DynamicFaq handleFaqs={handleDynamicDetails} faqs={campaign.faqs}/>
      </div>
      <div className="form-group ">
        <label htmlFor="name">Title Translations</label>
        <DynamicTitleTranslation handleTitleTrans={handleDynamicDetails} titleTrans={campaign.title_translations} languages={languages}/>
      </div>
    </>
  );
};

export default DonationOtherDetails;
