import React, { useEffect, useState } from "react";
import { profileService } from "../../../../services/user.service";
import { Dropdown, Modal, Spinner } from "react-bootstrap";
import { Link } from "react-router-dom";
import { DATE_OPTIONS } from "../../../../utilities/constants";
import { IoMdAddCircleOutline } from "react-icons/io";
import {
  notifyFailed,
  notifySuccess,
} from "../../../../components/toast/Toast";
import { useAuth } from "../../../../hooks/useAuth";

const Educations = () => {
  const { user } = useAuth();
  const [educationLists, setEducationLists] = useState([]);
  const [error, setSHowError] = useState(null);
  const [clickedIndex, setClickedIndex] = useState(-1);
  const [showClose, setShowClose] = useState(false);
  const [loading, setLoading] = useState(false);
  const [education, setEducation] = useState({
    type: "",
    name: "",
    from: "",
    to: "",
    currentlyStudying: true,
    city: "",
    description: "",
  });
  const fetchEducations = async () => {
    try {
      const res = await profileService.fetchProfileData();
      if (res?.profile_data?._id) {
        setEducationLists(res?.profile_data?.educationsInfo);
      } else {
        setSHowError("Please Complete Personal Information");
      }
    } catch (err) {
      return err;
    }
  };
  useEffect(() => {
    fetchEducations();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleShowClose = () => {
    setShowClose(!showClose);
    setEducation({});
    setClickedIndex(-1);
  };
  const handleSetEducation = (index) => {
    handleShowClose();
    setClickedIndex(index);
    const clikedWorkPlace = educationLists[index];
    setEducation(clikedWorkPlace);
  };
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (name === "currentlyStudying") {
      setEducation((previousState) => ({
        ...previousState,
        [name]: !education.currentlyStudying,
        to: "",
      }));
    } else {
      setEducation((previousState) => ({ ...previousState, [name]: value }));
    }
  };
  const handleSend = async (e) => {
    e.preventDefault();
    setLoading(true);
    if (clickedIndex >= 0) {
      try {
        const tempEducationsLists = [...educationLists];
        tempEducationsLists[clickedIndex] = education;
        const res = await profileService.updateProfileData(
          {
            educationsInfo: [...tempEducationsLists],
          },
          user.id
        );
        setEducationLists(res?.profile_data?.educationsInfo);
        notifySuccess("Your information updated successfully");
      } catch (err) {
        notifyFailed(err.message ?? "Failed Occur");
        return err;
      } finally {
        setLoading(false);
        handleShowClose();
      }
    } else {
      try {
        const res = await profileService.updateProfileData(
          {
            educationsInfo: [education, ...educationLists],
          },
          user.id
        );
        setEducationLists(res?.profile_data?.educationsInfo);
        notifySuccess("Your information created successfully");
      } catch (err) {
        notifyFailed(err.message ?? "Failed Occur");
        return err;
      } finally {
        setLoading(false);
        handleShowClose();
      }
    }
  };
  const handleDelete = async (index) => {
    try {
      const filteredEducations = educationLists.filter(
        (element, i) => i !== index
      );
      const res = await profileService.updateProfileData(
        {
          educationsInfo: filteredEducations,
        },
        user.id
      );
      setEducationLists(res?.profile_data?.educationsInfo);
      notifySuccess("Deleted successfully");
    } catch (err) {
      notifyFailed("Cannot delete it, try it later on");
      return err;
    } finally {
    }
  };
  return (
    <div className="m-2 p-2">
      <div className="row d-flex justify-content-left">
        <h4 className="text-dark">{error ? error : "Educations"}</h4>
      </div>
      {!error && (
        <>
          {educationLists?.map((ele, index) => (
            <div
              key={index}
              className="d-flex justify-content-between align-items-center p-2"
            >
              <div className="col-md-10 col-xs-12">
                <div className="d-flex">
                  <div>
                    <h5>
                      {ele.name} - {ele.type}
                    </h5>
                    <small className="text-muted">
                      {new Date(ele.from).toLocaleDateString(
                        "en-US",
                        DATE_OPTIONS
                      )}{" "}
                      -
                      {ele.currentlyStudying ? (
                        "Present"
                      ) : (
                        <>
                          {new Date(ele.to).toLocaleDateString(
                            "en-US",
                            DATE_OPTIONS
                          )}
                        </>
                      )}
                    </small>
                  </div>
                </div>
              </div>

              <div className="col-md-2 col-xs-12">
                <div className="card-header-toolbar  d-flex justify-content-end">
                  <Dropdown>
                    <Link to="#">
                      <Dropdown.Toggle as="span">
                        <i
                          className="material-symbols-outlined md-18 action-button"
                          style={{ fontSize: "28px" }}
                        >
                          more_horiz
                        </i>
                      </Dropdown.Toggle>
                    </Link>
                    <Dropdown.Menu className="dropdown-menu-right">
                      <Dropdown.Item
                        href="#"
                        className="d-flex align-items-center"
                        onClick={() => handleSetEducation(index)}
                      >
                        <i className="material-symbols-outlined me-2 md-18">
                          edit
                        </i>
                        Edit
                      </Dropdown.Item>
                      <Dropdown.Item
                        href="#"
                        className="d-flex align-items-center"
                        onClick={() => handleDelete(index)}
                      >
                        <i className="material-symbols-outlined me-2 md-18">
                          delete
                        </i>
                        Delete
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
              </div>
            </div>
          ))}
          <div className="d-flex  justify-content-left mb-3 mt-2">
            <div
              className="col-md-4 col-xs-12 my-button"
              onClick={handleShowClose}
            >
              <IoMdAddCircleOutline className="m-1" size={25} />
              <span>Add an Education</span>
            </div>
          </div>
          {showClose && (
            <Modal show={showClose} onHide={handleShowClose} size="xl">
              <Modal.Header closeButton>
                <Modal.Title>Edit Education</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <form onSubmit={handleSend}>
                  <div className="row p-2  d-flex align-items-center">
                    <div className="form-group col-md-6 col-xs-12">
                      <label htmlFor="name">Type</label>
                      <select
                        class="form-select"
                        aria-label="Default select example"
                        name="type"
                        required
                        value={education.type}
                        onChange={handleInputChange}
                      >
                        <option selected value="">
                          -- Please Select --
                        </option>
                        <option value="Doctorate degree">
                          Doctorate degree
                        </option>
                        <option value="Master's degree">Master's degree</option>
                        <option value="Bachelor's degree">
                          Bachelor's degree
                        </option>
                        <option value="Diploma">Diploma</option>
                        <option value="Certificate">Certificate</option>
                        <option value="High School">High School</option>
                      </select>
                    </div>
                    <div className="form-group col-md-6 col-xs-12">
                      <label htmlFor="name">Institution Name</label>
                      <input
                        type="text"
                        name="name"
                        required
                        className="form-control"
                        id="other_detail_label"
                        value={education.name}
                        onChange={handleInputChange}
                      />
                    </div>

                    <div className="form-group col-xs-12">
                      <label htmlFor="city">City</label>
                      <input
                        type="text"
                        name="city"
                        required
                        className="form-control"
                        id="other_detail_label"
                        value={education.city}
                        onChange={handleInputChange}
                      />
                    </div>
                    <div className="form-group col-xs-12">
                      <label htmlFor="description">Description</label>
                      <textarea
                        name="description"
                        className="form-control"
                        id="other_detail_value"
                        required
                        value={education.description}
                        rows={2}
                        data-container-id={2}
                        onChange={handleInputChange}
                      />
                    </div>
                    <div className="form-check col-xs-12">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        name="currentlyStudying"
                        checked={education.currentlyStudying}
                        value="zoneCeylon"
                        onChange={handleInputChange}
                        id="flexCheckDefault"
                      />
                      <label
                        className="form-check-label"
                        for="flexCheckDefault"
                      >
                        I am currently studying here
                      </label>
                    </div>
                    <div className="form-group col-md-6 col-xs-12">
                      <label htmlFor="from">From</label>
                      <input
                        type="date"
                        name="from"
                        required
                        className="form-control"
                        id="other_detail_label"
                        value={education.from}
                        onChange={handleInputChange}
                      />
                    </div>
                    {!education.currentlyStudying && (
                      <div className="form-group col-md-6 col-xs-12">
                        <label htmlFor="to">To</label>
                        <input
                          type="date"
                          name="to"
                          className="form-control"
                          required={!education.currentlyStudying ? true : false}
                          id="other_detail_label"
                          value={education.to}
                          onChange={handleInputChange}
                        />
                      </div>
                    )}
                  </div>
                  <div className="d-flex">
                    <div className="col-md-2 col-xs-6">
                      <button className="btn btn-danger" type="submit">
                        {loading ? (
                          <Spinner animation="border" size="sm" />
                        ) : (
                          "Send"
                        )}
                      </button>
                    </div>
                  </div>
                </form>
              </Modal.Body>
            </Modal>
          )}
        </>
      )}
    </div>
  );
};

export default Educations;
