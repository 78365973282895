import axios from "axios";
import userProfileApi from "../features/api/userProfileApi";
import { api } from "./axios.service";

const getProfileInfo = async (id) => {
  try {
    const result = await userProfileApi.get(`/user/${id}`);
    return result;
  } catch (err) {
    return err;
  }
};
const addNewWorkPlace = async (obj) => {
  try {
    const result = await axios.post(
      `http://localhost:3005/workPlacesInfo`,
      obj
    );
    return result;
  } catch (err) {
    return err;
  }
};
const editWorkPlace = async (id, obj) => {
  try {
    const result = await axios.put(
      `http://localhost:3005/workPlacesInfo/${id}`,
      obj
    );
    return result;
  } catch (err) {
    return err;
  }
};
const addNewEducation = async (obj) => {
  try {
    const result = await axios.post(
      `http://localhost:3006/educationsInfo`,
      obj
    );
    return result;
  } catch (err) {
    return err;
  }
};
const editEducation = async (id, obj) => {
  try {
    const result = await axios.put(
      `http://localhost:3006/educationsInfo/${id}`,
      obj
    );
    return result;
  } catch (err) {
    return err;
  }
};
const addNewResident = async (obj) => {
  try {
    const result = await axios.post(`http://localhost:3007/residentInfo`, obj);
    return result;
  } catch (err) {
    return err;
  }
};
const editResident = async (id, obj) => {
  try {
    const result = await axios.put(
      `http://localhost:3007/residentInfo/${id}`,
      obj
    );
    return result;
  } catch (err) {
    return err;
  }
};
const addNewWebsite = async (obj) => {
  try {
    const result = await axios.post(`http://localhost:3008/websites`, obj);
    return result;
  } catch (err) {
    return err;
  }
};
const editWebsite = async (id, obj) => {
  try {
    const result = await axios.put(`http://localhost:3008/websites/${id}`, obj);
    return result;
  } catch (err) {
    return err;
  }
};
const getWorkPlaces = async () => {
  try {
    const result = await userProfileApi.get(
      `http://localhost:3005/workPlacesInfo`
    );
    return result;
  } catch (err) {
    return err;
  }
};
const fetchSingleUser = (slug) => {
  return new Promise((resolve, reject) => {
    api("GET", "users", "", "", `${slug}`)
      .then((response) => {
        resolve(response.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
const fetchAllGalleryImages = (slug) => {
  return new Promise((resolve, reject) => {
    api("GET", "users", "", "", `${slug}/gallery-images`)
      .then((response) => {
        resolve(response.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
const fetchAllVideos = (slug) => {
  return new Promise((resolve, reject) => {
    api("GET", "users", "", "", `${slug}/video-links`)
      .then((response) => {
        resolve(response.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
const updateProfilePicture = (req) => {
  return new Promise((resolve, reject) => {
    api("PATCH", "user/display-picture", "token", req)
      .then((response) => {
        resolve(response.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
const updateCoverPicture = (req) => {
  return new Promise((resolve, reject) => {
    api("PATCH", "user/cover-picture", "token", req)
      .then((response) => {
        resolve(response.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
const updateGallaryImages = (req) => {
  return new Promise((resolve, reject) => {
    api("PATCH", "user/gallery-images", "token", req)
      .then((response) => {
        resolve(response.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
const updateVedioGallery = (req) => {
  return new Promise((resolve, reject) => {
    api("PATCH", "user/video-links", "token", req)
      .then((response) => {
        resolve(response.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
export const userProfileService = {
  fetchSingleUser,
  fetchAllGalleryImages,
  fetchAllVideos,
  updateProfilePicture,
  updateCoverPicture,
  updateGallaryImages,
  updateVedioGallery,
  getProfileInfo,
  addNewWorkPlace,
  getWorkPlaces,
  editWorkPlace,
  addNewEducation,
  editEducation,
  addNewResident,
  editResident,
  addNewWebsite,
  editWebsite,
};
