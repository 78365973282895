import React, { useMemo, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import ProfileHeader from "../../../../components/profile-header";
import OrganizationCard from "./OrganizationCard";
import org from "../../../../assets/images/organizations/cover.png";
import "../../app/css/custom.css";
import SearchBar from "../../../../components/search/SearchBar";
import loader from "../../../../assets/images/page-img/page-load-loader.gif";
import { useEffect } from "react";

export default function OrganizationList({
  organizations,
  searchQuery,
  onChange,
  onSubmit,
  loading,
  error,
  orgRef,
}) {
  const [organizationList, setOrganizationList] = useState([]);
  useMemo(() => setOrganizationList(organizations), [organizations]);
  useEffect(() => {
    // 👇️ scroll to top on page load
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);
  return (
    <>
      <div id="content-page" className="content-page">
        <Container>
          <ProfileHeader img={org} />
          <SearchBar
            placeHolder="Search by organization name ..."
            searchQuery={searchQuery}
            onChange={onChange}
            onSubmit={onSubmit}
          />
          <Row>
            {organizationList.length > 0 ? (
              organizationList?.map((organization, index) => {
                const isLastElement = organizationList.length === index + 1;
                return isLastElement ? (
                  <div key={`org-${index}`} ref={orgRef}>
                    <Col md={3}>
                      <OrganizationCard organization={organization} />
                    </Col>
                  </div>
                ) : (
                  <Col md={3} key={`org-${index}`}>
                    <OrganizationCard organization={organization} />
                  </Col>
                );
              })
            ) : (
              <div>Organizations not found.</div>
            )}
          </Row>
          {loading && (
            <div className="col-sm-12 text-center">
              <img src={loader} alt="loader" style={{ height: "100px" }} />
            </div>
          )}
          {error && <p>{error}</p>}
        </Container>
      </div>
    </>
  );
}
