import React from "react";
import Card from "../../../components/Card";
import { Container, Row, Col, Form, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import profilebg7 from "../../../assets/images/page-img/profile-bg7.jpg";
import ProfileHeader from "../../../components/profile-header";
import { useState, useEffect } from "react";
import { productService } from "../../../services/productService";
import "./product.css";
const CatergoryGrid = () => {
  const [products, setProducts] = useState();
  useEffect(() => {
    const fetchProducts = async () => {
      const res = await productService.fetchProducts();
      setProducts(res.data);
    };
    fetchProducts();
  }, []);
  const calculateFinalPrice = (price, discount) => {
    let finalPrice = price - (discount * price) / 100;
    return Math.round(finalPrice);
  };
  return (
    <>
      <ProfileHeader title="Store Category" img={profilebg7} />
      <div id="content-page" className="content-page">
        <Container>
          <Row>
            <Col lg="4">
              <Card>
                <Card.Header className="d-flex justify-content-between">
                  <div className="header-title">
                    <h4 className="card-title">Store Categories</h4>
                  </div>
                </Card.Header>
                <Card.Body>
                  <Form.Check className="form-check d-block mb-2">
                    <Form.Check.Input type="checkbox" id="customCheck1" />
                    <Form.Check.Label htmlFor="customCheck1">
                      All Categories
                    </Form.Check.Label>
                  </Form.Check>
                  <Form.Check className="form-check d-block mb-2">
                    <Form.Check.Input type="checkbox" id="customCheck2" />
                    <Form.Check.Label htmlFor="customCheck2">
                      PSD Templates
                    </Form.Check.Label>
                  </Form.Check>
                  <Form.Check className="form-check d-block mb-2">
                    <Form.Check.Input type="checkbox" id="customCheck3" />
                    <Form.Check.Label htmlFor="customCheck3">
                      HTML Templates
                    </Form.Check.Label>
                  </Form.Check>
                  <Form.Check className="form-check d-block mb-2">
                    <Form.Check.Input type="checkbox" id="customCheck4" />
                    <Form.Check.Label htmlFor="customCheck4">
                      WP Templates
                    </Form.Check.Label>
                  </Form.Check>
                  <Form.Check className="form-check d-block mb-2">
                    <Form.Check.Input type="checkbox" id="customCheck5" />
                    <Form.Check.Label htmlFor="customCheck5">
                      Stream Packs
                    </Form.Check.Label>
                  </Form.Check>
                  <Form.Check className="form-check d-block">
                    <Form.Check.Input type="checkbox" id="customCheck6" />
                    <Form.Check.Label htmlFor="customCheck6">
                      Logos and Badges
                    </Form.Check.Label>
                  </Form.Check>
                </Card.Body>
              </Card>
              <Card>
                <Card.Header className="d-flex justify-content-between">
                  <div className="header-title">
                    <h4 className="card-title">Price Range</h4>
                  </div>
                </Card.Header>
                <Card.Body>
                  <div className="d-flex align-items-center">
                    <Form.Group className="form-group me-3">
                      <label htmlFor="name01">From:</label>
                      <Form.Control
                        type="text"
                        id="name01"
                        defaultValue="$  "
                      />
                    </Form.Group>
                    <Form.Group className="form-group">
                      <label htmlFor="to">To:</label>
                      <Form.Control type="text" id="to" defaultValue="$" />
                    </Form.Group>
                  </div>
                  <Button type="submit" variant="primary">
                    Apply
                  </Button>
                </Card.Body>
              </Card>
              <Card>
                <Card.Header className="d-flex justify-content-between">
                  <div className="header-title">
                    <Card.Header.Title as="h4">Reviews</Card.Header.Title>
                  </div>
                </Card.Header>
                <Card.Body>
                  <div className="d-flex align-items-center justify-content-between mb-3">
                    <Form.Check className="form-check d-flex align-items-center">
                      <Form.Check.Input
                        type="radio"
                        name="bsradio"
                        id="radio1"
                        defaultChecked
                      />
                      <Form.Check.Label htmlFor="radio1" className=" mb-0 ps-2">
                        <b>Al Reviews</b>
                      </Form.Check.Label>
                    </Form.Check>
                    <h6 className="text-primary">6870</h6>
                  </div>
                  <div className="d-flex align-items-center justify-content-between mb-2">
                    <Form.Check className="form-check d-inline-block">
                      <Form.Check.Input
                        type="radio"
                        name="bsradio"
                        id="radio2"
                        defaultChecked
                      />
                      <Form.Check.Label htmlFor="radio2" className=" mb-0">
                        <span className=" text-warning d-block line-height">
                          <i className="material-symbols-outlined md-18">
                            star
                          </i>
                          <i className="material-symbols-outlined md-18">
                            star
                          </i>
                          <i className="material-symbols-outlined md-18">
                            star
                          </i>
                          <i className="material-symbols-outlined md-18">
                            star
                          </i>
                          <i className="material-symbols-outlined md-18">
                            star
                          </i>
                        </span>
                      </Form.Check.Label>
                    </Form.Check>
                    <h6 className="text-primary">1023</h6>
                  </div>
                  <div className="d-flex align-items-center justify-content-between mb-2">
                    <Form.Check className="form-check d-inline-block">
                      <Form.Check.Input
                        type="radio"
                        name="bsradio"
                        id="radio3"
                        defaultChecked
                      />
                      <Form.Check.Label htmlFor="radio3" className=" mb-0">
                        <span className="text-warning d-block line-height">
                          <i className="material-symbols-outlined md-18">
                            star
                          </i>
                          <i className="material-symbols-outlined md-18">
                            star
                          </i>
                          <i className="material-symbols-outlined md-18">
                            star
                          </i>
                          <i className="material-symbols-outlined md-18">
                            star
                          </i>
                          <i className="material-symbols-outlined md-18">
                            star_half
                          </i>
                        </span>
                      </Form.Check.Label>
                    </Form.Check>
                    <h6 className="text-primary">1000</h6>
                  </div>
                  <div className="d-flex align-items-center justify-content-between mb-2">
                    <Form.Check className="form-check d-inline-block">
                      <Form.Check.Input
                        type="radio"
                        name="bsradio"
                        id="radio4"
                        defaultChecked
                      />
                      <Form.Check.Label htmlFor="radio4" className="mb-0">
                        <span className="text-warning d-block line-height">
                          <i className="material-symbols-outlined md-18">
                            star
                          </i>
                          <i className="material-symbols-outlined md-18">
                            star
                          </i>
                          <i className="material-symbols-outlined md-18">
                            star
                          </i>
                          <i className="material-symbols-outlined md-18">
                            star
                          </i>
                          <i className="material-symbols-outlined md-18">
                            star
                          </i>
                        </span>
                      </Form.Check.Label>
                    </Form.Check>
                    <h6 className="text-primary">982</h6>
                  </div>
                  <div className="d-flex align-items-center justify-content-between mb-2">
                    <Form.Check className="form-check d-inline-block">
                      <Form.Check.Input
                        type="radio"
                        name="bsradio"
                        id="radio5"
                        defaultChecked
                      />
                      <Form.Check.Label htmlFor="radio5" className=" mb-0">
                        <span className="text-warning d-block line-height">
                          <i className="material-symbols-outlined md-18">
                            star
                          </i>
                          <i className="material-symbols-outlined md-18">
                            star
                          </i>
                          <i className="material-symbols-outlined md-18">
                            star
                          </i>
                          <i className="material-symbols-outlined md-18">
                            star
                          </i>
                          <i className="material-symbols-outlined md-18">
                            star
                          </i>
                        </span>
                      </Form.Check.Label>
                    </Form.Check>
                    <h6 className="text-primary">204</h6>
                  </div>
                  <div className="d-flex align-items-center justify-content-between mb-2">
                    <Form.Check className="form-check d-inline-block">
                      <Form.Check.Input
                        type="radio"
                        name="bsradio"
                        id="radio6"
                        defaultChecked
                      />
                      <Form.Check.Label htmlFor="radio6" className=" mb-0">
                        <span className="text-warning d-block line-height">
                          <i className="material-symbols-outlined md-18">
                            star
                          </i>
                          <i className="material-symbols-outlined md-18">
                            star
                          </i>
                          <i className="material-symbols-outlined md-18">
                            star
                          </i>
                          <i className="material-symbols-outlined md-18">
                            star
                          </i>
                          <i className="material-symbols-outlined md-18">
                            star
                          </i>
                        </span>
                      </Form.Check.Label>
                    </Form.Check>
                    <h6 className="text-primary">50</h6>
                  </div>
                  <div className="d-flex align-items-center justify-content-between">
                    <Form.Check className="form-check d-inline-block">
                      <Form.Check.Input
                        type="radio"
                        name="bsradio"
                        id="radio7"
                        defaultChecked
                      />
                      <Form.Check.Label htmlFor="radio7" className="mb-0">
                        <span className="text-warning d-block line-height">
                          <i className="material-symbols-outlined md-18">
                            star
                          </i>
                          <i className="material-symbols-outlined md-18">
                            star
                          </i>
                          <i className="material-symbols-outlined md-18">
                            star
                          </i>
                          <i className="material-symbols-outlined md-18">
                            star
                          </i>
                          <i className="material-symbols-outlined md-18">
                            star
                          </i>
                        </span>
                      </Form.Check.Label>
                    </Form.Check>
                    <h6 className="text-primary">8</h6>
                  </div>
                </Card.Body>
              </Card>
            </Col>
            <Col lg="8">
              <Row>
                {products?.map((ele) => (
                  <Col sm="6" md="6" key={ele.id}>
                    <Card className="card-block card-stretch card-height product">
                      <Card.Body>
                        <div className="d-flex align-items-center justify-content-between pb-3">
                          <div className="d-flex align-items-center">
                            <img
                              className="img-fluid rounded-circle avatar-30"
                              src={ele.sellerProfileUrl}
                              alt=""
                            />
                            <div className="media-body ms-2">
                              <p className="mb-0 line-height">Posted By</p>
                              <h6>
                                <Link to="#">{ele.seller}</Link>
                              </h6>
                            </div>
                          </div>
                          <span className="text-warning d-block line-height">
                            <i className="material-symbols-outlined md-18">
                              star
                            </i>
                            <i className="material-symbols-outlined md-18">
                              star
                            </i>
                            <i className="material-symbols-outlined md-18">
                              star
                            </i>
                            <i className="material-symbols-outlined md-18">
                              star
                            </i>
                            <i className="material-symbols-outlined md-18">
                              star
                            </i>
                          </span>
                        </div>
                        <div className="image-block position-relative">
                          <img
                            src={ele.productUrl}
                            //  style={{
                            //    objectFit: "cover",
                            //    width: "100%",
                            //    height: "200px",
                            //  }}
                            className="productImg"
                            //  className="img-fluid w-100 h-100 rounded"
                            alt="product-img"
                          />
                          <div className="offer">
                            <div className="offer-title">{ele.discount}%</div>
                          </div>
                          <h6 className="price">
                            <span className="regular-price text-dark pr-2">
                              ${ele.price}
                            </span>
                            ${calculateFinalPrice(ele.price, ele.discount)}
                          </h6>
                        </div>
                        <div className="product-description mt-3">
                          <h6 className="mb-1">{ele.name}</h6>
                          <span className="categry text-primary ps-3 mb-2 position-relative">
                            {ele.category}
                          </span>
                          <p className="mb-0">{ele.description}</p>
                        </div>
                      </Card.Body>
                    </Card>
                  </Col>
                ))}
              </Row>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default CatergoryGrid;
