
import {Link} from 'react-router-dom'
import {Container, Row, Col} from 'react-bootstrap'
import Card from '../../../components/Card'
import React, { useState, useEffect } from 'react';
import { productService } from './../../../services/store.service';



//img 
import storec1 from '../../../assets/images/store/c1.jpg'
import storec2 from '../../../assets/images/store/c2.jpg'
import storec3 from '../../../assets/images/store/c3.jpg'
import storec4 from '../../../assets/images/store/c4.jpg'

import user1 from '../../../assets/images/user/01.jpg'
import user2 from '../../../assets/images/user/02.jpg'
import user3 from '../../../assets/images/user/03.jpg'
import user4 from '../../../assets/images/user/04.jpg'
import user5 from '../../../assets/images/user/05.jpg'
import user6 from '../../../assets/images/user/06.jpg'
import user7 from '../../../assets/images/user/07.jpg'
import user8 from '../../../assets/images/user/08.jpg'
import user9 from '../../../assets/images/user/09.jpg'

import store1 from '../../../assets/images/store/01.jpg'

import store2 from '../../../assets/images/store/02.jpg'
import store3 from '../../../assets/images/store/03.jpg'
import store4 from '../../../assets/images/store/04.jpg'
import store5 from '../../../assets/images/store/05.jpg'
import store6 from '../../../assets/images/store/06.jpg'
import store7 from '../../../assets/images/store/07.jpg'
import store8 from '../../../assets/images/store/08.jpg'
import store9 from '../../../assets/images/store/09.jpg'

import profilebg7 from '../../../assets/images/page-img/profile-bg7.jpg'
//profile-header
import ProfileHeader from '../../../components/profile-header'
import ComingSoon from '../extrapages/comingsoon';

const store = [
    {
      "id": 1,
      "name": "Apple iPhone 12 Pro",
      "description": "The iPhone 12 Pro features a 6.1-inch Super Retina XDR display, Ceramic Shield with four times better drop performance, and A14 Bionic chip for ultimate performance.",
      "price": 1099,
      "discount": 12,
      "image": "store1",
      "seller": "Robert",
      "category": "Electronics",
      "sellerRating": 3.5,
      "sellerProfileUrl": "https://images.unsplash.com/photo-1633332755192-727a05c4013d?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=580&q=80",
      "productUrl": "https://images.unsplash.com/photo-1610945265064-0e34e5519bbf?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=870&q=80"
    },
    {
      "id": 2,
      "name": "Samsung Galaxy S21 Ultra",
      "description": "The Galaxy S21 Ultra features a 6.8-inch Dynamic AMOLED 2X display, 108MP camera system, and 5G connectivity.",
      "price": 1199,
      "discount": 12,
      "seller": "Jane",
      "category": "Electronics",
      "sellerRating": 4,
      "sellerProfileUrl": "https://images.unsplash.com/photo-1535713875002-d1d0cf377fde?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=580&q=80",
      "productUrl": "https://images.unsplash.com/photo-1610792516307-ea5acd9c3b00?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=870&q=80"
    },
    {
      "id": 3,
      "name": "Samsung Galaxy S21 plus",
      "description": "The Galaxy S21 Ultra features a 6.8-inch Dynamic AMOLED 2X display, 108MP camera system, and 5G connectivity.",
      "price": 1900,
      "discount": 20,
      "seller": "James",
      "category": "Electronics",
      "sellerRating": 3,
      "sellerProfileUrl": "https://images.unsplash.com/photo-1535713875002-d1d0cf377fde?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=580&q=80",
      "productUrl": "https://images.unsplash.com/photo-1610945265064-0e34e5519bbf?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=870&q=80"
    },
    {
        "id": 1,
        "name": "Apple iPhone 12 Pro",
        "description": "The iPhone 12 Pro features a 6.1-inch Super Retina XDR display, Ceramic Shield with four times better drop performance, and A14 Bionic chip for ultimate performance.",
        "price": 1099,
        "discount": 12,
        "image": "store1",
        "seller": "Robert",
        "category": "Electronics",
        "sellerRating": 3.5,
        "sellerProfileUrl": "https://images.unsplash.com/photo-1633332755192-727a05c4013d?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=580&q=80",
        "productUrl": "https://images.unsplash.com/photo-1610945265064-0e34e5519bbf?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=870&q=80"
      },
      {
        "id": 2,
        "name": "Samsung Galaxy S21 Ultra",
        "description": "The Galaxy S21 Ultra features a 6.8-inch Dynamic AMOLED 2X display, 108MP camera system, and 5G connectivity.",
        "price": 1199,
        "discount": 12,
        "seller": "Jane",
        "category": "Electronics",
        "sellerRating": 4,
        "sellerProfileUrl": "https://images.unsplash.com/photo-1535713875002-d1d0cf377fde?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=580&q=80",
        "productUrl": "https://images.unsplash.com/photo-1610792516307-ea5acd9c3b00?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=870&q=80"
      },
      {
        "id": 3,
        "name": "Samsung Galaxy S21 plus",
        "description": "The Galaxy S21 Ultra features a 6.8-inch Dynamic AMOLED 2X display, 108MP camera system, and 5G connectivity.",
        "price": 1900,
        "discount": 20,
        "seller": "James",
        "category": "Electronics",
        "sellerRating": 3,
        "sellerProfileUrl": "https://images.unsplash.com/photo-1535713875002-d1d0cf377fde?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=580&q=80",
        "productUrl": "https://images.unsplash.com/photo-1610945265064-0e34e5519bbf?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=870&q=80"
      }
  ];

function ProductStore() {
    
    const [products, setProducts] = useState([]);
    console.log('sxssxsxsx',products)
    // useEffect(() => {
    //     fetch('http://localhost:3005/products')
    //         .then((response) => response.json())
    //         .then((data) => {
    //             // Do something with the data
    //             const dataArray = data;
    //             setProducts(dataArray);
    //             console.log('fttdf',dataArray)
    //         })
    //         .catch(() => {
    //             // Handle errors
    //             // console.error(error);
    //         });
    // }, []);


            useEffect(() => {
            setProducts(store);
            }, []);

    
    
    return (
        <>
         <ComingSoon></ComingSoon>
            {/* <ProfileHeader title="Store" img={profilebg7}/> */}
            
                <div id="content-page" className="content-page">
                    <Container>
                        <Row>
                            <Col lg="12">
                                <Card>
                                    <Card.Header className=" d-flex justify-content-between">
                                        <div className="header-title">
                                            <h4>Store Categories</h4>
                                        </div>
                                    </Card.Header> 
                                    <Card.Body>
                                        <Row>
                                            <Col sm="6" md="6" lg="3" className=" mb-md-2 mb-2">
                                                <Link to="/dashboards/store/store-category-grid"><img className="img-fluid rounded" src={storec1} alt="category-image1"/></Link>
                                            </Col>
                                            <Col sm="6" md="6" lg="3" className=" mb-md-2 mb-2">
                                                <Link to="/dashboards/store/store-category-grid"><img className="img-fluid rounded" src={storec2} alt="category-image2"/></Link>
                                            </Col>
                                            <Col sm="6" md="6" lg="3" className=" mb-md-2 mb-2">
                                                <Link to="/dashboards/store/store-category-grid"><img className="img-fluid rounded" src={storec3} alt="category-image3"/></Link>
                                            </Col>
                                            <Col sm="6" md="6" lg="3"  className=" mb-md-2 mb-2" >
                                                <Link to="/dashboards/store/store-category-grid"><img className="img-fluid rounded" src={storec4} alt="category-image4"/></Link>
                                            </Col>
                                        </Row>
                                    </Card.Body>
                                </Card>
                            </Col>
                            <Col lg="12">
                                <Card className=" shadow-none p-0">
                                    <Card.Header className=" d-flex justify-content-between rounded border-bottom-0">
                                        <div className="header-title">
                                            <h4>Store Categories</h4>
                                        </div>
                                    </Card.Header> 
                                </Card>
                                <Row> 
                                {products.map((product) => (                       
                                    <Col sm="6" md="6" lg="4">
                                        <Card className="card-block card-stretch card-height product">
                                            <Card.Body>
                                                <div className="d-flex align-items-center justify-content-between pb-3">
                                                    <div className="d-flex align-items-center">
                                                        <img className="img-fluid rounded-circle avatar-30" src={user1} alt=""/>
                                                        <div className="ms-2">
                                                            <p className="mb-0 line-height">Posted By</p>
                                                            <h6><Link to="#">{product.name}</Link></h6>
                                                        </div>
                                                    </div>
                                                    <div className="d-block line-height">
                                                        <span className=" text-warning">
                                                            <i className="material-symbols-outlined md-18">star</i>
                                                            <i className="material-symbols-outlined md-18">star</i>
                                                            <i className="material-symbols-outlined md-18">star</i>
                                                            <i className="material-symbols-outlined md-18">star</i>
                                                            <i className="material-symbols-outlined md-18">star</i>
                                                        </span>                                             
                                                    </div>
                                                </div>
                                                <div className="image-block position-relative">
                                                    <img src={product.productUrl} className="img-fluid w-100 rounded" alt="product-img"/>
                                                    <div className="offer">
                                                        <div className="offer-title">20%</div>
                                                    </div>
                                                    <h6 className="price"><span className="regular-price text-dark pr-2">{product.price}</span>$75.00</h6>
                                                </div>
                                                <div className="product-description mt-3">
                                                    <h6 className="mb-1">Flaming Skull Team Logo</h6>
                                                    <span className="categry text-primary ps-3 mb-2 position-relative">Logo and badges</span>
                                                    <p className="mb-0">{product.description}</p>
                                                </div>
                                            </Card.Body>
                                        </Card>
                                    </Col>
                                ))}
                                </Row>
                            </Col>
                        </Row>
                    </Container>
                </div>
        </>
    )
}

export default ProductStore