import { useEffect, useState } from "react";
import "./lightBox.css";
import { useGlobal } from "../../hooks/useGlobal";
import Spiner from "../spinner/Spiner";

const LightBox = ({ images }) => {
  const { lightbox, closeLightbox } = useGlobal();
  const [slideNumber, setSlideNumber] = useState(lightbox.startIndex);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    setLoading(true); // Show the loading screen
    setSlideNumber(lightbox.startIndex);
    const timeout = setTimeout(() => {
      setLoading(false); // Hide the loading screen
    }, 600);

    return () => {
      clearTimeout(timeout);
    }; // Clean up the timeout
  }, [lightbox.startIndex]);
  // Previous Image
  const prevSlide = () => {
    slideNumber === 0
      ? setSlideNumber(images.length - 1)
      : setSlideNumber(slideNumber - 1);
  };

  // Next Image
  const nextSlide = () => {
    slideNumber + 1 === images.length
      ? setSlideNumber(0)
      : setSlideNumber(slideNumber + 1);
  };
  const getYoutubeVideoId = (url) => {
    const videoIdRegex =
      /(?:youtu\.be\/|youtube\.com\/(?:embed\/|v\/|watch\?v=|watch\?.+&v=))([\w-]{11})/i;
    const match = url.match(videoIdRegex);
    return match && match[1];
  };
  return (
    <div>
      {lightbox.open && (
        <div className="sliderWrap">
          {loading ? (
            <Spiner />
          ) : (
            <div>
              <span className="lightbox-button text">
                {slideNumber + 1} / {images.length}
              </span>
              <span
                className="lightbox-button close-button"
                onClick={closeLightbox}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                >
                  <path
                    className="fslightbox-svg-path"
                    d="M11.42 10l6.29-6.29a1 1 0 0 0-1.42-1.42L10 8.58 3.71 2.29a1 1 0 1 0-1.42 1.42L8.58 10l-6.29 6.29a1 1 0 0 0 1.42 1.42L10 11.42l6.29 6.29a1 1 0 0 0 1.42-1.42z"
                  />
                </svg>
              </span>
              <button
                className="lightbox-button prev-button"
                onClick={prevSlide}
              >
                <svg
                  width="20px"
                  height="20px"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    className="fslightbox-svg-path"
                    d="M18.271,9.212H3.615l4.184-4.184c0.306-0.306,0.306-0.801,0-1.107c-0.306-0.306-0.801-0.306-1.107,0L1.21,9.403C1.194,9.417,1.174,9.421,1.158,9.437c-0.181,0.181-0.242,0.425-0.209,0.66c0.005,0.038,0.012,0.071,0.022,0.109c0.028,0.098,0.075,0.188,0.142,0.271c0.021,0.026,0.021,0.061,0.045,0.085c0.015,0.016,0.034,0.02,0.05,0.033l5.484,5.483c0.306,0.307,0.801,0.307,1.107,0c0.306-0.305,0.306-0.801,0-1.105l-4.184-4.185h14.656c0.436,0,0.788-0.353,0.788-0.788S18.707,9.212,18.271,9.212z"
                  ></path>
                </svg>
              </button>

              <button
                className="lightbox-button next-button"
                onClick={nextSlide}
              >
                <svg
                  width="20px"
                  height="20px"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    className="fslightbox-svg-path"
                    d="M1.729,9.212h14.656l-4.184-4.184c-0.307-0.306-0.307-0.801,0-1.107c0.305-0.306,0.801-0.306,1.106,0l5.481,5.482c0.018,0.014,0.037,0.019,0.053,0.034c0.181,0.181,0.242,0.425,0.209,0.66c-0.004,0.038-0.012,0.071-0.021,0.109c-0.028,0.098-0.075,0.188-0.143,0.271c-0.021,0.026-0.021,0.061-0.045,0.085c-0.015,0.016-0.034,0.02-0.051,0.033l-5.483,5.483c-0.306,0.307-0.802,0.307-1.106,0c-0.307-0.305-0.307-0.801,0-1.105l4.184-4.185H1.729c-0.436,0-0.788-0.353-0.788-0.788S1.293,9.212,1.729,9.212z"
                  ></path>
                </svg>
              </button>
              <div className="fullScreenImage">
                {images[slideNumber]?.video_url ? (
                  <iframe
                    width="560"
                    height="315"
                    src={`https://www.youtube.com/embed/${getYoutubeVideoId(
                      images[slideNumber]?.video_url
                    )}`}
                    title="YouTube video player"
                    allowFullScreen
                  ></iframe>
                ) : (
                  <img src={images[slideNumber]?.large_img_url} alt="" />
                )}
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
};
export default LightBox;
