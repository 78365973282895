/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useMemo, useState } from "react";
import { Row, Col, Container, Nav, Tab } from "react-bootstrap";
import { HiClock } from "react-icons/hi";
import { MdOutlineCampaign } from "react-icons/md";
import { AiOutlineUser } from "react-icons/ai";
import Card from "../../../components/Card";
import { Link, Outlet, useNavigate, useParams } from "react-router-dom";
import ProfileHeader from "../../../components/user-profile/ProfileHeader";
import "../../../assets/css/style.css";
import { RiUserFollowFill } from "react-icons/ri";
import Newsfeed from "../../dashboard/components/Newsfeed";
import { useAuth } from "../../../hooks/useAuth";
import { VscFileMedia } from "react-icons/vsc";
import { useTab } from "../../../hooks/useTab";
import { profileService } from "../../../services/user.service";
import { useGlobal } from "../../../hooks/useGlobal";
import LightBox from "../../../components/lightbox/lightBox";

const UserProfile = () => {
  const { activeTab, handleTabChange, handleSubTabChange } = useTab();
  const navigate = useNavigate();
  const params = useParams();
  const { user } = useAuth();

  const [posts, setPosts] = useState([]);
  const [images, setImages] = useState([]);

  const getPosts = async () => {
    try {
      const res = await profileService.getUserPosts(params.userName);
      setPosts(res.posts);
    } catch (err) {
      console.log(err);
    }
  };
  const visibility = useMemo(() => {
    return params.userName === user.username;
  }, [params.userName]);
  const { openLightbox } = useGlobal();
  const getUserImages = async () => {
    try {
      const res = await profileService.getUserImages(params.userName);
      setImages(res.gallery_images);
    } catch (err) {
      return err;
    }
  };
  useEffect(() => {
    getPosts();
    getUserImages();
  }, [params.userName]);
  return (
    <>
      <Container>
        <Row>
          <Col sm={12}>
            <ProfileHeader visibility={visibility} />
          </Col>
        </Row>
        <Tab.Container
          id="left-tabs-example"
          activeKey={activeTab}
          onSelect={handleTabChange}
        >
          <Card className="p-0">
            <Card.Body className="p-0 ">
              <div className="d-flex profile-feed-items  align-items-center justify-content-around">
                <Nav.Item
                  as="li"
                  className={`p-0 ${activeTab === "first" ? "active-tab" : ""}`}
                >
                  <Nav.Link
                    eventKey="first"
                    className=" text-center p-3"
                    onClick={() => {
                      navigate(`/my-profile/${params.userName}/timeline`);
                    }}
                  >
                    <HiClock size={20} className="icon" />
                    <span className="mobile-text  ms-3 user-tabs ">
                      Timeline
                    </span>
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item
                  as="li"
                  className={`p-0 ${
                    activeTab === "second" ? "active-tab" : ""
                  }`}
                >
                  <Nav.Link
                    role="button"
                    eventKey="second"
                    className="text-center p-3"
                    onClick={() => {
                      navigate(
                        `/my-profile/${params.userName}/about/basicInfo`
                      );
                      handleSubTabChange("firstSubTab");
                    }}
                  >
                    <AiOutlineUser size={20} className="icon" />
                    <span className="mobile-text ms-3 user-tabs ">About</span>
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item
                  as="li"
                  className={`p-0 ${activeTab === "third" ? "active-tab" : ""}`}
                >
                  <Nav.Link
                    role="button"
                    eventKey="third"
                    className="text-center p-3"
                    onClick={() => {
                      navigate(
                        `/my-profile/${params.userName}/follows/friends`
                      );
                      handleSubTabChange("firstSubTab");
                    }}
                  >
                    <RiUserFollowFill size={20} className="icon" />
                    <span className="mobile-text ms-3 user-tabs">Follows</span>
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item
                  as="li"
                  className={`p-0 ${
                    activeTab === "fourth" ? "active-tab" : ""
                  }`}
                >
                  <Nav.Link
                    role="button"
                    eventKey="fourth"
                    className="text-center p-3"
                    onClick={() => {
                      navigate(`/my-profile/${params.userName}/medias`);
                    }}
                  >
                    <VscFileMedia size={20} className="icon" />
                    <span className="mobile-text ms-3  user-tabs">Medias</span>
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item
                  as="li"
                  className={`p-0 ${activeTab === "fifth" ? "active-tab" : ""}`}
                >
                  <Nav.Link
                    eventKey="fifth"
                    role="button"
                    className="text-center p-3"
                    onClick={() => {
                      navigate(`/my-profile/${params.userName}/campaigns`);
                    }}
                  >
                    <MdOutlineCampaign size={20} className="icon" />
                    <span className="mobile-text ms-3 user-tabs">
                      Campaigns
                    </span>
                  </Nav.Link>
                </Nav.Item>
              </div>
            </Card.Body>
          </Card>

          <Col sm={12}>
            <Tab.Content>
              <Tab.Pane eventKey="first">
                <Card.Body className=" p-0">
                  <Row>
                    <Col lg={4}>
                      <Card>
                        <div className="card-header d-flex justify-content-between">
                          <div className="header-title">
                            <h4 className="card-title">Photos</h4>
                          </div>
                        </div>
                        <Card.Body>
                          <ul className="profile-img-gallary p-0 m-0 list-unstyled">
                            {images.map((image, index) => (
                              <li style={{ height: "100%" }} key={index}>
                                <Link
                                  onClick={() => openLightbox(index)}
                                  to="#"
                                >
                                  <img
                                    loading="lazy"
                                    src={image.img_url}
                                    alt="gallary"
                                    className="img-fluid"
                                  />
                                </Link>
                              </li>
                            ))}
                          </ul>
                        </Card.Body>
                      </Card>
                    </Col>
                    <Col lg={8}>
                      <Newsfeed
                        posts={posts}
                        hideCreateOptions={{ organization: true }}
                        getPosts={getPosts}
                      />
                    </Col>
                  </Row>
                </Card.Body>
              </Tab.Pane>
            </Tab.Content>
            <LightBox images={images} />
            <Outlet />
          </Col>
        </Tab.Container>
      </Container>
    </>
  );
};
export default UserProfile;
