import React, { useEffect, useState } from "react";
import { Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import { FollowService } from "../../../../services/follow.service";
import { useAuth } from "../../../../hooks/useAuth";

const OragnizationLists = () => {
  const [orgLists, setOrgLists] = useState([]);
  const { user } = useAuth();
  const fetchFriendLists = async () => {
    try {
      const res = await FollowService.fetchOrganizationsFollowingByUser(
        user.id
      );
      setOrgLists(res?.user_organizations.organizations);
    } catch (err) {
      return err;
    }
  };

  useEffect(() => {
    fetchFriendLists();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Row>
      {orgLists?.map((ele, index) => (
        <div className="col-md-6 col-lg-6 mb-3" key={index}>
          <div className="iq-friendlist-block">
            <div className="d-flex align-items-center justify-content-between">
              <div className="d-flex align-items-center">
                <img
                  loading="lazy"
                  src={ele.organization.display_picture_media.img_url}
                  alt="profile-img"
                  className="img-fluid avatar-70"
                />
                <div className="friend-info ms-3">
                  <Link to={`/app/organizations/${ele.organization.slug}`}>
                    <p className="mb-0">
                      {ele.organization.name.toUpperCase()}
                    </p>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      ))}

      {orgLists?.length === 0 && (
        <div className="p-2 m-2">
          <h4>No Organizations Found</h4>
        </div>
      )}
    </Row>
  );
};

export default OragnizationLists;
