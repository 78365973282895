import React from "react";
import {
  Dropdown,
  Nav,
  Card,
  Container,
  Image,
  Spinner,
} from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";

import nlogo from "../../../../assets/images/healthier/zslogos.jpeg";
//image
import user1 from "../../../../assets/images/user/01.jpg"; //Modified by Darshan
//Componets
import { AiOutlineAudit, AiOutlineHome, AiOutlineTeam } from "react-icons/ai";
import { BiMenuAltLeft } from "react-icons/bi";

import CustomToggle from "../../../dropdowns";
import { authService } from "../../../../services/auth.service";
import { useAuth } from "../../../../hooks/useAuth";
import { useGlobal } from "../../../../hooks/useGlobal";
import { userProfileService } from "../../../../services/userProfile.service";
import { useEffect } from "react";
import { useCallback } from "react";
import { useMyProfileMedia } from "../../../../hooks/useMyProfileMedia";
import { useFollow } from "../../../../hooks/useFollow";
import { FollowService } from "../../../../services/follow.service";
import { useTab } from "../../../../hooks/useTab";

const Header = () => {
  const navigate = useNavigate();
  const minisidebar = () => {
    document.getElementsByTagName("ASIDE")[0].classList.toggle("sidebar-mini");
  };
  const {
    receivedFriendRequest,
    setReceivedFriendRequest,
    orgReqs,
    setOrgReqs,
  } = useFollow();
  const { handleTabChange } = useTab();
  const { user } = useAuth();
  const { profileData, setProfileData } = useMyProfileMedia();
  const { loading, setLoading } = useGlobal();

  const fetchSingleUserInfo = useCallback(async () => {
    try {
      const res = await userProfileService.fetchSingleUser(user?.username);
      setProfileData({
        userName: res.name,
        displayImg: res?.display_picture,
        coverImg: res?.cover_picture,
      });
    } catch (err) {
      console.log(err);
    }
  }, [user?.username, setProfileData]);
  const logout = () => {
    setLoading(true);
    authService
      .logout()
      .then(() => {
        setLoading(false);
        navigate("/auth/sign-in");
      })
      .catch((err) => console.log(err));
  };
  useEffect(() => {
    if (user?.username) {
      fetchSingleUserInfo();
    }
  }, [fetchSingleUserInfo, user?.username]);
  const fetchReceviedFrReq = async () => {
    try {
      const result = await FollowService.fetchRequests(
        `${user.id}?type=followers&request_type=requested`
      );
      setReceivedFriendRequest(result?.connections?.followers);
    } catch (err) {
      return err;
    }
  };
  const fetchFollowReqsforMyOrgs = async () => {
    try {
      const response = await FollowService.fetchFollowReqsforMyOrgs(
        "status=pending"
      );
      setOrgReqs(response?.organizations);
    } catch (err) {
      console.log(err);
    }
  };
  useEffect(() => {
    fetchReceviedFrReq();
    fetchFollowReqsforMyOrgs();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const updateRequest = async (status, reqId) => {
    try {
      await FollowService.updateReqest(
        `update?follower_id=${reqId}&status=${status}`
      );
      setReceivedFriendRequest(
        receivedFriendRequest.filter((ele) => ele.user.id !== reqId)
      );
    } catch (err) {
      return err;
    }
  };

  return (
    <>
      <div className="iq-top-navbar">
        <Nav
          expand="lg"
          variant="light"
          className="nav navbar navbar-expand-lg navbar-light iq-navbar p-lg-0"
        >
          <Container fluid className="navbar-inner">
            <div
              className="d-flex align-items-center gap-3  pb-2 pb-lg-0"
              // ref={sidebarRef}
            >
              <Link
                to="/"
                className="d-flex align-items-center gap-2 iq-header-logo"
              >
                <img src={nlogo} style={{ height: 50 }} alt="logo" />

                <h3
                  className="logo-title d-none d-sm-block"
                  data-setting="app_name"
                >
                  Zone Society
                </h3>
              </Link>
              <Link
                to="#"
                className="sidebar-toggle"
                data-toggle="sidebar"
                data-active="true"
                onClick={minisidebar}
              >
                <BiMenuAltLeft size={25} />
              </Link>
            </div>

            {/* <div className="iq-search-bar device-search  position-relative">
              <form
                action="#"
                className="searchbox"
                onClick={handleShow}
                data-bs-toggle="modal"
                data-bs-target="#exampleModalFullscreenSm"
              >
                <Link className="search-link d-none d-lg-block" to="/">
                  <span className="material-symbols-outlined">search</span>
                </Link>
                <Form.Control
                  type="text"
                  className="text search-input form-control bg-soft-primary  d-none d-lg-block"
                  placeholder="Search here..."
                />
                <Link
                  className="d-lg-none d-flex d-none d-lg-block"
                  to="/"
                  onClick={handleShow}
                  data-bs-toggle="modal"
                  data-bs-target="#exampleModalFullscreenSm"
                >
                  <span className="material-symbols-outlined">search</span>
                </Link>
              </form>

              <Modal
                show={show}
                onHide={handleClose}
                className="search-modal"
                id="post-modal"
              >
                <div className="modal-dialog modal-fullscreen-lg-down m-0">
                  <Modal.Header className="py-2">
                    <div className="d-flex align-items-center justify-content-between d-lg-none w-100">
                      <form
                        action="#"
                        className="searchbox w-50"
                        data-bs-toggle="modal"
                        data-bs-target="#exampleModalFullscreenSm"
                        onClick={handleShow}
                      >
                        <Link className="search-link" to="/">
                          <span className="material-symbols-outlined">
                            search
                          </span>
                        </Link>

                        <Form.Control
                          type="text"
                          className="text search-input bg-soft-primary"
                          placeholder="Search here..."
                        />
                      </form>

                      <Link
                        to="/"
                        className="material-symbols-outlined text-dark"
                        onClick={handleClose}
                      >
                        close
                      </Link>
                    </div>
                    <div className="d-flex align-items-center justify-content-between ms-auto w-100">
                      <h5 className=" h4" id="exampleModalFullscreenLabel">
                        Recent
                      </h5>

                      <Link to="/" className="text-dark">
                        Clear All
                      </Link>
                    </div>
                  </Modal.Header>
                  <Modal.Body className="p-0">
                    <div className="d-flex d-lg-none align-items-center justify-content-between w-100 p-3 pb-0">
                      <h5 className=" h4" id="exampleModalFullscreenLabel">
                        Recent
                      </h5>

                      <Link to="/" className="text-dark">
                        Clear All
                      </Link>
                    </div>
                    <div className="d-flex align-items-center border-bottom search-hover py-2 px-3">
                      <div className="flex-shrink-0">
                        <Image
                          className="align-self-center img-fluid avatar-50 rounded-pill"
                          src={user6}
                          alt=""
                          loading="lazy"
                        />
                      </div>

                      <div className="d-flex flex-column ms-3">
                        <Link to="/" className="h5">
                          Paige Turner
                        </Link>

                        <span>Paige001</span>
                      </div>

                      <div className="d-flex align-items-center ms-auto">
                        <Link to="/" className="me-3 d-flex align-items-center">
                          <small>Follow</small>{" "}
                        </Link>

                        <Link
                          to="/"
                          className="material-symbols-outlined text-dark"
                        >
                          close
                        </Link>
                      </div>
                    </div>
                    <div className="d-flex align-items-center border-bottom search-hover py-2 px-3">
                      <div className="flex-shrink-0">
                        <Image
                          className="align-self-center img-fluid avatar-50 rounded-pill"
                          src={user7}
                          alt=""
                          loading="lazy"
                        />
                      </div>

                      <div className="d-flex flex-column ms-3">
                        <Link to="/" className="h5">
                          Monty Carlo
                        </Link>

                        <span>Carlo.m</span>
                      </div>

                      <div className="d-flex align-items-center ms-auto">
                        <Link to="/" className="me-3 d-flex align-items-center">
                          <small>Unfollow</small>{" "}
                        </Link>

                        <Link
                          to="/"
                          className="material-symbols-outlined text-dark"
                        >
                          close
                        </Link>
                      </div>
                    </div>
                    <div className="d-flex align-items-center search-hover py-2 px-3 border-bottom">
                      <div className="flex-shrink-0">
                        <Image
                          className="align-self-center img-fluid avatar-50 rounded-pill"
                          src={user8}
                          alt=""
                          loading="lazy"
                        />
                      </div>

                      <div className="d-flex flex-column ms-3">
                        <Link to="/" className="h5">
                          Paul Molive
                        </Link>

                        <span>Paul.45</span>
                      </div>

                      <div className="d-flex align-items-center ms-auto">
                        <Link to="/" className="me-3 d-flex align-items-center">
                          <small>Request</small>{" "}
                        </Link>

                        <Link
                          to="/"
                          className="material-symbols-outlined text-dark"
                        >
                          close
                        </Link>
                      </div>
                    </div>
                    <div className="">
                      <h4 className="px-3 py-2">Suggestions</h4>

                      <div className="suggestion-card px-3 d-flex">
                        <div className="text-center story">
                          <div className="story-profile">
                            <Image
                              className="avatar-50 rounded-pill"
                              src={user8}
                              alt=""
                              loading="lazy"
                            />

                            <Link
                              to="/"
                              className="h6 mt-0 mt-lg-2 ms-3 ms-lg-0 text-ellipsis short-2 small"
                            >
                              Ammy Paul
                            </Link>
                          </div>

                          <Link
                            to="/"
                            className="d-lg-none align-items-center d-flex"
                          >
                            <small>Follow</small>{" "}
                          </Link>
                        </div>

                        <div className="text-center story">
                          <div className="story-profile">
                            <Image
                              className="avatar-50 rounded-pill"
                              src={user9}
                              alt=""
                              loading="lazy"
                            />

                            <Link
                              to="/"
                              className="h6 mt-0 mt-lg-2 ms-3 ms-lg-0 text-ellipsis short-2 small"
                            >
                              Roger Carlo
                            </Link>
                          </div>

                          <Link
                            to="/"
                            className="d-lg-none align-items-center d-flex"
                          >
                            <small>Unfollow</small>{" "}
                          </Link>
                        </div>

                        <div className="text-center story ">
                          <div className="story-profile">
                            <Image
                              className="avatar-50 rounded-pill"
                              src={user10}
                              alt=""
                              loading="lazy"
                            />

                            <Link
                              to="/"
                              className="h6 mt-0 mt-lg-2 ms-3 ms-lg-0 text-ellipsis short-2 small"
                            >
                              Justin Molive
                            </Link>
                          </div>

                          <Link
                            to="/"
                            className="d-lg-none align-items-center d-flex"
                          >
                            <small>Follow</small>{" "}
                          </Link>
                        </div>

                        <div className="text-center story">
                          <div className="story-profile ">
                            <Image
                              className="avatar-50 rounded-pill"
                              src={user11}
                              alt=""
                              loading="lazy"
                            />

                            <Link
                              to="/"
                              className="h6 mt-0 mt-lg-2 ms-3 ms-lg-0 text-ellipsis short-2 small"
                            >
                              Roy Fisher
                            </Link>
                          </div>

                          <Link
                            to="/"
                            className="d-lg-none align-items-center d-flex"
                          >
                            <small>Request</small>{" "}
                          </Link>
                        </div>

                        <div className="text-center story">
                          <div className="story-profile">
                            <Image
                              className="avatar-50 rounded-pill"
                              src={user12}
                              alt=""
                              loading="lazy"
                            />

                            <Link
                              to="/"
                              className="h6 mt-0 mt-lg-2 ms-3 ms-lg-0 text-ellipsis short-2 small"
                            >
                              Johan Carlo
                            </Link>
                          </div>

                          <Link
                            to="/"
                            className="d-lg-none align-items-center d-flex"
                          >
                            <small>Follow</small>{" "}
                          </Link>
                        </div>

                        <div className="text-center story">
                          <div className="story-profile">
                            <Image
                              className="avatar-50 rounded-pill"
                              src={user13}
                              alt=""
                              loading="lazy"
                            />

                            <Link
                              to="/"
                              className="h6 mt-0 mt-lg-2 ms-3 ms-lg-0 text-ellipsis short-2 small"
                            >
                              MedrLink Miles
                            </Link>
                          </div>

                          <Link
                            to="/"
                            className="d-lg-none align-items-center d-flex"
                          >
                            <small>Unfollow</small>{" "}
                          </Link>
                        </div>

                        <div className="text-center story">
                          <div className="story-profile">
                            <Image
                              className="avatar-50 rounded-pill"
                              src={user14}
                              alt=""
                              loading="lazy"
                            />

                            <Link
                              to="/"
                              className="h6 mt-0 mt-lg-2 ms-3 ms-lg-0 text-ellipsis short-2 small"
                            >
                              Aohan Paul
                            </Link>
                          </div>

                          <Link
                            to="/"
                            className="d-lg-none align-items-center d-flex"
                          >
                            <small>Request</small>{" "}
                          </Link>
                        </div>

                        <div className="text-center story">
                          <div className="story-profile">
                            <Image
                              className="avatar-50 rounded-pill"
                              src={user15}
                              alt=""
                              loading="lazy"
                            />

                            <Link
                              to="/"
                              className="h6 mt-0 mt-lg-2 ms-3 ms-lg-0 text-ellipsis short-2 small"
                            >
                              Rokni Joy
                            </Link>
                          </div>

                          <Link
                            to="/"
                            className="d-lg-none align-items-center d-flex"
                          >
                            <small>Follow</small>{" "}
                          </Link>
                        </div>

                        <div className="text-center story">
                          <div className="story-profile">
                            <Image
                              className="avatar-50 rounded-pill"
                              src={user16}
                              alt=""
                              loading="lazy"
                            />

                            <Link
                              to="/"
                              className="h6 mt-0 mt-lg-2 ms-3 ms-lg-0 text-ellipsis short-2 small"
                            >
                              Sepid Ryan
                            </Link>
                          </div>

                          <Link
                            to="/"
                            className="d-lg-none align-items-center d-flex"
                          >
                            <small>Unfollow</small>{" "}
                          </Link>
                        </div>
                      </div>
                    </div>
                  </Modal.Body>
                </div>
              </Modal>
            </div> */}

            <ul className="navbar-nav navbar-list">
              <Nav.Item as="li">
                <Link to="/" className="d-flex align-items-center">
                  <AiOutlineHome size={20} />
                </Link>
              </Nav.Item>
              {/* <Nav.Item as="li" className="d-lg-none">
                <div className="iq-search-bar device-search  position-relative">
                  <form
                    action="#"
                    className="searchbox"
                    onClick={handleShow}
                    data-bs-toggle="modal"
                    data-bs-target="#exampleModalFullscreenSm"
                  >
                    <Link className="search-link d-none d-lg-block" to="/">
                      <span className="material-symbols-outlined">search</span>
                    </Link>
                    <Form.Control
                      type="text"
                      className="text search-input form-control bg-soft-primary  d-none d-lg-block"
                      placeholder="Search here..."
                    />
                    <Link
                      className="d-lg-none d-flex"
                      to="/"
                      onClick={handleShow}
                      data-bs-toggle="modal"
                      data-bs-target="#exampleModalFullscreenSm"
                    >
                      <span className="material-symbols-outlined">search</span>
                    </Link>
                  </form>

                  <Modal
                    show={show}
                    onHide={handleClose}
                    className="search-modal"
                    id="post-modal"
                  >
                    <div className="modal-dialog modal-fullscreen-lg-down m-0">
                      <Modal.Header className="py-2">
                        <div className="d-flex align-items-center justify-content-between d-lg-none w-100">
                          <form
                            action="#"
                            className="searchbox w-50"
                            data-bs-toggle="modal"
                            data-bs-target="#exampleModalFullscreenSm"
                            onClick={handleShow}
                          >
                            <Link className="search-link" to="/">
                              <span className="material-symbols-outlined">
                                search
                              </span>
                            </Link>

                            <Form.Control
                              type="text"
                              className="text search-input bg-soft-primary"
                              placeholder="Search here..."
                            />
                          </form>

                          <Link
                            to="/"
                            className="material-symbols-outlined text-dark"
                            onClick={handleClose}
                          >
                            close
                          </Link>
                        </div>
                        <div className="d-flex align-items-center justify-content-between ms-auto w-100">
                          <h5 className=" h4" id="exampleModalFullscreenLabel">
                            Recent
                          </h5>

                          <Link to="/" className="text-dark">
                            Clear All
                          </Link>
                        </div>
                      </Modal.Header>
                      <Modal.Body className="p-0">
                        <div className="d-flex d-lg-none align-items-center justify-content-between w-100 p-3 pb-0">
                          <h5 className=" h4" id="exampleModalFullscreenLabel">
                            Recent
                          </h5>

                          <Link to="/" className="text-dark">
                            Clear All
                          </Link>
                        </div>
                        <div className="d-flex align-items-center border-bottom search-hover py-2 px-3">
                          <div className="flex-shrink-0">
                            <Image
                              className="align-self-center img-fluid avatar-50 rounded-pill"
                              src={user6}
                              alt=""
                              loading="lazy"
                            />
                          </div>

                          <div className="d-flex flex-column ms-3">
                            <Link to="/" className="h5">
                              Paige Turner
                            </Link>

                            <span>Paige001</span>
                          </div>

                          <div className="d-flex align-items-center ms-auto">
                            <Link
                              to="/"
                              className="me-3 d-flex align-items-center"
                            >
                              <small>Follow</small>{" "}
                            </Link>

                            <Link
                              to="/"
                              className="material-symbols-outlined text-dark"
                            >
                              close
                            </Link>
                          </div>
                        </div>
                        <div className="d-flex align-items-center border-bottom search-hover py-2 px-3">
                          <div className="flex-shrink-0">
                            <Image
                              className="align-self-center img-fluid avatar-50 rounded-pill"
                              src={user7}
                              alt=""
                              loading="lazy"
                            />
                          </div>

                          <div className="d-flex flex-column ms-3">
                            <Link to="/" className="h5">
                              Monty Carlo
                            </Link>

                            <span>Carlo.m</span>
                          </div>

                          <div className="d-flex align-items-center ms-auto">
                            <Link
                              to="/"
                              className="me-3 d-flex align-items-center"
                            >
                              <small>Unfollow</small>{" "}
                            </Link>

                            <Link
                              to="/"
                              className="material-symbols-outlined text-dark"
                            >
                              close
                            </Link>
                          </div>
                        </div>
                        <div className="d-flex align-items-center search-hover py-2 px-3 border-bottom">
                          <div className="flex-shrink-0">
                            <Image
                              className="align-self-center img-fluid avatar-50 rounded-pill"
                              src={user8}
                              alt=""
                              loading="lazy"
                            />
                          </div>

                          <div className="d-flex flex-column ms-3">
                            <Link to="/" className="h5">
                              Paul Molive
                            </Link>

                            <span>Paul.45</span>
                          </div>

                          <div className="d-flex align-items-center ms-auto">
                            <Link
                              to="/"
                              className="me-3 d-flex align-items-center"
                            >
                              <small>Request</small>{" "}
                            </Link>

                            <Link
                              to="/"
                              className="material-symbols-outlined text-dark"
                            >
                              close
                            </Link>
                          </div>
                        </div>
                        <div className="">
                          <h4 className="px-3 py-2">Suggestions</h4>

                          <div className="suggestion-card px-3 d-flex">
                            <div className="text-center story">
                              <div className="story-profile">
                                <Image
                                  className="avatar-50 rounded-pill"
                                  src={user8}
                                  alt=""
                                  loading="lazy"
                                />

                                <Link
                                  to="/"
                                  className="h6 mt-0 mt-lg-2 ms-3 ms-lg-0 text-ellipsis short-2 small"
                                >
                                  Ammy Paul
                                </Link>
                              </div>

                              <Link
                                to="/"
                                className="d-lg-none align-items-center d-flex"
                              >
                                <small>Follow</small>{" "}
                              </Link>
                            </div>

                            <div className="text-center story">
                              <div className="story-profile">
                                <Image
                                  className="avatar-50 rounded-pill"
                                  src={user9}
                                  alt=""
                                  loading="lazy"
                                />

                                <Link
                                  to="/"
                                  className="h6 mt-0 mt-lg-2 ms-3 ms-lg-0 text-ellipsis short-2 small"
                                >
                                  Roger Carlo
                                </Link>
                              </div>

                              <Link
                                to="/"
                                className="d-lg-none align-items-center d-flex"
                              >
                                <small>Unfollow</small>{" "}
                              </Link>
                            </div>

                            <div className="text-center story ">
                              <div className="story-profile">
                                <Image
                                  className="avatar-50 rounded-pill"
                                  src={user10}
                                  alt=""
                                  loading="lazy"
                                />

                                <Link
                                  to="/"
                                  className="h6 mt-0 mt-lg-2 ms-3 ms-lg-0 text-ellipsis short-2 small"
                                >
                                  Justin Molive
                                </Link>
                              </div>

                              <Link
                                to="/"
                                className="d-lg-none align-items-center d-flex"
                              >
                                <small>Follow</small>{" "}
                              </Link>
                            </div>

                            <div className="text-center story">
                              <div className="story-profile ">
                                <Image
                                  className="avatar-50 rounded-pill"
                                  src={user11}
                                  alt=""
                                  loading="lazy"
                                />

                                <Link
                                  to="/"
                                  className="h6 mt-0 mt-lg-2 ms-3 ms-lg-0 text-ellipsis short-2 small"
                                >
                                  Roy Fisher
                                </Link>
                              </div>

                              <Link
                                to="/"
                                className="d-lg-none align-items-center d-flex"
                              >
                                <small>Request</small>{" "}
                              </Link>
                            </div>

                            <div className="text-center story">
                              <div className="story-profile">
                                <Image
                                  className="avatar-50 rounded-pill"
                                  src={user12}
                                  alt=""
                                  loading="lazy"
                                />

                                <Link
                                  to="/"
                                  className="h6 mt-0 mt-lg-2 ms-3 ms-lg-0 text-ellipsis short-2 small"
                                >
                                  Johan Carlo
                                </Link>
                              </div>

                              <Link
                                to="/"
                                className="d-lg-none align-items-center d-flex"
                              >
                                <small>Follow</small>{" "}
                              </Link>
                            </div>

                            <div className="text-center story">
                              <div className="story-profile">
                                <Image
                                  className="avatar-50 rounded-pill"
                                  src={user13}
                                  alt=""
                                  loading="lazy"
                                />

                                <Link
                                  to="/"
                                  className="h6 mt-0 mt-lg-2 ms-3 ms-lg-0 text-ellipsis short-2 small"
                                >
                                  MedrLink Miles
                                </Link>
                              </div>

                              <Link
                                to="/"
                                className="d-lg-none align-items-center d-flex"
                              >
                                <small>Unfollow</small>{" "}
                              </Link>
                            </div>

                            <div className="text-center story">
                              <div className="story-profile">
                                <Image
                                  className="avatar-50 rounded-pill"
                                  src={user14}
                                  alt=""
                                  loading="lazy"
                                />

                                <Link
                                  to="/"
                                  className="h6 mt-0 mt-lg-2 ms-3 ms-lg-0 text-ellipsis short-2 small"
                                >
                                  Aohan Paul
                                </Link>
                              </div>

                              <Link
                                to="/"
                                className="d-lg-none align-items-center d-flex"
                              >
                                <small>Request</small>{" "}
                              </Link>
                            </div>

                            <div className="text-center story">
                              <div className="story-profile">
                                <Image
                                  className="avatar-50 rounded-pill"
                                  src={user15}
                                  alt=""
                                  loading="lazy"
                                />

                                <Link
                                  to="/"
                                  className="h6 mt-0 mt-lg-2 ms-3 ms-lg-0 text-ellipsis short-2 small"
                                >
                                  Rokni Joy
                                </Link>
                              </div>

                              <Link
                                to="/"
                                className="d-lg-none align-items-center d-flex"
                              >
                                <small>Follow</small>{" "}
                              </Link>
                            </div>

                            <div className="text-center story">
                              <div className="story-profile">
                                <Image
                                  className="avatar-50 rounded-pill"
                                  src={user16}
                                  alt=""
                                  loading="lazy"
                                />

                                <Link
                                  to="/"
                                  className="h6 mt-0 mt-lg-2 ms-3 ms-lg-0 text-ellipsis short-2 small"
                                >
                                  Sepid Ryan
                                </Link>
                              </div>

                              <Link
                                to="/"
                                className="d-lg-none align-items-center d-flex"
                              >
                                <small>Unfollow</small>{" "}
                              </Link>
                            </div>
                          </div>
                        </div>
                      </Modal.Body>
                    </div>
                  </Modal>
                </div>
              </Nav.Item> */}
              <Dropdown as="li" className="nav-item">
                <Dropdown.Toggle
                  href="/"
                  as={CustomToggle}
                  variant="d-flex align-items-center"
                >
                  <AiOutlineTeam size={20} />
                </Dropdown.Toggle>
                <Dropdown.Menu className="sub-drop sub-drop-large">
                  <Card className="shadow-none m-0">
                    <Card.Header className="d-flex justify-content-between bg-primary">
                      <div className="header-title">
                        <h5 className="mb-0 text-white">Friend Request</h5>
                      </div>
                      <small className="badge  bg-light text-dark ">4</small>
                    </Card.Header>
                    <Card.Body className="p-0">
                      {receivedFriendRequest?.map((ele, index) => (
                        <div className="iq-friend-request" key={index}>
                          <div className="iq-sub-card iq-sub-card-big d-flex align-items-center justify-content-between">
                            <div className="d-flex align-items-center">
                              <Image
                                className="avatar-40 rounded"
                                src={ele.user?.display_picture.img_url}
                                alt=""
                                loading="lazy"
                              />
                              <div className="ms-3">
                                <h6>User {ele.user?.name}</h6>
                                {/* <p className="mb-0">40 friends</p> */}
                              </div>
                            </div>
                            <div className="d-flex align-items-center">
                              <Link
                                to="#"
                                className="me-3 btn btn-primary rounded"
                                onClick={() =>
                                  updateRequest("accepted", ele.user?.id)
                                }
                              >
                                Confirm
                              </Link>
                              <Link
                                to="#"
                                className="me-3 btn btn-secondary rounded"
                                onClick={() =>
                                  updateRequest("rejected", ele.user?.id)
                                }
                              >
                                Delete
                              </Link>
                            </div>
                          </div>
                        </div>
                      ))}

                      <div className="text-center">
                        {receivedFriendRequest?.length === 0 ? (
                          <h4>No requests found</h4>
                        ) : (
                          <Link
                            to="/my-networks/peoples/received-requests"
                            onClick={
                              () => handleTabChange("third")
                              // localStorage.setItem("activeTab", "third")
                            }
                            className=" btn text-primary"
                          >
                            View More Request
                          </Link>
                        )}
                      </div>
                    </Card.Body>
                  </Card>
                </Dropdown.Menu>
              </Dropdown>

              <Dropdown as="li" className="nav-item ">
                <Dropdown.Toggle
                  href="#"
                  as={CustomToggle}
                  variant="search-toggle d-flex align-items-center"
                >
                  <AiOutlineAudit size={20} />
                </Dropdown.Toggle>
                <Dropdown.Menu className="sub-drop">
                  <Card className="shadow-none m-0">
                    <Card.Header className="d-flex justify-content-between bg-primary">
                      <div className="header-title bg-primary">
                        <h5 className="mb-0 text-white ">All Notifications</h5>
                      </div>
                      <small className="badge  bg-light text-dark">
                        {orgReqs?.length}
                      </small>
                    </Card.Header>
                    <Card.Body className="p-0">
                      {orgReqs?.map((ele, index) => (
                        <Link
                          to="/my-networks/organizations/received-requests"
                          onClick={
                            () => handleTabChange("third")
                            // localStorage.setItem("activeTab", "third")
                          }
                          className="iq-sub-card"
                        >
                          <div
                            className="d-flex align-items-center"
                            key={index}
                          >
                            <div className="">
                              <Image
                                className="avatar-40 rounded"
                                src={user1}
                                alt=""
                                loading="lazy"
                              />
                            </div>
                            <div className="ms-3 w-100">
                              <h6 className="mb-0 ">org Id : {ele.org_id}</h6>
                              <div className="d-flex justify-content-between align-items-center">
                                <p className="mb-0">
                                  {ele.users.length} requests
                                </p>
                                {/* <small className="float-right font-size-12">
                                  Just Now
                                </small> */}
                              </div>
                            </div>
                          </div>
                        </Link>
                      ))}
                      <div className="text-center">
                        {orgReqs?.length === 0 && <h4>No requests found</h4>}
                      </div>
                    </Card.Body>
                  </Card>
                </Dropdown.Menu>
              </Dropdown>
              {/* <Dropdown as="li" className="nav-item">
                <Dropdown.Toggle
                  href="#"
                  as={CustomToggle}
                  variant="d-flex align-items-center"
                >
                  <i className="material-symbols-outlined">mail</i>
                  <span className="mobile-text d-none ms-3">Message</span>
                </Dropdown.Toggle>
              </Dropdown> */}
              {/* <Nav.Item as="li" className="d-lg-none">
              <Link
                to="/dashboard/app/notification"
                className="d-flex align-items-center"
              >
                <i className="material-symbols-outlined">notifications</i>
                <span className="mobile-text  ms-3 d-none">Notifications</span>
              </Link>
  </Nav.Item>*/}
              <Nav.Item className="nav-item d-none d-lg-none">
                <Link
                  to="#"
                  className="dropdown-toggle d-flex align-items-center"
                  id="mail-drop-1"
                  data-bs-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  <i className="material-symbols-outlined">mail</i>
                  <span className="mobile-text  ms-3">Message</span>
                </Link>
              </Nav.Item>
              <Dropdown as="li" className="nav-item user-dropdown">
                <Dropdown.Toggle
                  // href="#"
                  as={CustomToggle}
                  // id="dropdown-autoclose-true"
                  variant="d-flex align-items-center"
                >
                  <Image
                    src={
                      profileData.displayImg?.img_url
                        ? profileData.displayImg.img_url
                        : user.display_picture_media?.thumb_img_url
                    }
                    className="img-fluid rounded-circle me-3"
                    alt="user"
                    loading="lazy"
                  />
                  <div className="caption d-none d-lg-block">
                    <h6 className="mb-0 line-height text-capitalize">
                      {profileData.userName}
                    </h6>
                  </div>
                </Dropdown.Toggle>
                <Dropdown.Menu className="sub-drop caption-menu">
                  <Card className="shadow-none m-0">
                    <Card.Header>
                      <div className="header-title">
                        <h5 className="mb-0 text-capitalize">
                          {profileData.userName}
                        </h5>
                      </div>
                    </Card.Header>
                    <Card.Body className="p-0 ">
                      <Dropdown.Item
                        onClick={() => navigate(`/my-profile/${user.username}`)}
                      >
                        <div className="d-flex align-items-center iq-sub-card border-0">
                          <span className="material-symbols-outlined">
                            line_style
                          </span>
                          <div className="ms-3">My Profile</div>
                        </div>
                      </Dropdown.Item>

                      <Dropdown.Item onClick={logout}>
                        <div
                          className="d-flex align-items-center iq-sub-card"
                          // onClick={logout}
                        >
                          <span className="material-symbols-outlined">
                            login
                          </span>
                          <div className="ms-3">
                            Sign out {loading && <Spinner size="sm" />}
                          </div>
                        </div>
                      </Dropdown.Item>
                    </Card.Body>
                  </Card>
                </Dropdown.Menu>
              </Dropdown>
            </ul>
          </Container>
        </Nav>
      </div>
    </>
  );
};

export default Header;
