import React from "react";
import { Button } from "react-bootstrap";

export default function SourceDetails() {
  //   const [inputFields, setInputFields] = useState({
  //     contactName: "",
  //     contactNumber: "",
  //     contactEmail: "",
  //   });

  //   const handleAddFields = () => {
  //     setInputFields([
  //       ...inputFields,
  //       { contactName: "", contactNumber: "", contactEmail: "" },
  //     ]);
  //   };

  //   const handleRemoveFields = (index) => {
  //     const values = [...inputFields];
  //     values.splice(index, 1);
  //     setInputFields([...values]);
  //   };
  const handleInputChange = (index, event) => {
    // const values = [...inputFields];
    // const { name } = event.target;
    // values[index][name] = event.target.value;
  };
  return (
    <>
      <div className="d-flex justify-content-center  ">
        <h4 className="text-dark mt-4 ">Source Details</h4>
      </div>
      <div className="d-flex justify-content-center  ">
        <h5 className="text-dark ">Fill your details below,</h5>
      </div>
      <div className="row p-2 mb-2 mt-1 d-flex align-items-center">
        <div className="form-group col-md-6 col-xs-12">
          <label htmlFor="website_label">Name</label>
          <input
            type="text"
            name="name"
            className="form-control"
            id="website_label"
            onChange={(event) => handleInputChange(event)}
            placeholder="Name"
          />
        </div>
        <div className="form-group col-6">
          <label htmlFor="contact_number">Contact Number</label>
          <input
            type="number"
            name="contact_number"
            className="form-control"
            id="contact_number"
            onChange={(event) => handleInputChange(event)}
            placeholder="Contact Number"
          />
        </div>
        <div className="form-group col-6">
          <label htmlFor="contact_email">Contact Email</label>
          <input
            type="email"
            name="contactEmail"
            className="form-control"
            onChange={(event) => handleInputChange(event)}
            placeholder="Contact Email"
          />
        </div>
        <div className="form-group col-md-6 col-xs-12">
          <label htmlFor="website_label">Relationship with deceased</label>
          <input
            type="text"
            name="contactName"
            className="form-control"
            id="website_label"
            onChange={(event) => handleInputChange(event)}
            placeholder="Label"
          />
        </div>
      </div>
      <div className="d-flex justify-content-between align-items-center">
        <Button>Skip payment and save Obituary as Draft</Button>
        <Button>Save Obituary and Procedd to Pay</Button>
      </div>
    </>
  );
}
