import React, { useState } from "react";
import { Card, Dropdown } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useAuth } from "../../../../hooks/useAuth";
import { useGlobal } from "../../../../hooks/useGlobal";
import { usePost } from "../../../../hooks/usePost";
import BasicPostModal from "../../../modals/BasicPostModal";
import DonationModal from "../../../modals/DonationModal";
import OrganizationModal from "../../../modals/OrganizationModal";
import VideoLinkModal from "../../../modals/VideoLinkModal";
import ObituaryModal from "../../../modals/ObituaryModal";
import { OrganizationProvider } from "../../../../hooks/useOrganization";
import { CampaignProvider } from "../../../../hooks/useCampaign";

export default function CreatePostSection({
  getPosts,
  showPost,
  handlePostShow,
  setPostShow,
}) {
  const { toggleMedia } = useGlobal();
  const { user } = useAuth();

  const [showVideoDetail, setVideoDetailShow] = useState(false);
  const { setPost, initialPost, handleDynamicDetails, post } = usePost();
  const handlePostClose = () => {
    setPostShow(false);
    const { organization_id } = post;
    setPost({ ...initialPost, organization_id });
  };
  const toggleVideoDetailModal = () => {
    setVideoDetailShow(!showVideoDetail);
  };

  const [showOrganization, setOrganizationShow] = useState(false);
  const handleOrganizationClose = () => setOrganizationShow(false);
  const handleOrganizationShow = () => setOrganizationShow(true);

  const [showDonation, setDonationShow] = useState(false);
  const handleDonationClose = () => setDonationShow(false);
  const handleDonationShow = () => setDonationShow(true);

  const [showObituary, setObituaryShow] = useState(false);
  const handleObituaryClose = () => setObituaryShow(false);

  return (
    <>
      <VideoLinkModal
        handleClose={toggleVideoDetailModal}
        show={showVideoDetail}
        videos={[]}
        handleVideoDetails={handleDynamicDetails}
      />
      <BasicPostModal
        getPosts={getPosts}
        handleShow={handlePostShow}
        handleClose={handlePostClose}
        show={showPost}
        toggleMedia={toggleMedia}
        toggleVideoDetailModal={toggleVideoDetailModal}
      />
      <CampaignProvider>
        <DonationModal handleClose={handleDonationClose} show={showDonation} />
      </CampaignProvider>
      <OrganizationProvider>
        <OrganizationModal
          handleClose={handleOrganizationClose}
          show={showOrganization}
        />
      </OrganizationProvider>
      <ObituaryModal handleClose={handleObituaryClose} show={showObituary} />
      <Card
        id="post-modal-data"
        className="card-block card-stretch card-height"
      >
        <div className="card-header d-flex justify-content-between">
          <div className="header-title">
            <h4 className="card-title">Create Post</h4>
          </div>
        </div>
        <Card.Body>
          <div className="d-flex align-items-center">
            <div className="user-img">
              <img
                src={user.display_picture_media.img_url}
                alt="auth-user"
                className="avatar-60 rounded-circle"
              />
            </div>
            <form className="post-text ms-3 w-100 " onClick={handlePostShow}>
              <input
                type="text"
                className="form-control rounded"
                placeholder="Write something here..."
                style={{ border: "none" }}
              />
            </form>
          </div>
          <hr></hr>
          <ul className=" post-opt-block d-flex list-inline m-0 p-0 flex-wrap">
            <li className="me-3 mb-md-0 mb-2">
              <Link
                to="#"
                className="btn btn-soft-primary"
                onClick={handleOrganizationShow}
              >
                {/* <img src={img1} alt="icon" className="img-fluid me-2"/>  */}
                Create Oragnization
              </Link>
            </li>
            {/* <li className="me-3 mb-md-0 mb-2">
              <Link
                to="#"
                className="btn btn-soft-primary"
                onClick={handleObituaryShow}
              >
                Create Obitua
              </Link>
            </li> */}
            <li className="me-3">
              <Link
                to="#"
                className="btn btn-soft-primary"
                onClick={handleDonationShow}
              >
                {/* <img src={img3} alt="icon" className="img-fluid me-2"/>  */}
                Create Donation
              </Link>
            </li>
            <li>
              <a href="#" className=" btn btn-soft-primary">
                <div className="card-header-toolbar d-flex align-items-center">
                  <Dropdown>
                    <Dropdown.Toggle as="div" className="lh-1">
                      <span className="material-symbols-outlined">
                        more_horiz
                      </span>
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      <Dropdown.Item onClick={handlePostShow} href="#">
                        Watch Party
                      </Dropdown.Item>
                      <Dropdown.Item onClick={handlePostShow} href="#">
                        Play with Friend
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
              </a>
            </li>
          </ul>
        </Card.Body>
      </Card>
    </>
  );
}
