import { ACCESS_TOKEN, AUTH_USER } from "../utilities/constants";
import { history } from "../utilities/history";
import { api } from "./axios.service"

const login = (credential)=>{
    return new Promise((resolve, reject) => {
        api('POST','login',null,credential,'')
          .then((response) => {
            window.localStorage.setItem(ACCESS_TOKEN,response.data.token );
            resolve(response.data);
          })
        .catch((err) => {
            reject(err)
        });
    });
}
const register = (newUser)=>{
  return new Promise((resolve, reject) => {
      api('POST','register',null,newUser,'')
        .then((response) => {
          resolve(response.data);
        })
      .catch((err) => {
          reject(err)
      });
  });
}
const logout = ()=>{
  return new Promise((resolve, reject) => {
      api('POST','user/logout','token','','')
        .then((response) => {
          window.localStorage.setItem(AUTH_USER , null );
          window.localStorage.removeItem(ACCESS_TOKEN );
          resolve(response.data);
        })
      .catch((err) => {
          reject(err)
      });
  });
}
const getProfile = ()=>{
  return new Promise((resolve, reject) => {
      api('GET','user/profile','token','','')
        .then((response) => {
          resolve(response.data);
        })
      .catch((err) => {
          reject(err)
      });
  });
}
const redirectUnAuthenticate=()=>{
  window.localStorage.setItem(AUTH_USER , null );
  window.localStorage.removeItem(ACCESS_TOKEN );
  history.navigate('/auth/sign-in')
}

const getDataForUserRegistration=()=>{
  return new Promise((resolve, reject) => {
    api('GET','register',null,'','')
      .then((response) => {
        resolve(response.data);
      })
    .catch((err) => {
        reject(err)
    });
});
}
export const authService = {
  login,
  logout,
  getProfile,
  register,
  redirectUnAuthenticate,
  getDataForUserRegistration
}