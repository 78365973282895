import React from "react";
import { useCampaign } from "../../../../../hooks/useCampaign";
import DynamicVideoDeatil from "../../DynamicVideoDeatil";
import Media from "../../FileUpload/Media";

const File = () => {
  const {campaign,handleChange,handleDynamicDetails} = useCampaign()
  return (
    <>
      <div className="d-flex justify-content-center">
        <h4 className="text-dark my-3">File Uploads</h4>
      </div>

      <div className="row">
        <Media style="col-12" label="Image" selectedPhotoes={campaign.image} isSingleImg={true} fieldName={'image'} model="campaign"/>
        <Media label="Image Gallery" selectedPhotoes={campaign.image_gallery} isSingleImg={false}  fieldName={'image_gallery'} model="campaign"/>
        <Media label="Medical Documents" selectedPhotoes={campaign.medical_documents} isSingleImg={false}  fieldName={'medical_documents'} model="campaign"/>

        <div className="form-group">
          <label>Videos</label>
          <div className="iconbox-repeater-wrapper" data-set="videos">
            <DynamicVideoDeatil videos={campaign.videos} handleVideoDetails={handleDynamicDetails}/>
          </div>
        </div>
      </div>
    </>
  );
};

export default File;