import React, { useState } from "react";
import { Card, Nav, Tab } from "react-bootstrap";
import Photos from "../../../../components/user-profile/Tabs/Photos";
import Videos from "../../../../components/user-profile/Tabs/Videos";
import DocumentTab from "./DocumentTab";

const MediaTab = () => {
  const [tab, setTab] = useState("photos");
  const renderMediaComponents = () => {
    switch (tab) {
      case "videos":
        return <Videos />;
      case "docs":
        return <DocumentTab />;

      default:
        return <Photos />;
    }
  };
  return (
    <Tab.Pane eventKey="fourth">
      <Card>
        <Nav
          variant="pills"
          defaultActiveKey="gallery"
          className=" d-flex align-items-center justify-content-left  p-1"
        >
          <Nav.Item>
            <Nav.Link
              eventKey="gallery"
              onClick={() => {
                setTab("photos");
              }}
            >
              Photos
            </Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link
              eventKey="videos"
              onClick={() => {
                setTab("videos");
              }}
            >
              Videos
            </Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link
              eventKey="documents"
              onClick={() => {
                setTab("docs");
              }}
            >
              Documents
            </Nav.Link>
          </Nav.Item>
        </Nav>
      </Card>
      <Tab.Content>{renderMediaComponents()}</Tab.Content>
    </Tab.Pane>
  );
};

export default MediaTab;
