import React, { Fragment, useState } from "react";

export default function ContactDetails() {
  const [inputFields, setInputFields] = useState([
    { contactName: "", contactNumber: "", contactEmail: "" },
  ]);

  const handleAddFields = () => {
    setInputFields([
      ...inputFields,
      { contactName: "", contactNumber: "", contactEmail: "" },
    ]);
  };

  const handleRemoveFields = (index) => {
    const values = [...inputFields];
    values.splice(index, 1);
    setInputFields([...values]);
  };
  const handleInputChange = (index, event) => {
    const values = [...inputFields];
    const { name } = event.target;
    values[index][name] = event.target.value;
  };
  return (
    <>
      <div className="d-flex justify-content-center  ">
        <h4 className="text-dark mt-4 ">Contacts Details</h4>
      </div>
      <div className="d-flex justify-content-center  ">
        <h5 className="text-dark ">
          Contacts Details of the Obituary. (Optional)
        </h5>
      </div>
      {inputFields.map((inputField, index) => (
        <Fragment key={`${inputField}~${index}`}>
          <div className="row p-2 mb-2 mt-1 d-flex align-items-center">
            <div className="form-group col-md-6 col-xs-12">
              <label htmlFor="website_label">Contact Name</label>
              <input
                type="text"
                name="contactName"
                className="form-control"
                id="website_label"
                onChange={(event) => handleInputChange(index, event)}
                placeholder="Label"
              />
            </div>
            <div className="form-group col-md-6 col-xs-12">
              <label htmlFor="website_label">Contact Relationship</label>
              <input
                type="text"
                name="contactName"
                className="form-control"
                id="website_label"
                onChange={(event) => handleInputChange(index, event)}
                placeholder="Label"
              />
            </div>
            <div className="form-group col-6">
              <label htmlFor="contact_number">Contact Number</label>
              <input
                type="number"
                name="contact_number"
                className="form-control"
                id="contact_number"
                onChange={(event) => handleInputChange(index, event)}
                placeholder="Contact Number"
              />
            </div>
            <div className="form-group col-6">
              <label htmlFor="contact_email">Contact Email</label>
              <input
                type="email"
                name="contactEmail"
                className="form-control"
                onChange={(event) => handleInputChange(index, event)}
                placeholder="Contact Email"
              />
            </div>
            {index > 0 ? (
              <div className="col-2 col-xs-4">
                <button
                  className="btn btn-danger"
                  type="button"
                  disabled={index === 0}
                  onClick={() => handleRemoveFields(index)}
                >
                  Remove
                </button>
              </div>
            ) : null}
          </div>
        </Fragment>
      ))}
      {inputFields.length > 0 && (
        <div className="row d-flex  justify-content-left ">
          <div
            className="col-xs-12 col-md-4 btn btn-success"
            onClick={handleAddFields}
          >
            Add More Contact Number
          </div>
        </div>
      )}
    </>
  );
}
