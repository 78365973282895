import React, { useState } from "react";
import { Card, Col, Row, Spinner } from "react-bootstrap";
import { FollowService } from "../../../../services/follow.service";
// import { useAuth } from "../../../../hooks/useAuth";
import Friendscard from "../../../../components/Friendscard";
import { useFollow } from "../../../../hooks/useFollow";

const ReceivedFriendRequest = () => {
  // const { user } = useAuth();
  // const [receivedFriendRequest, setReceivedFriendRequest] = useState([]);
  const [confirmingRequests, setConfirmingRequests] = useState([]);
  const [deletingRequests, setDeletingRequests] = useState([]);
  const { receivedFriendRequest, setReceivedFriendRequest } = useFollow();

  const confirmRequest = async (reqId, index) => {
    try {
      setConfirmingRequests([...confirmingRequests, index]); // Set confirming state to true
      await FollowService.updateReqest(
        `update?follower_id=${reqId}&status=accepted`
      );
      setTimeout(() => {
        setReceivedFriendRequest([
          ...receivedFriendRequest.filter((ele) => ele.user.id !== reqId),
        ]);
        setConfirmingRequests(confirmingRequests.filter((i) => i !== index));
      }, 600);
    } catch (err) {
      return err;
    }
  };
  const deleteRequest = async (reqId, index) => {
    try {
      setDeletingRequests([...deletingRequests, index]); // Set confirming state to true
      await FollowService.updateReqest(
        `update?follower_id=${reqId}&status=rejected`
      );
      setTimeout(() => {
        setReceivedFriendRequest([
          ...receivedFriendRequest.filter((ele) => ele.user.id !== reqId),
        ]);
        setDeletingRequests(deletingRequests.filter((i) => i !== index)); // Remove the index from the deletingRequests array
      }, 600);
    } catch (err) {
      return err;
    }
  };
  // const fetchSentRequests = async () => {
  //   try {
  //     const response = await FollowService.fetchRequests(
  //       `${user.id}?type=followers&request_type=requested`
  //     );
  //     setReceivedFriendRequest(response?.connections?.followers);
  //   } catch (err) {
  //     return err;
  //   }
  // };
  // useEffect(() => {
  //   fetchSentRequests();
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);
  console.log(receivedFriendRequest);
  return (
    <Row>
      <Col sm="12">
        <Card>
          {receivedFriendRequest?.length === 0 && <h4>No requests found</h4>}
        </Card>

        <div id="content-page" className="content-page">
          <Row>
            <>
              {receivedFriendRequest?.map((ele, index) => (
                <Col sm="6" md="3" key={index}>
                  <Friendscard className="friendscard">
                    <div className="user-img img-fluid flex-shrink-0">
                      <img
                        src={ele.user.display_picture.img_url}
                        alt="story-img"
                        className="rounded-circle avatar-40"
                      />

                      <h5> {ele.user.name}</h5>

                      <div>
                        Job Title: Software Engineer <br />
                        Industry: IT and Communication <br />
                        Short intro: I'm a software engineer
                      </div>
                      <button
                        type="submit"
                        className="btn btn-primary"
                        onClick={() => confirmRequest(ele.user.id, index)}
                        disabled={
                          confirmingRequests.includes(index) ||
                          deletingRequests.includes(index)
                        }
                      >
                        {confirmingRequests.includes(index) ? (
                          <Spinner animation="border" size="sm" />
                        ) : (
                          "Confirm"
                        )}
                      </button>
                      <button
                        type="submit"
                        className="btn btn-secondary"
                        onClick={() => deleteRequest(ele.user.id, index)}
                        disabled={
                          confirmingRequests.includes(index) ||
                          deletingRequests.includes(index)
                        }
                      >
                        {deletingRequests.includes(index) ? (
                          <Spinner animation="border" size="sm" />
                        ) : (
                          "Delete"
                        )}
                      </button>
                    </div>
                  </Friendscard>
                </Col>
              ))}
            </>
          </Row>
        </div>
      </Col>
    </Row>
  );
};

export default ReceivedFriendRequest;
