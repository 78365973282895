import React from "react";
import { Col, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import "./campaignCard.css";
import "../../assets/css/style.css";
const CampaignCard = ({ campaign }) => {
  if (campaign?.campaigns?.length === 0) {
    return (
      <Row>
        <h3>No Campaigns Found</h3>
      </Row>
    );
  }
  return (
    <Row>
      {campaign?.campaigns?.map((cam, index) => (
        <Col key={index} md={4} xs={6}>
          <div className="iq-friendlist-block mb-3">
            <div className="d-flex align-items-center justify-content-between">
              <div className="d-flex align-items-center">
                <img
                  loading="lazy"
                  src={cam?.image?.thumb_img_url}
                  alt="campaign-img"
                  className="img-fluid"
                  style={{ height: "100px" }}
                />
                <div className="d-flex flex-column">
                  <div className="ms-3">
                    <Link
                      to={`/donation-campaign/${cam?.id}`}
                      preventScrollReset={true}
                    >
                      <h5 className="text-underLine">{cam?.title}</h5>
                    </Link>
                  </div>
                  <div className="status mt-1 ms-3">
                    <div
                      className={`badge-container camp-status ${cam.status}`}
                    >
                      <span className={`badge-label`}>
                        {cam.status ? <>{cam.status}</> : "not specified"}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Col>
      ))}
    </Row>
  );
};

export default CampaignCard;
