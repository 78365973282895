import React, { useEffect } from "react";
import { Modal } from "react-bootstrap";
import { Link } from "react-router-dom";
import OrganizationMultiForm from "../dashboard/components/organization/OrganizationMultiForm";
import "../../assets/css/style.css";
import { useOrganization } from "../../hooks/useOrganization";
import { ADD } from "../../utilities/constants";

export default function OrganizationModal({ handleClose, show }) {
  const { setOrganization, setAction, initialOrganization } = useOrganization();
  useEffect(() => {
    setOrganization(initialOrganization);
    setAction(ADD);
  }, []);
  return (
    <Modal
      size="lg"
      className="fade"
      id="organization-modal"
      onHide={handleClose}
      show={show}
      style={{height:'100vh'}}
    >
      <Modal.Header className="d-flex justify-content-between">
        <Modal.Title id="post-modalLabel">Create Organization</Modal.Title>
        <Link to="#" className="lh-1" onClick={handleClose}>
          <span className="material-symbols-outlined">close</span>
        </Link>
      </Modal.Header>
      <Modal.Body className="organization-modal-body">
        <div className="form1 form2">
        <OrganizationMultiForm />
        </div>
      </Modal.Body>
    </Modal>
  );
}
