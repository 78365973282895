import React, { useContext, useEffect, useState } from "react";
import { EditorState } from "draft-js";
import { useGlobal } from "./useGlobal";
import { ADD } from "../utilities/constants";
const PostContext = React.createContext();
export const PostProvider = ({ children }) => {
  const content = JSON.stringify({
    entityMap: {},
    blocks: [
      {
        key: "637gr",
        text: "",
        type: "unstyled",
        depth: 0,
        inlineStyleRanges: [],
        entityRanges: [],
        data: {},
      },
    ],
  });
  let initialPost = {
    organization_id: null,
    content: {
      editorState: EditorState.createEmpty(),
      contentState: JSON.parse(content),
    },
    post_status: "publish",
    post_visibility: "public",
    comment_status: "open",
    title: "",
    photos: [],
    videos: [],
    author_type: "user",
    author_id: null,
    publish_at: "",
  };

  const handleDynamicDetails = (field, values) => {
    setPost({
      ...post,
      [field]: values,
    });
  };
  const handleChange = (event) => {
    const { name, value } = event.target;
    console.log({ name, value });
    setPost({
      ...post,
      [name]: value,
    });
  };
  const [post, setPost] = useState(initialPost);
  const [action, setAction] = useState(ADD);
  // const [error, setError] = useState(initialError);
  const handleSelect = (field, selectedValue) => {
    setPost({
      ...post,
      [field]: selectedValue,
    });
  };
  const onEditorStateChange = (editorState) => {
    console.log(editorState);
    setPost({
      ...post,
      content: { editorState },
    });
  };
  const { mediaState } = useGlobal();
  useEffect(() => {
    if (mediaState.model === "post") {
      setPost({
        ...post,
        [mediaState.field]: mediaState.values,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mediaState.values]);
  return (
    <PostContext.Provider
      value={{
        post,
        setPost,
        action,
        setAction,
        onEditorStateChange,
        initialPost,
        handleSelect,
        handleChange,
        handleDynamicDetails,
      }}
    >
      {children}
    </PostContext.Provider>
  );
};
export const usePost = () => {
  return useContext(PostContext);
};
