import React from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import { FaSearch } from "react-icons/fa";

const SearchBar = ({ placeHolder, searchQuery, onChange, onSubmit }) => {
  const handleSumbit = (e) => {
    e.preventDefault();
    onSubmit();
  };
  return (
    <Row>
      <Col sm={6}>
        <Form className="d-flex align-items-center" onSubmit={handleSumbit}>
          <Form.Control
            type="search"
            value={searchQuery}
            onChange={onChange}
            placeholder={placeHolder}
            required
          />
          <Button type="submit" variant="primary">
            <FaSearch />
          </Button>
        </Form>
      </Col>
    </Row>
  );
};

export default SearchBar;
