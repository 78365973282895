import React, { useContext, useState } from "react";

const GlobalContext = React.createContext();
export const GlobalProvider = ({ children }) => {
  const [mediaState, setMediaState] = useState({
    show: false,
    field: "",
    model: "organization",
    values: [],
  });
  const [loading, setLoading] = useState(false);
  const [authUserDetails, setAuthUserDetails] = useState(null);
  const [countryList, setCountryList] = useState([]);
  const [imageController, setImageController] = useState({
    toggler: false,
    slide: 1,
    sources: [],
  });
  const [videoController, setVideoController] = useState({
    toggler: false,
    slide: 1,
    sources: [],
  });
  // for lightbox -->start
  const [lightbox, setLightbox] = useState({
    open: false,
    startIndex: null,
  });
  const openLightbox = (startIndex) => {
    console.log(startIndex);
    setLightbox({ open: !lightbox.open, startIndex: startIndex });
  };
  const closeLightbox = () => {
    setLightbox({ open: !lightbox.open, startIndex: null });
  };
  // for lightbox -->end
  //for selecting image
  const [selectSingleImage, setSelectSingleImage] = useState(false);
  const viewImageOnSlide = (sources, number) => {
    setImageController({
      sources,
      toggler: !imageController.toggler,
      slide: number,
    });
  };
  const viewVideoOnSlide = (sources, number) => {
    console.log(sources, number);
    setVideoController({
      sources,
      toggler: !videoController.toggler,
      slide: number,
    });
  };
  const clearImageOnSlide = () => {
    setImageController({
      ...imageController,
      toggler: !imageController.toggler,
      slide: 1,
      sources: [],
    });
  };
  const imageOnSlide = (number) => {
    setImageController({
      toggler: !imageController.toggler,
      slide: number,
    });
  };
  const convertImagesToRequestString = (imagesArr) => {
    let reqString = "";
    if(imagesArr.length > 0){
      imagesArr?.forEach((element, index) => {
        if (index !== 0) {
          reqString += "|";
        }
        reqString += element.image_id;
      });
    }
    return reqString;
  };
  const toggleMedia = (field = null, selected = null, model) => {
    if (field != null) {
      setMediaState({
        ...mediaState,
        field,
        show: !mediaState.show,
        model: model ?? "organization",
      });
    } else if (selected != null) {
      setMediaState({
        ...mediaState,
        values: selected,
        show: !mediaState.show,
      });
    } else {
      setMediaState({
        ...mediaState,
        field: null,
        values: null,
        show: !mediaState.show,
      });
    }
  };

  return (
    <GlobalContext.Provider
      value={{
        showMedia: mediaState.show,
        mediaState,
        setMediaState,
        toggleMedia,
        convertImagesToRequestString,
        loading,
        setLoading,
        authUserDetails,
        setAuthUserDetails,
        countryList,
        setCountryList,
        imageOnSlide,
        imageController,
        viewImageOnSlide,
        setImageController,
        clearImageOnSlide,
        setSelectSingleImage,
        selectSingleImage,
        lightbox,
        openLightbox,
        closeLightbox,
        videoController,
        viewVideoOnSlide,
      }}
    >
      {children}
    </GlobalContext.Provider>
  );
};
export const useGlobal = () => {
  return useContext(GlobalContext);
};
