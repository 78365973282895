import React, { memo, useEffect, useMemo } from 'react'
import FsLightbox from 'fslightbox-react';
import { useGlobal } from '../../../hooks/useGlobal';
// const FsLightbox = ReactFsLightbox.default ? ReactFsLightbox.default : ReactFsLightbox;

const GalleryViewer=()=> {
    const {imageController,setImageController} =useGlobal()
   
    const onHandleClose=()=>{
        setImageController({
            toggler: false,
            slide: 1,
            sources:[]
        })
    }
  return (
    <>
    <FsLightbox
        toggler={imageController.toggler}
        sources={imageController.sources}
        slide={imageController.slide}
    /> 
    </>
  )
}
export default  memo(GalleryViewer)