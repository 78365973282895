import React from "react";
import Shimmer from "./Shimmer";
import SkeletonElement from "./SkeletonElement";

const SkeletonCard = ({ theme }) => {
  const themeClass = theme || "light";

  return (
    <div className={`skeleton-wrapper ${themeClass}`}>
      <div className="skeleton-profile">
        <div>
          <SkeletonElement type="avatar" />
        </div>
        <div>
          <SkeletonElement type="text" />
          <SkeletonElement type="text" />
          <SkeletonElement type="progressbar" />
          <SkeletonElement type="text" />
        </div>
        <div className="">
          <SkeletonElement type="button" />
        </div>
      </div>
      <Shimmer />
    </div>
  );
};

export default SkeletonCard;
