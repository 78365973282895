import React from "react";
import { Button } from "react-bootstrap";
import { useGlobal } from "../../../../hooks/useGlobal";

const Media = ({ label, description, style,selectedPhotoes,fieldName,model,isSingleImg }) => {
  const { toggleMedia,setSelectSingleImage } = useGlobal();
  return (
    <div className={style ? style : "col-md-6"}>
      <div className="card border border-primary">
        <div className="card-body">
          <h5 className="card-title">{label}</h5>
            {selectedPhotoes?.length > 0 && (
              <div className="d-flex ">
                {selectedPhotoes.map((img) => (
                  <div className="img-wrapper px-1">
                    <img
                      src={img.src}
                      className="select-img"
                      alt={`${label}`}
                    />
                  </div>
                ))}
              </div>
            )}
          <small>
            {description ? description : <>Recommended image size 1280x392</>}{" "}
            px. Maximum images: 5.Acceptable image formats: "jpg", "jpeg",
            "png", "gif", "webp"
          </small>
          <div className="col-12 mt-2">
            <Button className="btn-style1"onClick={() => {
              setSelectSingleImage(isSingleImg)
              toggleMedia(fieldName,selectedPhotoes, model)}}
            > {selectedPhotoes?.length > 0
                      ? "Change Image"
                      : "Attach Files"}</Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Media;
