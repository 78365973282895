import React, { useCallback, useEffect, useRef, useState } from "react";
import { organizationService } from "../../../../services/organization.service";
import OrganizationList from "./OrganizationList";
import { useSearch } from "../../../../hooks/useSearch";
import axios from "axios";

export default function AllOrganization() {
  const [allOrganizations, setAllOrganizations] = useState([]);
  const [hasMorePages, setHasMorePages] = useState(false);
  const [nextPagesUrl, setNextPageUrl] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const { searchQuery, onChange } = useSearch();
  const getAllOrganizations = async () => {
    setError(null);

    try {
      const res = await organizationService.getAllOrganizations(
        `?search[name]=${searchQuery}&limit=10`
      );
      setAllOrganizations(res.data);
      setHasMorePages(res.has_more_pages);
      if (res?.next_page_url) setNextPageUrl(res.next_page_url);
    } catch (error) {
      setError("Something went wrong, try again later");
    }
  };
  useEffect(() => {
    getAllOrganizations();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchQuery]);
  const getNextPageOrganizations = async () => {
    setIsLoading(true);
    try {
      const res = await axios.get(nextPagesUrl);
      setAllOrganizations((prevOrgs) => [
        ...new Set([...prevOrgs, ...res.data.data]),
      ]);
      setHasMorePages(res.data.has_more_pages);
      if (res?.data?.next_page_url) setNextPageUrl(res.data.next_page_url);
    } catch (err) {
      setError(err);
    } finally {
      setIsLoading(false);
    }
  };
  const observer = useRef();
  const lastOrgElementRef = useCallback(
    (node) => {
      if (isLoading) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && hasMorePages) {
          getNextPageOrganizations();
        }
      });
      if (node) observer.current.observe(node);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isLoading, hasMorePages]
  );

  return (
    <>
      <OrganizationList
        organizations={allOrganizations}
        searchQuery={searchQuery}
        onChange={onChange}
        onSubmit={getAllOrganizations}
        loading={isLoading}
        error={error}
        orgRef={lastOrgElementRef}
      />
    </>
  );
}
