import React, { useEffect } from "react";
import { Card, Col, Container, Row } from "react-bootstrap";
import { useCampaign } from "../../hooks/useCampaign";
import { ADD } from "../../utilities/constants";
import DonationCompaignForm from "../dashboard/components/Campaign/CampaignForm";

export default function CreateDonationPage() {
  const { setAction, setCampaign, initialState } = useCampaign();
  useEffect(() => {
    setCampaign(initialState);
    setAction(ADD);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <Container>
      <Row>
        <Col sm={12}>
          <Card>
            <Card.Header className="d-flex justify-content-between">
              <div className="header-title">
                <h4 className="card-title">Create Donation Compaign</h4>
              </div>
            </Card.Header>
            <div className="form1 form2">
              <DonationCompaignForm />
            </div>
          </Card>
        </Col>
      </Row>
    </Container>
  );
}
