import { useRef } from "react";
import "./dropZone.css";
const Dropzone = ({
  handleDragOver,
  handleDrop,
  handleInputChange,
  fileName,
}) => {
  const inputRef = useRef();

  return (
    <div
      className="dropzone"
      onDragOver={handleDragOver}
      onDrop={(event) => handleDrop(event, "file")}
    >
      <h3>Drag and Drop Files to Upload</h3>
      <h4>OR</h4>
      <input
        type="file"
        name="file"
        id="file"
        onChange={(event) => handleInputChange(event, "file")}
        hidden
        required
        accept=".png,.jpeg,.jpg.giff"
        ref={inputRef}
      />
      <button onClick={() => inputRef.current.click()}> Select File</button>
      {fileName ? <h4 className="fileName">{fileName}</h4> : null}
      <p className="note">
        The file must be a file of type: jpg, jpeg, png, giff. File size should
        be 5mb or less than it
      </p>
    </div>
  );
};

export default Dropzone;
