import { api } from "./axios.service";

const getObituaries = (params) => {
  console.log(params);
  return new Promise((resolve, reject) => {
    api("GET", null, "", "", `obituaries?${params}`)
      .then((response) => {
        resolve(response.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const obituaryService = {
  getObituaries,
};
