import React, { useEffect, useState } from "react";
import "./gallery.css";
import "react-perfect-scrollbar/dist/css/styles.css";
import axios from "axios";
import { ACCESS_TOKEN } from "../../utilities/constants";
import { mediaService } from "../../services/media.service";
import loader from "../../assets/images/page-img/page-load-loader.gif";
import { useGlobal } from "../../hooks/useGlobal";
import { notifySuccess } from "../toast/Toast";
import Swal from "sweetalert2";
import { useRef } from "react";
import { useCallback } from "react";

const Gallery = () => {
  const questionAlert = (e, reqId) => {
    e.stopPropagation();
    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
        cancelButton: "btn btn-outline-primary btn-lg ms-2",
        confirmButton: "btn btn-primary btn-lg",
      },
      buttonsStyling: false,
    });

    swalWithBootstrapButtons
      .fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        cancelButtonText: "cancel",
        confirmButtonText: "Yes, delete it!",

        reverseButtons: false,
        showClass: {
          popup: "animate__animated animate__zoomIn",
        },
        hideClass: {
          popup: "animate__animated animate__zoomOut",
        },
      })
      .then((result) => {
        if (result.isConfirmed) {
          handleRemove(reqId);
          swalWithBootstrapButtons.fire({
            title: "Deleted!",
            text: "Your Request has been deleted.",
            icon: "success",
            showClass: {
              popup: "animate__animated animate__zoomIn",
            },
            hideClass: {
              popup: "animate__animated animate__zoomOut",
            },
          });
        } else if (
          /* Read more about handling dismissals below */
          result.dismiss === Swal.DismissReason.cancel
        ) {
          swalWithBootstrapButtons.fire({
            title: "Your Request is safe!",
            showClass: {
              popup: "animate__animated animate__zoomIn",
            },
            hideClass: {
              popup: "animate__animated animate__zoomOut",
            },
          });
        }
      });
  };
  const [loading, setLoading] = useState(true);
  const [hasMoreImages, setHasMoreImages] = useState(false);
  const [hoveredImage, setHoveredImage] = useState(null);
  const [nextPageUrl, setNextPageUrl] = useState("");
  // const scrollbarRef = useRef(null);
  const [selectedImages, setSelectedImages] = useState([]);
  const [fetchedImages, setFetchedImages] = useState([]);
  const { toggleMedia, selectSingleImage, setSelectSingleImage } = useGlobal();
  const handleThumbnailClick = (image_id, title, src) => {
    const alreadySelected = selectedImages.some(
      (img) => img.image_id === image_id
    );
    if (alreadySelected) {
      setSelectedImages(
        selectedImages.filter((img) => img.image_id !== image_id)
      );
    } else if (selectSingleImage) {
      const newImage = { image_id, title, src };
      setSelectedImages([newImage]);
    } else {
      const newImage = { image_id, title, src };
      setSelectedImages([...selectedImages, newImage]);
    }
  };
  const handlePublish = () => {
    toggleMedia(null, selectedImages);
  };
  const fetchMedias = async () => {
    setLoading(true);
    // setError(false);
    try {
      const result = await mediaService.fetchImages();
      setFetchedImages(result?.all_image_files);
      setLoading(false);
      setHasMoreImages(result?.has_more_pages);
      setNextPageUrl(result?.next_page_url);
    } catch (err) {
      setLoading(false);

      return err;
    }
  };
  const loadMore = async () => {
    setLoading(true);
    // setError(false);
    try {
      const result = await axios.get(nextPageUrl, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem(ACCESS_TOKEN)}`,
        },
      });
      setFetchedImages([...fetchedImages, ...result.data?.all_image_files]);
      setLoading(false);
      setHasMoreImages(result.data.has_more_pages);
      setNextPageUrl(result.data.next_page_url);
    } catch (err) {
      setLoading(false);
      return err;
    }
  };
  useEffect(() => {
    fetchMedias();
  }, []);
  const handleMouseEnter = (imageId) => {
    setHoveredImage(imageId);
  };

  const handleMouseLeave = () => {
    setHoveredImage(null);
  };
  const handleRemove = async (imageId) => {
    try {
      const result = await mediaService.deleteMedia(imageId);
      if (result.success) {
        setFetchedImages(
          fetchedImages.filter((ele) => ele.image_id !== imageId)
        );
        setSelectedImages(
          selectedImages.filter((ele) => ele.image_id !== imageId)
        );
        handleMouseLeave();
      }
    } catch (err) {
      notifySuccess("Error Occured");
      return err;
    }
  };
  //method for infinite scrolling
  const observer = useRef();
  const lastImgRef = useCallback(
    (node) => {
      if (loading) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && hasMoreImages) {
          loadMore();
        }
      });
      if (node) observer.current.observe(node);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [hasMoreImages, loading]
  );
  useEffect(() => {
    // 👇️ scroll to top on page load
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);
  return (
    <div className="row" style={{ overflow: "auto" }}>
      <div style={{ height: "500px" }}>
        <div className="d-grid gap-2 d-grid-template-1fr-13">
          {fetchedImages?.map((ele, index) => {
            const isLastImg = fetchedImages.length === index + 1;
            return isLastImg ? (
              <div key={index} ref={lastImgRef}>
                <div
                  className="user-images position-relative overflow-hidden pics"
                  onClick={() =>
                    handleThumbnailClick(
                      ele.image_id,
                      ele.title,
                      ele.thumbnail_url
                    )
                  }
                  onMouseEnter={() => handleMouseEnter(ele.image_id)}
                  onMouseLeave={handleMouseLeave}
                >
                  <img
                    loading="lazy"
                    alt={ele.title}
                    src={ele.thumbnail_url}
                    className={`${
                      selectedImages.some(
                        (img) => img.image_id === ele.image_id
                      )
                        ? "selected"
                        : "img-fluid rounded"
                    }`}
                  />
                  {selectedImages.some(
                    (img) => img.image_id === ele.image_id
                  ) && <div className="tick-mark">&#10004;</div>}
                  {hoveredImage === ele.image_id && (
                    <div className="image-hover-data d-flex">
                      <button
                        type="button"
                        className="btn btn-danger btn-sm remove-button"
                        onClick={(e) => questionAlert(e, ele.image_id)}
                      >
                        Remove
                      </button>
                    </div>
                  )}
                </div>
              </div>
            ) : (
              <div key={index}>
                <div
                  className="user-images position-relative overflow-hidden pics"
                  onClick={() =>
                    handleThumbnailClick(
                      ele.image_id,
                      ele.title,
                      ele.thumbnail_url
                    )
                  }
                  onMouseEnter={() => handleMouseEnter(ele.image_id)}
                  onMouseLeave={handleMouseLeave}
                >
                  <img
                    loading="lazy"
                    alt={ele.title}
                    src={ele.thumbnail_url}
                    className={`${
                      selectedImages.some(
                        (img) => img.image_id === ele.image_id
                      )
                        ? "selected"
                        : "img-fluid rounded"
                    }`}
                  />
                  {selectedImages.some(
                    (img) => img.image_id === ele.image_id
                  ) && <div className="tick-mark">&#10004;</div>}
                  {hoveredImage === ele.image_id && (
                    <div className="image-hover-data d-flex">
                      <button
                        type="button"
                        className="btn btn-danger btn-sm remove-button"
                        onClick={(e) => questionAlert(e, ele.image_id)}
                      >
                        Remove
                      </button>
                    </div>
                  )}
                </div>
              </div>
            );
          })}
        </div>
        {/* {hasMoreImages ? (
          <div className="row  justify-content-start">
            <div className="col-md-2 col-xs-12">
              <Button variant="outline-primary" onClick={() => loadMore()}>
                Load More
              </Button>
            </div>
          </div>
        ) : null} */}
        {selectedImages.length > 0 ? (
          <div className="row  justify-content-end p-3">
            <div
              className="col-md-2 col-xs-12 btn btn-primary"
              onClick={() => {
                handlePublish();
                setSelectSingleImage(false);
              }}
            >
              Select
            </div>
          </div>
        ) : null}
        {loading ? (
          <div className="col-sm-12 text-center p-5">
            <img
              loading="lazy"
              src={loader}
              alt="loader"
              style={{ height: "100px" }}
            />
          </div>
        ) : (
          <>
            {fetchedImages.length === 0 ? (
              <div className="d-flex justify-content-center">
                <h3>Please Upload your photos</h3>
              </div>
            ) : null}
          </>
        )}
      </div>
    </div>
  );
};

export default Gallery;
