import React, { Fragment, useEffect, useState } from "react";

export default function DynamicSocialMediaProfile({socialMediaprofiles,handleSocialMediaProfiles}) {
  const [inputFields, setInputFields] = useState([
    { social_media: "", url: "" },
  ]);

  useEffect(()=>{
    socialMediaprofiles.length > 0 && setInputFields(socialMediaprofiles)
  },[socialMediaprofiles])

  const handleAddFields = () => {
    const values = [...inputFields];
    values.push({ social_media: "", url: "" });
    handleSocialMediaProfiles('social_media_profiles',values);
  };

  const handleRemoveFields = (index) => {
    const values = [...inputFields];
    values.splice(index, 1);
    handleSocialMediaProfiles('social_media_profiles',values);
  };

  const handleInputChange = (index, event) => {
    const values = [...inputFields];
    if (event.target.name === "social_media") {
      values[index].social_media = event.target.value;
    } else {
      values[index].url = event.target.value;
    }

    handleSocialMediaProfiles('social_media_profiles',values);
  };

  return (
    <>
      <div className="form-row">
        {inputFields.map((inputField, index) => (
          <Fragment key={`${inputField}~${index}`}>
            <div
              className="row p-2 mb-3 d-flex align-items-center"
              style={{ backgroundColor: "#f5f2efc4" }}
            >
              <div className="form-group col-md-6 col-xs-12">
                <label htmlFor="social_media">Social Media</label>
                <select
                  name="social_media"
                  className="form-control social_media"
                  id="social_media"
                  value={inputField.social_media}
                  onChange={(event) => handleInputChange(index, event)}
                >
                  <option value>Select Social Media Name</option>
                  <option value="facebook">Facebook</option>
                  <option value="twitter">Twitter</option>
                  <option value="instagram">Instagram</option>
                  <option value="linkedin">Linkedin</option>
                  <option value="pinterest">Pinterest</option>
                  <option value="youtube">Youtube</option>
                  <option value="vimeo">Vimeo</option>
                  <option value="whatsapp">Whatsapp</option>
                  <option value="telegram">Telegram</option>
                  <option value="skype">Skype</option>
                  <option value="snapchat">Snapchat</option>
                  <option value="flickr">Flickr</option>
                  <option value="tumblr">Tumblr</option>
                  <option value="foursquare">Foursquare</option>
                  <option value="behance">Behance</option>
                  <option value="dribbble">Dribbble</option>
                  <option value="github">Github</option>
                  <option value="bitbucket">Bitbucket</option>
                </select>
              </div>
              <div className="form-group col-md-6 col-xs-12">
                <label htmlFor="url">Url</label>
                <input
                  type="url"
                  name="url"
                  className="form-control"
                  id="url"
                  value={inputField.url}
                  onChange={(event) => handleInputChange(index, event)}
                  placeholder="Enter URL"
                />
              </div>
              {index > 0 ? (
                <div className="col-2 col-xs-4">
                  <button
                    className="btn btn-danger"
                    type="button"
                    disabled={index === 0}
                    onClick={() => handleRemoveFields(index)}
                  >
                    Remove
                  </button>
                </div>
              ) : null}
            </div>
          </Fragment>
        ))}
      </div>
      <div className="row d-flex align-items-center justify-content-left">
        <div
          className="col-md-2 col-xs-12 btn btn-success"
          onClick={() => handleAddFields()}
        >
          Add More
        </div>
      </div>

      {/* <br/>
          <pre>
            {JSON.stringify(inputFields, null, 2)}
          </pre> */}
    </>
  );
}
