import { api } from "./axios.service";

const fetchAllCompaigns = (params) => {
  return new Promise((resolve, reject) => {
    api("GET", null, "", "", `campaigns${params}`)
      .then((response) => {
        resolve(response.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

const fetchDonateSuggestions = (params) => {
  return new Promise((resolve, reject) => {
    api("GET", null, "", "", `campaigns${params}`)
      .then((response) => {
        resolve(response.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

const fetchIndividualCampaigns = (params) => {
  return new Promise((resolve, reject) => {
    api("GET", "user", "", "", `${params}`)
      .then((response) => {
        resolve(response.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
const createCampaign = (reqBody)=>{
  return new Promise((resolve, reject) => {
      api('POST',`user/campaigns`,'token',reqBody, '')
        .then((response) => {
          resolve(response.data);
        })
      .catch((err) => {
          reject(err)
      });
  });
}
const updateCampaign = (reqBody,slug)=>{
  return new Promise((resolve, reject) => {
      api('PUT',`user/campaigns`,'token',reqBody,slug )
        .then((response) => {
          resolve(response.data);
        })
      .catch((err) => {
          reject(err)
      });
  });
}
const getCreateCampaignDetails = ()=>{
  return new Promise((resolve, reject) => {
      api('GET',`user/campaigns/create`,'token','', '')
        .then((response) => {
          resolve(response.data);
        })
      .catch((err) => {
          reject(err)
      });
  });
}
const getEditCampaignDetails = (slug)=>{
  return new Promise((resolve, reject) => {
      api('GET',`user/campaigns/${slug}/edit`,'token','', '')
        .then((response) => {
          resolve(response.data);
        })
      .catch((err) => {
          reject(err)
      });
  });
}
const fetchSingleCompaign = (params) => {
  return new Promise((resolve, reject) => {
    api("GET", "campaigns", "", "", `${params}`)
      .then((response) => {
        resolve(response.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
export const donationCompaignService = {
  fetchAllCompaigns,
  fetchIndividualCampaigns,
  fetchSingleCompaign,
  createCampaign,
  updateCampaign,
  getCreateCampaignDetails,
  getEditCampaignDetails,
  fetchDonateSuggestions
};
