import React, { useEffect, useState } from "react";
import { DATE_OPTIONS } from "../../../../utilities/constants";
import { profileService } from "../../../../services/user.service";
import { FiEdit } from "react-icons/fi";
import { Modal, Spinner } from "react-bootstrap";
import Select from "react-select";
import { authService } from "../../../../services/auth.service";
import {
  notifyFailed,
  notifySuccess,
} from "../../../../components/toast/Toast";

const BasicInfo = () => {
  const [basicInfo, setBasicInfo] = useState({});
  const [srilankaId, setSrilankaId] = useState(null);
  const [loading, setLoading] = useState(false);
  const [showClose, setShowClose] = useState(false);
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    address: "",
    city: "",
    state: "",
    country_id: "",
    zipcode: "",
    gn_division_id: "",
    industry_id: "",
    job_title: "",
  });
  const [countryList, setCountryList] = useState([]);
  const countryOptions = countryList?.map((country) => ({
    value: country.id,
    label: country.name,
  }));
  const [industries, setIndustries] = useState([]);
  const industryOptions = industries?.map((industry) => ({
    value: industry.id,
    label: industry.name,
  }));
  const [gnDivisionList, setGnDivisionList] = useState([]);
  const gnDivisionOptions = gnDivisionList?.map((gnDivision) => ({
    value: gnDivision.id,
    label: gnDivision.name,
  }));
  const getCountyLists = () => {
    authService
      .getDataForUserRegistration()
      .then((data) => {
        setCountryList(data.all_countries);
        setIndustries(data.all_industries);
        setGnDivisionList(data.all_gn_divisions);
        setSrilankaId(data.sri_lanka_id);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  useEffect(() => {
    getCountyLists();
  }, []);
  const fetchBasicInfo = async () => {
    try {
      const res = await profileService.getMyProfile();
      setBasicInfo(res);
    } catch (err) {
      return err;
    }
  };
  useEffect(() => {
    fetchBasicInfo();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((previousState) => ({ ...previousState, [name]: value }));
  };
  const handleShowClose = () => {
    setShowClose(!showClose);
  };
  const openCloseForm = () => {
    handleShowClose(!showClose);
    setFormData({
      name: basicInfo.name,
      email: basicInfo.email,
      phone: basicInfo.phone,
      address: basicInfo.address,
      city: basicInfo.city,
      state: basicInfo.state,
      country_id: basicInfo.country?.id,
      zipcode: basicInfo.zipcode,
      gn_division_id: basicInfo.gn_division?.id,
      industry_id: basicInfo.industry?.id,
      job_title: basicInfo.job_title,
    });
  };
  const handleSend = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const res = await profileService.updateBasicInfo(formData);
      if (res?.success) {
        setBasicInfo(res.user);
        setShowClose(!showClose);
        notifySuccess("Your information updated successfully");
      }
    } catch (err) {
      notifyFailed(err.message ?? "Failed Occur");
      return err;
    } finally {
      setLoading(false);
    }
  };
  return (
    <div className="m-2 p-2">
      <div className="d-flex justify-content-between align-items-center">
        <h4 className="text-dark">
          {!showClose ? "Basic Information" : "Edit basic Information"}
        </h4>
        {!showClose && (
          <div className="my-button" onClick={openCloseForm}>
            <FiEdit className="m-1" size={20} />
          </div>
        )}
      </div>

      {!showClose ? (
        <>
          <div className="row mt-2 p-1">
            <div className="col-3">
              <h6>Name :</h6>
            </div>
            <div className="col-9">
              <p className="mb-0">{basicInfo?.name}</p>
            </div>
          </div>

          <div className="row p-1">
            <div className="col-3">
              <h6>Job :</h6>
            </div>
            <div className="col-9">
              <p className="mb-0">
                {basicInfo?.job_title ? basicInfo?.job_title : "Not Specified"}
              </p>
            </div>
          </div>
          <div className="row p-1">
            <div className="col-3">
              <h6>Industry :</h6>
            </div>
            <div className="col-9">
              <p className="mb-0">
                {basicInfo?.industry?.name
                  ? basicInfo?.industry?.name
                  : "Not Specified"}
              </p>
            </div>
          </div>
          <div className="row p-1">
            <div className="col-3">
              <h6>Email:</h6>
            </div>
            <div className="col-9">
              <p className="mb-0">{basicInfo?.email}</p>
            </div>
          </div>
          <div className="row p-1">
            <div className="col-3">
              <h6>Phone:</h6>
            </div>
            <div className="col-9">
              <p className="mb-0">
                {basicInfo?.phone ? basicInfo.phone : "Not Specified"}
              </p>
            </div>
          </div>
          <div className="row p-1">
            <div className="col-3">
              <h6>Address:</h6>
            </div>
            <div className="col-9">
              <p className="mb-0">
                {basicInfo?.address ? basicInfo?.address : "Not Specified"}
              </p>
            </div>
          </div>
          <div className="row p-1">
            <div className="col-3">
              <h6>Country:</h6>
            </div>
            <div className="col-9">
              <p className="mb-0">{basicInfo?.country?.name}</p>
            </div>
          </div>
          <div className="row p-1">
            <div className="col-3">
              <h6>State:</h6>
            </div>
            <div className="col-9">
              <p className="mb-0">
                {basicInfo?.state ? basicInfo?.state : "Not Specified"}
              </p>
            </div>
          </div>
          <div className="row p-1">
            <div className="col-3">
              <h6>City:</h6>
            </div>
            <div className="col-9">
              <p className="mb-0">{basicInfo?.city}</p>
            </div>
          </div>
          <div className="row p-1">
            <div className="col-3 ">
              <h6>GN Division:</h6>
            </div>
            <div className="col-9 ">
              <p className="mb-0">
                {basicInfo?.gn_division?.name
                  ? basicInfo?.gn_division?.name
                  : "Not Specified"}
              </p>
            </div>
          </div>
          <div className="row p-1">
            <div className="col-3">
              <h6>Zip code:</h6>
            </div>
            <div className="col-9">
              <p className="mb-0">
                {basicInfo?.zipcode ? basicInfo?.zipcode : "Not Spcified"}
              </p>
            </div>
          </div>
          <div className="row p-1">
            <div className="col-3">
              <h6>Joined at:</h6>
            </div>
            <div className="col-9">
              {new Date(basicInfo?.created_at).toLocaleDateString(
                "en-US",
                DATE_OPTIONS
              )}
            </div>
          </div>
        </>
      ) : (
        <Modal show={showClose} onHide={handleShowClose} size="lg">
          <Modal.Header closeButton>
            <Modal.Title>Edit Basic Info</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <form onSubmit={handleSend}>
              <div className="row p-2 mb-2 d-flex align-items-center">
                <div className="form-group col-md-6 col-xs-12">
                  <label htmlFor="gender">Name</label>
                  <input
                    className="form-control"
                    name="name"
                    type="text"
                    value={formData.name}
                    onChange={handleInputChange}
                  />
                </div>
                <div className="form-group col-md-6 col-xs-12">
                  <label htmlFor="gender">Job</label>
                  <input
                    className="form-control"
                    name="job_title"
                    type="text"
                    required
                    value={formData.job_title || ""}
                    onChange={handleInputChange}
                  />
                </div>
                <div className="form-group col-md-6 col-xs-12">
                  <label htmlFor="industry">Industry</label>
                  <Select
                    required
                    className="dropdown"
                    placeholder="Select Industry"
                    name="industry_id"
                    value={
                      industryOptions.find(
                        (obj) => obj.value === formData.industry_id
                      ) || ""
                    } // use find instead of filter
                    options={industryOptions}
                    onChange={(e) =>
                      setFormData((previousState) => ({
                        ...previousState,
                        industry_id: e.value,
                      }))
                    } // ass
                  />
                </div>
                <div className="form-group col-md-6 col-xs-12">
                  <label htmlFor="gender">Email</label>
                  <input
                    className="form-control"
                    name="email"
                    type="email"
                    value={formData.email}
                    onChange={handleInputChange}
                  />
                </div>
                <div className="form-group col-md-6 col-xs-12">
                  <label htmlFor="gender">Phone No</label>
                  <input
                    className="form-control"
                    name="phone"
                    type="text"
                    value={formData.phone || ""}
                    onChange={handleInputChange}
                  />
                </div>

                <div className="form-group col-md-6 col-xs-12">
                  <label htmlFor="country">Country</label>
                  <Select
                    required
                    className="dropdown"
                    placeholder="Select Country"
                    name="country_id"
                    value={countryOptions.filter(
                      (obj) => obj.value === formData.country_id
                    )} // set selected values
                    options={countryOptions} // set list of the data
                    onChange={(e) => {
                      if (e.value !== srilankaId) {
                        setFormData((previousState) => ({
                          ...previousState,
                          gn_division_id: null,
                        }));
                      }
                      setFormData((previousState) => ({
                        ...previousState,
                        country_id: e.value,
                      }));
                    }} // assign onChange function
                  />
                </div>
                {formData.country_id === srilankaId && (
                  <div className="form-group col-md-6 col-xs-12">
                    <label htmlFor="gender">Gn Division</label>
                    <Select
                      required
                      className="dropdown"
                      placeholder="Select Gn Division"
                      name="gn_division_id"
                      value={
                        gnDivisionOptions.find(
                          (obj) => obj.value === formData.gn_division_id
                        ) || ""
                      } // use find instead of filter
                      options={gnDivisionOptions}
                      onChange={(e) =>
                        setFormData((previousState) => ({
                          ...previousState,
                          gn_division_id: e.value,
                        }))
                      }
                    />
                  </div>
                )}

                <div className="form-group col-md-6 col-xs-12">
                  <label htmlFor="state">State</label>
                  <input
                    className="form-control"
                    name="state"
                    type="text"
                    value={formData.state || ""}
                    onChange={handleInputChange}
                  />
                </div>
                <div className="form-group col-md-6 col-xs-12">
                  <label htmlFor="city">City</label>
                  <input
                    className="form-control"
                    name="city"
                    type="text"
                    value={formData.city || ""}
                    onChange={handleInputChange}
                  />
                </div>
                <div className="form-group col-md-6 col-xs-12">
                  <label htmlFor="city">Zipcode</label>
                  <input
                    className="form-control"
                    name="zipcode"
                    type="text"
                    value={formData.zipcode || ""}
                    onChange={handleInputChange}
                  />
                </div>
                <div className="form-group col-xs-12">
                  <label htmlFor="gender">Address</label>
                  <input
                    className="form-control"
                    name="address"
                    type="text"
                    value={formData.address || ""}
                    onChange={handleInputChange}
                  />
                </div>
              </div>
              <div className="d-flex">
                <div className="col-md-2 col-xs-6">
                  <button
                    className="btn btn-danger"
                    type="submit"
                    disabled={loading}
                  >
                    {loading ? (
                      <Spinner animation="border" size="sm" />
                    ) : (
                      "Send"
                    )}
                  </button>
                </div>
              </div>
            </form>
          </Modal.Body>
        </Modal>
      )}
    </div>
  );
};

export default BasicInfo;
