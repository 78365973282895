import { createContext, useContext, useState } from "react";

let INITIAL_STATE = {
  id: null,
  name: "",
  email: "",
  mobile: "",
  dob: "",
  birthPlace: "",
  gender: "",
  joined: "",
  statue: "",
  aboutMe: "",
  languages: [],
  interestedIn: [],
  residentInfo: [],
  workPlacesInfo: [],
  educationsInfo: [],
  websites: [],
  socialLinks: [],
};
const MyProfileContext = createContext();
const MyProfileContextProvider = ({ children }) => {
  const [profileData, setProfileData] = useState(INITIAL_STATE);
  const [openComponents, setOpenComponents] = useState([]);
  const [galleryImages, setGalleryImages] = useState([]);
  const [videoLists, setVideoLists] = useState([]);
  const handleOpenForm = (value) => {
    if (!openComponents.includes(value)) {
      setOpenComponents([...openComponents, value]);
    } else {
      let filterComponents = openComponents.filter((ele) => ele !== value);
      setOpenComponents(filterComponents);
    }
  };
  const handleWorkPlace = (obj) => {
    setProfileData({ ...profileData, workPlacesInfo: obj });
  };
  const handleEducation = (obj) => {
    console.log(obj);
    const alreadyExistingEducation = profileData.educationsInfo.includes(
      obj.id
    );
    console.log(alreadyExistingEducation);

    setProfileData({ ...profileData, educationsInfo: obj });
  };
  const handleResidentials = (obj) => {
    setProfileData({ ...profileData, residentInfo: obj });
  };
  const handleWebsites = (obj) => {
    setProfileData({ ...profileData, websites: obj });
  };
  return (
    <MyProfileContext.Provider
      value={{
        profileData,
        setProfileData,
        openComponents,
        handleOpenForm,
        handleWorkPlace,
        handleEducation,
        handleResidentials,
        handleWebsites,
        galleryImages,
        setGalleryImages,
        videoLists,
        setVideoLists,
      }}
    >
      {children}
    </MyProfileContext.Provider>
  );
};
const useMyProfile = () => {
  return useContext(MyProfileContext);
};
export { useMyProfile, MyProfileContextProvider };
