import React, { useEffect } from 'react';
import { Container, Row, Col, Image, Card } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import logo from '../../../assets/images/logo.png';

const ComingSoon = () => {
  useEffect(() => {
    const getTimeRemaining = (endtime) => {
      const total = Date.parse(endtime) - Date.parse(new Date());
      const seconds = Math.floor((total / 1000) % 60);
      const minutes = Math.floor((total / 1000 / 60) % 60);
      const hours = Math.floor((total / (1000 * 60 * 60)) % 24);
      const days = Math.floor(total / (1000 * 60 * 60 * 24));

      return {
        total,
        days,
        hours,
        minutes,
        seconds
      };
    };

    const initializeClock = (elem, endtime) => {
      const clock = document.querySelector(elem);
      const daysSpan = clock.querySelector('[data-days]');
      const hoursSpan = clock.querySelector('[data-hours]');
      const minutesSpan = clock.querySelector('[data-minutes]');
      const secondsSpan = clock.querySelector('[data-seconds]');

      let timeinterval;

      const updateClock = () => {
        const t = getTimeRemaining(endtime);

        daysSpan.innerHTML = t.days;
        hoursSpan.innerHTML = ('0' + t.hours).slice(-2);
        minutesSpan.innerHTML = ('0' + t.minutes).slice(-2);
        secondsSpan.innerHTML = ('0' + t.seconds).slice(-2);

        if (t.total <= 0) {
          clearInterval(timeinterval);
        }
      };

      updateClock();
      timeinterval = setInterval(updateClock, 1000);
    };

    const time = document.querySelector('.countdown').getAttribute('data-date');
    const deadline = new Date(time);
    initializeClock('.countdown', deadline);
  }, []);

  return (
    <>
      <div className="iq-comingsoon pt-5">
        <Container>
          <Row className="justify-content-center">
            <Col md="12" className="text-center">
              <div className="iq-comingsoon-info">
                <Link
                  className="sign-in-logo mb-5 d-flex align-items-center justify-content-center gap-2 iq-header-logo d-none d-lg-flex"
                  to="#"
                >
                  <Image src={logo} className="img-fluid" alt="logo" />
                  <h3 className="logo-title d-none d-sm-block" data-setting="app_name">
                    Zone Ceylon
                  </h3>
                </Link>
                <h2 className="mt-4 mb-1">Stay tuned, we're launching very soon</h2>
                <p>We are working very hard to give you the best experience possible!</p>
                <ul className="countdown row list-inline d-flex align-items-center" data-date="Sep 31 2023 12:00:00">
                  <Col as="li" md="6" xl="3">
                    <Card>
                      <Card.Body>
                        <span className="text-center" data-days>
                          0
                        </span>
                        Days
                      </Card.Body>
                    </Card>
                  </Col>
                  <Col as="li" md="6" xl="3">
                    <Card>
                      <Card.Body>
                        <span data-hours>0</span>
                        Hours
                      </Card.Body>
                    </Card>
                  </Col>
                  <Col as="li" md="6" xl="3">
                    <Card>
                      <Card.Body>
                        <span data-minutes>0</span>
                        Minutes
                      </Card.Body>
                    </Card>
                  </Col>
                  <Col as="li" md="6" xl="3">
                    <Card>
                      <Card.Body>
                        <span data-seconds>0</span>
                        Seconds
                      </Card.Body>
                    </Card>
                  </Col>
                </ul>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default ComingSoon;
