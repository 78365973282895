import { createContext, useContext, useState } from "react";
const FollowContext = createContext();
const FollowContextProvider = ({ children }) => {
  const [receivedFriendRequest, setReceivedFriendRequest] = useState([]);
  const [listPeople, setListPeople] = useState([]);
  const [friendLists, setFriendLists] = useState([]);
  const [orgReqs, setOrgReqs] = useState([]);

  return (
    <FollowContext.Provider
      value={{
        receivedFriendRequest,
        setReceivedFriendRequest,
        listPeople,
        setListPeople,
        orgReqs,
        setOrgReqs,
        friendLists,
        setFriendLists,
      }}
    >
      {children}
    </FollowContext.Provider>
  );
};
const useFollow = () => {
  return useContext(FollowContext);
};
export { useFollow, FollowContextProvider };
