import React from "react";
import { Card, Col, Nav, Row, Tab } from "react-bootstrap";
import { Outlet, useNavigate, useParams } from "react-router-dom";
import { useTab } from "../../../../hooks/useTab";
const AboutTab = () => {
  const navigate = useNavigate();
  const { activeSubTab, handleSubTabChange } = useTab();
  const params = useParams();
  return (
    <Tab.Pane eventKey="second">
      <Row>
        <Col md={4}>
          <Card>
            <Card.Body>
              <Nav
                variant="pills"
                defaultActiveKey={activeSubTab}
                className=" basic-info-items list-inline d-block p-0 m-0"
              >
                <Nav.Item>
                  <Nav.Link
                    eventKey="firstSubTab"
                    onClick={() => {
                      navigate(
                        `/my-profile/${params.userName}/about/basicInfo`
                      );
                      handleSubTabChange("firstSubTab");
                    }}
                  >
                    Basic Information
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link
                    onClick={() => {
                      navigate(
                        `/my-profile/${params.userName}/about/personal-information`
                      );
                      handleSubTabChange("secondSubTab");
                    }}
                    eventKey="secondSubTab"
                  >
                    Personal Information
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link
                    onClick={() => {
                      navigate(
                        `/my-profile/${params.userName}/about/web-social-links`
                      );
                      handleSubTabChange("thirdSubTab");
                    }}
                    eventKey="thirdSubTab"
                  >
                    Websites and Social Links
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link
                    onClick={() => {
                      navigate(
                        `/my-profile/${params.userName}/about/work-places`
                      );
                      handleSubTabChange("fourthSubTab");
                    }}
                    eventKey="fourthSubTab"
                  >
                    Work Places
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link
                    onClick={() => {
                      navigate(
                        `/my-profile/${params.userName}/about/educations`
                      );
                      handleSubTabChange("fifthSubTab");
                    }}
                    eventKey="fifthSubTab"
                  >
                    Educations
                  </Nav.Link>
                </Nav.Item>
              </Nav>
            </Card.Body>
          </Card>
        </Col>
        <Col md={8} className="ps-4">
          <Card>
            <Outlet />
          </Card>
        </Col>
      </Row>
    </Tab.Pane>
  );
};

export default AboutTab;
