import React from "react";
import { Button, Card, Col, Modal, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
const CompaignActivityModal = ({ isHidden, onClose, activity }) => {
  return (
    <Modal show={isHidden} onHide={onClose} size="lg">
      <Modal.Header closeButton>
        <Modal.Title>{activity?.title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>{activity?.description}</p>
        <Row>
          <Col sm="4">
            <Card>
              <div className="event-images position-relative">
                <Link to="#">
                  <img
                    loading="lazy"
                    src={activity?.image?.large_img_url}
                    className="img-fluid"
                    alt="Responsive"
                    style={{ height: "200px" }}
                  />
                </Link>
              </div>
            </Card>
          </Col>
        </Row>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={onClose}>Close</Button>
      </Modal.Footer>
    </Modal>
  );
};

export default CompaignActivityModal;
