import React, { Fragment, useEffect, useState } from "react";

export default function DynamicTitleTranslation({titleTrans,handleTitleTrans,languages}) {
  const [inputFields, setInputFields] = useState([
    { language: "", translation: "" },
  ]);

  useEffect(() => {
    titleTrans?.length > 0 && setInputFields(titleTrans);
  }, [titleTrans]);

  const handleAddFields = () => {
    const values = [...inputFields];
    values.push({ language: "", translation: "" });
    handleTitleTrans("title_translations", values);
  };

  const handleRemoveFields = (index) => {
    const values = [...inputFields];
    values.splice(index, 1);
    handleTitleTrans("title_translations", values);
  };

  const handleInputChange = (index, event) => {
    const values = [...inputFields];
    if (event.target.name === "language") {
      values[index].language = event.target.value;
    } else {
      values[index].translation = event.target.value;
    }
    handleTitleTrans("title_translations", values);
  };

  return (
    <>
      <div className="form-row">
        {inputFields.map((inputField, index) => (
          <Fragment key={`${inputField}~${index}`}>
            <div
              className="row p-3 mb-3 d-flex align-items-center"
              style={{ backgroundColor: "#f5f2efc4" }}
            >
              <div className="form-group col-md-6 col-xs-12">
                <label htmlFor="language">Language</label>
                <select
                  className="form-control"
                  id="language"
                  name="language"
                  value={inputField.language}
                  onChange={(event) => handleInputChange(index, event)}
                >
                  <option value="" data-name="" data-code="" data-direction="">
                    Select Language
                  </option>
                  {languages.map((lang)=>(<option value={lang.id} key={`lang-${lang.id}`}>{lang.name}</option>))}
                </select>
              </div>

              <div className="form-group col-md-6 col-xs-12">
                <label htmlFor="translation">Translation</label>
                <input
                  type="text"
                  className="form-control"
                  id="translation"
                  name="translation"
                  value={inputField.translation}
                  onChange={(event) => handleInputChange(index, event)}
                />
              </div>

              {index > 0 ? (
                <div className="col-2 col-xs-4">
                  <button
                    className="btn btn-danger"
                    type="button"
                    disabled={index === 0}
                    onClick={() => handleRemoveFields(index)}
                  >
                    Remove
                  </button>
                </div>
              ) : null}
            </div>
          </Fragment>
        ))}
      </div>
      <div className="row d-flex align-items-center justify-content-start p-2">
        <div
          className="col-md-2 col-xs-12 btn btn-success"
          onClick={() => handleAddFields()}
        >
          Add More
        </div>
      </div>
    </>
  );
}
