import React, { useEffect, useMemo } from "react";
import { Card, Col, Container, Row } from "react-bootstrap";
import { useParams } from "react-router-dom";
import { useCampaign } from "../../hooks/useCampaign";
import { donationCompaignService } from "../../services/donationCompaign.service";
import { EDIT } from "../../utilities/constants";
import DonationCompaignForm from "../dashboard/components/Campaign/CampaignForm";
import { EditorState, ContentState } from "draft-js";
import htmlToDraft from "html-to-draftjs";

export default function EditDonationPage() {
  const {setAction,setCampaign} =useCampaign()
  let { donationSlug } = useParams();
  const getDonationDetails=(slug)=>{
    donationCompaignService.getEditCampaignDetails(slug)
    .then(data=>{
      const {
        title,
        slug,
        title_translations,
        required_amount,
        excerpt,

        category,
        deadline,
        meta_tags,
        meta_title,
        meta_description,

        campaign_content,
        organization_id,
        image,
        image_gallery,
        medical_documents,
        videos,
        faqs,
        og_meta_title,
        og_meta_description,
        og_meta_image
      } = data.campaign
      const contentBlock = htmlToDraft(campaign_content);
      const contentState1 = ContentState.createFromBlockArray(
        contentBlock.contentBlocks
      );
      const editorState1 = EditorState.createWithContent(contentState1);
      setCampaign({
        title,
        slug,
        title_translations : title_translations ?? [],
        amount:required_amount,
        excerpt,

        category_id:category.id,
        deadline:deadline ? deadline :'',
        meta_tags: [],
        meta_title:meta_title ?? '',
        meta_description:meta_description ?? '',

        campaign_content: { editorState: editorState1 },
        organization_id,
        image:image ? [image]:[],
        image_gallery:image_gallery ?? [],
        medical_documents:medical_documents ?? [],
        videos:videos ?? [],
        faqs:faqs??[],
        og_meta_title:og_meta_title ?? '',
        og_meta_description:og_meta_description ?? '',
        og_meta_image:og_meta_image ?[...og_meta_image]: []
      })
    })
  }
  useEffect(() => {
    donationSlug && getDonationDetails(donationSlug)
    setAction(EDIT);
  }, [donationSlug]);
  return (
    <Container>
      <Row>
        <Col sm={12}>
          <Card>
            <Card.Header className="d-flex justify-content-between">
              <div className="header-title">
                <h4 className="card-title">Edit Donation Compaign</h4>
              </div>
            </Card.Header>
            <div className="form1 form2">
            <DonationCompaignForm />
            </div>
          </Card>
        </Col>
      </Row>
    </Container>
  );
}
