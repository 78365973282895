import React, { useState } from "react";
import { Button, Card } from "react-bootstrap";
// import Stepper from "../../../components/stepper/Stepper";

import DeceaseDetails from "./steps/DeceaseDetails";
import { Form } from "react-router-dom";
import Stepper from "./stepper/Stepper";
import ObituaryDetails from "./steps/ObituaryDetails";
import ContactDetails from "./steps/ContactDetails";
import SourceDetails from "./steps/SourceDetails";
import EventsDetails from "./steps/EventsDetails";

export default function Obituary() {
  const [step, setStep] = useState(0);
  const [stepPercentage, setStepPercentage] = useState(25);
  const displayForms = () => {
    switch (step) {
      case 1:
        return <ObituaryDetails />;
      case 2:
        return <EventsDetails />;
      case 3:
        return <ContactDetails />;
      case 4:
        return <SourceDetails />;
      default:
        return <DeceaseDetails />;
    }
  };
  return (
    <>
      <Form>
        <Card>
          <Card.Body>
            <Card.Title className="px-5">
              <Stepper stepPercentage={stepPercentage} step={4} />
            </Card.Title>
            {displayForms()}
          </Card.Body>
          <Card.Footer>
            <div className="d-flex justify-content-end">
              <div className="m-2">
                {step > 0 ? (
                  <Button
                    style={{ backgroundColor: "#374254", border: "none" }}
                    onClick={() => {
                      setStep(step - 1);
                      setStepPercentage(stepPercentage - 20);
                    }}
                  >
                    Previous
                  </Button>
                ) : null}
              </div>
              <div className="m-2">
                {step < 4 ? (
                  <Button
                    variant="danger"
                    onClick={() => {
                      setStep(step + 1);
                      setStepPercentage(stepPercentage + 20);
                    }}
                  >
                    Next
                  </Button>
                ) : null}
              </div>
              {/* <div className="m-2">
                {step === 4 ? <Button variant="success">Submit</Button> : null}
              </div> */}
            </div>
          </Card.Footer>
        </Card>
      </Form>
    </>
  );
}
