import React from "react";
import { Card, Container, Nav, Row, Tab } from "react-bootstrap";
import "../../../../assets/css/style.css";
import { IoMdPeople } from "react-icons/io";
import { RiUserReceived2Line, RiUserShared2Line } from "react-icons/ri";
import { Outlet, useNavigate } from "react-router-dom";
import { useTab } from "../../../../hooks/useTab";

const People = () => {
  const navigate = useNavigate();
  const { activeTab, handleTabChange } = useTab();
  console.log(activeTab);
  return (
    <Container className="relative">
      <Row className="mt-3">
        <Tab.Container
          id="left-tabs-example"
          activeKey={activeTab}
          onSelect={handleTabChange}
        >
          <Card className="p-0">
            <Card.Body className="p-0">
              <div className="d-flex profile-feed-items  align-items-center justify-content-around">
                <Nav.Item
                  as="li"
                  role="button"
                  className={`p-0 ${activeTab === "first" ? "active-tab" : ""}`}
                >
                  <Nav.Link
                    eventKey="first"
                    onClick={() => {
                      navigate("/my-networks/peoples/lists");
                    }}
                    role="button"
                    className=" text-center p-3"
                  >
                    <IoMdPeople size={20} className="icon" />
                    <span className="mobile-text  ms-3 user-tabs">
                      People you may know
                    </span>
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item
                  as="li"
                  className={`p-0 ${
                    activeTab === "second" ? "active-tab" : ""
                  }`}
                >
                  <Nav.Link
                    eventKey="second"
                    onClick={() => {
                      navigate("/my-networks/peoples/sent-requests");
                    }}
                    role="button"
                    className=" text-center p-3"
                  >
                    <RiUserShared2Line size={20} className="icon" />
                    <span className="mobile-text  ms-3 user-tabs ">
                      Sent Requests
                    </span>
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item
                  as="li"
                  className={`p-0 ${activeTab === "third" ? "active-tab" : ""}`}
                >
                  <Nav.Link
                    eventKey="third"
                    onClick={() => {
                      navigate("/my-networks/peoples/received-requests");
                    }}
                    role="button"
                    className="text-center p-3"
                  >
                    <RiUserReceived2Line size={20} className="icon" />
                    <span className="mobile-text  ms-3 user-tabs ">
                      Received Requests
                    </span>
                  </Nav.Link>
                </Nav.Item>
              </div>
            </Card.Body>
          </Card>
        </Tab.Container>
        <Outlet />
      </Row>
    </Container>
  );
};

export default People;
