import React, { useState } from "react";
import { Button, Modal } from "react-bootstrap";
import Dropzone from "../../components/media/Dropzone";
import Gallery from "../../components/media/Gallery";
import { mediaService } from "../../services/media.service";
import { notifyFailed, notifySuccess } from "../../components/toast/Toast";
import "../../assets/css/style.css";
import { fileValidation } from "../../validation/fileValidation";
const MediaLibrary = ({ open, handleClose }) => {
  // const [key, setKey] = useState("upload");
  const [files, setFiles] = useState([]);
  const [show, setShow] = useState("upload");
  const [uploading, setUploading] = useState(false);
  const handleDragOver = (event) => {
    event.preventDefault();
  };
  const handleDrop = (event, name) => {
    event.preventDefault();
    setFiles(event.dataTransfer.files[0]);
  };
  const handleUpload = async () => {
    const isHugeSize = await fileValidation(files);
    if (isHugeSize) {
      return notifyFailed(isHugeSize);
    }
    const formData = new FormData();
    formData.append("file", files);
    setUploading(true);
    try {
      const result = await mediaService.fileUpload(formData);
      setUploading(false);
      setFiles([]);
      notifySuccess(result.message);
    } catch (err) {
      setUploading(false);
      switch (err.response.status) {
        case 422:
          return notifyFailed(err.response.data?.message);
        default:
          return notifyFailed("Error Occured");
      }
    }
  };
  const handleInputChange = (value, name) => {
    setFiles(value.target.files[0]);
  };
  // const [page, setPage] = useState(1);
  // const handleScroll = () => {
  //   if (
  //     window.innerHeight + document.documentElement.scrollTop ===
  //     document.documentElement.offsetHeight
  //   ) {
  //     setPage((prevPage) => prevPage + 1);
  //   }
  // };
  // useEffect(() => {
  //   console.log(
  //     window.innerHeight,
  //     document.documentElement.scrollTop,
  //     document.documentElement.offsetHeight
  //   );
  //   window.addEventListener("scroll", handleScroll);
  //   return () => window.removeEventListener("scroll", handleScroll);
  // }, []);
  return (
    <>
      <Modal show={open} onHide={handleClose} size="xl">
        <Modal.Header closeButton>
          <Modal.Title>Media Library</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row m-2">
            <div className="d-flex justify-content-start">
              <div className="d-flex justify-content-center">
                <Button
                  onClick={() => setShow("upload")}
                  active={show === "upload"}
                >
                  Upload
                </Button>
                <Button
                  onClick={() => setShow("library")}
                  active={show === "library"}
                >
                  Library
                </Button>
              </div>
            </div>
            {show === "upload" ? (
              <>
                <Dropzone
                  handleInputChange={handleInputChange}
                  handleDragOver={handleDragOver}
                  handleDrop={handleDrop}
                  fileName={files?.name}
                />
                <div className="d-flex justify-content-end">
                  <Button
                    variant="primary"
                    onClick={handleUpload}
                    disabled={files?.name ? false : true}
                  >
                    Upload
                  </Button>
                </div>
              </>
            ) : (
              <Gallery />
            )}
          </div>
        </Modal.Body>
      </Modal>
      {uploading && (
        <div className="backdrop">
          <div className="spinner" />
        </div>
      )}
    </>
  );
};

export default MediaLibrary;
