/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState, createContext, useContext } from "react";
import { notifyFailed, notifySuccess } from "../components/toast/Toast";
import { fileValidation } from "../validation/fileValidation";
import { mediaService } from "../services/media.service";
import { BASE_URL } from "../utilities/constants";
const DocumentContext = createContext();
const DocumentProvider = ({ children, uploadFn, fetchAllFn, orgId = null }) => {
  const [showClose, setShowClose] = useState(false);
  const [loading, setLoading] = useState(false);
  const [accessibility, setAccessibility] = useState("public");
  const [documents, setDocuments] = useState([]);
  const [file, setFile] = useState(null);

  const handleRadioChange = (event) => {
    setAccessibility(event.target.value);
  };
  const handleChange = (element) => {
    setFile(element.target.files[0]);
  };
  const handleDownload = async (docID) => {
    try {
      const link = document.createElement("a");
      link.href = `${BASE_URL}document-uploads/${docID}`;
      link.target = "_blank"; // Open the file in a new tab
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (err) {
      return notifyFailed(err.message ?? "Could not download it");
    }
  };
  const handleUpload = async () => {
    const isHugeSize = await fileValidation(file);
    if (isHugeSize) {
      return notifyFailed(isHugeSize);
    }
    const formData = new FormData();
    formData.append("file", file);
    if (orgId) {
      formData.append("organization_id", orgId);
    }
    formData.append("accessibility", accessibility);
    setLoading(true);
    try {
      const result = await uploadFn(formData);
      if (result && result.success) {
        setFile(null);
        notifySuccess(result.msg);
        setAccessibility("public");
        setShowClose(!showClose);
      }
    } catch (err) {
      return notifyFailed(err.message ?? "Error Occurred");
    } finally {
      setLoading(false);
    }
  };

  const fetchAllDocuments = async () => {
    try {
      const res = await fetchAllFn();
      setDocuments(res.documents);
    } catch (err) {
      notifyFailed("Could not display all the documents at the moment");
    }
  };

  const deleteDocument = async (docId) => {
    setLoading(true);
    try {
      const res = await mediaService.deleteDocument(docId);
      if (res.success) {
        notifySuccess(res.msg);
      }
    } catch (err) {
      notifyFailed("Could not delete the document at the moment");
    } finally {
      setLoading(false);
    }
  };
  const updateAccessibility = async (docId, status) => {
    setLoading(true);
    try {
      const res = await mediaService.updateAccessibility(docId, status);
      if (res.success) {
        notifySuccess(res.msg);
      }
    } catch (err) {
      notifyFailed("Could not update accessibility of the document");
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    fetchAllDocuments();
  }, [loading]);
  return (
    <DocumentContext.Provider
      value={{
        showClose,
        setShowClose,
        loading,
        documents,
        file,
        setFile,
        accessibility,
        setAccessibility,
        handleChange,
        handleUpload,
        deleteDocument,
        handleDownload,
        handleRadioChange,
        updateAccessibility,
        orgId
      }}
    >
      {children}
    </DocumentContext.Provider>
  );
};
const useDocument = () => {
  return useContext(DocumentContext);
};
export { useDocument, DocumentProvider };
