import { api } from "./axios.service";

const getAllOrganizations = (params) => {
  return new Promise((resolve, reject) => {
    api("GET", `organizations${params}`, "", "", "")
      .then((response) => {
        resolve(response.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
const getMyOrganizations = () => {
  return new Promise((resolve, reject) => {
    api("GET", "user/organizations", "token", "", "")
      .then((response) => {
        resolve(response.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

const getOrganizationBySlug = (slug) => {
  return new Promise((resolve, reject) => {
    api("GET", "organizations", "", "", slug)
      .then((response) => {
        resolve(response.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
const getOrganizationGalleryImagesBySlug = (slug) => {
  return new Promise((resolve, reject) => {
    api("GET", "organizations", "token", "", `${slug}/gallery-images`)
      .then((response) => {
        resolve(response.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
const getOrganizationGalleryVediosBySlug = (slug) => {
  return new Promise((resolve, reject) => {
    api("GET", "organizations", "token", "", `${slug}/gallery-videos`)
      .then((response) => {
        resolve(response.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
const getOrganizationTypes = () => {
  return new Promise((resolve, reject) => {
    api("GET", "organization-types", "token", "", "")
      .then((response) => {
        resolve(response.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
const getSectors = (param) => {
  return new Promise((resolve, reject) => {
    api("GET", `organization-sectors${param}`, "token", "", "")
      .then((response) => {
        resolve(response.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
const getSubSectors = (param) => {
  return new Promise((resolve, reject) => {
    api("GET", `organization-sub-sectors${param}`, "token", "", "")
      .then((response) => {
        resolve(response.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
const getGnDivisions = () => {
  return new Promise((resolve, reject) => {
    api("GET", "gn-divisions", "token", "", "")
      .then((response) => {
        resolve(response.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
const createOrganization = (req) => {
  return new Promise((resolve, reject) => {
    api("POST", "user/organizations", "token", req, "")
      .then((response) => {
        resolve(response.data);
      })
      .catch((err) => {
        console.log(err);
        reject(err);
      });
  });
};
const updateOrganization = (req, slug) => {
  return new Promise((resolve, reject) => {
    api("PUT", "user/organizations", "token", req, slug)
      .then((response) => {
        resolve(response.data);
      })
      .catch((err) => {
        console.log(err);
        reject(err);
      });
  });
};
const getEditOrganizationDetails = (id) => {
  return new Promise((resolve, reject) => {
    api("GET", "user/organizations", "token", "", `${id}/edit`)
      .then((response) => {
        resolve(response.data);
      })
      .catch((err) => {
        console.log(err);
        reject(err);
      });
  });
};

const updateImageGallery = (req, slug) => {
  return new Promise((resolve, reject) => {
    api("PATCH", "user/organizations", "token", req, `${slug}/gallery-images`)
      .then((response) => {
        resolve(response.data);
      })
      .catch((err) => {
        console.log(err);
        reject(err);
      });
  });
};
const updateVedioGallery = (req, slug) => {
  return new Promise((resolve, reject) => {
    api("PATCH", "user/organizations", "token", req, `${slug}/gallery-videos`)
      .then((response) => {
        resolve(response.data);
      })
      .catch((err) => {
        console.log(err);
        reject(err);
      });
  });
};

const getOrganizationImages = (params) => {
  return new Promise((resolve, reject) => {
    api(
      "GET",
      "organizations",
      "token",
      "",
      `${params}/gallery-images?sort_by=random&limit=9`
    )
      .then((response) => {
        console.log("xsxxxx", response.data);
        resolve(response.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

const getOrganizationVideos = (params) => {
  return new Promise((resolve, reject) => {
    api(
      "GET",
      "organizations",
      "token",
      "",
      `${params}/gallery-videos?sort_by=random&limit=3`
    )
      .then((response) => {
        resolve(response.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const organizationService = {
  createOrganization,
  updateOrganization,
  getAllOrganizations,
  getMyOrganizations,
  getOrganizationBySlug,
  getEditOrganizationDetails,
  getOrganizationGalleryImagesBySlug,
  getOrganizationGalleryVediosBySlug,
  getOrganizationTypes,
  getSectors,
  getSubSectors,
  getGnDivisions,
  updateVedioGallery,
  updateImageGallery,
  getOrganizationImages,
  getOrganizationVideos,
};
