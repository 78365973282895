import React, { useEffect, useState } from 'react'
import { organizationService } from '../../../../services/organization.service'
import OrganizationList from './OrganizationList'

export default function MyOrganization() {
    const [myOrganizations,setMyOrganizations] =useState([])
    const getMyOrganizations=()=>{
        organizationService.getMyOrganizations().then(data=>setMyOrganizations(data))
    }
    useEffect(()=>{
        getMyOrganizations()
    },[])
    return (<OrganizationList organizations={myOrganizations}/>)
}
