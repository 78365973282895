import { createContext, useContext, useState } from "react";
const MyProfileMediaContext = createContext();
const MyProfileMediaContextProvider = ({ children }) => {
  const [profileData, setProfileData] = useState({
    userName: "",
    displayImg: {},
    coverImg: {},
  });

  return (
    <MyProfileMediaContext.Provider value={{ profileData, setProfileData }}>
      {children}
    </MyProfileMediaContext.Provider>
  );
};
const useMyProfileMedia = () => {
  return useContext(MyProfileMediaContext);
};
export { useMyProfileMedia, MyProfileMediaContextProvider };
