import React, { useState } from "react";
import { Button } from "react-bootstrap";
import TagInputField from "../../../../../components/tag/TagInputField";
import { useGlobal } from "../../../../../hooks/useGlobal";
import { useOrganization } from "../../../../../hooks/useOrganization";

export default function SeoDetails(props) {
  const {handleChange,handleDynamicDetails,organization,error} = useOrganization()
  const { toggleMedia ,setSelectSingleImage} = useGlobal();
  if (props.currentStep !== 5) {
    return null;
  }
  const handleTags=(value)=>{
    console.log(value)
    handleDynamicDetails('seo_meta_keywords',value)
  }
  return (
    <>
      <div className="d-flex justify-content-center">
        <h4 className="text-dark mt-4">SEO Details</h4>
      </div>
     
      <div className="row">
        <div className="form-group col-12">
          <label htmlFor="seo_meta_title">Meta Title</label>
          <input
            type="text"
            className={`form-control ${error.seo_meta_title ? 'is-invalid':''}`}
            id="seo_meta_title"
            name="seo_meta_title"
            placeholder="Meta Title"
            value={organization.seo_meta_title}
            onChange={handleChange}
          />
           <div class="invalid-feedback">
              Please enter seo meta title.
            </div>
            <small>The seo meta title must be at least 24 characters.</small>
        </div>
        <div className="form-group col-12">
          <label htmlFor="seo_meta_description">Meta Description</label>
          <textarea
            className={`form-control ${error.seo_meta_description ? 'is-invalid':''}`}
            id="seo_meta_description"
            name="seo_meta_description"
            value={organization.seo_meta_description}
            onChange={handleChange}
          />
           <div class="invalid-feedback">
              Please enter seo meta description.
            </div>
            <small>The seo meta description must be at least 60 characters</small>
        </div>
        <div className="form-group  col-12">
          <label htmlFor="seo_meta_keywords">Meta Keywords</label>
          <div className="bootstrap-tagsinput">
          </div>
          <TagInputField 
            className={`form-control ${error.seo_meta_keywords ? 'is-invalid':''}`}
            tags={organization.seo_meta_keywords} 
            handleTags={handleTags}/>
            {error.seo_meta_keywords && <div class="text-danger">
              Please enter meta keywords.
            </div>}
          {/* <input
            type="text"
            className="form-control"
            id="seo_meta_keywords"
            name="seo_meta_keywords"
            data-role="tagsinput"
            placeholder="Meta Keywords"
            value={organization.seo_meta_keywords}
            onChange={handleChange}
          /> */}
        </div>
        <div class="col-12">
          <div class="card border border-primary">
            <div class="card-body">
            <h5 class="card-title"> Meta Image</h5>
                {organization.seo_meta_image?.length>0 && <>{organization.seo_meta_image.map(img=>(<div className="img-wrapper">
                   <img src={img.src} className="select-img"/>
                </div>))}</>}
                <small>
                  Recommended image size 330x360 px. Acceptable image formats:
                  "jpg", "jpeg", "png", "gif", "webp"
                </small>
                <div className="col-12 mt-2">
                  <Button variant="primary" className="btn-style1" onClick={()=>{
                    setSelectSingleImage(true)
                    toggleMedia('seo_meta_image',null)}}>
                    {organization.seo_meta_image?.length>0?'Change Image':'Attach Files'} 
                  </Button>
                </div>
            </div>
            {error.seo_meta_image && <div class="text-danger p-2">
              Please select seo image.
            </div>}
          </div>
        </div>
        {/* <div className="form-group col-md-6 col-xs-12">
          <label htmlFor="seo_meta_image">Meta Image</label>
          <div className="media-upload-btn-wrapper">
            <div className="img-wrap"></div>
            <input type="hidden" name="seo_meta_image" defaultValue />
            <button
              type="button"
              className="btn-style1"
              // className="btn btn-info media_upload_form_btn font-weight-bold"
              data-btntitle="Select Meta Image"
              data-modaltitle="Upload Meta Image"
              data-toggle="modal"
              data-target="#media_upload_modal"
            >
              Upload Meta Image
            </button>
            <Button variant="primary">Upload Meta Image</Button>
          </div>
          <small>
            Recommended image size 1200x630 px. Acceptable image formats: "jpg",
            "jpeg", "png", "gif", "webp"
          </small>
        </div> */}
      </div>
    </>
  );
}
