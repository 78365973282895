import React from "react";
import { useState } from "react";
import { Button, Card, Col, Row } from "react-bootstrap";
import { userProfileService } from "../../../services/userProfile.service";
import { useAuth } from "../../../hooks/useAuth";
import { useEffect } from "react";
import { useGlobal } from "../../../hooks/useGlobal";
import { notifyFailed, notifySuccess } from "../../toast/Toast";
import LightBox from "../../lightbox/lightBox";
import ImageCard from "../../Image/ImageCard";

const Photos = () => {
  const [uploading, setUploading] = useState(false);
  const [showUploadScreen, setShowUploadScreen] = useState(false);
  const [imageLists, setImageLists] = useState([]);
  const [images, setImages] = useState([]);
  const {
    toggleMedia,
    mediaState,
    setMediaState,
    convertImagesToRequestString,
    openLightbox,
  } = useGlobal();
  const { user } = useAuth();
  const fetchAllGalleryImages = async () => {
    try {
      const result = await userProfileService.fetchAllGalleryImages(
        user?.username
      );
      setImageLists(result?.gallery_images);
    } catch (err) {
      return err;
    }
  };
  useEffect(() => {
    fetchAllGalleryImages();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  console.log(imageLists);
  useEffect(() => {
    if (
      mediaState.model === "photoGallery" &&
      mediaState.field === "gallery_images"
    ) {
      setImages(mediaState.values);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mediaState.values, mediaState.show]);
  const handleOnClick = async () => {
    setUploading(true);
    try {
      let reqImages = {
        gallery_images: convertImagesToRequestString(images),
        overwrite: 0,
      };
      const res = await userProfileService.updateGallaryImages(reqImages);
      setUploading(false);
      if (res.success) {
        notifySuccess(res.message);
        setImageLists(res.gallery_images);
        setImages([]);
        setShowUploadScreen(!showUploadScreen);
      }
    } catch (err) {
      setUploading(false);
      notifyFailed("Error Occured");
    }
  };
  return (
    <>
      {!showUploadScreen ? (
        <>
          <div className="d-flex align-items-center justify-content-end">
            <button
              type="button"
              className="btn btn-secondary ms-2 btn-sm d-flex align-items-center"
              onClick={() => setShowUploadScreen(!showUploadScreen)}
            >
              <span
                className="material-symbols-outlined  md-12"
                style={{ fontSize: "14px" }}
              >
                edit
              </span>
              Upload Photos
            </button>
          </div>
          <div className="friend-list-tab mt-2">
            {imageLists.length > 0 ? (
              <Row>
                {imageLists.map((gallery, index) => {
                  if (gallery.preview_picture_id) {
                    return (
                      <ImageCard
                        key={index}
                        img={gallery.preview_picture.large_img_url}
                        onImageClick={() => openLightbox(index)}
                        type="video"
                      />
                    );
                  }
                  return (
                    <ImageCard
                      key={index}
                      size="3"
                      img={gallery.grid_img_url}
                      onImageClick={() => openLightbox(index)}
                    />
                  );
                })}
                <LightBox images={imageLists} />
              </Row>
            ) : (
              <Card className="p-2">
                <h4>No Data Found</h4>
              </Card>
            )}
          </div>
        </>
      ) : (
        <Row className="p-3 d-flex justify-content-center">
          <Col md="8" xs="12">
            <div className="card border border-primary">
              <div className="card-body">
                <h5 className="card-title">Gallery Photos</h5>
                {images?.length > 0 && (
                  <div className="d-flex ">
                    {images.map((img, index) => (
                      <div className="img-wrapper" key={index}>
                        <img
                          src={img.src}
                          className="select-img"
                          alt="gallery_images"
                        />
                      </div>
                    ))}
                  </div>
                )}
                <small>
                  Recommended image size 1920x1280 px. Acceptable image formats:
                  "jpg", "jpeg", "png", "gif", "webp"
                </small>
                <div className="col-12 mt-2">
                  <Button
                    className="btn-style1"
                    onClick={() =>
                      toggleMedia("gallery_images", null, "photoGallery")
                    }
                  >
                    {images?.length > 0 ? "Change Image" : "Attach Files"}
                  </Button>
                </div>
              </div>
            </div>
            <Col className="mt-2">
              <Button
                style={{ float: "right" }}
                disabled={!images || images?.length === 0 ? true : false}
                onClick={handleOnClick}
              >
                Save Changes
              </Button>
              <Button
                className="btn-info"
                style={{ float: "right" }}
                onClick={() => {
                  setMediaState({ ...mediaState, field: "", values: [] });
                  setShowUploadScreen(!showUploadScreen);
                  setImages([]);
                }}
              >
                Cancel
              </Button>
            </Col>
          </Col>
        </Row>
      )}
      {uploading && (
        <div className="backdrop">
          <div className="spinner" />
        </div>
      )}
    </>
  );
};

export default Photos;
