import React from "react";
import { mediaService } from "../../../../services/media.service";
import { DocumentProvider } from "../../../../hooks/useDocument";
import { useEffect } from "react";
import DocumentContent from "../../../../components/media/Document";
import { useAuth } from "../../../../hooks/useAuth";

const Document = ({ orgId,orgAuthorizedUserId }) => {
  const {user}=useAuth()
  const fetchAllDocuments = async () => {
    if(user.id != orgAuthorizedUserId){
      return await mediaService.fetchAllPublicDocuments(
        `?owner_id=${orgId}&owner_type=organization`
      );
    }
    return await mediaService.fetchAllDocuments(
      `?owner_id=${orgId}&owner_type=organization`
    );
   
  };

  const handleUploadFn = async (formData) => {
    return await mediaService.documentUpload(formData);
  };
  // useEffect(() => {
  //   orgId && fetchAllDocuments();
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [orgId]);
  return (
    <>
      {orgId ? (
        <DocumentProvider
          fetchAllFn={fetchAllDocuments}
          uploadFn={handleUploadFn}
          orgId={orgId}
        >
          <DocumentContent orgAuthorizedUserId={orgAuthorizedUserId}/>
        </DocumentProvider>
      ) : null}
    </>
  );
};

export default Document;
