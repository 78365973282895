/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useMemo, useState } from "react";
import {
  Container,
  Row,
  Col,
  Nav,
  Tab,
  Button,
  Dropdown,
} from "react-bootstrap";
import Card from "../../../../components/Card";
import { Link, useParams } from "react-router-dom";
import ProfileHeader from "../../../../components/profile-header";
// images
import user05 from "../../../../assets/images/user/05.jpg";
import { organizationService } from "../../../../services/organization.service";
import PhotoGallery from "./PhotoGallery";
import Newsfeed from "../Newsfeed";
import VedioGallery from "./VedioGallery";
import { useAuth } from "../../../../hooks/useAuth";
import EditVideoDetails from "../organization/EditVideoDetails";
import UploadGalleryImage from "../organization/UploadGalleryImage";
import { postService } from "../../../../services/post.service";
import { usePost } from "../../../../hooks/usePost";
import "../../../../assets/css/style.css";
import { HiClock, HiDocument } from "react-icons/hi";
import { AiOutlineUser } from "react-icons/ai";
import { FaImages, FaVideo } from "react-icons/fa";
import { MdOutlineCampaign } from "react-icons/md";
import CampaignTab from "./CampaignTab";
import Document from "./Document";
import { useGlobal } from "../../../../hooks/useGlobal";
import LightBox from "../../../../components/lightbox/lightBox";

const SingleOrganization = () => {
  const { user } = useAuth();
  const { handleSelect } = usePost();
  const [activeTab, setActiveTab] = useState("first"); // State to track the active tab
  const [organiztionDetails, setOrganizationDetails] = useState({});
  const [images, setImages] = useState([]);
  const { openLightbox } = useGlobal();
  const getOrganizationDetails = (slug) => {
    organizationService
      .getOrganizationBySlug(slug)
      .then((data) => setOrganizationDetails(data));
  };
  let { organizationId } = useParams();
  const [photoGalleryList, setPhotoGalleryList] = useState([]);
  const [vedioGalleryList, setVedioGalleryList] = useState([]);
  const [sources, setSources] = useState([]);
  const [editShow, SetEditShow] = useState({
    videoGallery: false,
    imageGallery: false,
  });
  const handleTabClick = (tabKey) => {
    setActiveTab(tabKey);
  };
  const getPhotos = (ogId) => {
    organizationService.getOrganizationGalleryImagesBySlug(ogId).then((res) => {
      let array = [...sources];
      let results = res.gallery_images.map((img) => img.img_url);
      array = [...array, ...results];
      setSources(array);
      setPhotoGalleryList(res.gallery_images);
    });
  };
  const getvideos = (ogId) => {
    organizationService.getOrganizationGalleryVediosBySlug(ogId).then((res) => {
      setVedioGalleryList(res.gallery_videos);
    });
  };

  const getOrganizationImages = async () => {
    try {
      const res = await organizationService.getOrganizationImages(
        organizationId
      );
      setImages(res.gallery_images);
    } catch (err) {
      return err;
    }
  };

  useMemo(() => {
    getOrganizationImages();
    organizationId !== undefined && getOrganizationDetails(organizationId);
  }, [organizationId]);

  useEffect(() => {
    organiztionDetails.id !== undefined &&
      handleSelect("organization_id", organiztionDetails.id);
    organiztionDetails.id !== undefined && getPosts(organiztionDetails.id);
    organiztionDetails.slug !== undefined && getPhotos(organiztionDetails.slug);
    organiztionDetails.slug !== undefined && getvideos(organiztionDetails.slug);
  }, [organiztionDetails]);

  const [imageController, setImageController] = useState({
    toggler: false,
    slide: 1,
  });
  const handleShowHide = (field) => {
    SetEditShow({
      [field]: !editShow[field],
    });
  };
  // posts for this organization
  const [posts, setPosts] = useState([]);
  const getPosts = (orgId) => {
    postService.getOrganizationPosts(orgId).then((res) => {
      setPosts(res.posts);
    });
  };
  return (
    <>
    <LightBox images={images} />
      <ProfileHeader img={organiztionDetails?.cover_picture_media?.img_url} />
      <div className="profile-2">
        <div id="content-page" className="content-page">
          <Container>
            <Row>
              <Col lg="12">
                <Card>
                  <Card.Body>
                    <Row>
                      <Col lg="2">
                        <div className="item1 ms-1">
                          <img
                            loading="lazy"
                            src={
                              organiztionDetails?.display_picture_media
                                ?.thumb_img_url
                            }
                            className="img-fluid rounded profile-image"
                            alt="profile-img"
                          />
                        </div>
                      </Col>
                      <Col lg="10">
                        <div className="d-flex justify-content-between">
                          <div className="item2 ">
                            <h4 className="text-capitalize">
                              {organiztionDetails?.name}
                            </h4>
                            <span>630 followers</span>
                          </div>
                          <div className="item4 ms-1">
                            <div className="d-flex justify-content-between align-items-center ms-1 flex-wrap">
                              {/* <div className="d-flex align-items-center">
                                                    <span className="material-symbols-outlined writ-icon md-18">
                                                    send
                                                    </span>
                                                    <h6 className="ms-1">Write a message</h6>
                                                </div> */}
                              {user.id ===
                              organiztionDetails.authorized_user_id ? (
                                <Link
                                  to={`/app/organizations/${organiztionDetails.slug}/edit`}
                                >
                                  <button
                                    type="button"
                                    className="btn btn-secondary ms-2 btn-sm d-flex align-items-center"
                                  >
                                    <span
                                      className="material-symbols-outlined  md-12"
                                      style={{ fontSize: "14px" }}
                                    >
                                      edit
                                    </span>
                                    Edit
                                  </button>
                                </Link>
                              ) : (
                                <button
                                  type="button"
                                  className="btn btn-primary ms-2 btn-sm d-flex align-items-center"
                                >
                                  <span className="material-symbols-outlined  md-16">
                                    add
                                  </span>
                                  Follow
                                </button>
                              )}
                            </div>
                          </div>
                        </div>
                        <Row>
                          <Col lg="5">
                            <div className="item5 mt-3">
                              <div className="d-flex align-items-center mb-1">
                                <span className="material-symbols-outlined  md-18">
                                  business_center
                                </span>
                                <span className="ms-2">
                                  {
                                    organiztionDetails?.organization_sector
                                      ?.name
                                  }
                                </span>
                              </div>
                              <div className="d-flex align-items-center mb-1">
                                <span className="material-symbols-outlined md-18">
                                  location_on
                                </span>
                                <span className="ms-2">
                                  {organiztionDetails?.postal_address}
                                </span>
                              </div>
                              <div className="d-flex align-items-center mb-1">
                                <span className="material-symbols-outlined md-18">
                                  bookmark_border
                                </span>
                                <span className="ms-2">
                                  Created on{" "}
                                  {new Date(
                                    organiztionDetails?.created_at
                                  ).toDateString()}
                                </span>
                              </div>
                            </div>
                          </Col>
                          {/* <Col lg="3">
                                            <div className="item6 border-light border-end border-start">
                                                <div className="ms-2">
                                                    <h6 className="mb-2 text-capitalize">People {organiztionDetails?.name} follows</h6>
                                                </div>
                                                <div className="iq-media-group ms-2">
                                                    <Link to="#" className="iq-media">
                                                        <img loading="lazy" className="img-fluid avatar-40 rounded-circle" src={imgp2} alt=""/>
                                                    </Link>
                                                    <Link to="#" className="iq-media">
                                                        <img loading="lazy" className="img-fluid avatar-40 rounded-circle" src={imgp3} alt=""/>
                                                    </Link>
                                                    <Link to="#" className="iq-media">
                                                        <img loading="lazy" className="img-fluid avatar-40 rounded-circle" src={imgp4} alt=""/>
                                                    </Link>
                                                    <Link to="#" className="iq-media">
                                                        <img loading="lazy" className="img-fluid avatar-40 rounded-circle" src={imgp5} alt=""/>
                                                    </Link>
                                                </div>
                                            </div>
                                        </Col> */}
                          <Col lg="4">
                            <div className="item7 ms-2">
                              <div className="d-flex justify-content-between mb-2 flex-wrap">
                                <h6 className="text-capitalize">
                                  {organiztionDetails?.name} Interest
                                </h6>
                              </div>
                              <div className="d-flex ">
                                <Button
                                  size="sm"
                                  variant="outline-secondary rounded-pill"
                                >
                                  Charity
                                </Button>
                                <Button
                                  size="sm"
                                  variant="outline-secondary rounded-pill ms-1"
                                >
                                  Education
                                </Button>
                                <Button
                                  size="sm"
                                  variant="outline-secondary rounded-pill ms-1"
                                >
                                  Business
                                </Button>
                                <Button
                                  size="sm"
                                  variant="outline-secondary rounded-pill ms-1"
                                >
                                  Healthier society
                                </Button>
                              </div>
                            </div>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </Card.Body>
                </Card>
              </Col>
            </Row>

            <Tab.Container id="left-tabs-example" defaultActiveKey="first">
              <Card className="p-0">
                <Card.Body className="p-0">
                  <div className="d-flex profile-feed-items  align-items-center justify-content-around">
                    <Nav.Item
                      as="li"
                      className={`p-0 ${
                        activeTab === "first" ? "active-tab" : ""
                      }`}
                    >
                      <Nav.Link
                        href="#pills-timeline-tab"
                        eventKey="first"
                        role="button"
                        className=" text-center p-3"
                        onClick={() => handleTabClick("first")}
                      >
                        <HiClock size={20} className="icon" />
                        <span className="mobile-text  ms-3 user-tabs ">
                          Timeline
                        </span>
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item
                      as="li"
                      className={`p-0 ${
                        activeTab === "second" ? "active-tab" : ""
                      }`}
                    >
                      <Nav.Link
                        href="#pills-about-tab"
                        eventKey="second"
                        role="button"
                        className="text-center p-3"
                        onClick={() => handleTabClick("second")}
                      >
                        <AiOutlineUser size={20} className="icon" />
                        <span className="mobile-text ms-3 user-tabs ">
                          About
                        </span>
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item
                      as="li"
                      className={`p-0 ${
                        activeTab === "forth" ? "active-tab" : ""
                      }`}
                    >
                      <Nav.Link
                        href="#pills-photos-tab"
                        eventKey="forth"
                        role="button"
                        className="text-center p-3"
                        onClick={() => handleTabClick("forth")}
                      >
                        <FaImages size={20} className="icon" />

                        <span className="mobile-text ms-3  user-tabs">
                          Photos
                        </span>
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item
                      as="li"
                      className={`p-0 ${
                        activeTab === "fifth" ? "active-tab" : ""
                      }`}
                    >
                      <Nav.Link
                        href="#pills-videos-tab"
                        eventKey="fifth"
                        role="button"
                        className="text-center p-3"
                        onClick={() => handleTabClick("fifth")}
                      >
                        <FaVideo size={20} className="icon" />
                        <span className="mobile-text ms-3 user-tabs">
                          Videos
                        </span>
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item
                      as="li"
                      className={`p-0 ${
                        activeTab === "sixth" ? "active-tab" : ""
                      }`}
                    >
                      <Nav.Link
                        href="#pills-campaigns-tab"
                        eventKey="sixth"
                        role="button"
                        className="text-center p-3"
                        onClick={() => handleTabClick("sixth")}
                      >
                        <MdOutlineCampaign size={20} className="icon" />
                        <span className="mobile-text ms-3 user-tabs">
                          Campaigns
                        </span>
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item
                      as="li"
                      className={`p-0 ${
                        activeTab === "seventh" ? "active-tab" : ""
                      }`}
                    >
                      <Nav.Link
                        href="#pills-document-tab"
                        eventKey="seventh"
                        role="button"
                        className="text-center p-3"
                        onClick={() => handleTabClick("seventh")}
                      >
                        <HiDocument size={20} className="icon" />
                        <span className="mobile-text ms-3 user-tabs">
                          Document
                        </span>
                      </Nav.Link>
                    </Nav.Item>
                  </div>
                </Card.Body>
              </Card>
              <Col sm={12}>
                <Tab.Content>
                  <Tab.Pane eventKey="first">
                    <Card.Body className=" p-0">
                      <Row>
                        <Col lg="4">
                          <Card>
                            <div className="card-header d-flex justify-content-between">
                              <div className="header-title">
                                <h4 className="card-title">Bio</h4>
                              </div>
                              <div className="d-flex align-items-center">
                                <p className="m-0">
                                  <Link to="#pills-about-tab"> Know More </Link>
                                </p>
                              </div>
                            </div>
                            <Card.Body>
                              <div className="d-flex flex-column justify-content-between">
                                <div className="mb-2">
                                  <span>{organiztionDetails?.name}</span>
                                </div>
                                <div>
                                  <span>
                                    {organiztionDetails?.registration_number}
                                  </span>
                                </div>
                                <div className="mt-2">
                                  <span>
                                    <Link to={organiztionDetails?.main_website}>
                                      {organiztionDetails?.main_website}
                                    </Link>
                                  </span>
                                </div>
                              </div>
                              <hr />
                              <div className="item5 mt-3">
                                <div className="d-flex align-items-center mb-1">
                                  <span className="material-symbols-outlined  md-18">
                                    business_center
                                  </span>
                                  <span className="ms-2">
                                    {
                                      organiztionDetails?.organization_sector
                                        ?.name
                                    }
                                  </span>
                                </div>
                                <div className="d-flex align-items-center mb-1">
                                  <span className="material-symbols-outlined md-18">
                                    call
                                  </span>
                                  <span className="ms-2">
                                    {organiztionDetails?.main_contact_number}
                                  </span>
                                </div>
                                <div className="d-flex align-items-center mb-1">
                                  <span className="material-symbols-outlined md-18">
                                    email
                                  </span>
                                  <span className="ms-2">
                                    {organiztionDetails?.main_email}
                                  </span>
                                </div>
                                <div className="d-flex align-items-center mb-1">
                                  <span className="material-symbols-outlined md-18">
                                    bookmark_border
                                  </span>
                                  <span className="ms-2">
                                    Created on{" "}
                                    {new Date(
                                      organiztionDetails?.created_at
                                    ).toDateString()}
                                  </span>
                                </div>
                              </div>
                            </Card.Body>
                          </Card>
                          <Card>
                            <Card.Header className="d-flex align-items-center justify-content-between">
                              <div className="header-title">
                                <h4 className="card-title">Photos</h4>
                              </div>
                              <Link to="#">See all photos</Link>
                            </Card.Header>
                            <Card.Body>
                              <div className="d-grid gap-2 grid-cols-3">
                                {images.map((image, index) => (
                                  <Link onClick={() => openLightbox(index)} to="#">
                                    <img
                                      loading="lazy"
                                      src={image.img_url}
                                      alt="gallary"
                                      className="img-fluid"
                                    />
                                  </Link>
                                ))}
                              </div>
                            </Card.Body>
                          </Card>
                          {/* <Card>
                            <div className="card-header d-flex align-items-center justify-content-between">
                              <div className="header-title">
                                <h4 className="card-title">Videos</h4>
                              </div>
                              <Link to="#">See all videos</Link>
                            </div>
                            <Card.Body>
                              <div className="d-grid gap-2">
                                <Link to="#" className="ratio ">
                                  <iframe
                                    title="myFrame"
                                    className="rounded embed-responsive-item"
                                    src="https://www.youtube.com/embed/zpOULjyy-n8?rel=0"
                                    allowFullScreen
                                  ></iframe>
                                </Link>
                                <Link to="#" className="ratio">
                                  <iframe
                                    title="myFrame"
                                    className="rounded embed-responsive-item"
                                    src="https://www.youtube.com/embed/zpOULjyy-n8?rel=0"
                                    allowFullScreen
                                  ></iframe>
                                </Link>
                              </div>
                            </Card.Body>
                          </Card> */}
                        </Col>
                        <Col lg={8}>
                          <Newsfeed
                            posts={posts}
                            hideCreateOptions={{ organization: true }}
                            getPosts={getPosts}
                          />
                        </Col>
                      </Row>
                    </Card.Body>
                  </Tab.Pane>
                  <Tab.Pane eventKey="second">
                    <Tab.Container
                      id="left-tabs-example"
                      defaultActiveKey="about1"
                    >
                      <Row>
                        <Col md={4}>
                          <Card>
                            <Card.Body>
                              <Nav
                                variant="pills"
                                className=" basic-info-items list-inline d-block p-0 m-0"
                              >
                                <Nav.Item>
                                  <Nav.Link href="#" eventKey="about1">
                                    Contact and Basic Info
                                  </Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                  <Nav.Link href="#" eventKey="about2">
                                    Websites and Social Links
                                  </Nav.Link>
                                </Nav.Item>
                              </Nav>
                            </Card.Body>
                          </Card>
                        </Col>
                        <Col md={8} className=" ps-4">
                          <Card>
                            <Card.Body>
                              <Tab.Content>
                                <Tab.Pane eventKey="about1">
                                  <h4>Contact and Basic Info</h4>
                                  <hr />
                                  <Row className="mb-2">
                                    <div className="col-3">
                                      <h6>About:</h6>
                                    </div>
                                    <div className="col-9">
                                      <p className="mb-0">
                                        {organiztionDetails?.short_intro}
                                      </p>
                                    </div>
                                  </Row>
                                  <Row className="mb-2">
                                    <div className="col-3">
                                      <h6>Registration No:</h6>
                                    </div>
                                    <div className="col-9">
                                      <p className="mb-0">
                                        {
                                          organiztionDetails?.registration_number
                                        }
                                      </p>
                                    </div>
                                  </Row>
                                  <Row className="mb-2">
                                    <div className="col-3">
                                      <h6>Published On:</h6>
                                    </div>
                                    <div className="col-9">
                                      <p className="mb-0">
                                        {new Date(
                                          organiztionDetails?.publish_at
                                        ).toDateString()}
                                      </p>
                                    </div>
                                  </Row>
                                  <Row className="row mb-2">
                                    <div className="col-3">
                                      <h6>Gn Division: </h6>
                                    </div>
                                    <div className="col-9">
                                      <p className="mb-0">
                                        {organiztionDetails?.gn_division_id}
                                      </p>
                                    </div>
                                  </Row>
                                  <Row className="row mb-2">
                                    <div className="col-3">
                                      <h6>Sector: </h6>
                                    </div>
                                    <div className="col-9">
                                      <p className="mb-0">
                                        {
                                          organiztionDetails
                                            ?.organization_sector?.name
                                        }
                                      </p>
                                    </div>
                                  </Row>

                                  <Row className="row mb-2">
                                    <div className="col-3">
                                      <h6>Organization Type: </h6>
                                    </div>
                                    <div className="col-9">
                                      <p className="mb-0">
                                        {
                                          organiztionDetails
                                            ?.organization_sector
                                            ?.organization_type.name
                                        }
                                      </p>
                                    </div>
                                  </Row>
                                  <Row className="mb-2">
                                    <div className="col-3">
                                      <h6>Sub Sector</h6>
                                    </div>
                                    <div className="col-9">
                                      <p className="mb-0">
                                        {
                                          organiztionDetails
                                            ?.organization_sub_sector?.name
                                        }
                                      </p>
                                    </div>
                                  </Row>

                                  <h4 className="mt-2">Contact Details</h4>
                                  <hr />
                                  <Row className="mb-2">
                                    <div className="col-3">
                                      <h6>Email:</h6>
                                    </div>
                                    <div className="col-9">
                                      <p className="mb-0">
                                        {organiztionDetails?.main_email}
                                      </p>
                                    </div>
                                  </Row>
                                  <Row className="mb-2">
                                    <div className="col-3">
                                      <h6>Contact No:</h6>
                                    </div>
                                    <div className="col-9">
                                      <p className="mb-0">
                                        {
                                          organiztionDetails?.main_contact_number
                                        }
                                      </p>
                                    </div>
                                  </Row>
                                  <Row className="mb-2">
                                    <div className="col-3">
                                      <h6>Address:</h6>
                                    </div>
                                    <div className="col-9">
                                      <p className="mb-0">
                                        {organiztionDetails?.postal_address}
                                      </p>
                                    </div>
                                  </Row>
                                </Tab.Pane>
                                <Tab.Pane eventKey="about2">
                                  <h4 className="mt-2">
                                    Websites and Social Links
                                  </h4>
                                  <hr />
                                  <Row className="mb-2">
                                    <div className="col-3">
                                      <h6>Website:</h6>
                                    </div>
                                    <div className="col-9">
                                      <span>
                                        <Link
                                          to={organiztionDetails?.main_website}
                                        >
                                          {organiztionDetails?.main_website}
                                        </Link>
                                      </span>
                                    </div>
                                  </Row>
                                  {organiztionDetails?.social_media_profiles?.map(
                                    (media) => (
                                      <Row className="mb-2">
                                        <div className="col-3">
                                          <h6>{media?.social_media}:</h6>
                                        </div>
                                        <div className="col-9">
                                          <span>
                                            <Link to={media?.url}>
                                              {media?.url}
                                            </Link>
                                          </span>
                                        </div>
                                      </Row>
                                    )
                                  )}
                                </Tab.Pane>
                              </Tab.Content>
                            </Card.Body>
                          </Card>
                        </Col>
                      </Row>
                    </Tab.Container>
                  </Tab.Pane>
                  <Tab.Pane eventKey="third">
                    <Tab.Container
                      id="left-tabs-example"
                      defaultActiveKey="all-friends"
                    >
                      <Card>
                        <Card.Body>
                          <h2>Followings</h2>
                          <div className="friend-list-tab mt-2">
                            <Nav
                              variant="pills"
                              className=" d-flex align-items-center justify-content-left friend-list-items p-0 mb-2"
                            >
                              <Nav.Item>
                                <Nav.Link
                                  href="#pill-recently-add"
                                  eventKey="recently-add"
                                >
                                  Organizations
                                </Nav.Link>
                              </Nav.Item>
                              <Nav.Item>
                                <Nav.Link
                                  href="#pill-home"
                                  eventKey="home-town"
                                >
                                  Projects
                                </Nav.Link>
                              </Nav.Item>
                              <Nav.Item>
                                <Nav.Link
                                  href="#pill-all-friends"
                                  eventKey="all-friends"
                                >
                                  Obituary
                                </Nav.Link>
                              </Nav.Item>
                              <Nav.Item>
                                <Nav.Link
                                  href="#pill-following"
                                  eventKey="following"
                                >
                                  Following
                                </Nav.Link>
                              </Nav.Item>
                            </Nav>
                            <Tab.Content>
                              <Tab.Pane eventKey="all-friends">
                                <Card.Body className="p-0">
                                  <Row>
                                    <div className="col-md-6 col-lg-6 mb-3">
                                      <div className="iq-friendlist-block">
                                        <div className="d-flex align-items-center justify-content-between">
                                          <div className="d-flex align-items-center">
                                            <Link to="#">
                                              <img
                                                loading="lazy"
                                                src={user05}
                                                alt="profile-img"
                                                className="img-fluid"
                                              />
                                            </Link>
                                            <div className="friend-info ms-3">
                                              <h5>Petey Cruiser</h5>
                                              <p className="mb-0">15 friends</p>
                                            </div>
                                          </div>
                                          <div className="card-header-toolbar d-flex align-items-center">
                                            <Dropdown>
                                              <Dropdown.Toggle variant="secondary me-2 d-flex align-items-center">
                                                <i className="material-symbols-outlined me-2">
                                                  done
                                                </i>
                                                Friend
                                              </Dropdown.Toggle>
                                              <Dropdown.Menu className="dropdown-menu-right">
                                                <Dropdown.Item href="#">
                                                  Get Notification
                                                </Dropdown.Item>
                                                <Dropdown.Item href="#">
                                                  Close Friend
                                                </Dropdown.Item>
                                                <Dropdown.Item href="#">
                                                  Unfollow
                                                </Dropdown.Item>
                                                <Dropdown.Item href="#">
                                                  Unfriend
                                                </Dropdown.Item>
                                                <Dropdown.Item href="#">
                                                  Block
                                                </Dropdown.Item>
                                              </Dropdown.Menu>
                                            </Dropdown>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </Row>
                                </Card.Body>
                              </Tab.Pane>
                            </Tab.Content>
                          </div>
                        </Card.Body>
                      </Card>
                    </Tab.Container>
                  </Tab.Pane>
                  <Tab.Pane eventKey="forth">
                    <Tab.Container id="left-tabs-example" defaultActiveKey="p1">
                      <Card>
                        <Card.Body>
                          <div className="d-flex align-items-center justify-content-between">
                            <h2>Photos</h2>
                            {user.id ===
                              organiztionDetails.authorized_user_id && (
                              <button
                                type="button"
                                className="btn btn-secondary ms-2 btn-sm d-flex align-items-center"
                                onClick={() => handleShowHide("imageGallery")}
                              >
                                <span
                                  className="material-symbols-outlined  md-12"
                                  style={{ fontSize: "14px" }}
                                >
                                  edit
                                </span>
                                Upload Photos
                              </button>
                            )}
                          </div>

                          <div className="friend-list-tab mt-2">
                            <Tab.Content>
                              <Tab.Pane eventKey="p1">
                                <Card.Body className="p-0">
                                  {editShow.imageGallery ? (
                                    <UploadGalleryImage
                                      handleShowHide={handleShowHide}
                                      organizationId={organizationId}
                                      getPhotos={getPhotos}
                                    />
                                  ) : (
                                    <PhotoGallery images={photoGalleryList} />
                                  )}
                                </Card.Body>
                              </Tab.Pane>
                            </Tab.Content>
                          </div>
                        </Card.Body>
                      </Card>
                    </Tab.Container>
                  </Tab.Pane>
                  <Tab.Pane eventKey="fifth">
                    <Tab.Container
                      id="left-tabs-example"
                      defaultActiveKey="about1"
                    >
                      <Card>
                        <Card.Body>
                          <div className="d-flex align-items-center justify-content-between">
                            <h2>videos</h2>
                            {user.id ===
                              organiztionDetails.authorized_user_id && (
                              <button
                                type="button"
                                className="btn btn-secondary ms-2 btn-sm d-flex align-items-center"
                                onClick={() => handleShowHide("videoGallery")}
                              >
                                <span
                                  className="material-symbols-outlined  md-12"
                                  style={{ fontSize: "14px" }}
                                >
                                  edit
                                </span>
                                Edit
                              </button>
                            )}
                          </div>
                          {editShow.videoGallery ? (
                            <EditVideoDetails
                              organizationId={organizationId}
                              handleShowHide={handleShowHide}
                              getvideos={getvideos}
                              orgVideos={vedioGalleryList}
                            />
                          ) : (
                            <VedioGallery videos={vedioGalleryList} />
                          )}
                        </Card.Body>
                      </Card>
                    </Tab.Container>
                  </Tab.Pane>
                  <Tab.Pane eventKey="sixth">
                    <Tab.Container
                      id="left-tabs-example"
                      defaultActiveKey="about1"
                    >
                      <Card>
                        <Card.Body>
                          <CampaignTab orgId={organiztionDetails?.id} />
                        </Card.Body>
                      </Card>
                    </Tab.Container>
                  </Tab.Pane>
                  <Tab.Pane eventKey="seventh">
                    <Tab.Container
                      id="left-tabs-example"
                      defaultActiveKey="about1"
                    >
                      <div style={{ marginTop: "80px" }}>
                        <Document orgId={organiztionDetails?.id} orgAuthorizedUserId={organiztionDetails?.authorized_user_id}/>
                      </div>
                    </Tab.Container>
                  </Tab.Pane>
                </Tab.Content>
              </Col>
            </Tab.Container>
          </Container>
        </div>
      </div>
    </>
  );
};
export default SingleOrganization;
