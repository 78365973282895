import React from 'react'
import {Card,Container,Row,Col} from 'react-bootstrap'

const PrivacyPolicy = () => {
    return (
        <>
            <Container>
                <Container fluid>
                    <Row>
                        <Col lg="12">
                            <Card>
                                <Card.Header className="d-flex justify-content-between">
                                    <div className="header-title">
                                        <h3 className="card-title">Privacy Policy</h3>
                                    </div>
                                </Card.Header>
                                <Card.Body>
                                    <p>This Privacy Policy governs the manner in which Zonesociety collects, uses, maintains, and discloses information collected from users (referred to as "Users") of the zonesociety.com website (referred to as "the Site").</p>
                                </Card.Body>
                            </Card>
                            <Card>
                                <Card.Header className="d-flex justify-content-between">
                                    <div className="header-title">
                                        <h4 className="card-title">Personal Identification Information</h4>
                                    </div>
                                </Card.Header>
                                <Card.Body>
                                    <p>We may collect personal identification information from Users in various ways, including when Users register on the Site, fill out a form, and in connection with other activities, services, features, or resources we make available on our Site. Users may be asked for, as appropriate, name, email address, mailing address, phone number, and other relevant information. Users may visit our Site anonymously, and personal identification information will only be collected from Users if they voluntarily submit such information to us. Users can always refuse to supply personal identification information, except that it may prevent them from engaging in certain Site-related activities.</p>
                                </Card.Body>
                            </Card>
                            <Card>
                                <Card.Header className="d-flex justify-content-between">
                                    <div className="header-title">
                                        <h4 className="card-title">Non-Personal Identification Information</h4>
                                    </div>
                                </Card.Header>
                                <Card.Body>
                                    <p>The personal information that you are asked to provide, and the reasons why you are asked to provide it, will be made clear to you at the point we ask you to provide your personal information.

If you contact us directly, we may receive additional information about you such as your name, email address, phone number, the contents of the message and/or attachments you may send us, and any other information you may choose to provide.

When you register for an Account, we may ask for your contact information, including items such as name, company name, address, email address, and telephone number.</p>
                                </Card.Body>
                            </Card>
                            <Card>
                                <Card.Header className="d-flex justify-content-between">
                                    <div className="header-title">
                                        <h4 className="card-title">Information we collect</h4>
                                    </div>
                                </Card.Header>
                                <Card.Body>
                                    <p>We may collect non-personal identification information about Users whenever they interact with our Site. Non-personal identification information may include the browser name, the type of computer or device, and technical information about Users' means of connection to our Site, such as the operating system and internet service providers utilized and other similar information.</p>
                                </Card.Body>
                            </Card>
                            <Card>
                                <Card.Header className="d-flex justify-content-between">
                                    <div className="header-title">
                                        <h4 className="card-title">Web Browser Cookies</h4>
                                    </div>
                                </Card.Header>
                                <Card.Body>
                                    <p>Our Site may use "cookies" to enhance User experience. Users' web browsers place cookies on their hard drive for record-keeping purposes and sometimes to track information about them. Users may choose to set their web browser to refuse cookies or to alert them when cookies are being sent. If they do so, note that some parts of the Site may not function properly.</p>
                                </Card.Body>
                            </Card>
                            <Card>
                                <Card.Header className="d-flex justify-content-between">
                                    <div className="header-title">
                                        <h4 className="card-title">How We Use Collected Information</h4>
                                    </div>
                                </Card.Header>
                                <Card.Body>
                                    <p>Zonesociety may collect and use Users' personal information for the following purposes:
                                        To personalize user experience: We may use information in the aggregate to understand how our Users as a group use the services and resources provided on our Site.
                                        To improve our Site: We continually strive to improve our website offerings based on the information and feedback we receive from Users.
                                        To send periodic emails: We may use the email address to respond to inquiries, questions, and/or other requests from Users. If Users decide to opt-in to our mailing list, they will receive emails that may include company news, updates, related product or service information, etc. If at any time Users would like to unsubscribe from receiving future emails, we include detailed unsubscribe instructions at the bottom of each email or Users may contact us via our Site.</p>
                                </Card.Body>
                            </Card>
                            <Card>
                                <Card.Header className="d-flex justify-content-between">
                                    <div className="header-title">
                                        <h4 className="card-title">How We Protect Your Information</h4>
                                    </div>
                                </Card.Header>
                                <Card.Body>
                                    <p>We adopt appropriate data collection, storage, and processing practices and security measures to protect against unauthorized access, alteration, disclosure, or destruction of your personal information and data stored on our Site.</p>
                                </Card.Body>
                            </Card>
                            <Card>
                                <Card.Header className="d-flex justify-content-between">
                                    <div className="header-title">
                                        <h4 className="card-title">Sharing Your Personal Information</h4>
                                    </div>
                                </Card.Header>
                                <Card.Body>
                                    <p>We do not sell, trade, or rent Users' personal identification information to others. We may share generic aggregated demographic information not linked to any personal identification information regarding visitors and users with our business partners, trusted affiliates, and advertisers for the purposes outlined above.</p>
                                </Card.Body>
                            </Card>
                            <Card>
                                <Card.Header className="d-flex justify-content-between">
                                    <div className="header-title">
                                        <h4 className="card-title">Third-Party Websites</h4>
                                    </div>
                                </Card.Header>
                                <Card.Body>
                                    <p>Users may find advertising or other content on our Site that links to the sites and services of our partners, suppliers, advertisers, sponsors, licensors, and other third parties. We do not control the content or links that appear on these sites and are not responsible for the practices employed by websites linked to or from our Site. Browsing and interaction on any other website, including websites that have a link to our Site, are subject to that website's own terms and policies. </p>
                                </Card.Body>
                            </Card>

                            <Card>
                                <Card.Header className="d-flex justify-content-between">
                                    <div className="header-title">
                                        <h4 className="card-title">Changes to This Privacy Policy</h4>
                                    </div>
                                </Card.Header>
                                <Card.Body>
                                    <p>Zonesociety has the discretion to update this privacy policy at any time. When we do, we will revise the updated date at the bottom of this page. We encourage Users to frequently check.</p>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                </Container>   
            </Container>
        </>
    )
}

export default PrivacyPolicy
