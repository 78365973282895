import React from "react";
import { mediaService } from "../../../../services/media.service";
import { DocumentProvider } from "../../../../hooks/useDocument";
import Document from "../../../../components/media/Document";

const DocumentTab = () => {
  const fetchAllDocuments = async () => {
    return await mediaService.fetchAllDocuments();
  };

  const handleUploadFn = async (formData) => {
    return await mediaService.documentUpload(formData);
  };

  return (
    <DocumentProvider
      fetchAllFn={fetchAllDocuments}
      uploadFn={handleUploadFn}
      orgId={null}
    >
      <Document />
    </DocumentProvider>
  );
};

export default DocumentTab;
