import React, { Fragment, useState } from "react";

export default function EventsDetails() {
  const [inputFields, setInputFields] = useState([
    { contactName: "", contactNumber: "", contactEmail: "" },
  ]);

  const handleAddFields = () => {
    setInputFields([
      ...inputFields,
      { contactName: "", contactNumber: "", contactEmail: "" },
    ]);
  };

  const handleRemoveFields = (index) => {
    const values = [...inputFields];
    values.splice(index, 1);
    setInputFields([...values]);
  };
  const handleInputChange = (index, event) => {
    const values = [...inputFields];
    const { name } = event.target;
    values[index][name] = event.target.value;
  };
  return (
    <>
      <div className="d-flex justify-content-center  ">
        <h4 className="text-dark mt-4 ">Events Details</h4>
      </div>
      <div className="d-flex justify-content-center">
        <h5 className="text-dark ">
          Events Details of the Obituary. (Optional)
        </h5>
      </div>
      {inputFields.map((inputField, index) => (
        <Fragment key={`${inputField}~${index}`}>
          <div className="row p-2 mb-2 mt-2 d-flex align-items-center">
            <div className="form-group col-md-6 col-xs-12">
              <label htmlFor="website_label">Event Title</label>
              <input
                type="text"
                name="contactName"
                className="form-control"
                id="website_label"
                onChange={(event) => handleInputChange(index, event)}
                placeholder="Label"
              />
            </div>
            <div className="form-group col-md-6 col-xs-12">
              <label htmlFor="website_label">Event Date</label>
              <input
                type="date"
                name="contactName"
                className="form-control"
                id="website_label"
                onChange={(event) => handleInputChange(index, event)}
              />
            </div>
            <div className="form-group col-md-6 col-xs-12">
              <label htmlFor="website_label">Event Start Time</label>
              <input
                type="time"
                name="contactName"
                className="form-control"
                id="website_label"
                onChange={(event) => handleInputChange(index, event)}
              />
            </div>
            <div className="form-group col-md-6 col-xs-12">
              <label htmlFor="website_label">Event End Time</label>
              <input
                type="time"
                name="contactName"
                className="form-control"
                id="website_label"
                onChange={(event) => handleInputChange(index, event)}
              />
            </div>
            <div className="form-group col-md-6 col-xs-12">
              <label htmlFor="website_label">Event Location Name</label>
              <input
                type="text"
                name="contactName"
                className="form-control"
                id="website_label"
                onChange={(event) => handleInputChange(index, event)}
                placeholder="Event Location Name"
              />
            </div>
            <div className="form-group col-6">
              <label htmlFor="contact_number">Event Location Address</label>
              <input
                type="text"
                name="contact_number"
                className="form-control"
                id="contact_number"
                onChange={(event) => handleInputChange(index, event)}
                placeholder="Event Location Address"
              />
            </div>
            <div className="form-group col-xs-12">
              <label htmlFor="contact_email">Event Location Map Link</label>
              <input
                type="url"
                name="contactEmail"
                className="form-control"
                onChange={(event) => handleInputChange(index, event)}
                placeholder="Event Location Map Link"
              />
            </div>
            <div className="form-group cols-xs-12">
              <label htmlFor="contact_email">Event Notes</label>
              <textarea
                className="form-control"
                name="excerpt"
                rows={4}
                placeholder="Event Notes"
                defaultValue={""}
              />
            </div>
            {index > 0 ? (
              <div className="col-2 col-xs-4">
                <button
                  className="btn btn-danger"
                  type="button"
                  disabled={index === 0}
                  onClick={() => handleRemoveFields(index)}
                >
                  Remove
                </button>
              </div>
            ) : null}
          </div>
        </Fragment>
      ))}
      {inputFields.length > 0 && (
        <div className="row d-flex  justify-content-left ">
          <div
            className="col-xs-12 col-md-4 btn btn-success"
            onClick={handleAddFields}
          >
            Add More
          </div>
        </div>
      )}
    </>
  );
}
