import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import CampaignCard from "../../../components/Campaign/CampaignCard";
import { donationCompaignService } from "../../../services/donationCompaign.service";

const CampaignTab = () => {
  const [data, setData] = useState([]);
  const fetchIndividualCompaigns = async () => {
    try {
      const result = await donationCompaignService.fetchIndividualCampaigns(
        "campaigns"
      );
      //   console.log(result);
      setData(result);
    } catch (err) {
      return err;
    }
  };
  useEffect(() => {
    fetchIndividualCompaigns();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return <CampaignCard campaign={data} />;
};

export default CampaignTab;
