import React, { useEffect, useRef, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import Card from "../../../components/Card";
import { Link } from "react-router-dom";
import ProfileHeader from "../../../components/profile-header";
import "../../dashboard/app/css/custom.css";
// images
import cover from "../../../assets/images/obituary/coverrip.gif";
import { Button } from "react-bootstrap";
import { useSearch } from "../../../hooks/useSearch";
import { obituaryService } from "../../../services/obituaryService";
import SkeletonCard from "../../../components/skeleton/SkeletonCard";
// import loader from "../../../assets/images/page-img/page-load-loader.gif";
import Friendscard from "../../../components/Friendscard";
import AsyncSelect from "../../../components/search/AutoSuggestSearchBar";
import { useCallback } from "react";

const ObituaryLists = () => {
  const [obituaryLists, setObituaryLists] = useState([]);
  const [showSkeleton, setShowSkeleton] = useState(true);
  const [nextPage, setNextPage] = useState({
    exists: false,
    url: "",
    queryParams: "",
  });
  const { searchQuery } = useSearch();
  // const navigate = useNavigate();
  // const handleNavigate = (slug) => {
  //   navigate(`/obituaries/name/${slug}`);

  // };
  const fetchObituaries = async (
    queryParams = `name=${searchQuery}&limit=9`
  ) => {
    setShowSkeleton(true);
    try {
      const result = await obituaryService.getObituaries(queryParams);
      setTimeout(() => {
        if (searchQuery !== "") {
          setObituaryLists(result?.obituaries);
        } else {
          setObituaryLists(result?.obituaries);
        }

        setNextPage({
          exists: result?.meta?.has_more_pages,
          url: result?.links?.next,
          queryParams: new URL(result?.links?.next).searchParams.toString(),
        });
        setShowSkeleton(false);
      }, 500);
    } catch (err) {
      setShowSkeleton(false);
      return err;
    }
  };

  useEffect(() => {
    if (searchQuery === "") {
      fetchObituaries();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchQuery]);
  const loadMore = async (queryParams) => {
    setShowSkeleton(true);
    try {
      const result = await obituaryService.getObituaries(queryParams);
      setTimeout(() => {
        if (searchQuery !== "") {
          setObituaryLists(result?.obituaries);
        } else {
          setObituaryLists((prevObituaries) => {
            return [...prevObituaries, ...result?.obituaries];
          });
        }

        setNextPage({
          exists: result?.meta?.has_more_pages,
          url: result?.links?.next,
          queryParams: new URL(result?.links?.next).searchParams.toString(),
        });
        setShowSkeleton(false);
      }, 500);
    } catch (err) {
      setShowSkeleton(false);
      return err;
    }
  };
  //method for infinite scrolling
  const observer = useRef();
  const lastObituaryElementRef = useCallback(
    (node) => {
      if (showSkeleton) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && nextPage.exists) {
          loadMore();
        }
      });
      if (node) observer.current.observe(node);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [showSkeleton, nextPage.exists]
  );
  useEffect(() => {
    // 👇️ scroll to top on page load
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);
  return (
    <>
      <div id="content-page" className="content-page">
        <Container>
          <ProfileHeader img={cover} className="header-obituary" />
          {/* <SearchBar
            placeHolder="Search by name ..."
            searchQuery={searchQuery}
            onChange={onChange}
            onSubmit={fetchObituaries}
          /> */}
          <AsyncSelect />
          <Row className="mt-3">
            <>
              {obituaryLists?.length > 0 ? (
                obituaryLists?.map((obituary, index) => {
                  const isLastElement = obituaryLists.length === index + 1;
                  return isLastElement ? (
                    <div key={index} ref={lastObituaryElementRef}>
                      <Col sm={6} md={3}>
                        <Friendscard className="ln-friendscard">
                          <Card.Body className=" text-center">
                            <div className="event-images position-relative">
                              <img
                                src={
                                  obituary.deceased.display_picture_media
                                    .grid_img_url
                                }
                                alt="profile-img"
                                className="obituary img-fluid avatar-120 rounded"
                              />
                            </div>
                            <div className="group-info pt-3 pb-3">
                              {/* <h3>{obituary.custom_title}</h3> */}
                              <div className="badge-container">
                                <span className="badge-label">Obituary</span>
                              </div>
                              <h4>
                                <Link to="#">{obituary.deceased.name}</Link>
                              </h4>
                              <p>
                                {
                                  obituary.deceased.name_translations[0]
                                    .name_translation
                                }
                              </p>
                            </div>
                            <div className="d-flex flex-column">
                              <Row className="">
                                <div className="col-5">
                                  <h6>
                                    <b>Date of Birth :</b>
                                  </h6>
                                </div>
                                <div className="align-items-center col-7">
                                  <p className="mb-0">
                                    {obituary.deceased.birth_date}
                                  </p>
                                </div>
                              </Row>
                              <Row className="">
                                <div className="col-5">
                                  <h6>
                                    <b>Birth Place:</b>
                                  </h6>
                                </div>
                                <div className="align-items-center col-7">
                                  <p className="mb-0">
                                    {obituary.deceased.birth_place}
                                  </p>
                                </div>
                              </Row>
                              <Row className="">
                                <div className="col-5">
                                  <h6>
                                    <b>Date of Death:</b>
                                  </h6>
                                </div>
                                <div className="align-items-center col-7">
                                  <p className="mb-0">
                                    {obituary.deceased.death_date}
                                  </p>
                                </div>
                              </Row>
                              <Row className="">
                                <div className="col-5">
                                  <h6>
                                    <b>Death Place:</b>
                                  </h6>
                                </div>
                                <div className="align-items-center col-7">
                                  <p className="mb-0">
                                    {obituary.deceased.death_place}
                                  </p>
                                </div>
                              </Row>
                            </div>
                            <div className="d-flex justify-content-center btn-custom">
                              <Button
                                className="w-100"
                                href={obituary.url}
                                target="_blank"
                                // onClick={() =>
                                //   // handleNavigate(obituary.deceased.name)

                                // }
                              >
                                View
                              </Button>
                            </div>
                          </Card.Body>
                        </Friendscard>
                      </Col>
                    </div>
                  ) : (
                    <Col sm={6} md={3} key={index}>
                      <Friendscard className="ln-friendscard">
                        <Card.Body className=" text-center">
                          <div className="event-images position-relative">
                            <img
                              src={
                                obituary.deceased.display_picture_media
                                  .grid_img_url
                              }
                              alt="profile-img"
                              className="obituary img-fluid avatar-120 rounded"
                            />
                          </div>
                          <div className="group-info pt-3 pb-3">
                            {/* <h3>{obituary.custom_title}</h3> */}
                            <div className="badge-container">
                              <span className="badge-label">Obituary</span>
                            </div>
                            <h4>
                              <Link to="#">{obituary.deceased.name}</Link>
                            </h4>
                            <p>
                              {
                                obituary.deceased.name_translations[0]
                                  .name_translation
                              }
                            </p>
                          </div>
                          <div className="d-flex flex-column">
                            <Row className="">
                              <div className="col-5">
                                <h6>
                                  <b>Date of Birth :</b>
                                </h6>
                              </div>
                              <div className="align-items-center col-7">
                                <p className="mb-0">
                                  {obituary.deceased.birth_date}
                                </p>
                              </div>
                            </Row>
                            <Row className="">
                              <div className="col-5">
                                <h6>
                                  <b>Birth Place:</b>
                                </h6>
                              </div>
                              <div className="align-items-center col-7">
                                <p className="mb-0">
                                  {obituary.deceased.birth_place}
                                </p>
                              </div>
                            </Row>
                            <Row className="">
                              <div className="col-5">
                                <h6>
                                  <b>Date of Death:</b>
                                </h6>
                              </div>
                              <div className="align-items-center col-7">
                                <p className="mb-0">
                                  {obituary.deceased.death_date}
                                </p>
                              </div>
                            </Row>
                            <Row className="">
                              <div className="col-5">
                                <h6>
                                  <b>Death Place:</b>
                                </h6>
                              </div>
                              <div className="align-items-center col-7">
                                <p className="mb-0">
                                  {obituary.deceased.death_place}
                                </p>
                              </div>
                            </Row>
                          </div>
                          <div className="d-flex justify-content-center btn-custom">
                            <Button
                              className="w-100"
                              href={obituary.url}
                              target="_blank"
                              // onClick={() =>
                              //   // handleNavigate(obituary.deceased.name)

                              // }
                            >
                              View
                            </Button>
                          </div>
                        </Card.Body>
                      </Friendscard>
                    </Col>
                  );
                })
              ) : (
                <Col sm={12}>
                  <p>No Obituaries found.</p>
                </Col>
              )}
            </>
            {showSkeleton && (
              <>
                {[1, 2, 3, 4, 5, 6, 7, 8].map((n) => (
                  <Col sm={3} key={n} xs={6}>
                    <SkeletonCard theme="light" />
                  </Col>
                ))}
                {/* <div className="col-sm-6 text-center p-5">
                  <img
                    loading="lazy"
                    src={loader}
                    alt="loader"
                    style={{ height: "100px" }}
                  />
                </div> */}
              </>
            )}
          </Row>
        </Container>
      </div>
    </>
  );
};

export default ObituaryLists;
