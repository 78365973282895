import React from "react";
import "./profileHeader.css";
const ProfileHeader = (props) => {
  return (
    <>
      <div className="">
        <div className="cover-image-container">
          <img
            loading="lazy"
            src={props.img}
            className="img-fluid"
            alt="header-bg"
          />
        </div>
      </div>
    </>
  );
};
export default ProfileHeader;
