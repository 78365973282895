import React, { memo, useEffect, useMemo } from 'react'
import ReactFsLightbox from 'fslightbox-react';
import { useGlobal } from '../../../hooks/useGlobal';
const FsLightbox = ReactFsLightbox.default ? ReactFsLightbox.default : ReactFsLightbox;

const VideoViewer=()=> {
    const {videoController} =useGlobal()
    useEffect(()=>{},[videoController.toggler])
  return (
    <>
    <FsLightbox
        toggler={videoController.toggler}
        sources={videoController.sources}
        slide={videoController.slide}
    /> 
    </>
  )
}
export default  memo(VideoViewer)