import React, { useContext, useEffect, useState } from "react";
import { EditorState } from "draft-js";
import { useGlobal } from "./useGlobal";
import { ADD } from "../utilities/constants";
const CampaignContext = React.createContext();
export const CampaignProvider = ({ children }) => {
    const content = JSON.stringify({
        entityMap: {},
        blocks: [
          {
            key: "637gr",
            text: "",
            type: "unstyled",
            depth: 0,
            inlineStyleRanges: [],
            entityRanges: [],
            data: {},
          },
        ],
      });
      
    const initialState ={
        title:'',
        slug:'',
        title_translations:[],
        amount:'',
        excerpt:'',

        category_id:'',
        deadline:'',
        meta_tags:[],
        meta_title:null,
        meta_description:null,

        campaign_content: {
            editorState: EditorState.createEmpty(),
            contentState: JSON.parse(content),
        },
        organization_id:null,
        image:[],
        image_gallery:[],
        medical_documents:[],
        videos:[],
        faqs:[],
        og_meta_title:null,
        og_meta_description:null,
        og_meta_image:[]
    }
    const initialError ={
        title:false,
        slug:false,
        title_translations:false,
        amount:false,
        campaign_content:false,
        excerpt:false,

        category_id:false,
        deadline:false,
        meta_tags:false,
        meta_title:false,
        meta_description:false,

        organization_id:false,
        image:false,
        image_gallery:false,
        medical_documents:false,
        videos:false,
        faqs:false,
        og_meta_title:false,
        og_meta_description:false,
        og_meta_image:false
    }
    const [campaign,setCampaign]=useState(initialState)
    const [error, setError] = useState(initialError);
    const [action,setAction]=useState(ADD)
    const onEditorStateChange = (editorState) => {
        setCampaign({
          ...campaign,
          campaign_content: { editorState },
        });
        setError({
          ...error,
          campaign_content: !campaign.campaign_content.editorState
            .getCurrentContent()
            .hasText(),
        });
      };
    const handleError = (errObj) => {
        setError(errObj);
      };
    const checkFieldValidity = (field, value) => {
      // console.log(typeof value ,field)
        if (field === "campaign_content") {
            return !campaign.campaign_content.editorState
              .getCurrentContent()
              .hasText();
        }else if (typeof value == "string") {
            return value === "" || !(field in campaign) ? true : false;
        } else {
            return value.length === 0;
        }
    };
    const handleChange = (event) => {
        const { name, value } = event.target;
        setCampaign({
            ...campaign,
            [name]: value,
          });
          setError({ ...error, [name] : checkFieldValidity(name, value) });
    };
    const handleSelect = (field, selectedValue) => {
        setCampaign({
            ...campaign,
            [field]: selectedValue,
        });
    };
    const handleDynamicDetails = (field, values) => {
        setCampaign({
            ...campaign,
            [field]: values,
        });
        setError({ ...error, [field] : checkFieldValidity(field, values) });
    };
    useEffect(()=>{
        setCampaign({
            ...campaign,
            slug : campaign.title.toLowerCase().replace(/ /g, '-').replace(/[^\w-]+/g, '')
        });
    },[campaign.title])
    const { mediaState } = useGlobal();
    useEffect(() => {
      // console.log(mediaState)
      if (mediaState.model === "campaign") {
        setCampaign({
          ...campaign,
          [mediaState.field]: mediaState.values,
        });
        mediaState.field !== "" &&
          mediaState.values &&
          setError({
            ...error,
            [mediaState.field]: checkFieldValidity(
              mediaState.field,
              mediaState.values
            ),
          });
      }
    }, [mediaState.values]);
    return (
        <CampaignContext.Provider
          value={{
            initialState,
            campaign,
            error,
            initialError,
            action,
            setAction,
            setCampaign,
            handleChange,
            handleSelect,
            handleDynamicDetails,
            handleError,
            checkFieldValidity,
            onEditorStateChange
          }}
        >
          {children}
        </CampaignContext.Provider>
    );
};
export const useCampaign = () => {
    return useContext(CampaignContext);
};