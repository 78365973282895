import React, { useEffect, useState } from "react";
import { Button, Card } from "react-bootstrap";
import { donationCompaignService } from "../../../services/donationCompaign.service";
import { useParams } from "react-router";
import axios from "axios";

const DonorInfo = () => {
  const [donorLists, setDonorLists] = useState([]);
  const [nextPageUrl, setNextPageUrl] = useState(null);
  const params = useParams();

  const fetchDonorInfo = async () => {
    try {
      const response = await donationCompaignService.fetchAllCompaigns(
        `/${params.slug}/donors?limit=3&sort[column]=created_at&sort[direction]=desc`
      );
      setDonorLists(response.donors.data);
      setNextPageUrl(response.donors.next_page_url);
    } catch (err) {
      return err;
    }
  };
  useEffect(() => {
    fetchDonorInfo();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const onLoad = async () => {
    try {
      const response = await axios.get(nextPageUrl);
      console.log(response);
      if (response.status === 200) {
        setDonorLists([...donorLists, ...response.data.donors.data]);
        setNextPageUrl(response.data.donors.next_page_url);
      }
    } catch (err) {
      return err;
    }
  };
  return (
    <Card>
      <Card.Body className="text-center">
        <h4 className="text-center">People Have Made A Donation</h4>
        {donorLists.length > 0 ? (
          <>
            {donorLists.map((donor, index) => (
              <div className="doner-info mt-3" key={index}>
                <div className="icon">
                  <i className="fas fa-donate"></i>
                </div>
                <div className="content">
                  <h3 className="title">{donor.name}</h3>
                  <div className="dinfo">
                    <span>£{donor.amount}</span>
                    {new Date(donor.created_at).toDateString()}
                  </div>
                </div>
              </div>
            ))}
          </>
        ) : (
          <>No Donors at the moment</>
        )}
        {nextPageUrl && (
          <div className="d-flex justify-content-center">
            <Button className="w-100" onClick={onLoad}>
              Load More
            </Button>
          </div>
        )}
      </Card.Body>
    </Card>
  );
};

export default DonorInfo;
