import React, { useState } from 'react'
import { Button, Col, Row } from 'react-bootstrap'
import { notifySuccess } from '../../../../components/toast/Toast'
import { useGlobal } from '../../../../hooks/useGlobal'
import { organizationService } from '../../../../services/organization.service'
import DynamicVideoDeatil from '../DynamicVideoDeatil'

export default function EditVideoDetails({organizationId,handleShowHide,getvideos,orgVideos}) {
    const [videos,setVideos]=useState([])
    const {convertImagesToRequestString} =useGlobal()
    const handleVideoDetails=(field,values)=>{
        setVideos(values)
    }
    const handleSubmit=()=>{
        let oldVideos = orgVideos.map((v)=>({
            preview_picture_id:v.preview_picture_id,
            video_url:v.video_url,
            title:v.preview_picture.img_title
        }))
        let newVideos = videos.map(v=>({...v,preview_picture_id:convertImagesToRequestString(v.preview_picture)}))
        let mergeVideos = [...newVideos,...oldVideos]
        let reqBody = {videos:JSON.stringify(mergeVideos)}
         
        organizationService.updateVedioGallery(reqBody,organizationId).then(res=>{
                console.log(res)
                notifySuccess("Gallery Videos updated successfully.")
                getvideos(organizationId)
                handleShowHide('videoGallery')
        })
    }
  return (
    <Row className='p-3 d-flex justify-content-center'>
        <Col md="8" xs="12">
            <DynamicVideoDeatil videos={videos} handleVideoDetails={handleVideoDetails}/>
            <Button className='mt-2' style={{float:'right'}} onClick={handleSubmit}>Save Changes</Button>
            <Button className='mt-2 btn-info' style={{float:'right'}} onClick={()=>handleShowHide('videoGallery')}>Cancel</Button>
        </Col>
    </Row>
  )
}
