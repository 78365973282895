import React, { useEffect, useState } from "react";
import { Button, Form } from "react-bootstrap";
import Select from "react-select";
import { useGlobal } from "../../../../../hooks/useGlobal";
import { useOrganization } from "../../../../../hooks/useOrganization";
import { organizationService } from "../../../../../services/organization.service";

const BasicDetails = (props) => {
  const [organizationTypeOptions,setOrganizationTypeOptions] = useState([])
  const [organizationSectorOptions,setOrganizationSectorOptions] =useState([])
  const [organizationSubSectorOptions,setOrganizationSubSectorOptions] = useState([])
  const [gnDivisionOptions,setGnDivisionOptions] = useState([])
  const [myOrganizationsOptions,setMyOrganizationOption] = useState([])
  const {handleChange,handleSelect,organization,error} = useOrganization()
  const { toggleMedia,setSelectSingleImage } = useGlobal();
  const getGnDivisionOptions = ()=>{
    organizationService.getGnDivisions()
    .then(res=> setGnDivisionOptions(res.data.map(gnd=>({value:gnd.id,label:gnd.name}))))
  }
  const getOrganizationTypes = ()=>{
    organizationService.getOrganizationTypes()
    .then(res=> setOrganizationTypeOptions(res.data.map(ot=>({value:ot.id,label:ot.name}))))
  }
  const getOrganizationSectorsByType = (typeId)=>{
    organizationService.getSectors(`?search[type]=${typeId}`)
    .then(res=> setOrganizationSectorOptions(res.data.map(os=>({value:os.id,label:os.name}))))
  }
  const getOrganizationSubSectorsBySector = (sectorId)=>{
    organizationService.getSubSectors(`?search[sector]=${sectorId}`)
    .then(res=> setOrganizationSubSectorOptions(res.data.map(oss=>({value:oss.id,label:oss.name}))))
  }
  const getMyOrganizations=()=>{
    organizationService.getMyOrganizations()
    .then(res=> setMyOrganizationOption(res.map(org=>({value:org.id,label:org.name}))))
  }
  useEffect(()=>{
    getOrganizationTypes()
    getGnDivisionOptions()
    getMyOrganizations()
  },[])
  useEffect(()=>{organization.type_id && getOrganizationSectorsByType(organization.type_id); setOrganizationSubSectorOptions([])},[organization.type_id])
  useEffect(()=>{organization.sector_id && getOrganizationSubSectorsBySector(organization.sector_id)},[organization.sector_id])
  // useEffect(()=>{},[Object.values(error).includes(true)])
  if (props.currentStep !== 1) {
    return null;
  }

  return (
    <>
      <div className="d-flex justify-content-center">
        <h4 className="text-dark mt-4">Basic Details</h4>
      </div>
      <Form.Group>
        
        <div className="row m-3">
          <div class="col-sm-12">
            <div class="card border border-primary">
              <div class="card-body">
                <h5 class="card-title">Logo</h5>
                {organization.logo?.length>0 && <>{organization.logo.map(img=>(<div className="img-wrapper">
                   <img src={img.src} className="select-img"/>
                </div>))}</>}
                <small>
                  Recommended image size 330x360 px. Acceptable image formats:
                  "jpg", "jpeg", "png", "gif", "webp"
                </small>
                <div className="col-12 mt-2">
                  <Button variant="primary" className="btn-style1" onClick={()=>{
                    setSelectSingleImage(true)
                    toggleMedia('logo',null)
                    }}>
                  {organization.logo?.length>0?'Change Image':'Attach Files'}
                  </Button>
                </div>
                {error.logo && <span className="text-danger">Please select logo image</span>}
              </div>
            </div>
          </div>
          <div className="form-group col-md-6 col-xs-12">
            <label htmlFor="name">Name</label>
            <input
              type="text"
              className={`form-control ${error.name ? 'is-invalid':''}`}
              id="name"
              name="name"
              placeholder="Organization Name"
              value={organization.name}
              onChange={handleChange}
            />
            <div class="invalid-feedback">
              Please enter name.
            </div>
          </div>
          <div className="form-group col-md-6 col-xs-12">
            <label htmlFor="registration_number">Registration Number</label>
            <input
              type="text"
              className={`form-control ${error.registration_number ? 'is-invalid':''}`}
              id="registration_number"
              name="registration_number"
              value={organization.registration_number}
              onChange={handleChange}
              placeholder="Registration Number"
            />
            <div class="invalid-feedback">
              Please enter registration number.
            </div>
          </div>
          <div className="form-group col-12">
            <label htmlFor="short_intro">Short Introduction</label>
            <textarea
              className={`form-control ${error.short_intro ? 'is-invalid':''}`}
              id="short_intro"
              name="short_intro"
              value={organization.short_intro}
              onChange={handleChange}
            />
             <div class="invalid-feedback">
              This field needs to include min 10 characters.
            </div>
            <small>Minimum length: 10, Maximum length: 500 Characters</small>
          </div>

          <div className="form-group col-md-6 col-xs-12">
          <Form.Group className="form-group">
            <Form.Label >Organization Type</Form.Label>
              <Select
              required
              className="dropdown"
              placeholder="Select Organization Type"
              name="type_id"
              value={organizationTypeOptions.filter(obj => obj.value===organization.type_id)} // set selected values
              options={organizationTypeOptions} // set list of the data
              onChange={(selected)=>handleSelect('type_id',selected.value)} // assign onChange function
              />
              {error.type_id && <span className="text-danger">Please select organization type</span>}
            </Form.Group>
          </div>
          <div className="form-group col-md-6 col-xs-12">
          <Form.Group className="form-group">
            <Form.Label >Sector</Form.Label>
              <Select
              required
              className="dropdown"
              placeholder="Select Sector"
              name="sector_id"
              value={organizationSectorOptions.filter(obj => obj.value===organization.sector_id)} // set selected values
              options={organizationSectorOptions} // set list of the data
              onChange={(selected)=>handleSelect('sector_id',selected.value)} // assign onChange function
              />
              {error.sector_id && <span className="text-danger">Please select sector</span>}
            </Form.Group>
          </div>
          <div className="form-group col-md-6 col-xs-12">
            <Form.Group className="form-group">
            <Form.Label >Sub Sector</Form.Label>
              <Select
              required
              className="dropdown"
              placeholder="Select Sub Sector"
              name="sub_sector_id"
              value={organizationSubSectorOptions.filter(obj => obj.value===organization.sub_sector_id)} // set selected values
              options={organizationSubSectorOptions} // set list of the data
              onChange={(selected)=>handleSelect('sub_sector_id',selected.value)} // assign onChange function
              />
              {error.sub_sector_id && <span className="text-danger">Please select sub sector</span>}
            </Form.Group>
          </div>
          <div className="form-group col-md-6 col-xs-12">
          <Form.Group className="form-group">
            <Form.Label >GN Division</Form.Label>
              <Select
              required
              className="dropdown"
              placeholder="Select GN Division"
              name="gn_division_id"
              value={gnDivisionOptions.filter(obj => obj.value===organization.gn_division_id)} // set selected values
              options={gnDivisionOptions} // set list of the data
              onChange={(selected)=>handleSelect('gn_division_id',selected.value)} // assign onChange function
              />
               {error.gn_division_id && <span className="text-danger">Please select GN division</span>}
            </Form.Group>
            </div>
            <div className="form-group col-md-6 col-xs-12">
            <Form.Group className="form-group">
            <Form.Label >Parent Organization</Form.Label>
              <Select
              className="dropdown"
              placeholder="Select Parent Organization"
              name="parent_organization_id"
              value={myOrganizationsOptions.filter(obj => obj.value===organization.parent_organization_id)} // set selected values
              options={myOrganizationsOptions} // set list of the data
              onChange={(selected)=>handleSelect('parent_organization_id',selected.value)} // assign onChange function
              />
             {error.parent_organization_id && <span className="text-danger">Please select organization</span>}
            </Form.Group>
          </div>
          <div className="form-group col-md-6 col-xs-12">
          <Form.Group className="form-group">
            <label  className="form-label" htmlFor="registration_number">Publish At</label>
            <input
              type="date"
              className={`form-control ${error.publish_at ? 'is-invalid':''}`}
              id="publish_at"
              name="publish_at"
              value={organization.publish_at}
              onChange={handleChange}
              placeholder="Publish At"
            />
            <div class="invalid-feedback">
              Please select date.
            </div>
            </Form.Group>
          </div>
          <div className="form-group col-md-12">
            <label htmlFor="google_map_url">Google Map URL</label>
            <input
              type="url"
              className={`form-control ${error.google_map_url ? 'is-invalid':''}`}
              id="google_map_url"
              name="google_map_url"
              value={organization.google_map_url}
              onChange={handleChange}
              placeholder="Google Map URL"
            />
            <div class="invalid-feedback">
              Please enter url.
            </div>
          </div>
        </div>
      </Form.Group>
    </>
  );
};

export default BasicDetails;