import React, { useEffect, useState } from "react";
import { Alert, Button, Card } from "react-bootstrap";
// import Stepper from "../../../components/stepper/Stepper";

import BasicDetails from "./steps/BasicDetails";
import { Form, Navigate, useNavigate, useParams } from "react-router-dom";
import Stepper from "./stepper/Stepper";
import MetaDetails from "./steps/MetaDetails";
import File from "./steps/File";

import DonationOtherDetails from "./steps/DonationOtherDetails";
import { useCampaign } from "../../../../hooks/useCampaign";
import { donationCompaignService } from "../../../../services/donationCompaign.service";
import { useGlobal } from "../../../../hooks/useGlobal";
import { convertToRaw } from "draft-js";
import draftToHtml from "draftjs-to-html";
import { notifyFailed, notifySuccess } from "../../../../components/toast/Toast";
import { ADD } from "../../../../utilities/constants";
export default function CampaignForm() {
  let { donationSlug } = useParams();
  let navigate = useNavigate();
  const { convertImagesToRequestString } = useGlobal();
  const [requesting,setRequesting]=useState(false)
  const {checkFieldValidity,campaign,error,handleError,action} = useCampaign()
  const [step, setStep] = useState(1);
  const [stepPercentage, setStepPercentage] = useState(0);
  const [selectOptionDetail,setSelectOptionDetail]=useState({
    organizations:[],
    campaignCategories:[],
    campaignGifts:[],
    languages:[]
  })
  const [responseError,setResponseError]=useState(null)
  const getCreateDetails =()=>{
    donationCompaignService.getCreateCampaignDetails().then(res=>setSelectOptionDetail({
      organizations:res.organizations.map(org=>({value:org.id,label:org.name})),
      campaignCategories:res.campaign_categories,
      campaignGifts:res.campaign_gifts,
      languages:res.languages
    }))
  }
  useEffect(()=>{ getCreateDetails()},[])
  const displayForms = () => {
    switch (step) {
      case 1:
        return <BasicDetails campaignCategories={selectOptionDetail.campaignCategories}/>;
      case 2:
        return <DonationOtherDetails organizations={selectOptionDetail.organizations} languages={selectOptionDetail.languages}/>;
      case 3:
        return <File />;
      case 4:
        return <MetaDetails />;
      default: return null;
    }
  };

  const _next = () => {
    let currentErrorObj = error;
    if (step === 1) {
      currentErrorObj = {
        ...currentErrorObj,
        title:checkFieldValidity('title',campaign.title),
        amount:checkFieldValidity('amount',campaign.amount),
        category_id:checkFieldValidity('category_id',campaign.category_id),
        campaign_content:checkFieldValidity('campaign_content',campaign.campaign_content),
        excerpt:checkFieldValidity('excerpt',campaign.excerpt),
      }
    }
    if (!Object.values(currentErrorObj).includes(true)) {
      setStep(step + 1);
      setStepPercentage(stepPercentage + 34);
    } else {
      handleError(currentErrorObj);
    }
   
  }
  const handleSubmit = () => {
    const {title_translations,meta_tags,og_meta_image,image,image_gallery,medical_documents,videos,faqs,campaign_content} = campaign
    let reqBody = {
      ...campaign,
      title_translations:JSON.stringify(title_translations),
      meta_tags:JSON.stringify(meta_tags),
      og_meta_image:convertImagesToRequestString(og_meta_image),
      image:convertImagesToRequestString(image),
      image_gallery:convertImagesToRequestString(image_gallery),
      medical_documents:convertImagesToRequestString(medical_documents),
      videos:JSON.stringify(videos.map((v) => ({
        ...v,
        preview_picture_id: convertImagesToRequestString(v.preview_picture),
      }))),
      faqs:JSON.stringify(faqs),
      campaign_content:draftToHtml(
        convertToRaw(
          campaign_content.editorState.getCurrentContent()
        )
      ),
    }
    if(action === ADD){
      setRequesting(true)
      donationCompaignService.createCampaign(reqBody)
      .then(res=>{
        setRequesting(false)
        notifySuccess(res.msg)
        navigate(`/donation-campaign/${res.campaign.id}`)
      }).catch(error=>{
        setRequesting(false)
        notifyFailed(error)
        setResponseError(error.errors)
      })
    }else{
      setRequesting(true)
      donationCompaignService.updateCampaign(reqBody,donationSlug)
      .then(res=>{
        notifySuccess(res.msg)
        navigate(`/donation-campaign/${res.campaign.id}`)
      }).catch(error=>{
        setRequesting(false)
        notifyFailed(error)
        setResponseError(error.errors)
      })
    }
  }
  return (
    <>
      <Form>
        <Card>
        {requesting && (
          <div className="backdrop">
            <div className="spinner" />
          </div>
        )}
          <Card.Body>
          {responseError != null && <Alert variant="danger">
              {Object.keys(responseError).map(function(key,index){
                var value = responseError[key];
                return (<div>{index+1}. {value}</div>)
              })}
              </Alert>
            }
            <Card.Title className="px-5">
              <Stepper stepPercentage={stepPercentage} step={4} />
            </Card.Title>
            {displayForms()}
          </Card.Body>
          <Card.Footer>
            <div className="d-flex justify-content-end">
              <div className="m-2">
                {step > 1 ? (
                  <Button
                    style={{ backgroundColor: "#374254", border: "none" }}
                    onClick={() => {
                      setStep(step - 1);
                      setStepPercentage(stepPercentage - 34);
                    }}
                  >
                    Previous
                  </Button>
                ) : null}
              </div>
              <div className="m-2">
                {step < 4 ? (
                  <Button
                    variant="danger"
                    onClick={_next}
                  >
                    Next
                  </Button>
                ) : null}
              </div>
              <div className="m-2">
                {step === 4 ? <Button variant="success" onClick={handleSubmit}>Submit</Button> : null}
              </div>
            </div>
          </Card.Footer>
        </Card>
      </Form>
    </>
  );
}