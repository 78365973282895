import React from "react";
import ChatLayout from "../layouts/dashboard/chat-layout";
import Chat from "../views/dashboard/app/chat";
import { ProtectedRoute } from "./ProtectedRoute";

export const ChatRouter = [
  {
    path: "chat",
    element: <ProtectedRoute><ChatLayout /></ProtectedRoute>,
    children: [
      {
        path: "index",
        element: <Chat />,
      },
    ],
  },
];
