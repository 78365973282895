import React, { useEffect, useState } from "react";
import { Col, OverlayTrigger, Row, Tooltip } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useGlobal } from "../../../../hooks/useGlobal";

export default function VedioGallery({ videos }) {
  const [sources, setSources] = useState([]);
  useEffect(() => {
    let results = videos.map((video) => video.video_url);
    setSources(results);
  }, [videos]);
  const { viewImageOnSlide } = useGlobal();
  return (
    <>
      <Row>
        {videos?.map((video, key) => (
          <Col
            lg="3"
            md="6"
            onClick={() => viewImageOnSlide(sources, key + 1)}
            key={`video-${key}`}
          >
            <div className="user-images position-relative overflow-hidden mb-3">
              <Link to="#">
                <img
                  src={video.preview_picture.img_url}
                  className="img-fluid rounded"
                  alt="Responsive"
                  style={{ height: 200 }}
                />
              </Link>
              <div className="image-hover-data">
                <div className="product-elements-icon">
                  <ul className="d-flex align-items-center m-0 p-0 list-inline">
                    <li className="">
                      <Link
                        to="#"
                        className="pe-3 text-white d-flex align-items-center"
                      >
                        {" "}
                        60
                        <i className="material-symbols-outlined md-14 ms-1">
                          thumb_up
                        </i>
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="#"
                        className="pe-3 text-white d-flex align-items-center"
                      >
                        {" "}
                        30
                        <span className="material-symbols-outlined  md-14 ms-1">
                          chat_bubble_outline
                        </span>
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="#"
                        className="pe-3 text-white d-flex align-items-center"
                      >
                        {" "}
                        10
                        <span className="material-symbols-outlined md-14 ms-1">
                          forward
                        </span>
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
              <OverlayTrigger
                placement="top"
                overlay={<Tooltip>Remove</Tooltip>}
              >
                <Link
                  to="#"
                  className="image-edit-btn material-symbols-outlined md-16"
                >
                  drive_file_rename_outline
                </Link>
              </OverlayTrigger>
            </div>
          </Col>
        ))}
      </Row>
    </>
  );
}
