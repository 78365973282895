import React, { useEffect, useState } from "react";
import { AiFillEye } from "react-icons/ai";
import { donationCompaignService } from "../../../services/donationCompaign.service";
import { useParams } from "react-router-dom";
import { DATE_OPTIONS } from "../../../utilities/constants";
import CompaignActivityModal from "./CompaignActivityModal";
import { Card } from "react-bootstrap";

const ActivityTab = () => {
  const [showCloseModal, setShowCloseModal] = useState(false);
  const [activities, setActivities] = useState([]);
  const [activity, setActivity] = useState([]);
  const params = useParams();
  const fetchAllCompaigns = async () => {
    try {
      const result = await donationCompaignService.fetchAllCompaigns(
        `/${params.slug}/updates`
      );
      setActivities(result);
    } catch (err) {
      return err;
    }
  };

  useEffect(() => {
    fetchAllCompaigns();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <>
      <Card>
        <Card.Header className="d-flex justify-content-between">
          <div className="header-title">
            <h4 className="card-title">
              {activities?.campaign_updates?.length ? (
                <>Compaign Activities</>
              ) : (
                <>No Campaigns Activities </>
              )}
            </h4>
          </div>
        </Card.Header>
        {activities?.campaign_updates?.length > 0 && (
          <Card.Body>
            <>
              <CompaignActivityModal
                isHidden={showCloseModal}
                onClose={() => setShowCloseModal(!showCloseModal)}
                activity={activity}
              />
              <ul className="iq-timeline ms-1">
                {activities?.campaign_updates?.map((activity, index) => (
                  <li key={index}>
                    <div className="timeline-dots"></div>
                    <div className="d-flex align-items-center justify-content-between">
                      <h6 className="mb-1">{activity.title}</h6>
                      <small>
                        {new Date(activity.created_at).toLocaleDateString(
                          "en-US",
                          DATE_OPTIONS
                        )}
                      </small>
                    </div>
                    <div className="d-flex align-items-center justify-content-between">
                      <p>{activity.description}</p>
                      <AiFillEye
                        size={22}
                        onClick={() => {
                          setShowCloseModal(!showCloseModal);
                          setActivity(activity);
                        }}
                        className="react-icon-btn"
                      />
                    </div>
                  </li>
                ))}
              </ul>
            </>
          </Card.Body>
        )}
      </Card>
    </>
  );
};

export default ActivityTab;
