import React, { useEffect, useState } from "react";
import { Button, Col, Row } from "react-bootstrap";
import { notifySuccess } from "../../../../components/toast/Toast";
import { useGlobal } from "../../../../hooks/useGlobal";
import { organizationService } from "../../../../services/organization.service";

export default function UploadGalleryImage({
  handleShowHide,
  organizationId,
  getPhotos,
}) {
  const [images, setImages] = useState();
  const { toggleMedia, mediaState, convertImagesToRequestString } = useGlobal();
  useEffect(() => {
    if (
      mediaState.model === "photoGallery" &&
      mediaState.field === "gallery_images"
    ) {
      setImages(mediaState.values);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mediaState.values]);
  const handleSubmit = () => {
    let reqImages = {
      gallery_images: convertImagesToRequestString(images),
      overwrite: 0,
    };
    organizationService
      .updateImageGallery(reqImages, organizationId)
      .then((res) => {
        console.log(res);
        notifySuccess("Gallery Images updated successfully.");
        getPhotos(organizationId);
        handleShowHide("imageGallery");
      });
  };
  return (
    <Row className="p-3 d-flex justify-content-center">
      <Col md="8" xs="12">
        <div class="card border border-primary">
          <div class="card-body">
            <h5 class="card-title">Gallery Photos</h5>
            {images?.length > 0 && (
              <div className="d-flex ">
                {images.map((index,img) => (
                  <div className="img-wrapper" key={`gimg-${index}`}>
                    <img
                      src={img.src}
                      className="select-img"
                      alt="gallery_images"
                    />
                  </div>
                ))}
              </div>
            )}
            <small>
              Recommended image size 1920x1280 px. Acceptable image formats:
              "jpg", "jpeg", "png", "gif", "webp"
            </small>
            <div className="col-12 mt-2">
              <Button
                className="btn-style1"
                onClick={() =>
                  toggleMedia("gallery_images", null, "photoGallery")
                }
              >
                {images?.length > 0 ? "Change Image" : "Attach Files"}
              </Button>
            </div>
          </div>
        </div>
        <Col className="mt-2">
          <Button style={{ float: "right" }} onClick={handleSubmit}>
            Save Changes
          </Button>
          <Button
            className="btn-info"
            style={{ float: "right" }}
            onClick={() => handleShowHide("imageGallery")}
          >
            Cancel
          </Button>
        </Col>
      </Col>
    </Row>
  );
}
