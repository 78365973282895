import React, { useState } from "react";
import { Alert, Button, Card, Form } from "react-bootstrap";

import MultiStepProgressBar from "./MultiStepProgressBar";
import BasicDetails from "./steps/BasicDetails";
import ProfileDetails from "./steps/ProfileDetails";
import ContactDetails from "./steps/ContactDetails";
import OtherDetails from "./steps/OtherDetails";
import SeoDetails from "./steps/SeoDetails";
import { useOrganization } from "../../../../hooks/useOrganization";
import { useGlobal } from "../../../../hooks/useGlobal";
import { organizationService } from "../../../../services/organization.service";
import { convertToRaw } from "draft-js";
import draftToHtml from "draftjs-to-html";
import { history } from "../../../../utilities/history";
import { notifySuccess } from "../../../../components/toast/Toast";
import { ADD, EDIT } from "../../../../utilities/constants";

const OrganizationMultiForm = ({ organizationSlug }) => {
  const [currentStep, setCurrentStep] = useState(1);
  const [requesting,setRequesting]=useState(false)
  const { organization, action, error, handleError, initialError } =
    useOrganization();
    const [responseError,setResponseError]=useState(null)
  const { convertImagesToRequestString } = useGlobal();
  // Trigger an alert on form submission
  const handleSubmit = (event) => {
    event.preventDefault();
    console.log(organization);
    let currentErrorObj = error;
    if (currentStep === 5) {
      currentErrorObj = {
        ...currentErrorObj,
        seo_meta_title: checkValidity("seo_meta_title"),
        seo_meta_description: checkValidity("seo_meta_description"),
        seo_meta_image: checkValidity("seo_meta_image"),
        seo_meta_keywords: checkValidity("seo_meta_keywords"),
        publish_at: false,
        verification_info: false,
        verification_documents: false,
      };
    }
    console.log(currentErrorObj);
    if (!Object.values(currentErrorObj).includes(true)) {
      let requestBody = {
        ...organization,
        logo: convertImagesToRequestString(organization.logo),
        files: JSON.stringify(organization.files),
        profile_picture: convertImagesToRequestString(
          organization.profile_picture
        ),
        cover_picture: convertImagesToRequestString(organization.cover_picture),
        gallery_images: convertImagesToRequestString(
          organization.gallery_images
        ),
        slider_images: convertImagesToRequestString(organization.slider_images),
        videos: JSON.stringify(organization.videos.map((v) => ({
          ...v,
          preview_picture_id: convertImagesToRequestString(v.preview_picture),
        }))),
        seo_meta_image: convertImagesToRequestString(
          organization.seo_meta_image
        ),
        // publish_at:"2023-10-31",
        parent_organization_id: 7,
        rich_content: draftToHtml(
          convertToRaw(
            organization.rich_content.editorState.getCurrentContent()
          )
        ),
        social_media_profiles: JSON.stringify(
          organization.social_media_profiles
        ),
        seo_meta_keywords: JSON.stringify(organization.seo_meta_keywords),
        verification_info: JSON.stringify(organization.verification_info),
        verification_documents: JSON.stringify(
          organization.verification_documents
        ),
        other_details: JSON.stringify(organization.other_details),
        other_emails: JSON.stringify(organization.other_emails),
        other_websites: JSON.stringify(organization.other_websites),
        other_contact_numbers: JSON.stringify(
          organization.other_contact_numbers
        ),
      };
      if (action === ADD) {
        setRequesting(true)
        organizationService.createOrganization(requestBody).then((res) => {
          console.log(res);
          setRequesting(false)
          notifySuccess("Organization created Successfully");
          history.navigate("app/my-organizations");
        }).catch(errRes=>{
          setRequesting(false)
          setResponseError(errRes.errors)
        })
      }
      if (action === EDIT) {
        setRequesting(true)
        organizationService
          .updateOrganization(requestBody, organizationSlug)
          .then((res) => {
            console.log(res);
            notifySuccess("Organization Updated Successfully");
            history.navigate("app/my-organizations");
          }).catch(errRes=>{
            setRequesting(false)
            setResponseError(errRes.errors)
          })
      }
    } else {
      handleError(currentErrorObj);
    }
  };
  // Test current step with ternary
  // _next and _previous functions will be called on button click

  const checkValidity = (field) => {
    if (typeof organization[field] == "string") {
      return organization[field] === "" || !(field in organization)
        ? true
        : false;
    }
    if (field === "rich_content") {
      return !organization.rich_content.editorState
        .getCurrentContent()
        .hasText();
    } else {
      return organization[field].length === 0 ? true : false;
    }
  };

  const _next = () => {
    let currentErrorObj = error;
    if (currentStep === 1) {
      currentErrorObj = {
        ...currentErrorObj,
        logo:checkValidity('logo') ,
        name: checkValidity('name') ,
        registration_number: checkValidity('registration_number') ,
        short_intro: checkValidity('short_intro') ,
        type_id: checkValidity('type_id') ,
        sector_id: checkValidity('sector_id') ,
        sub_sector_id: checkValidity('sub_sector_id') ,
        // gn_division_id: checkValidity('gn_division_id') ,
        // parent_organization_id: checkValidity('parent_organization_id') ,
        google_map_url:checkValidity('google_map_url')
      }
    }
    if(currentStep === 2){
      currentErrorObj={
        ...initialError,
        rich_content: checkValidity("rich_content"),
        files: false,
        profile_picture: checkValidity("profile_picture"),
        cover_picture: checkValidity("cover_picture"),
        gallery_images: false,
        slider_images: false,
        videos: false,
      };
    }
    if (currentStep === 3) {
      currentErrorObj = {
        ...initialError,
        postal_address: checkValidity("postal_address"),
        latitude: false,
        longitude: false,
        postal_address_number: false,
        postal_address_street: false,
        postal_address_city: false,
        postal_address_province: false,
        postal_address_zipcode: false,
        postal_address_country: false,
        main_website: checkValidity("main_website"),
        other_websites: false,
        main_email: checkValidity("main_email"),
        other_emails: false,
        main_contact_number: checkValidity("main_contact_number"),
        other_contact_numbers: false,
        social_media_profiles: false,
      };
    }
    if (currentStep === 4) {
      currentErrorObj = {
        ...initialError,
      };
    }
    console.log(currentErrorObj);
    if (!Object.values(currentErrorObj).includes(true)) {
      let currStep = currentStep >= 4 ? 5 : currentStep + 1;
      setCurrentStep(currStep);
    } else {
      handleError(currentErrorObj);
    }
  };

  const _prev = () => {
    let currStep = currentStep <= 1 ? 1 : currentStep - 1;
    setCurrentStep(currStep);
  };

  // The "next" and "previous" button functions
  const previousButton = () => {
    // If the current step is not 1, then render the "previous" button
    if (currentStep !== 1) {
      return (
        <Button
          // variant="dark"
          style={{ backgroundColor: "#374254", border: "none" }}
          className="previous action-button-previous float-end me-3"
          onClick={_prev}
        >
          Previous
        </Button>
      );
    }

    // ...else return nothing
    return null;
  };

  const nextButton = () => {
    // If the current step is not 3, then render the "next" button
    if (currentStep < 5) {
      return (
        <Button
          variant="danger"
          className="next action-button float-end"
          onClick={_next}
        >
          Next
        </Button>
      );
    }
    // ...else render nothing
    return null;
  };

  const submitButton = () => {
    // If the current step is the last step, then render the "submit" button
    if (currentStep > 4) {
      return (
        <Button
          variant="success"
          className="action-button float-end"
          type="submit"
        >
          Submit
        </Button>
      );
    }
    // ...else render nothing
    return null;
  };

  return (
    <>
      <Form onSubmit={handleSubmit}>
        <Card>
          <Card.Body>
           {responseError != null && <Alert variant="danger">
              {Object.keys(responseError).map(function(key,index){
                var value = responseError[key];
                return (<div>{index+1}. {value}</div>)
              })}
              </Alert>
            }
         
            <Card.Title className="px-5">
              <MultiStepProgressBar currentStep={currentStep} />
            </Card.Title>
            <Card.Text />
            <BasicDetails currentStep={currentStep} />
            <ProfileDetails currentStep={currentStep} />
            <ContactDetails currentStep={currentStep} />
            <OtherDetails currentStep={currentStep} />
            <SeoDetails currentStep={currentStep} />
          </Card.Body>
          <Card.Footer>
            <div className="d-flex justify-content-end">
              <div className="m-2">{previousButton()}</div>
              <div className="m-2">{nextButton()}</div>
              <div className="m-2">{submitButton()}</div>
            </div>
          </Card.Footer>
        </Card>
      </Form>
    </>
  );
};

export default OrganizationMultiForm;
