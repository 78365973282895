export const ACCESS_TOKEN = "access-token";
export const AUTH_USER = "auth-user";

export const BASE_URL = process.env.REACT_APP_ZONECEYLON_BASE_URL;
export const API_URL = process.env.REACT_APP_ZONECEYLON_API_URL;

//export const BASE_URL = "https://zoneceylon.lk";
//export const API_URL = "https://zoneceylon.lk/api/v1/";

export const DATE_OPTIONS = {
  year: "numeric",
  month: "short",
  day: "numeric",
  timeZone: "UTC",
};


export const LANGUAGES = [
  { value: "Tamil", label: "Tamil" },
  { value: "English", label: "English" },
  /*{ value: "Tongan", label: "Tongan" },
  { value: "Ukrainian", label: "Ukrainian" },
  { value: "Urdu", label: "Urdu" },*/
];
export const INTERESTED_IN = [
  { value: "Design", label: "Design" },
  { value: "Drawing", label: "Drawing" },
  { value: "Programming", label: "Programming" },
  { value: "Music", label: "Music" },
  { value: "Cinema", label: "Cinema" },
];
export const ADD = "ADD";
export const EDIT = "EDIT";
export const NOT_SPECIFIED = "Not specified";
