import React from "react";
import { Button, Form } from "react-bootstrap";
import DynamicVideoDeatil from "../../DynamicVideoDeatil";
import "../../../../../assets/css/style.css";
import { useGlobal } from "../../../../../hooks/useGlobal";
import { useOrganization } from "../../../../../hooks/useOrganization";
import { Editor } from "react-draft-wysiwyg";
import "../../../../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { ADD } from "../../../../../utilities/constants";

const ProfileDetails = (props) => {
  const { toggleMedia,setSelectSingleImage } = useGlobal();
  const {
    organization,
    handleDynamicDetails,
    onEditorStateChange,
    action,
    error,
  } = useOrganization();
  if (props.currentStep !== 2) {
    return null;
  }
  const { editorState } = organization.rich_content;
  return (
    <>
      <div className="d-flex justify-content-center">
        <h4 className="fs-title py-3">Profile Details</h4>
      </div>
      <Form.Group>
        <div class="row">
          <div className="col-sm-12 mb-3 ">
            <div
              className={`form-control ${
                error.rich_content ? "is-invalid" : ""
              }`}
              style={{ minHeight: 250 }}
            >
              <Editor
                editorState={editorState}
                onEditorStateChange={onEditorStateChange}
              />
            </div>
            {error.rich_content && (
              <span className="text-danger ">Please enter content.</span>
            )}
            The content must be at least 10 characters.
          </div>
          <div class="col-sm-6">
            <div class="card border border-primary">
              <div class="card-body">
                <h5 class="card-title">Profile Picture</h5>
                {organization.profile_picture?.length > 0 && (
                  <>
                    {organization.profile_picture.map((img) => (
                      <div className="img-wrapper">
                        <img
                          src={img.src}
                          className="select-img"
                          alt="profile_picture"
                        />
                      </div>
                    ))}
                  </>
                )}
                <small>
                  Recommended image size 330x360 px. Acceptable image formats:
                  "jpg", "jpeg", "png", "gif", "webp"
                </small>
                <div className="col-12 mt-2">
                  <Button
                    variant="primary"
                    className="btn-style1"
                    onClick={() => {
                      toggleMedia("profile_picture", null)
                      setSelectSingleImage(true)
                    }}
                  >
                    {organization.profile_picture?.length > 0
                      ? "Change Image"
                      : "Attach Files"}
                  </Button>
                </div>
                {error.profile_picture && (
                  <span className="text-danger">
                    Please select profile picture
                  </span>
                )}
              </div>
            </div>
          </div>
          <div class="col-sm-6">
            <div class="card border border-primary">
              <div class="card-body">
                <h5 class="card-title">Cover Picture</h5>
                {organization.cover_picture?.length > 0 && (
                  <>
                    {organization.cover_picture.map((img) => (
                      <div className="img-wrapper">
                        <img
                          src={img.src}
                          className="select-img"
                          alt="cover_picture"
                        />
                      </div>
                    ))}
                  </>
                )}
                <small>
                  Recommended image size 1584x396 px. Acceptable image formats:
                  "jpg", "jpeg", "png", "gif", "webp"
                </small>
                <div className="col-12 mt-2">
                  <Button
                    className="btn-style1"
                    onClick={() => {
                      setSelectSingleImage(true)
                      toggleMedia("cover_picture", null)
                    }}
                  >
                    {organization.cover_picture?.length > 0
                      ? "Change Image"
                      : "Attach Files"}
                  </Button>
                </div>
                {error.cover_picture && (
                  <span className="text-danger">
                    Please select cover picture
                  </span>
                )}
              </div>
            </div>
          </div>
          {action === ADD && (
            <>
              {" "}
              <div class="col-sm-6">
                <div class="card border border-primary">
                  <div class="card-body">
                    <h5 class="card-title">Gallery Photos</h5>
                    {organization.gallery_images?.length > 0 && (
                      <>
                        {organization.gallery_images.map((img) => (
                          <div className="img-wrapper">
                            <img
                              src={img.src}
                              className="select-img"
                              alt="gallery_images"
                            />
                          </div>
                        ))}
                      </>
                    )}
                    <small>
                      Recommended image size 1920x1280 px. Acceptable image
                      formats: "jpg", "jpeg", "png", "gif", "webp"
                    </small>
                    <div className="col-12 mt-2">
                      <Button
                        className="btn-style1"
                        onClick={() => toggleMedia("gallery_images", null)}
                      >
                        {organization.gallery_images?.length > 0
                          ? "Change Image"
                          : "Attach Files"}
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-sm-6">
                <div class="card border border-primary">
                  <div class="card-body">
                    <h5 class="card-title">Slider Photos</h5>
                    {organization.slider_images?.length > 0 && (
                      <>
                        {organization.slider_images.map((img) => (
                          <div className="img-wrapper">
                            <img
                              src={img.src}
                              className="select-img"
                              alt="slider_images"
                            />
                          </div>
                        ))}
                      </>
                    )}
                    <small>
                      Recommended image size 1280x392 px. Maximum images:
                      5.Acceptable image formats: "jpg", "jpeg", "png", "gif",
                      "webp"
                    </small>
                    <div className="col-12 mt-2">
                      <Button
                        className="btn-style1"
                        onClick={() => toggleMedia("slider_images", null)}
                      >
                        {organization.slider_images?.length > 0
                          ? "Change Image"
                          : "Attach Files"}
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
              <div className="form-group col-12 mt-4">
                <label>Videos</label>
                <DynamicVideoDeatil
                  videos={organization.videos}
                  handleVideoDetails={handleDynamicDetails}
                />
              </div>{" "}
            </>
          )}
        </div>
      </Form.Group>
    </>
  );
};

export default ProfileDetails;
