import React from "react";
import { Card, Col, Row, Spinner } from "react-bootstrap";
import { FollowService } from "../../../../services/follow.service";
import { useState } from "react";
import { useEffect } from "react";
import { useAuth } from "../../../../hooks/useAuth";
import Friendscard from "../../../../components/Friendscard";

const SentRequest = () => {
  const { user } = useAuth();
  const [sentRequests, setSentRequests] = useState([]);
  const [loadingId, setLoadingId] = useState(null);
  const [requestSentIds, setRequestSentIds] = useState([]);
  const updateRequest = async (reqId) => {
    setLoadingId(reqId);
    try {
      const res = await FollowService.updateReqest(
        `update?follower_id=${reqId}&status=cancelled`
      );
      console.log(res);
      setTimeout(() => {
        setLoadingId(null);
        setRequestSentIds([...requestSentIds, reqId]);
      }, 800);
    } catch (err) {
      setLoadingId(null);
      return err;
    }
  };
  const fetchSentRequests = async () => {
    try {
      const response = await FollowService.fetchRequests(
        `${user.id}?type=following&request_type=requested`
      );
      setSentRequests(response?.connections);
    } catch (err) {
      return err;
    }
  };
  useEffect(() => {
    fetchSentRequests();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const handleClick = (id) => {
    if (loadingId === null) {
      updateRequest(id);
    }
  };

  const renderButtonContent = (id) => {
    if (loadingId === id) {
      return (
        <Spinner animation="border" size="sm" role="status">
          <span className="visually-hidden">Loading...</span>
        </Spinner>
      );
    } else if (requestSentIds.includes(id)) {
      return "Cancelled";
    } else {
      return "Cancel Request";
    }
  };
  const isButtonDisabled = (id) => {
    return loadingId !== null || requestSentIds.includes(id);
  };
  return (
    // <Row>
    //   <Col sm="12">
    //     <Card>
    //       <Card.Header className="d-flex justify-content-between">
    //         <div className="header-title">
    //           <h4 className="card-title">Sent Friend Requests</h4>
    //         </div>
    //       </Card.Header>
    //       <Card.Body>
    //         <ul className="request-list list-inline m-0 p-0">
    //           {sentRequests?.map((ele, index) => (
    //             <li
    //               className="d-flex align-items-center  justify-content-between flex-wrap"
    //               key={index}
    //             >
    //               <div className="user-img img-fluid flex-shrink-0">
    //                 <img
    //                   src={ele.user?.display_picture.img_url}
    //                   alt="story-img"
    //                   className="rounded-circle avatar-40"
    //                 />
    //               </div>
    //               <div className="flex-grow-1 ms-3">
    //                 <h6>{ele.user?.name}</h6>
    //                 {/* <p className="mb-0">40 friends</p> */}
    //               </div>
    //               <div className="d-flex align-items-center mt-2 mt-md-0">
    //                 <Link
    //                   to="#"
    //                   onClick={() => questionAlert(ele.user?.id)}
    //                   className="btn btn-primary rounded"
    //                   data-extra-toggle="delete"
    //                   data-closest-elem=".item"
    //                 >
    //                   Cancel Request
    //                 </Link>
    //               </div>
    //             </li>
    //           ))}

    //           {sentRequests?.length === 0 && (
    //             //   <li className="d-block text-center mb-0 pb-0">
    //             //     <Link to="#" className="me-3 btn btn-primary">
    //             //       View More Request
    //             //     </Link>
    //             //   </li>
    //             // ) : (
    //             <h4>No requests found</h4>
    //           )}
    //         </ul>
    //       </Card.Body>
    //     </Card>
    //   </Col>
    // </Row>
    <div id="content-page" className="content-page">
      <Row>
        <>
          {sentRequests?.following?.map((ele, index) => (
            <Col sm="6" md="3" key={index}>
              <Friendscard className="friendscard">
                <div className="user-img img-fluid flex-shrink-0">
                  <img
                    src={ele.user.display_picture.img_url}
                    alt="story-img"
                    className="rounded-circle avatar-40"
                  />
                  <button
                    type="submit"
                    class="btn btn-primary"
                    disabled={isButtonDisabled(ele.user.id)}
                    onClick={() => handleClick(ele.user.id)}
                  >
                    {renderButtonContent(ele.user.id)}
                  </button>

                  <h5> {ele.user.name}</h5>

                  <div>
                        <b>JobTitle:</b> {ele.job_title}<br />
                        <b>Industry:</b> {ele.industry?.name}<br />
                        {/* <b>Country:</b> {ele.country?.name} */}
                      </div>
                </div>
              </Friendscard>
            </Col>
          ))}
          {sentRequests?.following?.length === 0 && (
            <Card className="p-2">
              <h4>No Requests Found</h4>
            </Card>
          )}
        </>
      </Row>
    </div>
  );
};

export default SentRequest;
