import React, { useEffect, useState } from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useGlobal } from "../../../../hooks/useGlobal";

export default function PhotoGallery({ images }) {
  const [sources, setSources] = useState([]);
  useEffect(() => {
    let results = images.map((img) => img.img_url);
    setSources(results);
  }, [images]);
  const { viewImageOnSlide } = useGlobal();

  return (
    <>
      <div className="d-grid gap-2 d-grid-template-1fr-13">
        {images?.map((photo, index) => (
          <div
            key={`photo-${index}`}
            onClick={() => viewImageOnSlide(sources, index + 1)}
          >
            <div className="user-images position-relative overflow-hidden">
              <Link to="#">
                <img
                  loading="lazy"
                  src={photo.grid_img_url}
                  className="img-fluid rounded"
                  alt="Responsive"
                  style={{ height: 200 }}
                />
              </Link>
              <div className="image-hover-data">
                <div className="product-elements-icon">
                  <ul className="d-flex align-items-center m-0 p-0 list-inline">
                    <li>
                      <Link
                        to="#"
                        className="pe-3 text-white d-flex align-items-center"
                      >
                        {" "}
                        60{" "}
                        <i className="material-symbols-outlined md-14 ms-1">
                          thumb_up
                        </i>{" "}
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="#"
                        className="pe-3 text-white d-flex align-items-center"
                      >
                        {" "}
                        30{" "}
                        <span className="material-symbols-outlined  md-14 ms-1">
                          chat_bubble_outline
                        </span>{" "}
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="#"
                        className="pe-3 text-white d-flex align-items-center"
                      >
                        {" "}
                        10{" "}
                        <span className="material-symbols-outlined md-14 ms-1">
                          forward
                        </span>{" "}
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
              <OverlayTrigger
                placement="top"
                overlay={<Tooltip>Remove</Tooltip>}
              >
                <Link
                  to="#"
                  className="image-edit-btn material-symbols-outlined md-16"
                >
                  drive_file_rename_outline
                </Link>
              </OverlayTrigger>
            </div>
          </div>
        ))}
      </div>
    </>
  );
}
