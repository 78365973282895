import { api } from "./axios.service";

//for users
const fetchListofPeoples = () => {
  return new Promise((resolve, reject) => {
    api("GET", "connections", "", "")
      .then((response) => {
        resolve(response.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
// fetch received requests and sent requests
const fetchRequests = (params) => {
  return new Promise((resolve, reject) => {
    api("GET", "connections", "", "", `${params}`)
      .then((response) => {
        resolve(response.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
const sendRequest = (params) => {
  return new Promise((resolve, reject) => {
    api("POST", null, "", "", `${params}`)
      .then((response) => {
        resolve(response.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
const updateReqest = (params) => {
  return new Promise((resolve, reject) => {
    api("PUT", "connections", "", "", `${params}`)
      .then((response) => {
        resolve(response.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
const unfriendRequest = (params) => {
  return new Promise((resolve, reject) => {
    api("DELETE", "connections", "", "", `${params}`)
      .then((response) => {
        resolve(response.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
// for organization
const addFollowOrganization = (params) => {
  return new Promise((resolve, reject) => {
    api("POST", "organization/user", "", "", `${params}`)
      .then((response) => {
        resolve(response.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
/**
 * fetch organization lists which are not followed by currentuse
 * @returns <Promise[OrganizationsLists]>
 */
const fetchListofOrganization = () => {
  return new Promise((resolve, reject) => {
    api("GET", "organization", "", "")
      .then((response) => {
        resolve(response.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
/**
 *
 * @param {status:string(approved or pending)} params
 * @returns {Promise<>} follow requests
 */
const fetchFollowReqsforMyOrgs = (params) => {
  return new Promise((resolve, reject) => {
    api("GET", "organization/user_requests", "", "", `all?${params}`)
      .then((response) => {
        resolve(response.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
/**
 *
 * @param {organizationId:number} orgId
 * @param {volunteer_id:number & status:string(approved,rejected)} params
 * @returns {Promise} return approved connections
 */
const approveVolunteer = (orgId, params) => {
  return new Promise((resolve, reject) => {
    api("PUT", "organization", "", "", `${orgId}?${params}`)
      .then((response) => {
        resolve(response.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

const fetchOrganizationsFollowingByUser = (userId) => {
  return new Promise((resolve, reject) => {
    api("GET", "organization/user", "", "", `${userId}`)
      .then((response) => {
        resolve(response.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
export const FollowService = {
  fetchListofPeoples,
  fetchRequests,
  updateReqest,
  sendRequest,
  unfriendRequest,
  addFollowOrganization,
  fetchListofOrganization,
  fetchFollowReqsforMyOrgs,
  approveVolunteer,
  fetchOrganizationsFollowingByUser,
};
