import React, { useEffect, useState } from "react";
import { Dropdown, Form, Modal } from "react-bootstrap";
import { Link } from "react-router-dom";
// import DateTimePicker from "react-xdsoft-datetimepicker";
import CustomToggle from "../../components/dropdowns";
import img1 from "../../assets/images/small/07.png";
import user1 from "../../assets/images/user/01.jpg"; //Modified by Darshan
import { usePost } from "../../hooks/usePost";
import { Editor } from "react-draft-wysiwyg";
import Select from "react-select";
import { organizationService } from "../../services/organization.service";
import { useGlobal } from "../../hooks/useGlobal";
import { convertToRaw } from "draft-js";
import draftToHtml from "draftjs-to-html";
import { useAuth } from "../../hooks/useAuth";
import { postService } from "../../services/post.service";
import { ADD, EDIT } from "../../utilities/constants";

export default function BasicPostModal({
  handleClose,
  show,
  toggleMedia,
  toggleVideoDetailModal,
  getPosts,
}) {
  const { user } = useAuth();
  const { post, onEditorStateChange, handleSelect, handleChange, action } =
    usePost();
  const { convertImagesToRequestString } = useGlobal();
  const [myOrganizationsOptions, setMyOrganizationOption] = useState([]);
  const getMyOrganizations = () => {
    organizationService
      .getMyOrganizations()
      .then((res) =>
        setMyOrganizationOption(
          res.map((org) => ({ value: org.id, label: org.name }))
        )
      );
  };

  const handleSubmit = (postStatus) => {
    const { post_visibility, comment_status, title } = post;
    let reqBody = {
      post_visibility,
      comment_status,
      title,
      // publish_at:publishAt,
      author_type: post.organization_id != null ? "organization" : "user",
      author_id: post.organization_id != null ? post.organization_id : user.id,
      post_status: postStatus,
      photos: convertImagesToRequestString(post.photos),
      videos: JSON.stringify(
        post.videos?.map((v) => ({
          video_url: v.video_url,
          video_title: v.video_title,
          preview_picture_id: convertImagesToRequestString(v.preview_picture),
        }))
      ),
      content: draftToHtml(
        convertToRaw(post.content.editorState.getCurrentContent())
      ),
    };
    postService
      .createOrganizationPost(reqBody)
      .then((res) => {
        console.log(res);
        if (res.type === "success") {
          handleClose();
        }
        getPosts(reqBody.author_id);
      })
      .catch((err) => console.log(err));
  };
  const handleUpdate = (postStatus) => {
    console.log(post);
    const { post_visibility, comment_status, title } = post;
    let reqBody = {
      post_visibility,
      comment_status,
      title,
      // publish_at:publishAt,
      author_type: post.organization_id != null ? "organization" : "user",
      author_id: post.organization_id != null ? post.organization_id : user.id,
      post_status: postStatus,
      photos: convertImagesToRequestString(post.photos),
      videos: JSON.stringify(
        post.videos?.map((v) => ({
          video_url: v.video_url,
          video_title: v.video_title,
          preview_picture_id: convertImagesToRequestString(v.preview_picture),
        }))
      ),
      content: draftToHtml(
        convertToRaw(post.content.editorState.getCurrentContent())
      ),
    };
    postService.updateOrganizationPost(reqBody, post.ulid).then((res) => {
      getPosts();
      handleClose();
    });
    console.log(reqBody);
  };
  useEffect(() => {
    getMyOrganizations();
  }, []);
  return (
    <>
      <Modal
        size="lg"
        className="fade"
        id="post-modal"
        onHide={handleClose}
        show={show}
      >
        <Modal.Header className="d-flex justify-content-between">
          <Modal.Title id="post-modalLabel">Create Post</Modal.Title>
          <Link to="#" className="lh-1" onClick={handleClose}>
            <span className="material-symbols-outlined">close</span>
          </Link>
        </Modal.Header>
        <Modal.Body>
          <div className="d-flex align-items-center">
            <div className="user-img">
              <img
                src={user1}
                alt="user1"
                className="avatar-60 rounded-circle img-fluid"
              />
            </div>
            <form
              className="post-text ms-3 w-100 "
              data-bs-toggle="modal"
              data-bs-target="#post-modal"
            >
              <input
                type="text"
                className="form-control rounded"
                name="title"
                placeholder="Write something here..."
                style={{ border: "none" }}
                value={post.title}
                onChange={handleChange}
              />
            </form>
          </div>
          {post.title === "" && (
            <span className="text-danger">Please enter title</span>
          )}
          <hr className="m-0 my-3" />
          <div className="d-flex flex-wrap align-items-center list-inline m-0 p-0">
            <div className="col-md-3">
              <ul className="list-inline m-0 p-0">
                <li className="m-2">
                  <div
                    className="bg-soft-primary rounded p-2 pointer me-3 btn"
                    onClick={() => toggleMedia("photos", null, "post")}
                  >
                    <img src={img1} alt="icon" className="img-fluid" />
                    {post.photos?.length > 0
                      ? "Change Images"
                      : "Attach Images"}
                  </div>
                </li>
                <li className="m-2">
                  <div
                    className="bg-soft-primary rounded p-2 pointer me-3 btn"
                    onClick={toggleVideoDetailModal}
                  >
                    <img src={img1} alt="icon" className="img-fluid" />
                    {post.videos?.length > 0
                      ? "Change Video Link"
                      : "Attach Video Link"}
                  </div>
                </li>
              </ul>
            </div>
            <div className="col-md-9">
              <div className={`form-control `} style={{ minHeight: 150 }}>
                <Editor
                  // wrapperClassName="wrapper-class"
                  // editorClassName="editor-class"
                  // toolbarClassName="toolbar-class"
                  editorState={post.content.editorState}
                  onEditorStateChange={onEditorStateChange}

                  // toolbar={{
                  //   inline: { inDropdown: true },
                  //   list: { inDropdown: true },
                  //   textAlign: { inDropdown: true },
                  //   link: { inDropdown: true },
                  //   history: { inDropdown: true },
                  // }}
                />
              </div>
            </div>
          </div>
          <div className="d-flex align-items-center justify-content-between m-3">
            <div className="form-group">
              <label htmlFor="comment_status" className="px-4">
                Comment Status:
              </label>
              <Form.Check className="form-check radio form-check-inline">
                <Form.Check.Label>
                  Open
                  <Form.Check.Input
                    type="radio"
                    name="comment_status"
                    id="radio1"
                    defaultChecked={post.comment_status === "open"}
                    value="open"
                    onChange={handleChange}
                  />
                </Form.Check.Label>
              </Form.Check>
              <Form.Check className="form-check radio form-check-inline ">
                <Form.Check.Label>
                  Close
                  <Form.Check.Input
                    type="radio"
                    name="comment_status"
                    id="radio2"
                    defaultChecked={post.comment_status === "closed"}
                    value="closed"
                    onChange={handleChange}
                  />
                </Form.Check.Label>
              </Form.Check>
            </div>
          </div>
          <div className="d-flex align-items-center justify-content-between m-2">
            <div className="col-6 col-xs-12">
              <Form.Group>
                <label htmlFor="content">Organization:</label>
                <Select
                  className="dropdown"
                  placeholder="Select Organization"
                  name="parent_organization_id"
                  value={myOrganizationsOptions.filter(
                    (obj) => obj.value === post.organization_id
                  )} // set selected values
                  options={myOrganizationsOptions} // set list of the data
                  onChange={(selected) =>
                    handleSelect("organization_id", selected.value)
                  } // assign onChange function
                />
              </Form.Group>
            </div>
            <div className="col-5 col-xs-12">
              <label htmlFor="content">Schedule post:</label>
              {/* <DateTimePicker
                className="schedule-btn"
                defaultValue="2015-05-25 05:05"
              /> */}
              <Form.Control
                type="datetime-local"
                name="publish_at"
                min={new Date().toISOString().slice(0, 10)}
                value={post.publish_at}
                onChange={handleChange}
              />
            </div>
          </div>
          <div className="d-flex m-3">
            <label htmlFor="content">Post Audience:</label> &nbsp;&nbsp;
            <Dropdown>
              <Dropdown.Toggle as={CustomToggle} role="button">
                <span className="btn btn-primary text-capitalize">
                  {post.post_visibility}
                </span>
              </Dropdown.Toggle>
              <Dropdown.Menu className=" m-0 p-0">
                <Dropdown.Item
                  className=" p-3"
                  to="#"
                  onClick={() => handleSelect("post_visibility", "public")}
                >
                  <div className="d-flex align-items-top">
                    <i className="ri-save-line h4"></i>
                    <div className="data ms-2">
                      <h6>Public</h6>
                      <p className="mb-0">Anyone on or off Facebook</p>
                    </div>
                  </div>
                </Dropdown.Item>
                {/* <Dropdown.Item className="p-3" to="#">
                    <div className="d-flex align-items-top">
                      <i className="ri-close-circle-line h4"></i>
                      <div className="data ms-2">
                        <h6>Friends</h6>
                        <p className="mb-0">Your Friend on facebook</p>
                      </div>
                    </div>
                  </Dropdown.Item>
                  <Dropdown.Item className=" p-3" to="#">
                    <div className="d-flex align-items-top">
                      <i className="ri-user-unfollow-line h4"></i>
                      <div className="data ms-2">
                        <h6>Friends except</h6>
                        <p className="mb-0">Don't show to some friends</p>
                      </div>
                    </div>
                  </Dropdown.Item> */}
                <Dropdown.Item
                  className=" p-3"
                  to="#"
                  onClick={() => handleSelect("post_visibility", "private")}
                >
                  <div className="d-flex align-items-top">
                    <i className="ri-notification-line h4"></i>
                    <div className="data ms-2">
                      <h6>Private</h6>
                      <p className="mb-0">Private</p>
                    </div>
                  </div>
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </div>
          <div className="d-flex p-1">
            {action === ADD && (
              <button
                type="button"
                className="btn btn-primary d-block w-100 m-0"
                onClick={() => handleSubmit("publish")}
              >
                Publish Post
              </button>
            )}
            {action === EDIT && (
              <button
                type="button"
                className="btn btn-primary d-block w-100 m-0"
                onClick={() => handleUpdate("publish")}
              >
                Update Post
              </button>
            )}
          </div>
          <div className="d-flex p-1 mt-2">
            <button
              type="button"
              className="btn btn-warning d-block w-50 m-0 "
              onClick={() =>
                action === ADD ? handleSubmit("draft") : handleUpdate("draft")
              }
            >
              Save as Draft
            </button>
            <button
              type="button"
              className="btn btn-secondary d-block w-50 m-0"
              onClick={handleClose}
            >
              Cancel Post
            </button>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}
