import React, { StrictMode } from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

// import reportWebVitals from './reportWebVitals';

//router
import { createBrowserRouter, RouterProvider } from "react-router-dom";

//store

import { Provider } from "react-redux";
//reducer
import { store } from "./store";

import { IndexRouters } from "./router";
import { SimpleRouter } from "./router/simple-router";
import { ChatRouter } from "./router/chat-router";
import { GlobalProvider } from "./hooks/useGlobal";
import { AuthProvider } from "./hooks/useAuth";
import { Spinner } from "react-bootstrap";
import { MyProfileMediaContextProvider } from "./hooks/useMyProfileMedia";
import { FollowContextProvider } from "./hooks/useFollow";
import { TabProvider } from "./hooks/useTab";

const router = createBrowserRouter(
  [...IndexRouters, ...SimpleRouter, ...ChatRouter],
  { basename: process.env.PUBLIC_URL }
);
ReactDOM.createRoot(document.getElementById("root")).render(
  <StrictMode>
    <Provider store={store}>
      <GlobalProvider>
        <ToastContainer />
        <AuthProvider>
          <TabProvider>
            <MyProfileMediaContextProvider>
              <FollowContextProvider>
                <App>
                  <RouterProvider
                    router={router}
                    fallbackElement={<Spinner animation="border" />}
                  ></RouterProvider>
                </App>
              </FollowContextProvider>
            </MyProfileMediaContextProvider>
          </TabProvider>
        </AuthProvider>
      </GlobalProvider>
    </Provider>
  </StrictMode>
);
