import { createContext, useContext, useEffect, useState } from "react";
const TabContext = createContext();
const TabProvider = ({ children }) => {
  const [activeTab, setActiveTab] = useState(() => {
    return localStorage.getItem("activeTab") || "first";
  });
  const [activeSubTab, setActiveSubTab] = useState(() => {
    return localStorage.getItem("activeSubTab") || "firstSubTab";
  });
  const handleTabChange = (tabKey) => {
    setActiveTab(tabKey);
  };
  const handleSubTabChange = (tabKey) => {
    setActiveSubTab(tabKey);
  };
  console.log(activeSubTab);

  useEffect(() => {
    localStorage.setItem("activeTab", activeTab);
    localStorage.setItem("activeSubTab", activeSubTab);
    return () => {
      localStorage.removeItem("activeTab");
      localStorage.removeItem("activeSubTab");
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeTab, activeSubTab]);
  return (
    <TabContext.Provider
      value={{ activeTab, handleTabChange, activeSubTab, handleSubTabChange }}
    >
      {children}
    </TabContext.Provider>
  );
};
const useTab = () => {
  return useContext(TabContext);
};
export { useTab, TabProvider };
