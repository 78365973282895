import axios from "axios";

const fetchProducts = async () => {
  try {
    const result = await axios.get(`http://localhost:3009/products`);
    return result;
  } catch (err) {
    return err;
  }
};

export const productService = {
  fetchProducts,
};
