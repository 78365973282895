import React, { useEffect } from "react";
import { Card, Col, Container, Row } from "react-bootstrap";
import { useParams } from "react-router-dom";
import Header from "../../components/user-profile/Header";
import { useOrganization } from "../../hooks/useOrganization";
import { organizationService } from "../../services/organization.service";
import OrganizationMultiForm from "../dashboard/components/organization/OrganizationMultiForm";
import { EditorState, ContentState } from "draft-js";
import htmlToDraft from "html-to-draftjs";
import { EDIT } from "../../utilities/constants";
export default function EditOrganizationPage() {
  let { organizationSlug } = useParams();
  const { setOrganization, setAction } = useOrganization();
  const getOrganizationDetails = (slug) => {
    organizationService.getEditOrganizationDetails(slug).then((data) => {
      const {
        logo_details,
        name,
        registration_number,
        short_intro,
        organization_sector,
        sector_id,
        sub_sector_id,
        gn_division_id,
        parent_organization_id,
        google_map_url,
        rich_content,
        profile_picture_details,
        cover_picture_details,
        postal_address,
        postal_address_city,
        postal_address_country,
        postal_address_number,
        postal_address_province,
        postal_address_street,
        postal_address_zipcode,
        latitude,
        longitude,
        main_website,
        other_websites,
        main_email,
        other_emails,
        main_contact_number,
        other_contact_numbers,
        social_media_profiles,
        other_details,
        seo_meta_title,
        seo_meta_description,
        seo_meta_keywords,
        seo_meta_image_details,
        publish_at,
        verification_info,
        verification_documents,
      } = data.organization;
      const contentBlock = htmlToDraft(rich_content);
      const contentState1 = ContentState.createFromBlockArray(
        contentBlock.contentBlocks
      );
      const editorState1 = EditorState.createWithContent(contentState1);
      setOrganization({
        // basic details
        logo: logo_details,
        name,
        registration_number,
        short_intro,
        type_id: organization_sector.organization_type_id,
        sector_id,
        sub_sector_id,
        gn_division_id,
        parent_organization_id,
        google_map_url,
        // profile details
        rich_content: { editorState: editorState1 },
        files: [],
        profile_picture: profile_picture_details,
        cover_picture: cover_picture_details,
        gallery_images: [],
        slider_images: [],
        videos: [],
        // contact details
        postal_address,
        latitude,
        longitude,
        postal_address_number,
        postal_address_street,
        postal_address_city,
        postal_address_province,
        postal_address_zipcode,
        postal_address_country,
        main_website,
        other_websites: other_websites ?? [],
        main_email,
        other_emails: [],
        main_contact_number,
        other_contact_numbers: other_contact_numbers ?? [],
        social_media_profiles: social_media_profiles ?? [],
        other_details: other_details ?? [],
        // seo details
        seo_meta_title,
        seo_meta_description,
        seo_meta_image: seo_meta_image_details ?? [],
        seo_meta_keywords: seo_meta_keywords ?? [],
        publish_at,
        verification_info: verification_info ?? [],
        verification_documents: verification_documents ?? [],
      });
      console.log(data);
    });
  };

  useEffect(() => {
    organizationSlug && getOrganizationDetails(organizationSlug);
    setAction(EDIT);
  }, [organizationSlug]);

  return (
    <Container>
      <Row>
        <Col sm={12}>
          <Card>
            <Card.Header className="d-flex justify-content-between">
              <div className="header-title">
                <h4 className="card-title">Edit Organization</h4>
              </div>
            </Card.Header>
            <Card.Body>
              <div className="form1 form2">
                <OrganizationMultiForm organizationSlug={organizationSlug} />
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
}
