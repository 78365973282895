import { api } from "./axios.service";

const getUserAllOrganizations = () => {
  return new Promise((resolve, reject) => {
    api("GET", "user/organizations", "token", "", "")
      .then((response) => {
        console.log("jj", response.data);
        resolve(response.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
const getMyProfile = () => {
  return new Promise((resolve, reject) => {
    api("GET", "user/profile", "token", "", "")
      .then((response) => {
        resolve(response.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
const getAllUsers = (params) => {
  return new Promise((resolve, reject) => {
    api("GET", null, "", "", params)
      .then((response) => {
        console.log("sss", response.data);
        resolve(response.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

const getUserProfile = (params) => {
  return new Promise((resolve, reject) => {
    api("GET", "users", "token", "", `${params}`)
      .then((response) => {
        resolve(response.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
const updateBasicInfo = (reqBody) => {
  return new Promise((resolve, reject) => {
    api("PUT", `user/profile`, "", reqBody, "")
      .then((response) => {
        resolve(response.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

const getUserImages = (params) => {
  return new Promise((resolve, reject) => {
    api(
      "GET",
      "users",
      "token",
      "",
      `${params}/gallery-images?sort_by=random&limit=8`
    )
      .then((response) => {
        resolve(response.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

const fetchProfileData = () => {
  return new Promise((resolve, reject) => {
    api("GET", `profile/list`, "", "", "")
      .then((response) => {
        resolve(response.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
const createProfileData = (reqBody) => {
  return new Promise((resolve, reject) => {
    api("POST", `profile/store`, "", reqBody)
      .then((response) => {
        resolve(response.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
const updateProfileData = (reqBody, profileId) => {
  return new Promise((resolve, reject) => {
    api("PUT", `profile/update`, "", reqBody, profileId)
      .then((response) => {
        resolve(response.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
const deleteAccount = (password) => {
  return new Promise((resolve, reject) => {
    api("DELETE", `user/profile?password=${password}`, "", "", "")
      .then((response) => {
        resolve(response.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
const getUserPosts = (userName) => {
  return new Promise((resolve, reject) => {
    api("GET", `users`, "token", "", `${userName}/posts`)
      .then((response) => {
        resolve(response.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
export const profileService = {
  getUserAllOrganizations,
  getMyProfile,
  getAllUsers,
  getUserProfile,
  updateBasicInfo,
  getUserImages,
  fetchProfileData,
  createProfileData,
  updateProfileData,
  deleteAccount,
  getUserPosts,
};
