import React, { useEffect, useState } from "react";
import { Badge, Dropdown, OverlayTrigger, Tooltip } from "react-bootstrap";
import { Link } from "react-router-dom";
import ShareOffcanvas from "../../../../components/share-offcanvas";
import user2 from "../../../../assets/images/user/02.jpg";
import unlike from "../../../../assets/images/icon/unlike.png";
import icon1 from "../../../../assets/images/icon/01.png";
import icon2 from "../../../../assets/images/icon/02.png";
import icon3 from "../../../../assets/images/icon/03.png";
import icon4 from "../../../../assets/images/icon/04.png";
import icon5 from "../../../../assets/images/icon/05.png";
import icon6 from "../../../../assets/images/icon/06.png";
import icon7 from "../../../../assets/images/icon/07.png";
import donationbtn from "../../../../assets/images/donations/donate-button.gif";
import CustomToggle from "../../../../components/dropdowns";
import { timeDiffCalc } from "../../../../utilities/customFun";
import { useGlobal } from "../../../../hooks/useGlobal";
import ReactFsLightbox from "fslightbox-react";
import { EDIT } from "../../../../utilities/constants";
import Swal from "sweetalert2";
import { postService } from "../../../../services/post.service";
import SmartText from "../SmartText";
import { useAuth } from "../../../../hooks/useAuth";
const FsLightbox = ReactFsLightbox.default
  ? ReactFsLightbox.default
  : ReactFsLightbox;
export default function SinglePost({ postDetails, handlePostShow,getPosts }) {
  const [sources, setSources] = useState([]);
  const [videoSources, setVideoSources] = useState([]);
  const {user} = useAuth()
  useEffect(() => {
    let results = postDetails.photos.map((img) => img.img_url);
    setSources(results);
    let videos = postDetails.videos.map((v) => v.video_url);
    setVideoSources(videos);
  }, [postDetails.photos, postDetails.videos]);
  const { imageOnSlide, viewImageOnSlide, viewVideoOnSlide } = useGlobal();
  const [comment,setComment] =useState('')
  const [isClickMoreComments,setClickMoreComments]=useState(false)
  const [comments,setComments] =useState([])
  const [post,setPost]=useState(postDetails)

  const getComments =(ulid)=>{
    postService.getPostComments(ulid).then(res=>{
      setComments(res.comments)
    }).catch(err=>console.log(err))
  }
  const onClickViewComments =(ulid)=>{
    setClickMoreComments(true)
    getComments(ulid)
  }
  const handleComment =()=>{
    let req = {
      content:comment,
      organization_id:postDetails.auther_type === 'organization' ? postDetails.author_id : null
    }
    postService.commentPost(postDetails.ulid,req)
    .then(res=>{
      setPost(res.post)
      setComment('')
    })
    .catch(err=>console.log(err))
  }
  const handleRemove = (swalWithBootstrapButtons,ulid)=>{
    postService.deletePost(ulid)
    .then(res=> {
      swalWithBootstrapButtons.fire({
        title: "Deleted!",
        text: "Your Request has been deleted.",
        icon: "success",
        showClass: {
          popup: "animate__animated animate__zoomIn",
        },
        hideClass: {
          popup: "animate__animated animate__zoomOut",
        },
      });
      getPosts()
    })
    .catch(err=>{
      swalWithBootstrapButtons.fire({
        title: "Error!",
        text: "You don't have permission to access the post",
        icon: "error",
        showClass: {
          popup: "animate__animated animate__zoomIn",
        },
        hideClass: {
          popup: "animate__animated animate__zoomOut",
        },
      });
    })
  }
  const getCurrentUserReationIcon = (type)=>{
    switch(type){
      case 'like' : return icon1;
      case 'love' : return icon2;
      case 'haha' : return icon4;
      case 'sad' : return icon6;
      default : break;
    }
  }

  const reactionPost = (reaction)=>{
    let searchParam =''
    // if(postDetails.author_type === 'organization'){
    //   searchParam = `?organization_id=${postDetails.author_id}`
    // }
    postService.reactPost(postDetails.ulid,reaction,searchParam).then(res=>{
     setPost(res.post)
    }).catch(err=>{
      console.log(err)
    })
  }
  
  
  const questionAlert = (reqId) => {
    // e.stopPropagation();
    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
        cancelButton: "btn btn-outline-primary btn-lg ms-2",
        confirmButton: "btn btn-primary btn-lg",
      },
      buttonsStyling: false,
    });

    swalWithBootstrapButtons
      .fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        cancelButtonText: "cancel",
        confirmButtonText: "Yes, delete it!",

        reverseButtons: false,
        showClass: {
          popup: "animate__animated animate__zoomIn",
        },
        hideClass: {
          popup: "animate__animated animate__zoomOut",
        },
      })
      .then((result) => {
        if (result.isConfirmed) {
          handleRemove(swalWithBootstrapButtons,reqId)
        } else if (
          /* Read more about handling dismissals below */
          result.dismiss === Swal.DismissReason.cancel
        ) {
          swalWithBootstrapButtons.fire({
            title: "Your Request is safe!",
            showClass: {
              popup: "animate__animated animate__zoomIn",
            },
            hideClass: {
              popup: "animate__animated animate__zoomOut",
            },
          });
        }
      });
  };

  const TemplatePostImg = ({ images }) => {
    return (
      <>
        <div className="row img-contain">
          <div
            className="pb-1 cursor-pointer "
            onClick={() => viewImageOnSlide(sources, 1)}
            key={`img-${images[0].image_id}`}
            style={{ padding: 1 }}
          >
            <img
              key={images[0].image_id}
              src={images[0].img_url}
              alt={`post-img-${images[0].image_id}`}
              className="img-fluid rounded w-100"
            />
          </div>
        </div>
        <div className="row img-contain">
          <div
            className="pb-1 cursor-pointer col-6 d-flex align-items-center justify-content-center"
            onClick={() => viewImageOnSlide(sources, 2)}
            key={`img-${images[1].image_id}`}
            style={{ padding: 1 }}
          >
            <img
              key={images[1].image_id}
              src={images[1].img_url}
              alt={`post-img-${images[1].image_id}`}
              className="img-fluid rounded w-100"
            />
          </div>
          <div
            className="pb-1 cursor-pointer col-6 d-flex align-items-center justify-content-center"
            onClick={() => viewImageOnSlide(sources, 3)}
            key={`img-${images[2].image_id}`}
            style={{ padding: 1 }}
          >
            <img
              key={images[2].image_id}
              src={images[2].img_url}
              alt={`post-img-${images[2].image_id}`}
              className="img-fluid rounded w-100"
            />
          </div>
        </div>
      </>
    );
  };
  return (
    <>
      <div
        className="card card-block card-stretch card-height"
        key={`post-${post.ulid}`}
      >
        <div className="card-body">
          <div className="user-post-data">
            <div className="d-flex justify-content-between">
              <div className="me-3">
                <Link to={post.author_type==='organization' ? `app/organizations/${post.author.slug}`:"#"}>
                <img
                  className="rounded-circle img-fluid avatar-50"
                  src={post.author.display_picture_media?.thumb_img_url}
                  alt=""
                />
                </Link>
              </div>
              <div className="w-100">
                <div className="d-flex  justify-content-between">
                  <div>
                    <h5 className="mb-0 d-inline-block pr-2">
                    <Link to={post.author_type==='organization' ? `app/organizations/${post.author.slug}`:"#"}>{post.author_type !== 'admin' ? post.author.name : 'Admin'}</Link>
                    </h5>
                    <p className="mb-0 text-primary">
                      {timeDiffCalc(new Date(post.publish_at))}{" "}
                      <span className="mb-0 d-inline-block">
                        {" "}
                        . {post.post_visibility}
                      </span>{" "}
                    </p>
                  <div className="pb-3">
                  <Badge pill bg="info" className="mt-2 text-capitalize" style={{lineHeight:0.75}}>{post.post_type}</Badge>
                  </div>
                  </div>
                  {post.author_id === user.id && <div className="card-post-toolbar">
                    <Dropdown>
                      <Dropdown.Toggle variant="bg-transparent">
                        <span className="material-symbols-outlined">
                          more_horiz
                        </span>
                      </Dropdown.Toggle>
                      <Dropdown.Menu className="dropdown-menu m-0 p-0">
                        {post.post_type !== 'campaign' && 
                        <Dropdown.Item
                          className=" p-3"
                          to="#"
                          onClick={() => handlePostShow(post, EDIT)}
                        >
                          <div className="d-flex align-items-top">
                            <div className="h4">
                              <i className="ri-save-line"></i>
                            </div>
                            <div className="data ms-2">
                              <h6>Edit Post</h6>
                              <p className="mb-0">You can update this post</p>
                            </div>
                          </div>
                        </Dropdown.Item>}
                        <Dropdown.Item className="p-3" to="#" onClick={()=>questionAlert(post.ulid)}>
                          <div className="d-flex align-items-top">
                            <i className="ri-close-circle-line h4"></i>
                            <div className="data ms-2">
                              <h6>Delete Post</h6>
                              <p className="mb-0">Delete your own post.</p>
                            </div>
                          </div>
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  </div>}
                </div>
              </div>
            </div>
          </div>
          <strong className="mb-0 d-inline-block ">{post.title}</strong>
          <div className="mt-3">
            {/* <div
              dangerouslySetInnerHTML={{ __html: post.content }}
            >
              
            </div> */}
            <SmartText text={post.content}/>
          </div>
          {post?.photos?.length === 1 && (
            <div>
              {post?.photos.map((photo, key) => (
                <div
                  className="pb-1 cursor-pointer"
                  onClick={() => viewImageOnSlide(sources, key + 1)}
                  key={`img-${photo.image_id}`}
                >
                  <img
                    key={photo.image_id}
                    src={photo.img_url}
                    alt={`post-img-${photo.image_id}`}
                    className="img-fluid rounded w-100"
                  />
                </div>
              ))}
            </div>
          )}
          {post?.photos?.length === 3 && (
            <TemplatePostImg images={post?.photos} />
          )}
          {post?.photos?.length === 2 && (
            <div className="row img-contain">
              <div
                className="pb-1 col-6 cursor-pointer d-flex align-items-center justify-content-center"
                onClick={() => viewImageOnSlide(sources, 1)}
                key={`img-${post?.photos[0].image_id}`}
                style={{ padding: 1 }}
              >
                <img
                  key={post?.photos[0].image_id}
                  src={post?.photos[0].img_url}
                  alt={`post-img-${post?.photos[0].image_id}`}
                  className="img-fluid rounded w-100"
                />
              </div>
              <div
                className="pb-1 col-6 cursor-pointer d-flex align-items-center justify-content-center"
                onClick={() => viewImageOnSlide(sources, 2)}
                key={`img-${post?.photos[1].image_id}`}
                style={{ padding: 1 }}
              >
                <img
                  key={post?.photos[1].image_id}
                  src={post?.photos[1].img_url}
                  alt={`post-img-${post?.photos[1].image_id}`}
                  className="img-fluid rounded w-100"
                />
              </div>
            </div>
          )}
          {post?.photos?.length > 3 && (
            <div className="row d-flex img-contain">
              {post?.photos.map((photo, key) => (
                <>
                  {key < 3 && (
                    <div
                      className="pb-1 col-6 cursor-pointer d-flex align-items-center justify-content-center"
                      onClick={() => viewImageOnSlide(sources, key + 1)}
                      key={`img-${photo.image_id}`}
                      style={{ padding: 1 }}
                    >
                      <img
                        key={photo.image_id}
                        src={photo.img_url}
                        alt={`post-img-${photo.image_id}`}
                        className="img-fluid rounded w-100"
                        style={{ objectFit: "cover", height: "100%" }}
                      />
                    </div>
                  )}
                  {key === 3 && (
                    <div
                      className="pb-1 col-6 cursor-pointer see-more "
                      onClick={() => viewImageOnSlide(sources, 4)}
                      key={`img-${photo.image_id}`}
                      style={{ padding: 1, zIndex: 0 }}
                    >
                      <img
                        key={photo.image_id}
                        src={photo.img_url}
                        alt={`post-img-${photo.image_id}`}
                        className="img-fluid rounded w-100"
                        style={{
                          objectFit: "cover",
                          height: "100%",
                          top: "-45px",
                          position: "relative",
                        }}
                      />
                    </div>
                  )}
                </>
              ))}
            </div>
          )}

          {/* videos in posts */}
          {post?.videos?.length === 1 && (
            <div className="img-contain">
              {post?.videos.map((video, key) => (
                <div
                  className="pb-1 col-6 cursor-pointer ratio ratio-16x9 video-img cursor-pointer "
                  onClick={() => viewVideoOnSlide(videoSources, key + 1)}
                  //   key={`v-${video.preview_picture[0]?.image_id}`}
                  key={key}
                  style={{ padding: 1 }}
                >
                  <img
                    src={video.preview_picture[0]?.thumb_img_url}
                    className="img-fluid rounded w-100 "
                    alt="video0img"
                  />
                </div>
              ))}
            </div>
          )}
          {post?.videos?.length === 2 && (
            <div className="row d-flex img-contain">
              {post?.videos.map((video, key) => (
                <div
                  className="col-6 ratio ratio-16x9 video-img cursor-pointer"
                  onClick={() => viewVideoOnSlide(videoSources, key + 1)}
                  //   key={`v-${video.preview_picture[0].image_id}`}
                  key={key}
                  style={{ padding: 1 }}
                >
                  <img
                    src={video.preview_picture[0]?.thumb_img_url}
                    className="img-fluid rounded w-100"
                    alt="video-img"
                  />
                </div>
              ))}
            </div>
          )}
          {post?.videos?.length === 3 && (
            <>
              <div className="row img-contain">
                <div
                  className="ratio ratio-16x9 video-img cursor-pointer"
                  onClick={() => viewVideoOnSlide(videoSources, 1)}
                  key={`img-${post?.videos[0].preview_picture[0].image_id}`}
                  style={{ padding: 1 }}
                >
                  <img
                    key={post?.videos[0].preview_picture[0].image_id}
                    src={post?.videos[0].preview_picture[0].img_url}
                    alt={`post-img-${post?.videos[0].preview_picture[0].image_id}`}
                    className="img-fluid rounded w-100"
                  />
                </div>
              </div>
              <div className="row img-contain">
                <div
                  className="pb-1 col-6 cursor-pointer ratio ratio-16x9 video-img"
                  onClick={() => viewVideoOnSlide(videoSources, 2)}
                  key={`img-${post?.videos[1].preview_picture[0].image_id}`}
                  style={{ padding: 1 }}
                >
                  <img
                    key={post?.videos[1].preview_picture[0].image_id}
                    src={post?.videos[1].preview_picture[0].img_url}
                    alt={`post-img-${post?.videos[0].preview_picture[0].image_id}`}
                    className="img-fluid rounded w-100"
                  />
                </div>
                <div
                  className="pb-1 col-6 cursor-pointer ratio ratio-16x9 video-img"
                  onClick={() => viewVideoOnSlide(videoSources, 3)}
                  key={`img-${post?.videos[2].preview_picture[0].image_id}`}
                  style={{ padding: 1 }}
                >
                  <img
                    key={post?.videos[2].preview_picture[0].image_id}
                    src={post?.videos[2].preview_picture[0].img_url}
                    alt={`post-img-${post?.videos[0].preview_picture[0].image_id}`}
                    className="img-fluid rounded w-100"
                  />
                </div>
              </div>
            </>
          )}
      {
      post.post_type==='campaign'&&
      <>
        {post.campaign.deadline &&
        <div className="d-flex d-flex justify-content-between">
                  Deadline:
                  <small>
                     { new Date(post.campaign.deadline).toDateString()}
                  </small>
                </div>
              }
                <div className="d-flex mt-2">
                  <div className="w-100">
                    <div className="progress" style={{ height: "15px" }}>
                      <div
                        className="progress-bar bg-success"
                        role="progressbar"
                        style={{
                          width: `${post.campaign.raised_percentage}%`,
                        }}
                        aria-valuenow="75"
                        aria-valuemin="0"
                        aria-valuemax="100"
                      >
                        {post.campaign.raised_percentage}%
                      </div>
                    </div>
                  </div>
                </div>
                <p>
                  $
                  {post.campaign.raised_amount
                    ? post.campaign.raised_amount
                    : 0}{" "}
                  Raised of ${post.campaign.required_amount} Goal
                </p>
            <div className="d-flex justify-content-between p-2">
                 <Link to={`/donation-campaign/${post.campaign.id}`} className="btn btn-success w-50 m-1">View</Link>
                 <a href={post.campaign.donate_url} target="_blank" className="btn btn-primary w-50 m-1">Donate Now</a>
                </div>
                </>
                
           }
          {
            post.comment_status === 'open' &&<div className="comment-area mt-3">
            <div className="d-flex justify-content-between align-items-center flex-wrap">
              <div className="like-block position-relative d-flex align-items-center">
                <div className="d-flex align-items-center">
                  <div className="like-data">
                    <Dropdown>
                      <Dropdown.Toggle as={CustomToggle}>
                        {
                        post?.current_user_interactions?.reaction !=null ? 
                        <img src={`${getCurrentUserReationIcon(post.current_user_interactions?.reaction)}`} className="img-fluid me-2" alt="" />:
                        <img src={unlike} className="img-fluid me-2" alt="" style={{width:20}}/>
                      }
                      </Dropdown.Toggle>
                      <Dropdown.Menu className=" py-2" >
                        <OverlayTrigger
                          placement="top"
                          overlay={<Tooltip>Like</Tooltip>}
                          className="ms-2 me-2"
                        >
                          <img src={icon1} className="img-fluid me-2" alt="" onClick={()=>reactionPost('like')}/>
                        </OverlayTrigger>
                        <OverlayTrigger
                          placement="top"
                          overlay={<Tooltip>Love</Tooltip>}
                          className="me-2"
                        >
                          <img src={icon2} className="img-fluid me-2" alt=""  onClick={()=>reactionPost('love')}/>
                        </OverlayTrigger>
                        {/* <OverlayTrigger
                          placement="top"
                          overlay={<Tooltip>Happy</Tooltip>}
                          className="me-2"
                        >
                          <img src={icon3} className="img-fluid me-2" alt="" />
                        </OverlayTrigger> */}
                        <OverlayTrigger
                          placement="top"
                          overlay={<Tooltip>HaHa</Tooltip>}
                          className="me-2"
                        >
                          <img src={icon4} className="img-fluid me-2" alt=""  onClick={()=>reactionPost('haha')}/>
                        </OverlayTrigger>
                        {/* <OverlayTrigger
                          placement="top"
                          overlay={<Tooltip>Think</Tooltip>}
                          className="me-2"
                        >
                          <img src={icon5} className="img-fluid me-2" alt="" />
                        </OverlayTrigger> */}
                        <OverlayTrigger
                          placement="top"
                          overlay={<Tooltip>Sad</Tooltip>}
                          className="me-2"
                        >
                          <img src={icon6} className="img-fluid me-2" alt=""  onClick={()=>reactionPost('sad')}/>
                        </OverlayTrigger>
                        {/* <OverlayTrigger
                          placement="top"
                          overlay={<Tooltip>Lovely</Tooltip>}
                          className="me-2"
                        >
                          <img src={icon7} className="img-fluid me-2" alt="" />
                        </OverlayTrigger> */}
                      </Dropdown.Menu>
                    </Dropdown>
                  </div>
                  <div className="total-like-block ms-2 me-3">
                    <Dropdown>
                      <Dropdown.Toggle as={CustomToggle} id="post-option">
                        {post.reactions_count_total} Likes
                      </Dropdown.Toggle>
                      {/* <Dropdown.Menu>
                        <Dropdown.Item href="#">Max Emum</Dropdown.Item>
                        <Dropdown.Item href="#">Bill Yerds</Dropdown.Item>
                        <Dropdown.Item href="#">Hap E. Birthday</Dropdown.Item>
                        <Dropdown.Item href="#">Tara Misu</Dropdown.Item>
                        <Dropdown.Item href="#">Midge Itz</Dropdown.Item>
                        <Dropdown.Item href="#">Sal Vidge</Dropdown.Item>
                        <Dropdown.Item href="#">Other</Dropdown.Item>
                      </Dropdown.Menu> */}
                    </Dropdown>
                  </div>
                </div>
                <div className="total-comment-block">
                  <Dropdown>
                    <Dropdown.Toggle as={CustomToggle} id="post-option" onClick={()=> post.comments_count_total != 0 && !isClickMoreComments &&onClickViewComments(post.ulid)}>
                    {post.comments_count_total} Comments
                    </Dropdown.Toggle>
                    {/* <Dropdown.Menu>
                      <Dropdown.Item href="#">Max Emum</Dropdown.Item>
                      <Dropdown.Item href="#">Bill Yerds</Dropdown.Item>
                      <Dropdown.Item href="#">Hap E. Birthday</Dropdown.Item>
                      <Dropdown.Item href="#">Tara Misu</Dropdown.Item>
                      <Dropdown.Item href="#">Midge Itz</Dropdown.Item>
                      <Dropdown.Item href="#">Sal Vidge</Dropdown.Item>
                      <Dropdown.Item href="#">Other</Dropdown.Item>
                    </Dropdown.Menu> */}
                  </Dropdown>
                </div>
              </div>
              {/* <ShareOffcanvas /> */}
            </div>
            <hr />
            {
              <ul className="post-comments list-inline p-0 m-0">
              {
                post.current_user_interactions.latest_comment != null  && !isClickMoreComments &&
                <li className="mb-2">
                  <div className="d-flex ">
                    <div className="user-img">
                      <img
                        src={post.current_user_interactions.latest_comment.commenter.display_picture_media.thumb_img_url}
                        alt="user1"
                        className="avatar-35 rounded-circle img-fluid"
                      />
                    </div>
                    <div className="comment-data-block ms-3">
                      <h6>{post.current_user_interactions.latest_comment.commenter.name}</h6>
                      <p className="mb-0">{post.current_user_interactions.latest_comment.content}</p>
                      <div className="d-flex flex-wrap align-items-center comment-activity">
                        {/* <Link to="#">like</Link>
                        <Link to="#">reply</Link>
                        <Link to="#">translate</Link> */}
                        <span> {timeDiffCalc(new Date(post.current_user_interactions.latest_comment.created_at))}</span>
                      </div>
                    </div>
                  </div>
                </li>
              }
              {
                isClickMoreComments && comments.map(comment=>(
                  <li className="mb-2">
                  <div className="d-flex ">
                    <div className="user-img">
                      <img
                        src={comment.commenter.display_picture_media.thumb_img_url}
                        alt="user1"
                        className="avatar-35 rounded-circle img-fluid"
                      />
                    </div>
                    <div className="comment-data-block ms-3">
                      <h6>{comment.commenter.name}</h6>
                      <p className="mb-0">{comment.content}</p>
                      <div className="d-flex flex-wrap align-items-center comment-activity">
                        {/* <Link to="#">like</Link>
                        <Link to="#">reply</Link>
                        <Link to="#">translate</Link> */}
                        <span> {timeDiffCalc(new Date(comment.created_at))}</span>
                      </div>
                    </div>
                  </div>
                </li>
                ))
              }
              </ul> }
            <form className="comment-text d-flex align-items-center mt-3">
              <input
                type="text"
                className="form-control rounded"
                placeholder="Enter Your Comment"
                value={comment}
                onChange={(e)=>setComment(e.target.value)}
              />
              <div className="comment-attagement d-flex">
              <Link to={"#"} className="btn-success btn" onClick={handleComment}>comment</Link>
              </div>
            </form>
          </div>
          }
        </div>
      </div>
    </>
  );
}
