import { api } from "./axios.service";

const getOrganizationPosts = (authorId)=>{
    return new Promise((resolve, reject) => {
        api('GET',`posts?author_type=organization&author_id=${authorId}`,'token','', '')
          .then((response) => {
            resolve(response.data);
          })
        .catch((err) => {
            reject(err)
        });
    });
}
const getUserPosts = (authorId)=>{
  return new Promise((resolve, reject) => {
      api('GET',`posts?author_type=user&author_id=${authorId}`,'token','', '')
        .then((response) => {
          resolve(response.data);
        })
      .catch((err) => {
          reject(err)
      });
  });
}
const getAllPosts = (searchParam='')=>{
  return new Promise((resolve, reject) => {
      api('GET',`posts${searchParam}`,'token','', '')
        .then((response) => {
          resolve(response.data);
        })
      .catch((err) => {
          reject(err)
      });
  });
}
const createOrganizationPost = (reqBody)=>{
  return new Promise((resolve, reject) => {
      api('POST',`user/posts`,'token',reqBody, '')
        .then((response) => {
          resolve(response.data);
        })
      .catch((err) => {
          reject(err)
      });
  });
}
const updateOrganizationPost = (reqBody,postUlid)=>{
  return new Promise((resolve, reject) => {
      api('PUT',`user/posts`,'token',reqBody, postUlid)
        .then((response) => {
          resolve(response.data);
        })
      .catch((err) => {
          reject(err)
      });
  });
}

const deletePost = (postUlid)=>{
  return new Promise((resolve, reject) => {
      api('DELETE',`user/posts`,'token',{skip_trash:false}, postUlid)
        .then((response) => {
          resolve(response.data);
        })
      .catch((err) => {
          reject(err)
      });
  });
}
const reactPost = (postUlid,reaction='like',searchParam='')=>{
  return new Promise((resolve, reject) => {
      api('PATCH',`posts`,'token','', `${postUlid}/react/${reaction}${searchParam}`)
        .then((response) => {
          resolve(response.data);
        })
      .catch((err) => {
          reject(err)
      });
  });
}
const commentPost = (postUlid,reqBody)=>{
  return new Promise((resolve, reject) => {
      api('POST',`posts`,'token',reqBody, `${postUlid}/comment`)
        .then((response) => {
          resolve(response.data);
        })
      .catch((err) => {
          reject(err)
      });
  });
}
const getPostComments = (postUlid)=>{
  return new Promise((resolve, reject) => {
      api('GET',`posts`,'token','', `${postUlid}/comments`)
        .then((response) => {
          resolve(response.data);
        })
      .catch((err) => {
          reject(err)
      });
  });
}
export const postService = {
    getOrganizationPosts,
    createOrganizationPost,
    getUserPosts,
    getAllPosts,
    updateOrganizationPost,
    deletePost,
    reactPost,
    commentPost,
    getPostComments
}