import React, { Fragment, useEffect, useState } from "react";

export default function DynamicOtherDetail({
  otherDetails,
  handleOtherDetails,
}) {
  const [inputFields, setInputFields] = useState([{ label: "", value: "" }]);

  useEffect(() => {
    otherDetails?.length > 0 && setInputFields(otherDetails);
  }, [otherDetails]);

  const handleAddFields = () => {
    const values = [...inputFields];
    values.push({ label: "", value: "" });
    handleOtherDetails("other_details", values);
  };

  const handleRemoveFields = (index) => {
    const values = [...inputFields];
    values.splice(index, 1);
    handleOtherDetails("other_details", values);
  };

  const handleInputChange = (index, event) => {
    const values = [...inputFields];
    if (event.target.name === "label") {
      values[index].label = event.target.value;
    } else {
      values[index].value = event.target.value;
    }

    handleOtherDetails("other_details", values);
  };

  return (
    <>
      {/* <div className="form-row"> */}
      {inputFields?.map((inputField, index) => (
        <Fragment key={`${inputField}~${index}`}>
          <div
            className="row p-2 mb-3 d-flex align-items-center"
            style={{ backgroundColor: "#f5f2efc4" }}
          >
            <div className="form-group col-md-6 col-xs-12">
              <label htmlFor="other_detail_label">Label</label>
              <input
                type="text"
                name="label"
                className="form-control"
                id="other_detail_label"
                value={inputField.label}
                onChange={(event) => handleInputChange(index, event)}
                placeholder="Label"
              />
            </div>
            <div className="form-group col-md-6 col-xs-12">
              <label htmlFor="value">Value</label>
              <textarea
                name="value"
                className="form-control"
                id="other_detail_value"
                placeholder="Value"
                value={inputField.value}
                onChange={(event) => handleInputChange(index, event)}
              />
            </div>

            {index > 0 ? (
              <div className="col-2 col-xs-4">
                <button
                  className="btn btn-danger"
                  type="button"
                  disabled={index === 0}
                  onClick={() => handleRemoveFields(index)}
                >
                  Remove
                </button>
              </div>
            ) : null}
          </div>
        </Fragment>
      ))}
      <div className="row d-flex  justify-content-left ">
        <div
          className="col-md-2 col-xs-12 btn btn-success"
          onClick={handleAddFields}
        >
          Add More
        </div>
      </div>

      {/* <br/>
          <pre>
            {JSON.stringify(inputFields, null, 2)}
          </pre> */}
    </>
  );
}
