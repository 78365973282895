import React, { useEffect } from "react";
import { Card, Col, Container, Row } from "react-bootstrap";
import { useOrganization } from "../../hooks/useOrganization";
import { ADD } from "../../utilities/constants";
import OrganizationMultiForm from "../dashboard/components/organization/OrganizationMultiForm";

export default function CreateOrganizationPage() {
  const { setOrganization, setAction, initialOrganization } = useOrganization();
  useEffect(() => {
    setOrganization(initialOrganization);
    setAction(ADD);
  }, []);
  return (
    <Container>
      <Row>
        <Col sm={12}>
          <Card>
            <Card.Header className="d-flex justify-content-between">
              <div className="header-title">
                <h4 className="card-title">Create Organizations</h4>
              </div>
            </Card.Header>
            <Card.Body>
              <div className="form1 form2">
                <OrganizationMultiForm />
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
}
