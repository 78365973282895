import React, { useEffect, useState } from "react";
import { Button, Col, Container, Row } from "react-bootstrap";
import Card from "../../../components/Card";
import ProfileHeader from "../../../components/profile-header";
import donate from "../../../assets/images/donations/donationcover.png";
import { Link, useNavigate } from "react-router-dom";
import { donationCompaignService } from "../../../services/donationCompaign.service";
import "../../../assets/css/style.css";
import SearchBar from "../../../components/search/SearchBar";
import AsyncSelect from "../../../components/search/AutoSuggestSearchBar";
import { useSearch } from "../../../hooks/useSearch";
import SkeletonCard from "../../../components/skeleton/SkeletonCard";
import { useAuth } from "../../../hooks/useAuth";
import Friendscard from "../../../components/Friendscard";

const DonationListsInfo = () => {
  const {user} = useAuth()
  const [donationLists, setDonationLists] = useState([]);
  const [showSkeleton, setShowSkeleton] = useState(true);
  const { searchQuery, onChange } = useSearch();
  const navigate = useNavigate();
  const handleNavigate = (campId) => {
    navigate(`/donation-campaign/${campId}`);
  };
  const fetchAllCompaigns = async () => {
    setShowSkeleton(true);
    try {
      const result = await donationCompaignService.fetchAllCompaigns(
        `?search=${searchQuery}`
      );
      setTimeout(() => {
        setDonationLists(result);
        setShowSkeleton(false);
      }, 600);
    } catch (err) {
      setShowSkeleton(false);
      return err;
    }
  };
  const handleDonate = (e) => {
    e.stopPropagation();
  };
  
  useEffect(() => {
    if (searchQuery === "") {
      fetchAllCompaigns();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchQuery]);
  return (
    <>
      <div id="content-page" className="content-page">
        <Container>
          <ProfileHeader img={donate} />
          <SearchBar
            placeHolder="Search by campaign title ..."
            searchQuery={searchQuery}
            onChange={onChange}
            onSubmit={fetchAllCompaigns}
          />
           {/* <AsyncSelect
           /> */}
          <Row className="mt-3">
            {showSkeleton ? (
              <>
                {[1, 2, 3, 4, 5, 6, 7, 8].map((n) => (
                  <Col sm={3} key={n}>
                    <SkeletonCard theme="light" />
                  </Col>
                ))}
              </>
            ) : (
              <>
                {donationLists?.campaigns?.length > 0 ? (
                  donationLists?.campaigns?.map((donation, index) => (
                    <Col sm="6" md={3} key={index}>
                      <Friendscard className="ln-donatecard ">
                        <Card.Body>
                          <div className="event-images position-relative">
                            <img
                              loading="lazy"
                              src={donation.image.img_url}
                              style={{
                                height: "100px",
                                width: "100%",
                                objectFit: "cover",
                              }}
                              className="donate img-fluid donate-img"
                              alt="Responsive"
                            />
                          </div>
                          <div className="mt-1">
                            <h4>{donation.title}</h4>
                          </div>
                          <div>
                            <small
                              className="text-primary campaignDesc"
                              onClick={() => handleNavigate(donation.id)}
                            >
                              {donation.excerpt}
                            </small>
                          </div>
                          <div className="d-flex mt-2">
                            <div className="w-100">
                              <div
                                className="progress"
                                style={{ height: "15px" }}
                              >
                                <div
                                  className="progress-bar bg-success"
                                  role="progressbar"
                                  style={{
                                    width: `${donation.raised_percentage}%`,
                                  }}
                                  aria-valuenow="75"
                                  aria-valuemin="0"
                                  aria-valuemax="100"
                                >
                                  {donation.raised_percentage}%
                                </div>
                              </div>
                            </div>
                          </div>
                          <p className="mt-2">
                            {donation.raised_percentage}% of goal reached
                          </p>
                          <div className="d-flex justify-content-between">
                            <p>
                              Goal:
                              {donation.required_amount ? (
                                <>${donation.required_amount}</>
                              ) : (
                                "Not Specified"
                              )}
                            </p>
                            <p>
                              Raised: $
                              <>
                                {donation.raised_amount
                                  ? donation.raised_amount
                                  : 0}
                              </>
                            </p>
                          </div>

                          {/* <div className="d-flex justify-content-center">
                            <Button onClick={handleDonate} className="w-100">
                              Donate Now
                            </Button>
                          </div> */}
                          { donation.user?.id === user?.id ? 
                            (<div className="d-flex justify-content-center">
                              <Link to={`/campaigns/${donation.slug}/edit`} className="btn btn-success w-100">Edit</Link>
                              </div>):(<div className="d-flex justify-content-center">
                              <Link className="btn btn-primary w-100">Donate Now</Link>
                            </div>)}
                        </Card.Body>
                      </Friendscard>
                    </Col>
                  ))
                ) : (
                  <Col sm={12}>
                    <p>No donation campaigns found.</p>
                  </Col>
                )}
              </>
            )}
          </Row>
        </Container>
      </div>
    </>
  );
};

export default DonationListsInfo;
