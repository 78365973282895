import React from "react";
import { Button, Card, Col, Dropdown, Modal, Row } from "react-bootstrap";
import img1 from "../../assets/images/page-img/coverimage.png";
import { Link, useParams } from "react-router-dom";
import blankImg from "../../assets/images/user/blank-image.webp";
import img3 from "../../assets/images/icon/08.png";
import img4 from "../../assets/images/icon/09.png";
import img5 from "../../assets/images/icon/10.png";
import img6 from "../../assets/images/icon/11.png";
import img7 from "../../assets/images/icon/12.png";
import img8 from "../../assets/images/icon/13.png";
import "./profileHeader.css";
import { useMyProfileMedia } from "../../hooks/useMyProfileMedia";
import { useGlobal } from "../../hooks/useGlobal";
import { useState } from "react";
import { useEffect } from "react";
import { userProfileService } from "../../services/userProfile.service";
import { notifyFailed, notifySuccess } from "../toast/Toast";
import Swal from "sweetalert2";
import { profileService } from "../../services/user.service";
import { authService } from "../../services/auth.service";
const ProfileHeader = ({ visibility }) => {
  const { profileData, setProfileData } = useMyProfileMedia();
  const [uploading, setUploading] = useState(false);
  const [userProfile, setUserProfile] = useState({});
  const [show, setShow] = useState(false);
  const params = useParams();
  const getUserProfile = async () => {
    try {
      const res = await profileService.getUserProfile(params.userName);
      setUserProfile(res);
      console.log(res);
    } catch (err) {
      console.log(err);
    }
  };
  const [accPassword, setAccPassword] = useState("");
  const [showPasswordModal, setShowPasswordModal] = useState(false);
  const { toggleMedia, mediaState, setMediaState, setSelectSingleImage } =
    useGlobal();
  const handleClose = () => {
    setShow(false);
    setMediaState({ ...mediaState, field: "", values: [] });
  };

  const handleClosePasswordModal = () => {
    setAccPassword("");
    setShowPasswordModal(false);
  };

  const swalWithBootstrapButtons = Swal.mixin({
    customClass: {
      cancelButton: "btn btn-outline-primary btn-lg ms-2",
      confirmButton: "btn btn-primary btn-lg",
    },
    buttonsStyling: false,
  });
  const questionAlert = () => {
    // e.stopPropagation();

    swalWithBootstrapButtons
      .fire({
        title: "Are you sure?",
        // text: "You won't be able to revert this!",
        text: "Write something interesting:",
        type: "input",
        icon: "warning",
        showCancelButton: true,
        cancelButtonText: "cancel",
        confirmButtonText: "Yes, delete it!",

        reverseButtons: false,
        showClass: {
          popup: "animate__animated animate__zoomIn",
        },
        hideClass: {
          popup: "animate__animated animate__zoomOut",
        },
      })
      .then((result) => {
        if (result.isConfirmed) {
          setShowPasswordModal(true);
        } else if (
          /* Read more about handling dismissals below */
          result.dismiss === Swal.DismissReason.cancel
        ) {
          swalWithBootstrapButtons.fire({
            title: "Your Request is safe!",
            showClass: {
              popup: "animate__animated animate__zoomIn",
            },
            hideClass: {
              popup: "animate__animated animate__zoomOut",
            },
          });
        }
      });
  };
  const handleRemoveAcc = () => {
    profileService
      .deleteAccount(accPassword)
      .then((res) => {
        swalWithBootstrapButtons.fire({
          title: "Deleted!",
          text: "Your Request has been deleted.",
          icon: "success",
          showClass: {
            popup: "animate__animated animate__zoomIn",
          },
          hideClass: {
            popup: "animate__animated animate__zoomOut",
          },
        });
        setShowPasswordModal(false);
        authService.logout();
      })
      .catch((err) => {
        swalWithBootstrapButtons.fire({
          title: "Error!",
          text: "You don't have permission invlid password",
          icon: "error",
          showClass: {
            popup: "animate__animated animate__zoomIn",
          },
          hideClass: {
            popup: "animate__animated animate__zoomOut",
          },
        });
      });
  };

  const handleOnClick = (statement) => {
    setSelectSingleImage(true);
    toggleMedia(statement, null, "photoGallery");
  };
  const handleSave = async () => {
    if (mediaState.field === "Cover Picture") {
      setUploading(true);
      try {
        const res = await userProfileService.updateCoverPicture({
          cover_picture: mediaState?.values[0]?.image_id.toString(),
        });
        setUploading(false);
        if (res.success) {
          handleClose();
          setProfileData({
            ...profileData,
            coverImg: {
              id: res.cover_picture_media.image_id,
              large_img_url: res.cover_picture_media.large_img_url,
            },
          });
          notifySuccess(res.message);
        }
      } catch (err) {
        setUploading(false);
        notifyFailed("Error Occured");
      }
    } else {
      setUploading(true);
      try {
        const res = await userProfileService.updateProfilePicture({
          display_picture: mediaState?.values[0]?.image_id.toString(),
        });
        setUploading(false);
        if (res.success) {
          handleClose();
          setProfileData({
            ...profileData,
            displayImg: {
              id: res.display_picture_media.image_id,
              img_url: res.display_picture_media.img_url,
            },
          });
          notifySuccess(res.message);
        }
      } catch (err) {
        setUploading(false);
        notifyFailed("Error Occured");
      }
    }
  };
  useEffect(() => {
    if (
      (mediaState.field === "Cover Picture" ||
        mediaState.field === "Profile Picture") &&
      mediaState.values?.length >= 0
    ) {
      setShow(!show);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mediaState.values]);
  useEffect(() => {
    getUserProfile();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params.userName]);
  return (
    <>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>{mediaState.field}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col xs={12}>
              <div className="text-center">
                {mediaState?.values ? (
                  <img
                    src={mediaState?.values[0]?.src}
                    // src={mediaState?.values[0]?.src}
                    className="rounded"
                    alt="SelectedImage"
                  />
                ) : null}
              </div>
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={handleSave}>
            Save Changes
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal show={showPasswordModal} onHide={handleClosePasswordModal}>
        <Modal.Header closeButton>
          <Modal.Title>Delete Account</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col xs={12}>
              <label for="">Password</label>
              <input
                type="password"
                placeholder="Enter password"
                name="password"
                className="form-control"
                value={accPassword}
                onChange={(e) => setAccPassword(e.target.value)}
              />
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={handleRemoveAcc}>
            Delete
          </Button>
        </Modal.Footer>
      </Modal>

      <Card>
        <Card.Body className=" profile-page p-0">
          <div className="profile-header">
            <div className="cover-image-container">
              <img
                loading="lazy"
                // src={
                //   profileData.coverImg?.large_img_url
                //     ? profileData.coverImg?.large_img_url
                //     : img1
                // }
                src={
                  userProfile.cover_picture?.large_img_url
                    ? userProfile.cover_picture?.large_img_url
                    : img1
                }
                alt="profile-bg"
                className="rounded img-fluid"
              />
            </div>
            <div className="user-detail text-center mb-3">
              <div className="profile-img position-relative">
                <img
                  loading="lazy"
                  src={
                    userProfile.display_picture?.img_url
                      ? userProfile.display_picture?.img_url
                      : blankImg
                  }
                  alt="profile-img1"
                  className="avatar-130 img-fluid"
                />
              </div>

              <div className="profile-detail">
                <h3>{visibility ? profileData?.userName : userProfile.name}</h3>
              </div>
            </div>
            <div className="profile-info p-3 d-flex align-items-center justify-content-between position-relative">
              <div className="social-links">
                <ul className="social-data-block d-flex align-items-center justify-content-between list-inline p-0 m-0">
                  <li className="text-center pe-3">
                    <Link to="#">
                      <img
                        loading="lazy"
                        src={img3}
                        className="img-fluid rounded"
                        alt="facebook"
                      />
                    </Link>
                  </li>
                  <li className="text-center pe-3">
                    <Link to="#">
                      <img
                        loading="lazy"
                        src={img4}
                        className="img-fluid rounded"
                        alt="Twitter"
                      />
                    </Link>
                  </li>
                  <li className="text-center pe-3">
                    <Link to="#">
                      <img
                        loading="lazy"
                        src={img5}
                        className="img-fluid rounded"
                        alt="Instagram"
                      />
                    </Link>
                  </li>
                  <li className="text-center pe-3">
                    <Link to="#">
                      <img
                        loading="lazy"
                        src={img6}
                        className="img-fluid rounded"
                        alt="Google plus"
                      />
                    </Link>
                  </li>
                  <li className="text-center pe-3">
                    <Link to="#">
                      <img
                        loading="lazy"
                        src={img7}
                        className="img-fluid rounded"
                        alt="You tube"
                      />
                    </Link>
                  </li>
                  <li className="text-center md-pe-3 pe-0">
                    <Link to="#">
                      <img
                        loading="lazy"
                        src={img8}
                        className="img-fluid rounded"
                        alt="linkedin"
                      />
                    </Link>
                  </li>
                </ul>
              </div>

              {visibility ? (
                <div className="social-info">
                  <div className="d-flex align-items-center justify-content-end">
                    <button
                      type="button"
                      className="btn btn-info m-0 my-1"
                      onClick={questionAlert}
                    >
                      Delete Account
                    </button>
                  </div>
                  <ul className="social-data-block d-flex align-items-center justify-content-between list-inline p-0 m-0">
                    <li className="">
                      <div className="card-header-toolbar d-flex align-items-center">
                        <Dropdown>
                          <Dropdown.Toggle
                            as="span"
                            className="material-symbols-outlined dropdown-toggle-btn "
                          >
                            edit
                          </Dropdown.Toggle>
                          <Dropdown.Menu
                            className="dropdown-menu-right"
                            aria-labelledby="dropdownMenuButton5"
                          >
                            <Dropdown.Item
                              href="#"
                              onClick={() => handleOnClick("Cover Picture")}
                            >
                              <i className="ri-camera-fill me-2"></i>
                              Change Cover Picture
                            </Dropdown.Item>
                            <Dropdown.Item
                              href="#"
                              onClick={() => handleOnClick("Profile Picture")}
                            >
                              <i className="ri-camera-fill me-2"></i>
                              Change Profile Picture
                            </Dropdown.Item>
                          </Dropdown.Menu>
                        </Dropdown>
                      </div>
                    </li>
                    {/* <li className="text-center ps-3">
                    <h6>Posts</h6>
                    <p className="mb-0">690</p>
                  </li>
                  <li className="text-center ps-3">
                    <h6>Followers</h6>
                    <p className="mb-0">206</p>
                  </li>
                  <li className="text-center ps-3">
                    <h6>Following</h6>
                    <p className="mb-0">100</p>
                  </li> */}
                  </ul>
                </div>
              ) : null}
            </div>
          </div>
        </Card.Body>
      </Card>
      {uploading && (
        <div className="backdrop">
          <div className="spinner" />
        </div>
      )}
    </>
  );
};

export default ProfileHeader;
