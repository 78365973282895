import React from "react";
import DocumentListsTable from "./DocumentListsTable";
import { Col, Row } from "react-bootstrap";
import UploadDocumentWidget from "./UploadDocumentWidget";
import { useDocument } from "../../hooks/useDocument";

const Document = ({orgAuthorizedUserId}) => {
  const { showClose, loading,orgId } = useDocument();

  return (
    <div id="content-page" className="content-page">
      <Row className=" d-flex justify-content-center">
        <Col sm="12">
          {!showClose ? <DocumentListsTable orgAuthorizedUserId={orgAuthorizedUserId} orgId={orgId}/> : <UploadDocumentWidget />}
        </Col>
      </Row>
      {loading && (
        <div className="backdrop">
          <div className="spinner" />
        </div>
      )}
    </div>
  );
};

export default Document;
