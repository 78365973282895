import axios from "axios";
import { api } from "./axios.service";
import { BASE_URL } from "../utilities/constants";

const fileUpload = (file) => {
  return new Promise((resolve, reject) => {
    api("POST", "user/media-uploads", "token", file, "", "multipart/form-data")
      .then((response) => {
        resolve(response.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
const fetchImages = () => {
  return new Promise((resolve, reject) => {
    api("GET", "user/media-uploads", "token", "", "")
      .then((response) => {
        resolve(response.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
const deleteMedia = (mediaId) => {
  return new Promise((resolve, reject) => {
    api("DELETE", "user/media-uploads", "", "", `${mediaId}`)
      .then((response) => {
        resolve(response.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
const documentUpload = (file) => {
  return new Promise((resolve, reject) => {
    api(
      "POST",
      "user/document-uploads",
      "token",
      file,
      "",
      "multipart/form-data"
    )
      .then((response) => {
        resolve(response.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
/**
 *
 * @returns <Promise>
 */
const fetchAllDocuments = (params = "") => {
  return new Promise((resolve, reject) => {
    api("GET", "user", "", "", `document-uploads${params}`)
      .then((response) => {
        resolve(response.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
const fetchAllPublicDocuments = (params = "") => {
  return new Promise((resolve, reject) => {
    api("GET", `document-uploads${params}`, "", "", "")
      .then((response) => {
        resolve(response.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
const downloadDocument = (docId) => {
  return axios.get(`${BASE_URL}document-uploads/${docId}`);
};
const deleteDocument = (docId) => {
  return new Promise((resolve, reject) => {
    api("DELETE", "user/document-uploads", "", "", docId)
      .then((response) => {
        resolve(response.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
const updateAccessibility = (docId, accessibility) => {
  return new Promise((resolve, reject) => {
    api(
      "PATCH",
      "user/document-uploads",
      "",
      "",
      `${docId}/accessibility/${accessibility}`
    )
      .then((response) => {
        resolve(response.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
export const mediaService = {
  fileUpload,
  fetchImages,
  deleteMedia,
  documentUpload,
  fetchAllDocuments,
  deleteDocument,
  downloadDocument,
  updateAccessibility,
  fetchAllPublicDocuments
};
