import React, { useEffect, useState } from "react";
import {
  DATE_OPTIONS,
  INTERESTED_IN,
  LANGUAGES,
} from "../../../../utilities/constants";
import { profileService } from "../../../../services/user.service";
import { FiEdit } from "react-icons/fi";
import { Modal, Spinner } from "react-bootstrap";
import Select from "react-select";
import {
  notifyFailed,
  notifySuccess,
} from "../../../../components/toast/Toast";
import { IoMdAddCircleOutline } from "react-icons/io";
import { useAuth } from "../../../../hooks/useAuth";

const PersonalInfo = () => {
  const [personalInfo, setPersonalInfo] = useState({});
  const [formData, setFormData] = useState({
    aboutMe: "",
    status: "",
    dob: "",
    gender: "",
    interestedIn: [],
    languages: [],
  });
  const [status, setStatus] = useState(null);
  const { user } = useAuth();
  const [loading, setLoading] = useState(false);
  const [showCloseForm, setShowCloseForm] = useState(false);

  const selectedOptions = LANGUAGES.filter((option) =>
    formData.languages?.includes(option.value)
  );
  const selectedIneterestedIN = INTERESTED_IN.filter((option) =>
    formData?.interestedIn?.includes(option.value)
  );
  const fetchBasicInfo = async () => {
    try {
      const res = await profileService.fetchProfileData();
      if (Array.isArray(res.profile_data)) {
        setPersonalInfo({});
        setStatus("create");
      } else {
        setPersonalInfo(res.profile_data);
        // setWebSiteLists(res?.profile_data?.websites ?? [{ url: "" }]);
        // setSocialLinkLists(res?.profile_data?.socialLinks ?? []);
        setStatus("edit");
      }
    } catch (err) {
      return err;
    }
  };
  useEffect(() => {
    fetchBasicInfo();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((previousState) => ({ ...previousState, [name]: value }));
  };
  const handleSelectLanguages = (selectedOptions) => {
    const selectedValues = selectedOptions
      ? selectedOptions.map((option) => option.value)
      : [];
    setFormData({ ...formData, languages: selectedValues });
  };
  const handleSelectInterested = (selectedIneterestedIN) => {
    const selectedValues = selectedIneterestedIN
      ? selectedIneterestedIN.map((option) => option.value)
      : [];
    setFormData({ ...formData, interestedIn: selectedValues });
  };
  const openCloseForm = () => {
    // setStatus(type);
    setShowCloseForm(!showCloseForm);
    setFormData({
      aboutMe: personalInfo?.aboutMe,
      birthPlace: personalInfo?.birthPlace,
      dob: personalInfo?.dob,
      status: personalInfo?.status ?? "",
      gender: personalInfo?.gender ?? "",
      interestedIn: personalInfo?.interestedIn,
      languages: personalInfo?.languages,
    });
  };
  const handleSend = async (e) => {
    e.preventDefault();
    setLoading(true);
    if (!personalInfo?._id) {
      try {
        const res = await profileService.createProfileData(formData);
        setPersonalInfo(res.profile_data);
        setStatus("edit");
        setShowCloseForm(!showCloseForm);
        notifySuccess("Your information created successfully");
      } catch (err) {
        console.log(err);
        notifyFailed(err.message ?? "Failed Occur");
        return err;
      } finally {
        setLoading(false);
      }
    } else {
      try {
        const res = await profileService.updateProfileData(formData, user.id);
        setPersonalInfo(res.profile_data);
        notifySuccess("Your information updated successfully");
        setShowCloseForm(!showCloseForm);
      } catch (err) {
        notifyFailed(err.message ?? "Failed Occur");
        return err;
      } finally {
        setLoading(false);
      }
    }
  };
  return (
    <div className="m-2 p-2">
      <div className="row d-flex justify-content-left">
        <div className="d-flex justify-content-between align-items-center">
          <h4 className="text-dark">Personal Information</h4>
          {status === "edit" && (
            <div className="my-button" onClick={openCloseForm}>
              <FiEdit className="m-1" size={20} />
            </div>
          )}
        </div>
      </div>
      {status === "create" && !showCloseForm ? (
        <div className="d-flex  justify-content-left mb-3 mt-2">
          <div className="col-md-6 col-xs-12 my-button" onClick={openCloseForm}>
            <IoMdAddCircleOutline className="m-1" size={25} />
            <span>Add Personal Information</span>
          </div>
        </div>
      ) : (
        <></>
      )}

      {status === "edit" ? (
        <>
          <div className="row mt-2 p-1">
            <div className="col-3">
              <h6>Date of Birth :</h6>
            </div>
            <div className="col-9">
              <p className="mb-0">
                {new Date(personalInfo.dob).toLocaleDateString(
                  "en-US",
                  DATE_OPTIONS
                )}
              </p>
            </div>
          </div>
          <div className="row p-1">
            <div className="col-3">
              <h6>Gender :</h6>
            </div>
            <div className="col-9">
              <p className="mb-0">{personalInfo?.gender}</p>
            </div>
          </div>
          <div className="row p-1">
            <div className="col-3">
              <h6>Status:</h6>
            </div>
            <div className="col-9">
              <p className="mb-0">{personalInfo?.status}</p>
            </div>
          </div>
          <div className="row p-1">
            <div className="col-3">
              <h6>About me:</h6>
            </div>
            <div className="col-9">
              <p className="mb-0">{personalInfo?.aboutMe}</p>
            </div>
          </div>
          <div className="row p-1">
            <div className="col-3">
              <h6>Languages:</h6>
            </div>
            <div className="col-9">
              <p className="mb-0">{personalInfo?.languages?.toString()}</p>
            </div>
          </div>
          <div className="row p-1">
            <div className="col-3">
              <h6>Interested in:</h6>
            </div>
            <div className="col-9">
              <p className="mb-0">{personalInfo?.interestedIn?.toString()}</p>
            </div>
          </div>
          {/* <div className="row p-1">
            <div className="col-3">
              <h6>Websites:</h6>
            </div>
            <div className="col-9">
              <p className="mb-0">
                {personalInfo?.websites.length > 0 ? (
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href={personalInfo.websites[0].url}
                  >
                    {personalInfo.websites[0].url}
                  </a>
                ) : (
                  NOT_SPECIFIED
                )}
              </p>
            </div>
          </div> */}
        </>
      ) : null}
      {showCloseForm && (
        <Modal show={showCloseForm} onHide={openCloseForm} size="lg">
          <Modal.Header closeButton>
            <Modal.Title>Profile Information</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <form onSubmit={handleSend}>
              <div className="row p-2 mb-2 d-flex align-items-center">
                <div className="form-group col-md-6 col-xs-12">
                  <label htmlFor="dob">Date of Birth</label>
                  <input
                    required
                    className="form-control"
                    name="dob"
                    type="date"
                    value={formData.dob}
                    onChange={handleInputChange}
                  />
                </div>
                <div className="form-group col-md-6 col-xs-12">
                  <label htmlFor="gender">Gender</label>
                  <select
                    required
                    className="form-select"
                    name="gender"
                    aria-label="Default select example"
                    value={formData.gender}
                    onChange={handleInputChange}
                  >
                    <option selected disabled value="">
                      --Please select--
                    </option>
                    <option value="male">Male</option>
                    <option value="female">Female</option>
                  </select>
                </div>

                <div className="form-group col-md-6 col-xs-12">
                  <label htmlFor="status">Status</label>
                  <select
                    required
                    className="form-select"
                    name="status"
                    aria-label="Default select example"
                    value={formData.status}
                    onChange={handleInputChange}
                  >
                    <option selected disabled value="">
                      --Please select--
                    </option>
                    <option value="married">Married</option>
                    <option value="unmarried">Unmarried</option>
                    <option value="divorced">Divorced</option>
                    <option value="other">Other</option>
                  </select>
                </div>
                <div className="form-group col-md-6 col-xs-12">
                  <label htmlFor="aboutMe">About Me</label>
                  <textarea
                    name="aboutMe"
                    required
                    className="form-control"
                    id="other_detail_value"
                    value={formData.aboutMe}
                    rows={2}
                    data-container-id={2}
                    onChange={handleInputChange}
                  />
                </div>

                <div className="form-group col-md-6 col-xs-12">
                  <label htmlFor="country">Languages</label>
                  <Select
                    required
                    isMulti
                    className="dropdown"
                    placeholder="Select Languages"
                    name="languages"
                    value={selectedOptions} // set selected values
                    options={LANGUAGES} // set list of the data
                    onChange={handleSelectLanguages} // assign onChange function
                  />
                </div>
                <div className="form-group col-md-6 col-xs-12">
                  <label htmlFor="languages">Interested In</label>
                  <Select
                    required
                    isMulti
                    className="dropdown"
                    placeholder="Select"
                    name="interestedIn"
                    value={selectedIneterestedIN} // set selected values
                    options={INTERESTED_IN} // set list of the data
                    onChange={handleSelectInterested} // assign onChange function
                  />
                </div>
                {/* <div className="form-group col-xs-12">
                  <label htmlFor="languages">Websites</label>
                  <div className="col-md-12">
                    {webSiteLists.map((data, index) => {
                      const { url } = data;
                      return (
                        <div className="d-flex align-items-center" key={index}>
                          <div className="col">
                            <input
                              type="url"
                              onChange={(event) => handleChange(index, event)}
                              value={url}
                              required
                              name="url"
                              className="form-control"
                              placeholder="URL"
                            />
                          </div>
                          <div className="col">
                            <button
                              className="btn btn-outline-danger"
                              onClick={() => removeWebSite(index)}
                              type="button"
                            >
                              X
                            </button>
                          </div>
                        </div>
                      );
                    })}
                    <div
                      className="col-md-4 col-xs-12 my-button"
                      onClick={addWebsites}
                    >
                      <IoMdAddCircleOutline className="m-1" size={25} />
                      <span>Add New</span>
                    </div>
                    <div className="col-sm-12"></div>
                  </div>
                </div>
                <div className="form-group col-xs-12">
                  <label htmlFor="languages">Social Media Links</label>
                  <div className="col-md-12">
                    {socialLinkLists.map((data, index) => {
                      const { type, url } = data;
                      return (
                        <div className="d-flex align-items-center" key={index}>
                          <div className="col-xs-3">
                            <select
                              required
                              className="form-select"
                              name="type"
                              aria-label="Default select example"
                              value={type}
                              onChange={(event) =>
                                handleChangeSocialLink(index, event)
                              }
                            >
                              <option selected disabled value="">
                                --Please select--
                              </option>
                              <option value="facebook">Facebook</option>
                              <option value="instagram">Instagram</option>
                              <option value="linkedIn">Linkedin</option>
                              <option value="other">Other</option>
                              <option value="twitter">Twitter</option>
                              <option value="youtube">Youtube</option>
                            </select>
                          </div>
                          &nbsp;&nbsp;
                          <div className="col-xs-6">
                            <input
                              type="url"
                              onChange={(event) =>
                                handleChangeSocialLink(index, event)
                              }
                              value={url}
                              name="url"
                              required
                              className="form-control"
                              placeholder="URL"
                            />
                          </div>
                          <div className="col">
                            <button
                              className="btn btn-outline-danger"
                              onClick={() => removeSocialLink(index)}
                              type="button"
                            >
                              X
                            </button>
                          </div>
                        </div>
                      );
                    })}
                    <div
                      className="col-md-4 col-xs-12 my-button"
                      onClick={addSocialLink}
                    >
                      <IoMdAddCircleOutline className="m-1" size={25} />
                      <span>Add New</span>
                    </div>
                    <div className="col-sm-12"></div>
                  </div>
                </div> */}
              </div>

              <div className="row d-flex  justify-content-end ">
                <div className="col-xs-4">
                  <button
                    className="btn btn-danger"
                    type="submit"
                    disabled={loading}
                  >
                    {loading ? (
                      <Spinner animation="border" size="sm" />
                    ) : (
                      "Send"
                    )}
                  </button>
                </div>
              </div>
            </form>
          </Modal.Body>
        </Modal>
      )}
    </div>
  );
};

export default PersonalInfo;
