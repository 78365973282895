import { useState } from "react";

export function useSearch() {
  const [searchQuery, setSearchQuery] = useState("");

  const handleSearchInputChange = (event) => {
    setSearchQuery(event.target.value);
  };

  return {
    onChange: handleSearchInputChange,
    searchQuery,
  };
}
