import React, { useEffect, useState } from "react";
import { Col, Row, Spinner } from "react-bootstrap";
import Friendscard from "../../../../components/Friendscard";
import AsyncSelect from "../../../../components/search/AutoSuggestSearchBar";
import { FollowService } from "../../../../services/follow.service";
import { notifyFailed } from "../../../../components/toast/Toast";

const OrganizationLists = () => {
  const [allOrganizations, setAllOrganizations] = useState([]);
  const [followRequests, setFollowRequests] = useState([]);
  const [volunteerRequests, setVolunteerRequests] = useState([]);
  useEffect(() => {
    getAllOrganizations();
  }, []);

  const getAllOrganizations = async () => {
    try {
      const response = await FollowService.fetchListofOrganization();
      setAllOrganizations(response?.organizations);
    } catch (err) {
      console.log(err);
    }
  };

  const handleFollow = async (id, index) => {
    try {
      setFollowRequests([...followRequests, index]);

      await FollowService.addFollowOrganization(`${id}?is_volunteer=0`);
      setTimeout(() => {
        setAllOrganizations([
          ...allOrganizations.filter((ele) => ele.id !== id),
        ]);
      }, 600);
    } catch (err) {
      notifyFailed("Oops sorry, Try it later");
    } finally {
      setFollowRequests(followRequests.filter((i) => i !== index)); // Remove the index from the followRequests array
    }
  };
  const handleVolunteer = async (id, index) => {
    try {
      setVolunteerRequests([...volunteerRequests, index]);
      await FollowService.addFollowOrganization(`${id}?is_volunteer=1`);
      setTimeout(() => {
        setAllOrganizations([
          ...allOrganizations.filter((ele) => ele.id !== id),
        ]);
      }, 600);
    } catch (err) {
      notifyFailed("Oops sorry, Try it later");
    } finally {
      setVolunteerRequests(volunteerRequests.filter((i) => i !== index)); // Remove the index from the volunteerRequests array
    }
  };
  const truncateText = (text, maxLength) => {
    if (text.length > maxLength) {
      return text.split(" ").slice(0, maxLength).join(" ") + "...";
    }
    return text;
  };
  return (
    <div id="content-page" className="content-page">
      <AsyncSelect />

      <Row>
        {allOrganizations?.length > 0 ? (
          <>
            {allOrganizations?.map((ele, index) => (
              <Col sm="6" md="3" key={ele.id}>
                <Friendscard className="friendscard">
                  <div className="user-img img-fluid flex-shrink-0">
                    <img
                      src={ele.display_picture_media.img_url}
                      alt="story-img"
                      className="rounded-circle avatar-90"
                    />
                  </div>
                  <div className="card-content text-center">
                    <h5 className="card-title">{ele.name}</h5>
                    <p className="card-text mt-2 text-capitalize badge rounded-pill bg-info">
                      {ele.organization_sector.name}
                    </p>
                    <p className="card-text">
                      {truncateText(ele.short_intro, 8)}
                    </p>
                  </div>
                  <div className="button-row d-flex justify-content-between">
                    <button
                      type="submit"
                      className="btn btn-primary flex-grow-1 me-2"
                      onClick={() => handleFollow(ele.id, index)}
                      disabled={
                        followRequests.includes(index) ||
                        volunteerRequests.includes(index)
                      }
                    >
                      {followRequests.includes(index) ? (
                        <Spinner animation="border" size="sm" />
                      ) : (
                        "Follow"
                      )}
                    </button>
                    <button
                      type="submit"
                      className="btn btn-primary flex-grow-1"
                      disabled={
                        followRequests.includes(index) ||
                        volunteerRequests.includes(index)
                      }
                      onClick={() => {
                        handleVolunteer(ele.id, index);
                      }}
                    >
                      {volunteerRequests.includes(index) ? (
                        <Spinner animation="border" size="sm" />
                      ) : (
                        "Volunteer"
                      )}
                    </button>
                  </div>
                </Friendscard>
              </Col>
            ))}
          </>
        ) : (
          "Not Found"
        )}
      </Row>
    </div>
  );
};

export default OrganizationLists;
