import React, { useEffect } from "react";
import { Row, Col, Spinner } from "react-bootstrap";
import Friendscard from "../../../../components/Friendscard";
import "./../cardcss/friendscard.css";
//profile-header

// image
import { useFollow } from "../../../../hooks/useFollow";
import { FollowService } from "../../../../services/follow.service";
import { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { useSearch } from "../../../../hooks/useSearch";
import SearchBar from "../../../../components/search/SearchBar";
import { profileService } from "../../../../services/user.service";

const ListPeople = () => {
  const { listPeople, setListPeople } = useFollow();
  const [loadingId, setLoadingId] = useState(null);
  const [requestSentIds, setRequestSentIds] = useState([]);
  const { searchQuery, onChange } = useSearch();
  const location = useLocation();
  const sendRequest = async (reqId) => {
    setLoadingId(reqId);
    try {
      await FollowService.sendRequest(`connections?user_id=${reqId}`);
      setTimeout(() => {
        setLoadingId(null);
        setRequestSentIds([...requestSentIds, reqId]);
      }, 800);
    } catch (err) {
      setLoadingId(null);
      return err;
    }
  };
  const handleClick = (id) => {
    if (loadingId === null) {
      sendRequest(id);
    }
  };

  const renderButtonContent = (id) => {
    if (loadingId === id) {
      return (
        <Spinner animation="border" size="sm" role="status">
          <span className="visually-hidden">Loading...</span>
        </Spinner>
      );
    } else if (requestSentIds.includes(id)) {
      return "Request Sent";
    } else {
      return "Add Friend";
    }
  };
  const isButtonDisabled = (id) => {
    return loadingId !== null || requestSentIds.includes(id);
  };
  useEffect(() => {
    return () => setRequestSentIds([]);
  }, [location.hash]);
  const fetchUserLists = async () => {
    // Perform the data fetching logic based on the tab
    try {
      const result = await FollowService.fetchListofPeoples();
      console.log("xx", result?.users);
      setListPeople(result?.users);
    } catch (err) {
      return err;
    }
  };
  useEffect(() => {
    if (searchQuery === "") {
      fetchUserLists();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchQuery]);
  //search users
  const handleSearch = async () => {
    try {
      const response = await profileService.getAllUsers(
        `users?search[name]=${searchQuery}`
      );
      setListPeople(response?.users);
    } catch (err) {
      return err;
    }
  };
  return (
    <>
      <div id="content-page" className="content-page">
        <Row>
          <SearchBar
            placeHolder="Search by name  ..."
            searchQuery={searchQuery}
            onChange={onChange}
            onSubmit={handleSearch}
          />

          {listPeople.length === 0 ? (
            <p>No users found</p>
          ) : (
            <>
              {listPeople.map((ele, index) => (
                <Col sm="6" md="3" key={index}>
                  <Friendscard className="friendscard">
                    <div className="user-img img-fluid flex-shrink-0">
                      <img
                        src={ele.display_picture.img_url}
                        alt="story-img"
                        className="rounded-circle avatar-40"
                      />
                      <button
                        type="submit"
                        class="btn btn-primary"
                        disabled={isButtonDisabled(ele.id)}
                        onClick={() => handleClick(ele.id)}
                      >
                        {renderButtonContent(ele.id)}
                      </button>

                      <Link to={`/my-profile/${ele.username}`}>
                        <h5 style={{ color: "#50b5ff" }}> {ele.name}</h5>
                      </Link>

                      <div>
                        <b>JobTitle:</b> {ele.job_title}
                        <br />
                        <b>Industry:</b> {ele.industry?.name}
                        <br />
                        {/* <b>Country:</b> {ele.country?.name} */}
                      </div>
                    </div>
                  </Friendscard>
                </Col>
              ))}
            </>
          )}

          {/* {listPeople.map((ele, index) => (
            <Col md={4} key={index}>
              <Card className=" card-block card-stretch card-height">
                <Card.Body className=" profile-page p-0">
                  <div className="profile-header-image">
                    <div className="cover-container">
                      <img
                        loading="lazy"
                        src={ele.cover_picture.img_url}
                        alt="profile-bg"
                        className="rounded img-fluid w-100"
                      />
                    </div>
                    <div className="profile-info p-4">
                      <div className="user-detail">
                        <div className="d-flex flex-wrap justify-content-between align-items-start">
                          <div className="profile-detail d-flex">
                            <div className="profile-img pe-4">
                              <img
                                loading="lazy"
                                src={ele.display_picture.img_url}
                                alt="profile-img"
                                className="avatar-90 img-fluid"
                              />
                            </div>
                            <div className="user-data-block">
                              <h4>
                                <Link to="/dashboard/app/friend-profile">
                                  {ele.name}
                                </Link>
                              </h4>
                              <h6>@designer</h6>
                              <p>Lorem Ipsum is simply dummy text of the</p>
                            </div>
                          </div>
                          <button
                            type="submit"
                            className="btn btn-primary"
                            onClick={() => sendRequest(ele.id)}
                          >
                            <i className="ri-user-add-line me-1"></i>Add Friend
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </Card.Body>
              </Card>
            </Col>
          ))} */}
        </Row>
      </div>
    </>
  );
};

export default ListPeople;
