import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { Container, Row, Col, Tab } from "react-bootstrap";
import Card from "../../../components/Card";

// import "./donationCompaign.css";
import { useEffect } from "react";
import "../../../assets/css/style.css";
import { obituaryService } from "../../../services/obituaryService";

const SingleObituary = () => {
  const params = useParams();
  const [obituary, setObituary] = useState([]);
  console.log(params.slug);
  const fetchSingleObituary = async () => {
    try {
      const response = await obituaryService.getObituaries(
        `name=${params.slug}`
      );
      setObituary(response.obituaries[0]);
    } catch (err) {
      return err;
    }
  };
  useEffect(() => {
    fetchSingleObituary();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  console.log(obituary);

  return (
    <Container>
      <Row>
        <Col lg="5">
          <Row>
            <Card>
              <div className="top-bg-image">
                <img
                  loading="lazy"
                  src={obituary?.deceased?.display_picture_media.large_img_url}
                  className="img-fluid"
                  alt="Responsive"
                />
              </div>
              <Card.Body className="text-center">
                <h4 className="text-center">
                  {obituary?.deceased?.name_translations[0].name_translation}
                </h4>
                <h4 className="text-center">{obituary?.deceased?.name}</h4>
              </Card.Body>
            </Card>
          </Row>
          <Row>
            <Card>
              <Card.Body className="text-center">
                <div className="d-flex justify-content-between">
                  Age:<p>{obituary?.deceased?.age}</p>
                </div>
                <div className="d-flex justify-content-between">
                  Date of Birth:
                  <p>{obituary?.deceased?.birth_date}</p>
                </div>
                <div className="d-flex justify-content-between">
                  Place of Birth :<p>{obituary?.deceased?.birth_place}</p>
                </div>
                <div className="d-flex justify-content-between">
                  Date of Death:
                  <p>{obituary?.deceased?.death_date}</p>
                </div>
                <div className="d-flex justify-content-between">
                  Place of Death :<p>{obituary?.deceased?.death_place}</p>
                </div>
                <div className="d-flex justify-content-between">
                  Religion :<p>{obituary?.deceased?.religion}</p>
                </div>
              </Card.Body>
            </Card>
          </Row>
        </Col>
        <Col lg="7">
          <Tab.Container defaultActiveKey="f1">
            <Card>
              <div className="d-flex profile-feed-items  justify-content-center">
                <h2 className="text-center">{obituary?.custom_title}</h2>
              </div>
            </Card>
            <Tab.Content className="forum-content">
              <Tab.Pane eventKey="f1">
                <Card>
                  <Card.Header className="d-flex justify-content-between">
                    <div className="header-title">
                      <h4 className="card-title">Description</h4>
                    </div>
                  </Card.Header>
                  <Card.Body></Card.Body>
                </Card>
              </Tab.Pane>
              <Tab.Pane eventKey="f2">
                <Card>
                  <div className="d-flex justify-content-start p-2">
                    <h2 className="text-center">{obituary?.custom_title}</h2>
                  </div>
                </Card>
              </Tab.Pane>
            </Tab.Content>
          </Tab.Container>
        </Col>
      </Row>
    </Container>
  );
};
export default SingleObituary;
