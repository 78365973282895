import { createContext, useContext, useMemo } from "react";
import { AUTH_USER } from "../utilities/constants";
import { useLocalStorage } from "./useLocalStorage";
const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useLocalStorage(AUTH_USER, null);

  const value = useMemo(
    () => ({
      user,
      setUser,
    }),
    [user]
  );
  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

// export  default AuthContext;
export const useAuth = () => {
  return useContext(AuthContext);
};
