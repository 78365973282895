import React, { useEffect, useState } from "react";
import { Dropdown, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import { FollowService } from "../../../../services/follow.service";

const FriendList = () => {
  const [friendLists, setFriendLists] = useState([]);
  const fetchFriendLists = async () => {
    try {
      const res = await FollowService.fetchRequests(`list/friends`);
      setFriendLists(res?.connections.followers);
    } catch (err) {
      return err;
    }
  };

  useEffect(() => {
    fetchFriendLists();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const handleUnfriend = async (friendId) => {
    try {
      await FollowService.unfriendRequest(friendId);
      setFriendLists([
        ...friendLists.filter((ele) => ele.user.id !== friendId),
      ]);
    } catch (err) {
      return err;
    }
  };
  return (
    <Row>
      {friendLists?.map((ele, index) => (
        <div className="col-md-6 col-lg-6 mb-3" key={index}>
          <div className="iq-friendlist-block">
            <div className="d-flex align-items-center justify-content-between">
              <div className="d-flex align-items-center">
                <Link to="#">
                  <img
                    loading="lazy"
                    src={ele.user.display_picture.img_url}
                    alt="profile-img"
                    className="img-fluid avatar-70"
                  />
                </Link>
                <div className="friend-info ms-3">
                  <p className="mb-0">{ele.user.name}</p>
                </div>
              </div>
              <div className="card-header-toolbar d-flex align-items-center">
                <Dropdown>
                  <Dropdown.Toggle variant="secondary me-2 d-flex align-items-center">
                    <i className="material-symbols-outlined me-2">done</i>
                    View
                  </Dropdown.Toggle>
                  <Dropdown.Menu className="dropdown-menu-right">
                    <Link to={`/app/users/${ele.user.id}`}>
                      <Dropdown.Item>View Profile</Dropdown.Item>
                    </Link>

                    <Dropdown.Item
                      href=""
                      onClick={() => handleUnfriend(ele.user.id)}
                    >
                      Unfriend
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            </div>
          </div>
        </div>
      ))}

      {friendLists?.length === 0 && (
        <div className="p-2 m-2">
          <h4>No Friends Lists</h4>
        </div>
      )}
    </Row>
  );
};

export default FriendList;
