import React, { useMemo } from 'react'
import { Navigate } from "react-router-dom";
import { useAuth } from '../hooks/useAuth';

export const ProtectedRoute = ({children}) => {
    const {user} = useAuth()
    if (user != null) {
        return (
          <>{children}</>
        );
    }else{
      return <Navigate to="/auth/sign-in" />;
    }
};