import React from 'react'
import { Link } from 'react-router-dom'
import { Col, Container, Row } from "react-bootstrap";
import Card from '../../../../components/Card'
// import SearchBar from "../../../components/search/SearchBar";
import Friendscard from "../../../../components/Friendscard";

export default function OrganizationCard({organization}) {

    const truncateText = (text, maxLength) => {
        if (text.length > maxLength) {
          return text.split(' ').slice(0, maxLength).join(' ') + '...';
        }
        return text;
      };

  return (
      <Col sm="6" md="3">
   <Friendscard className="friendscard">
        <Card.Body className=" text-center">
            {/* <div className="group-icon" style={{ marginTop: '40px'}}>
                <img src={organization?.display_picture_media?.img_url} alt="profile-img" style={{borderRadius: '10px'}} className="rounded img-fluid avatar-120"/>
            </div> */}
            <div className="user-img img-fluid flex-shrink-0">
                <img
                src={organization?.display_picture_media?.img_url}
                alt="story-img"
                className="rounded-circle avatar-90"
                />
            </div>
            <div className="card-content text-center">
                <h5 className='text-uppercase'>{organization.name}</h5>
                <p className="card-text text-capitalize badge rounded-pill bg-info">{organization.organization_sector.name}</p>
                <p className="card-text"> {truncateText(organization.short_intro, 8)}</p>
                <Link to={`/app/organizations/${organization.slug}`} ><button type="button" className="btn btn-primary d-block" style={{width:'80%'}}>View</button></Link>
            
                {/* <div className="badge-container">
                        <span className="badge-label">Charity</span>
                </div> */}
            </div>
             </Card.Body>
    </Friendscard>
    </Col>
  )
}
