import React from "react";
import { Modal } from "react-bootstrap";
import { Link } from "react-router-dom";
import ObituaryForm from "../dashboard/components/Obituary/Obituary";
import "../../assets/css/style.css";
export default function ObituaryModal({ handleClose, show }) {
  return (
    <>
      <Modal
        size="lg"
        className="fade"
        id="donation-modal"
        onHide={handleClose}
        show={show}
      >
        <Modal.Header className="d-flex justify-content-between">
          <Modal.Title id="post-modalLabel">Create Obituary</Modal.Title>
          <Link to="#" className="lh-1" onClick={handleClose}>
            <span className="material-symbols-outlined">close</span>
          </Link>
        </Modal.Header>
        <Modal.Body>
          <div className="form1 form2">
            <ObituaryForm />
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}
