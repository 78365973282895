import React from "react";
import { Card, Col, Nav, Row, Tab } from "react-bootstrap";
import { Outlet, useNavigate } from "react-router-dom";
import { useTab } from "../../../../hooks/useTab";

const FollowsTab = () => {
  const navigate = useNavigate();
  const { activeSubTab, handleSubTabChange } = useTab();

  return (
    <Tab.Pane eventKey="third">
      <Row>
        <Col md={4}>
          <Card>
            <Card.Body>
              <Nav
                variant="pills"
                defaultActiveKey={activeSubTab}
                className=" basic-info-items list-inline d-block p-0 m-0"
              >
                <Nav.Item>
                  <Nav.Link
                    eventKey="firstSubTab"
                    onClick={() => {
                      navigate("/my-profile/follows/friends");
                      handleSubTabChange("firstSubTab");
                    }}
                  >
                    Friends
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link
                    eventKey="secondSubTab"
                    onClick={() => {
                      navigate("/my-profile/follows/organizations");
                      handleSubTabChange("secondSubTab");
                    }}
                  >
                    Organizations
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="proFollows">Projects</Nav.Link>
                </Nav.Item>
              </Nav>
            </Card.Body>
          </Card>
        </Col>
        <Col md={8} className="ps-4">
          <Card>
            <Outlet />
          </Card>
        </Col>
      </Row>
    </Tab.Pane>
  );
};

export default FollowsTab;
