import React, { Fragment, useEffect, useState } from "react";

export default function DynamicOtherContactNumber({otherContactNumbers,handleOtherContactNumbers}) {
  const [inputFields, setInputFields] = useState([]);
  // ,
  useEffect(()=>{
    setInputFields(otherContactNumbers)
  },[otherContactNumbers])
  const handleAddFields = () => {
    const values = [...inputFields];
    values.push({ label: "", contact_number: "" });
    handleOtherContactNumbers('other_contact_numbers',values)
  };

  const handleRemoveFields = (index) => {
    const values = [...inputFields];
    values.splice(index, 1);
    handleOtherContactNumbers('other_contact_numbers',values)
  };
  const handleInputChange = (index, event) => {
    const values = [...inputFields];
    const {name} = event.target
    values[index][name] = event.target.value;
    handleOtherContactNumbers('other_contact_numbers',values)
  };
  return (
    <>
      {inputFields.map((inputField, index) => (
        <Fragment key={`${inputField}~${index}`}>
          <div
            className="row p-2 mb-3 d-flex align-items-center"
            style={{ backgroundColor: "#f5f2efc4" }}
          >
            <div className="form-group col-4">
              <label htmlFor="website_label">Label</label>
              <input
                type="text"
                name="label"
                className="form-control"
                id="website_label"
                onChange={(event) => handleInputChange(index, event)}
                placeholder="Label"
              />
            </div>
            <div className="form-group col-6">
              <label htmlFor="contact_number">Contact Number</label>
              <input
                type="number"
                name="contact_number"
                className="form-control"
                id="contact_number"
                onChange={(event) => handleInputChange(index, event)}
                placeholder="Contact Number"
              />
            </div>

              <div className="col-2 col-xs-4">
                <button
                  className="btn btn-danger"
                  type="button"
                  onClick={() => handleRemoveFields(index)}
                >
                  Remove
                </button>
              </div>
          </div>
        </Fragment>
      ))}
     {inputFields.length>0 && <div className="row d-flex  justify-content-left ">
        <div
          className="col-xs-12 col-6 btn btn-success"
          onClick={handleAddFields}
        >
          Add More Contact Number
        </div>
      </div>}
    </>
  )
}
