import React, { Fragment, useEffect, useState } from "react";

export default function DynamicFaq({handleFaqs,faqs}) {
  const [inputFields, setInputFields] = useState([
    { question: "", answer: "" },
  ]);
  useEffect(() => {
    faqs?.length > 0 && setInputFields(faqs);
  }, [faqs]);
  const handleAddFields = () => {
    const values = [...inputFields];
    values.push({ question: "", answer: "" });
    handleFaqs("faqs", values);
  };

  const handleRemoveFields = (index) => {
    const values = [...inputFields];
    values.splice(index, 1);
    handleFaqs("faqs", values);
  };

  const handleInputChange = (index, event) => {
    const values = [...inputFields];
    if (event.target.name === "question") {
      values[index].question = event.target.value;
    } else {
      values[index].answer = event.target.value;
    }

    handleFaqs("faqs", values);
  };

  return (
    <>
      {inputFields.map((inputField, index) => (
        <Fragment key={`${inputField}~${index}`}>
          <div
            className="row p-3 mb-3 mt-3 d-flex align-items-center"
            style={{ backgroundColor: "#f5f2efc4" }}
          >
            <div className="form-group col-xs-12">
              <label htmlFor="fa_question">Question</label>
              <input
                type="text"
                name="question"
                className="form-control"
                id="fa_question"
                defaultValue=""
                placeholder="Question"
                onChange={(event) => handleInputChange(index, event)}
              />
            </div>

            <div className="form-group col-xs-12">
              <label htmlFor="fa_answer">Answer</label>
              <textarea
                name="answer"
                className="form-control"
                id="fa_answer"
                placeholder="Answer"
                rows={1}
                onChange={(event) => handleInputChange(index, event)}
              />
            </div>

            {index > 0 ? (
              <div className="col-2 col-xs-4">
                <button
                  className="btn btn-danger"
                  type="button"
                  disabled={index === 0}
                  onClick={() => handleRemoveFields(index)}
                >
                  Remove
                </button>
              </div>
            ) : null}
          </div>
        </Fragment>
      ))}

      <div className="row d-flex align-items-center justify-content-start">
        <div
          className="col-md-2 col-xs-12 btn btn-success"
          onClick={() => handleAddFields()}
        >
          Add More
        </div>
      </div>
    </>
  );
}
