import axios from "axios";
import { ACCESS_TOKEN, API_URL } from "../utilities/constants";
import { authService } from "./auth.service";

function addParamsToURL(url, params) {
  let tempUrl = API_URL + url;
  if (url === "null") {
    return API_URL + params;
  }
  if (params) {
    tempUrl = tempUrl + "/" + params;
    return tempUrl;
  }
  return tempUrl;
}

const getHeaders = (token, contentType) => {
  const headers = {
    Accept: "application/json",
    "Content-Type": "application/json",
  };

  if (contentType != null) {
    headers["Content-Type"] = contentType;
  }
  return token != null
    ? {
        headers: {
          ...headers,
          Authorization: `Bearer ${localStorage.getItem(ACCESS_TOKEN)}`,
        },
      }
    : { headers };
};

export const api = (method, endpoint, token, body, params, contentType) => {
  switch (method) {
    case "GET":
      // HTTP GET Request - Returns Resolved or Rejected Promise
      return new Promise((resolve, reject) => {
        const URL = addParamsToURL(`${endpoint}`, params);
        axios
          .get(URL, getHeaders(token, contentType))
          .then((response) => {
            resolve(response);
          })
          .catch((error) => {
            if (error.response.status === 401) {
              authService.redirectUnAuthenticate();
            }
            reject(error);
          });
      });
    case "POST":
      // HTTP POST Request - Returns Resolved or Rejected Promise
      return new Promise((resolve, reject) => {
        const URL = addParamsToURL(`${endpoint}`, params);
        console.log(URL);
        axios
          .post(URL, body, getHeaders(token, contentType))
          .then((response) => {
            resolve(response);
          })
          .catch((error) => {
            if (error.response.status === 401) {
              authService.redirectUnAuthenticate();
            }
            if (error.response.status === 422) {
              reject(error.response.data);
            }
            console.log(error.response);
            reject(error);
          });
      });
    case "DELETE":
      // HTTP DELETE Request - Returns Resolved or Rejected Promise
      return new Promise((resolve, reject) => {
        const URL = addParamsToURL(`${endpoint}`, params);
        axios
          .delete(URL, getHeaders(token, contentType))
          .then((response) => {
            resolve(response);
          })
          .catch((error) => {
            if (error.response.status === 401) {
              authService.redirectUnAuthenticate();
            }
            console.log(error);
            reject(error);
          });
      });
    case "PUT":
      // HTTP PUT Request - Returns Resolved or Rejected Promise
      return new Promise((resolve, reject) => {
        const URL = addParamsToURL(`${endpoint}`, params);
        axios
          .put(URL, body, getHeaders(token, contentType))
          .then((response) => {
            resolve(response);
          })
          .catch((error) => {
            if (error.response.status === 401) {
              authService.redirectUnAuthenticate();
            }
            reject(error.response.data);
          });
      });
    case "PATCH":
      // HTTP PUT Request - Returns Resolved or Rejected Promise
      return new Promise((resolve, reject) => {
        const URL = addParamsToURL(`${endpoint}`, params);
        axios
          .patch(URL, body, getHeaders(token, contentType))
          .then((response) => {
            resolve(response);
          })
          .catch((error) => {
            if (error.response.status === 401) {
              authService.redirectUnAuthenticate();
            }
            reject(error.response.data);
          });
      });

    default:
      return null;
  }
};
