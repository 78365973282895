import React from "react";
import { useState } from "react";
import { Button, Card, Col, Row } from "react-bootstrap";
import { useMyProfile } from "../../../hooks/useMyProfile";
import { userProfileService } from "../../../services/userProfile.service";
import { useAuth } from "../../../hooks/useAuth";
import { useEffect } from "react";
import DynamicVideoDeatil from "../../../views/dashboard/components/DynamicVideoDeatil";
import { notifyFailed, notifySuccess } from "../../toast/Toast";
import { useGlobal } from "../../../hooks/useGlobal";
import LightBox from "../../lightbox/lightBox";
import ImageCard from "../../Image/ImageCard";
// import DynamicVideoDeatil from "../../views/dashboard/components/DynamicVideoDeatil";

const Videos = () => {
  const [uploading, setUploading] = useState(false);
  const { user } = useAuth();
  const [showUploadScreen, setShowUploadScreen] = useState(null);
  const { convertImagesToRequestString, setSelectSingleImage, openLightbox } =
    useGlobal();
  const { videoLists, setVideoLists } = useMyProfile();
  const [videos, setVideos] = useState([]);
  const fetchAllVideos = async () => {
    try {
      const result = await userProfileService.fetchAllVideos(user?.username);
      setVideoLists(result?.video_links);
    } catch (err) {
      return err;
    }
  };

  useEffect(() => {
    fetchAllVideos();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleVideoDetails = (field, values) => {
    setVideos(values);
  };
  const handleOpenUploadForm = () => {
    setShowUploadScreen(!showUploadScreen);
    setSelectSingleImage(true);
  };
  const handleSubmit = async () => {
    setUploading(true);
    try {
      let oldVideos = videoLists.map((v) => ({
        preview_picture_id: v.preview_picture_id,
        video_url: v.video_url,
        title: v.preview_picture.img_title,
      }));
      let newVideos = videos.map((v) => ({
        ...v,
        preview_picture_id: convertImagesToRequestString(v.preview_picture),
      }));
      let mergeVideos = [...newVideos, ...oldVideos];
      let reqBody = { video_links: JSON.stringify(mergeVideos) };

      const res = await userProfileService.updateVedioGallery(reqBody);
      setUploading(false);
      if (res.success) {
        notifySuccess(res.message);
        setVideoLists(res.video_links);
        setVideos([]);
        setShowUploadScreen(!showUploadScreen);
        setSelectSingleImage(false);
      }
    } catch (err) {
      setUploading(false);
      notifyFailed("Error Occured");
      return err;
    }
  };

  return (
    <>
      {!showUploadScreen ? (
        <>
          <div className="d-flex align-items-center justify-content-end">
            <button
              type="button"
              className="btn btn-secondary ms-2 btn-sm d-flex align-items-center"
              onClick={() => handleOpenUploadForm()}
            >
              <span
                className="material-symbols-outlined  md-12"
                style={{ fontSize: "14px" }}
              >
                edit
              </span>
              Upload Videos
            </button>
          </div>
          <div className="friend-list-tab mt-2">
            <Row>
              {videoLists.length > 0 ? (
                <Row>
                  <LightBox images={videoLists} />
                  {videoLists.map((gallery, index) => (
                    <ImageCard
                      key={index}
                      img={gallery.preview_picture.grid_img_url}
                      type="video"
                      size="3"
                      onImageClick={() => openLightbox(index)}
                    />
                  ))}
                </Row>
              ) : (
                <Card className="p-2">
                  <h4>No Data Found</h4>
                </Card>
              )}
            </Row>
          </div>
        </>
      ) : (
        <Row className="p-3 d-flex justify-content-center">
          <Col md="8" xs="12">
            <DynamicVideoDeatil
              videos={videos}
              handleVideoDetails={handleVideoDetails}
            />
            <Button
              className="mt-2"
              style={{ float: "right" }}
              onClick={handleSubmit}
            >
              Save Changes
            </Button>
            <Button
              className="mt-2 btn-info"
              style={{ float: "right" }}
              onClick={() => {
                setShowUploadScreen(!showUploadScreen);
                setSelectSingleImage(false);
              }}
            >
              Cancel
            </Button>
          </Col>
        </Row>
      )}

      {uploading && (
        <div className="backdrop">
          <div className="spinner" />
        </div>
      )}
    </>
  );
};

export default Videos;
