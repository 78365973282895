import React from "react";
import { Form } from "react-bootstrap";
import { useOrganization } from "../../../../../hooks/useOrganization";
import DynamicOtherContactNumber from "../../DynamicOtherContactNumber";
import DynamicOtherEmailDetail from "../../DynamicOtherEmailDetail";
import DynamicOtherWebsite from "../../DynamicOtherWebsite";
import DynamicSocialMediaProfile from "../../DynamicSocialMediaProfile";

const ContactDetails = (props) => {
  const {handleChange,organization,handleDynamicDetails,error} = useOrganization()
  if (props.currentStep !== 3) {
    return null;
  }
  return (
    <>
      <div className="d-flex justify-content-center">
        <h4 className="text-dark mt-4 ">Contact Details</h4>
      </div>
      <Form.Group>
        <div className="row">
          <div className="form-group col-12 ">
            <label htmlFor="postal_address" className="col-form-label ">
              Postal Address
            </label>
            <input
              type="text"
              className={`form-control ${error.postal_address ? 'is-invalid':''}`}
              name="postal_address"
              id="postal_address"
              placeholder="Enter your address"
              autoComplete="off"
              value={organization.postal_address}
              onChange={handleChange}
            />
             <div class="invalid-feedback">
              Please enter postal address.
            </div>
          </div>
          <div className="form-group col-md-6 col-xs-12">
            <label htmlFor="street_number" className="col-form-label ">
              Latitude
            </label>
            <input
              type="text"
              className="form-control"
              name="postal_lat"
              id="postal_lat"
              placeholder="Enter your latitute"
              value={organization.postal_lat}
              onChange={handleChange}
              disabled
            />
          </div>
          <div className="form-group col-md-6 col-xs-12">
            <label htmlFor="route" className="col-form-label ">
              Longitude
            </label>
            <input
              type="text"
              className="form-control"
              name="postal_long"
              id="postal_long"
              placeholder="Enter your longtitute"
              value={organization.postal_long}
              onChange={handleChange}
              disabled
            />
          </div>
          <div className="form-group col-md-6 col-xs-12">
            <label htmlFor="street_number" className="col-form-label ">
              Number
            </label>
            <input
              type="text"
              className="form-control"
              name="postal_address_number"
              id="street_number"
              value={organization.postal_address_number}
              onChange={handleChange}
              disabled
            />
          </div>
          <div className="form-group col-md-6 col-xs-12">
            <label htmlFor="route" className="col-form-label ">
              Street
            </label>
            <input
              type="text"
              className="form-control"
              name="postal_address_street"
              id="route"
              value={organization.postal_address_street}
              onChange={handleChange}
              disabled
            />
          </div>
          <div className="form-group col-md-6 col-xs-12">
            <label htmlFor="locality" className="col-form-label ">
              City
            </label>
            <input
              type="text"
              className="form-control"
              name="postal_address_city"
              id="locality"
              value={organization.postal_address_city}
              onChange={handleChange}
              disabled
            />
          </div>
          <div className="form-group col-md-6 col-xs-12">
            <label
              htmlFor="administrative_area_level_1"
              className="col-form-label "
            >
              Province
            </label>
            <input
              type="text"
              className="form-control"
              name="postal_address_province"
              id="administrative_area_level_1"
              value={organization.postal_address_province}
              onChange={handleChange}
              disabled
            />
          </div>
          <div className="form-group col-md-6 col-xs-12">
            <label htmlFor="postal_code" className="col-form-label ">
              Zip/Postal code
            </label>
            <input
              type="text"
              className="form-control"
              name="postal_address_zipcode"
              id="postal_code"
              value={organization.postal_address_zipcode}
              onChange={handleChange}
              disabled
            />
          </div>
          <div className="form-group col-md-6 col-xs-12">
            <label htmlFor="country" className="col-form-label ">
              Country
            </label>
            <input
              type="text"
              className="form-control"
              name="postal_address_country"
              id="country"
              value={organization.postal_address_country}
              onChange={handleChange}
              disabled
            />
          </div>
          <div className="form-group col-xs-12">
            <label htmlFor="main_website" className=" ">
              Website (Main)
            </label>
            <input
              type="url"
              className={`form-control ${error.main_website ? 'is-invalid':''}`}
              name="main_website"
              id="main_website"
              placeholder="Website (Main)"
              value={organization.main_website}
              onChange={handleChange}
            />
            <div class="invalid-feedback">
              Please enter main website.
            </div>
            <span
              className="text-primary"
              style={{ cursor: organization.other_websites.length === 0 ? "pointer" :"not-allowed"}}
              onClick={ ()=>handleDynamicDetails('other_websites',[{ label: "", url: "" }])}
            >
              Click here to add more websites
            </span>

            <div className="row">
              <div className="col-md-8 col-lg-12">
                <DynamicOtherWebsite otherWebsites={organization.other_websites} handleOtherWebsites={handleDynamicDetails}/>
              </div>
            </div>
          </div>
          <div className="form-group col-12 col-xs-12 ">
            <label htmlFor="main_email" className="col-form-label ">
              Email (Main)
            </label>
            <input
              type="email"
              className={`form-control ${error.main_email ? 'is-invalid':''}`}
              name="main_email"
              id="main_email"
              placeholder="Email (Main)"
              value={organization.main_email}
              onChange={handleChange}
            />
            <div class="invalid-feedback">
              Please enter main email.
            </div>
            <span
              className="text-primary"
              style={{ cursor: organization.other_emails.length === 0 ? "pointer" :"not-allowed"}}
              onClick={ ()=>  handleDynamicDetails('other_emails',[{ label: "", email: "" }])}
            >
              Click here to add more emails
            </span>
            <div className="row">
              <div className="col-md-8 col-lg-12">
                <DynamicOtherEmailDetail otherEmails={organization.other_emails} handleOtherEmails={handleDynamicDetails}/>
              </div>
            </div>
          </div>
          <div className="form-group col-12 col-lg-12">
            <label htmlFor="main_contact_number" className="col-form-label ">
              Contact Number (Main)
            </label>
            <input
              type="tel"
              className={`form-control ${error.main_contact_number ? 'is-invalid':''}`}
              name="main_contact_number"
              id="main_contact_number"
              value={organization.main_contact_number}
              onChange={handleChange}
              placeholder="Contact Number (Main)"
            />
            <div class="invalid-feedback">
              Please enter contact number.
            </div>
            <span
              className="text-primary"
              style={{ cursor: organization.other_contact_numbers.length === 0 ? "pointer" :"not-allowed"}}
              onClick={ ()=>  handleDynamicDetails('other_contact_numbers',[{ label: "", contact_number: "" }])}
            >
              Click here to add more contact numbers
            </span>
            <div className="row">
              <div className="col-md-8 col-lg-12">
                <DynamicOtherContactNumber otherContactNumbers={organization.other_contact_numbers} handleOtherContactNumbers={handleDynamicDetails}/>
              </div>
            </div>
          </div>
          <label className="col-form-label ">Social Media Profiles</label>
          <DynamicSocialMediaProfile socialMediaprofiles={organization.social_media_profiles} handleSocialMediaProfiles={handleDynamicDetails}/>
        </div>
      </Form.Group>
    </>
  );
};

export default ContactDetails;
