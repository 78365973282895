import imgl1 from '../../../assets/images/healthier/zslog.jpeg';
import React, { useState, useRef } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import Card from '../../../components/Card';
import SwiperCore, { Autoplay, Pagination } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper-bundle.min.css';
import '../extrapages/css/goals.css';
import { organizationService } from "../../../services/organization.service";
import VedioGallery from "./../components/organizations/VedioGallery";
import playlistData from './playlist/playlistData.json';


SwiperCore.use([Autoplay, Pagination]);

const Music = () => {
  const [currentPlaylist, setCurrentPlaylist] = useState('https://www.youtube.com/embed/zpOULjyy-n8?rel=0'); // Default playlist

  const videoPlayerRef = useRef(null);

  const handlePlaylistClick = (newPlaylist) => {
    setCurrentPlaylist(newPlaylist);

    // Scroll to the video player element
    videoPlayerRef.current.scrollIntoView({ behavior: 'smooth' });
  };

  const visiblePlaylistData = playlistData; // Your playlist data fetched from the JSON file

  return (
    <>
      {/* ... your profile header ... */}
      <div id="content-page" className="content-page">
        <Container>
          <Row>
            <Col md="4">
              <Card className="card-block card-stretch card-height color-pad">
                <Card.Header className="d-flex justify-content-between">
                  <div className="header-title">
                    {/* <h4 className="card-title">Play Lists</h4> */}
                  </div>
                </Card.Header>
                <Card.Body>
                  <div className="playlist-container">
                    {visiblePlaylistData.map((item) => (
                      <div
                        key={item.id}
                        className="playlist-item d-flex mb-4 align-items-center"
                        onClick={() => handlePlaylistClick(item.playlistLink)}
                      >
                        <div className="user-img img-fluid">
                          <img src={imgl1} alt="story-img" className="rounded-circle avatar-40" />
                        </div>
                        <div className="d-flex justify-content-between w-100">
                          <div className="ms-3">
                            <h6>{item.name}</h6>
                          </div>
                          <div className="music-time">{item.time}</div>
                        </div>
                      </div>
                    ))}
                  </div>
                </Card.Body>
              </Card>
            </Col>
            <Col md="8">
              <Card ref={videoPlayerRef} id="video-plays" className="card-block card-stretch card-height card-heightnew">
                <Card.Body className="p-0">
                  <div className="ratio ratio-4x3">
                    <iframe
                      className="embed-responsive-item"
                      src={currentPlaylist}
                      title="v2"
                      tabIndex="0" // Make the player focusable
                    />
                  </div>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default Music;
