import React from 'react';
import AsyncSelect from 'react-select/async';

// const fetchDonateSuggestions = (query) => {
//   // Replace the URL with the correct endpoint
//   return fetch(`https:staging.zoneceylon.lk/api/v1/campaigns?search=${query}`)
//     .then((response) => response.json())
//     .then((data) => {
//       const dataCampaign = data.campaigns;
//       return dataCampaign.map((item) => ({
//         value: item.id,
//         label: item.title,
//         image: item.image.img_url,
//         link: `/donation-campaign/${item.id}`
//       }));
//     })
//     .catch((err) => {
//       console.error(err);
//       return [];
//     });
// };

const fetchObituarySuggestions = (query) => {
  // Replace the URL with the correct endpoint
  return fetch(`https://staging.zoneceylon.lk/api/v1/obituaries?name=${query}`)
    .then((response) => response.json())
    .then((data) => {
      const dataObituary = data.obituaries;
      console.log("scsc",dataObituary)
      return dataObituary.map((obituary) => ({
        value: obituary.type,
        label: obituary.deceased.name,
        image: obituary.deceased.display_picture_media.img_url,
        link: `/obituaries/${obituary.id}/${obituary.deceased.name}`
      }));
    })
    .catch((err) => {
      console.error(err);
      return [];
    });
};

// const fetchFriendSuggestions = (query) => {
//   // Replace the URL with the correct endpoint
//   return fetch(`https:staging.zoneceylon.lk/api/v1/users?search[name]=${query}&search[username]=${query}&search[email]=${query}&search[city]=${query}`)
//     .then((response) => response.json())
//     .then((data) => {
//       const dataObituary = data.obituaries;
//       console.log("scsc",dataObituary)
//       return dataObituary.map((obituary) => ({
//         value: obituary.type,
//         label: obituary.deceased.name,
//         image: obituary.deceased.display_picture_media.img_url,
//         link: `/users/${obituary.id}/${obituary.deceased.name}`
//       }));
//     })
//     .catch((err) => {
//       console.error(err);
//       return [];
//     });
// };

const fetchOrganizationSuggestions = (query) => {
  // Replace the URL with the correct endpoint
  return fetch(`https://staging.zoneceylon.lk/api/v1/organizations?search[name]=${query}`)
  //&search[type]=${query}&search[sector]=${query}&search[sub-sector]=${query}&search[ds-division]=${query}&search[gn-division]=${query}&search[parent]=${query}&search[donation-receivables-only]=${query}&search[parents-only]=${query}
    .then((response) => response.json())
    .then((data) => {
      const dataOrganization = data.data;
      console.log("org",dataOrganization)
      return dataOrganization.map((organization) => ({
        value: organization.organization_sector.name,
        label: organization.name,
        image: organization.display_picture_media.img_url,
        link: `organizations/${organization.id}/${organization.slug}`
      }));
    })
    .catch((err) => {
      console.error(err);
      return [];
    });
};

const AutoSuggestSearchBar = ({ onSelected, placeHolder, searchQuery, onChange, onSubmit }) => {

  const handleSumbit = (e) => {
    e.preventDefault();
    onSubmit();
  }; 
  const loadOptions = (inputValue, callback) => {
    if (!inputValue || inputValue.length < 4) {
      callback([]);
      return;
    }

    // fetchDonateSuggestions(inputValue).then((suggestions) => {
    //   callback(suggestions);
    // });

    // fetchFriendSuggestions(inputValue).then((suggestions) => {
    //   callback(suggestions);
    // });

    fetchObituarySuggestions(inputValue).then((suggestions) => {
      callback(suggestions);
    });

    fetchOrganizationSuggestions(inputValue).then((suggestions) => {
      callback(suggestions);
    });
  };

  const handleChange = (selectedOption) => {
    if (onSelected) {
      onSelected(selectedOption);
    }
  };

  const handleOptionClick = (selectedOption) => {
    if (selectedOption && selectedOption.link) {
      window.location.href = selectedOption.link; // Navigate to the specific donation link
    }
  };

  const formatOptionLabel = ({ value, label, image }) => (
    <div>
      {image && <img src={image} alt={label} style={{ width: '50px', marginRight: '10px' ,height:'50px' }} />}
      <span>{label}</span>
    </div>
  );

  return (
    
    <AsyncSelect
      cacheOptions
      loadOptions={loadOptions}
      defaultOptions
      onInputChange={handleChange}
      placeholder="Search..."
      value={null} // Set initial value to null
      onChange={handleOptionClick} // Update selected value and navigate to donation link
      formatOptionLabel={formatOptionLabel} // Custom option label with image
    />
  );
};

export default AutoSuggestSearchBar;
