import React from "react";
import { useState } from "react";
import { Dropdown, Modal, Spinner } from "react-bootstrap";
import { IoMdAddCircleOutline } from "react-icons/io";
import { profileService } from "../../../../services/user.service";
import { useEffect } from "react";
import {
  notifyFailed,
  notifySuccess,
} from "../../../../components/toast/Toast";
import { useAuth } from "../../../../hooks/useAuth";
import { Link } from "react-router-dom";

const WebSocialLinks = () => {
  const [showCloseWebsite, setShowCloseWebsite] = useState(false);
  const [showCloseSocialLink, setShowCloseSocialLink] = useState(false);
  const [error, setShowError] = useState(null);
  const { user } = useAuth();
  const [loading, setLoading] = useState(false);
  const [websites, setWebsites] = useState([]);
  const [socialLinks, setSocialLinks] = useState([]);
  const [clickedIndex, setClickedIndex] = useState(-1);
  const [website, setWebsite] = useState({
    url: "",
  });
  console.log(website);
  const [socialLink, setSocialLink] = useState({
    type: "",
    url: "",
  });
  const handleOpenForm = (type) => {
    if (type === "web") {
      setShowCloseWebsite(!showCloseWebsite);
      setWebsite({ url: "" });
    } else {
      setShowCloseSocialLink(!showCloseSocialLink);
      setSocialLink({
        type: "",
        url: "",
      });
    }
    setClickedIndex(-1);
  };
  const handleWebInputChange = (event) => {
    let { name, value } = event.target;
    setWebsite({ [name]: value });
  };
  const handleSubmitWebsite = async (e) => {
    e.preventDefault();
    setLoading(true);
    if (clickedIndex >= 0) {
      try {
        const tempWebSiteLists = [...websites];
        tempWebSiteLists[clickedIndex] = website;
        const res = await profileService.updateProfileData(
          {
            websites: tempWebSiteLists,
          },
          user.id
        );
        setWebsites(res?.profile_data?.websites);
        notifySuccess("Website updated successfully");
        handleOpenForm("web");
      } catch (err) {
        notifyFailed(err.message ?? "Failed Occur");
        return err;
      } finally {
        setLoading(false);
      }
    } else {
      try {
        const res = await profileService.updateProfileData(
          {
            websites: [website, ...websites],
          },
          user.id
        );
        setWebsites(res?.profile_data?.websites);
        notifySuccess("Website updated successfully");
        handleOpenForm("web");
      } catch (err) {
        notifyFailed(err.message ?? "Failed Occur");
        return err;
      } finally {
        setLoading(false);
      }
    }
  };

  const handleSetWeb = (index) => {
    setShowCloseWebsite(!showCloseWebsite);
    setClickedIndex(index);
    const clikedWeb = websites[index];
    setWebsite(clikedWeb);
  };

  const handleDeleteWeb = async (index) => {
    try {
      const filteredWebsites = websites.filter((element, i) => i !== index);
      const res = await profileService.updateProfileData(
        {
          websites: filteredWebsites,
        },
        user.id
      );
      setWebsites(res?.profile_data?.websites);
      notifySuccess("Website deleted successfully");
    } catch (err) {
      notifyFailed("Cannot delete it, try it later on");
      return err;
    } finally {
    }
  };
  const handleSocialInputChange = (event) => {
    let { name, value } = event.target;
    setSocialLink((previous) => ({ ...previous, [name]: value }));
  };
  const handleSubmitSocialLink = async (e) => {
    e.preventDefault();
    setLoading(true);
    if (clickedIndex >= 0) {
      try {
        const tempSocialLinks = [...socialLinks];
        tempSocialLinks[clickedIndex] = socialLink;
        const res = await profileService.updateProfileData(
          {
            socialLinks: tempSocialLinks,
          },
          user.id
        );
        setSocialLinks(res?.profile_data?.socialLinks);
        notifySuccess("Social link updated successfully");
        handleOpenForm("social");
      } catch (err) {
        notifyFailed(err.message ?? "Failed Occur");
        return err;
      } finally {
        setLoading(false);
      }
    } else {
      try {
        const res = await profileService.updateProfileData(
          {
            socialLinks: [socialLink],
          },
          user.id
        );
        setSocialLinks(res?.profile_data?.socialLinks);
        notifySuccess("Social created successfully");
        handleOpenForm("social");
      } catch (err) {
        console.log(socialLink);
        console.log(socialLinks);
        console.log(err);
        notifyFailed(err.message ?? "Failed Occur");
        return err;
      } finally {
        setLoading(false);
      }
    }
  };
  const handleSetSocialLink = (index) => {
    setShowCloseSocialLink(!showCloseSocialLink);
    setClickedIndex(index);
    const clikedSocialLink = socialLinks[index];
    setSocialLink(clikedSocialLink);
  };
  const handleDeleteSocialLInk = async (index) => {
    try {
      const filteredSocialLinks = socialLinks.filter(
        (element, i) => i !== index
      );
      const res = await profileService.updateProfileData(
        {
          socialLinks: filteredSocialLinks,
        },
        user.id
      );
      setSocialLinks(res?.profile_data?.socialLinks);
      notifySuccess("Social link deleted successfully");
    } catch (err) {
      notifyFailed("Cannot delete it, try it later on");
      return err;
    } finally {
    }
  };
  const fetchWebSitesAndSocialLinks = async () => {
    try {
      const res = await profileService.fetchProfileData();
      if (res.profile_data?._id) {
        setWebsites(res?.profile_data?.websites);
        setSocialLinks(res?.profile_data?.socialLinks);
      } else {
        setShowError("Please Complete Personal Information");
      }
    } catch (err) {
      return err;
    }
  };
  useEffect(() => {
    fetchWebSitesAndSocialLinks();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <div className="m-2 p-2">
      <div className="row d-flex justify-content-left">
        <h4 className="text-dark">{error ? error : "Websites"}</h4>
      </div>
      {!error && (
        <>
          {websites?.map((ele, index) => (
            <div
              key={index}
              className="d-flex justify-content-between align-items-center p-2"
            >
              <div className="col-md-10 col-xs-12">
                <div className="d-flex">
                  <div className="my-button">
                    <a href={ele.url} target="_blank" rel="noreferrer">
                      {ele.url}
                    </a>
                  </div>
                </div>
              </div>

              <div className="col-md-2 col-xs-12">
                <div className="card-header-toolbar  d-flex justify-content-end">
                  <Dropdown>
                    <Link to="#">
                      <Dropdown.Toggle as="span">
                        <i
                          className="material-symbols-outlined md-18 action-button"
                          style={{ fontSize: "28px" }}
                        >
                          more_horiz
                        </i>
                      </Dropdown.Toggle>
                    </Link>
                    <Dropdown.Menu className="dropdown-menu-right">
                      <Dropdown.Item
                        href="#"
                        className="d-flex align-items-center"
                        onClick={() => handleSetWeb(index)}
                      >
                        <i className="material-symbols-outlined me-2 md-18">
                          edit
                        </i>
                        Edit
                      </Dropdown.Item>
                      <Dropdown.Item
                        href="#"
                        className="d-flex align-items-center"
                        onClick={() => handleDeleteWeb(index)}
                      >
                        <i className="material-symbols-outlined me-2 md-18">
                          delete
                        </i>
                        Delete
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
              </div>
            </div>
          ))}
          <div className="row d-flex  justify-content-left mb-3 mt-2">
            <div
              className="col-md-4 col-xs-12 my-button"
              onClick={() => handleOpenForm("web")}
            >
              <IoMdAddCircleOutline className="m-1" size={25} />
              <span>Add a website</span>
            </div>
          </div>
          {showCloseWebsite && (
            <Modal
              show={showCloseWebsite}
              onHide={() => handleOpenForm("web")}
              size="md"
            >
              <Modal.Header closeButton>
                <Modal.Title>Edit Website</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <form onSubmit={handleSubmitWebsite}>
                  <div className="row  mb-1 d-flex align-items-center">
                    <div className="form-group col-xs-12">
                      <label htmlFor="country">WebSite</label>
                      <input
                        required
                        type="url"
                        name="url"
                        className="form-control"
                        placeholder="Url"
                        id="other_detail_label"
                        value={website.url}
                        onChange={handleWebInputChange}
                      />
                    </div>
                  </div>
                  <div className="d-flex">
                    <div className="col-md-2 col-xs-6">
                      <button className="btn btn-danger" type="submit">
                        {loading ? (
                          <Spinner animation="border" size="sm" />
                        ) : (
                          "Send"
                        )}
                      </button>
                    </div>
                  </div>
                </form>
              </Modal.Body>
            </Modal>
          )}
          <div className="row d-flex justify-content-left">
            <h4 className="text-dark">Social Media Links</h4>
          </div>
          {socialLinks?.map((ele, index) => (
            <div
              key={index}
              className="d-flex justify-content-between align-items-center p-2"
            >
              <div className="col-md-10 col-xs-12">
                <div className="my-button">
                  <div>
                    <a href={ele.url} target="_blank" rel="noreferrer">
                      {ele.url}
                    </a>
                    <small className="text-muted">{ele.type}</small>
                  </div>
                </div>
              </div>

              <div className="col-md-2 col-xs-12">
                <div className="card-header-toolbar  d-flex justify-content-end">
                  <Dropdown>
                    <Link to="#">
                      <Dropdown.Toggle as="span">
                        <i
                          className="material-symbols-outlined md-18 action-button"
                          style={{ fontSize: "28px" }}
                        >
                          more_horiz
                        </i>
                      </Dropdown.Toggle>
                    </Link>
                    <Dropdown.Menu className="dropdown-menu-right">
                      <Dropdown.Item
                        href="#"
                        className="d-flex align-items-center"
                        onClick={() => handleSetSocialLink(index)}
                      >
                        <i className="material-symbols-outlined me-2 md-18">
                          edit
                        </i>
                        Edit
                      </Dropdown.Item>
                      <Dropdown.Item
                        href="#"
                        className="d-flex align-items-center"
                        onClick={() => handleDeleteSocialLInk(index)}
                      >
                        <i className="material-symbols-outlined me-2 md-18">
                          delete
                        </i>
                        Delete
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
              </div>
            </div>
          ))}
          <div className="row d-flex  justify-content-left mb-3 mt-2">
            <div
              className="col-md-4 col-xs-12 my-button"
              onClick={() => handleOpenForm("social")}
            >
              <IoMdAddCircleOutline className="m-1" size={25} />
              <span>Add a social media</span>
            </div>
          </div>
          {showCloseSocialLink && (
            <Modal
              show={showCloseSocialLink}
              onHide={() => handleOpenForm("social")}
              size="md"
            >
              <Modal.Header closeButton>
                <Modal.Title>Edit social media</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <form onSubmit={handleSubmitSocialLink}>
                  <div className="row  mb-1 d-flex align-items-center">
                    <div className="form-group col-xs-12">
                      <label htmlFor="Type">Type</label>
                      <select
                        required
                        className="form-select"
                        name="type"
                        aria-label="Default select example"
                        value={socialLink.type}
                        onChange={handleSocialInputChange}
                      >
                        <option selected disabled value="">
                          --Please select--
                        </option>
                        <option value="facebook">Facebook</option>
                        <option value="youtube">Youtube</option>
                        <option value="twitter">Twitter</option>
                        <option value="linkedin">Linkedin</option>
                        <option value="skype">Skype</option>
                        <option value="instagram">Instagram</option>
                      </select>
                    </div>
                    <div className="form-group col-xs-12">
                      <label htmlFor="Url">Url</label>
                      <input
                        required
                        type="url"
                        name="url"
                        className="form-control"
                        placeholder="Url"
                        id="other_detail_label"
                        value={socialLink.url}
                        onChange={handleSocialInputChange}
                      />
                    </div>
                  </div>
                  <div className="d-flex">
                    <div className="col-md-2 col-xs-6">
                      <button className="btn btn-danger" type="submit">
                        {loading ? (
                          <Spinner animation="border" size="sm" />
                        ) : (
                          "Send"
                        )}
                      </button>
                    </div>
                  </div>
                </form>
              </Modal.Body>
            </Modal>
          )}
        </>
      )}
    </div>
  );
};

export default WebSocialLinks;
