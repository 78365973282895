import React, { useState } from "react";
import { Card, Col, Modal, Row, Spinner } from "react-bootstrap";
import Friendscard from "../../../../components/Friendscard";
import { useEffect } from "react";
import { FollowService } from "../../../../services/follow.service";
import { notifyFailed } from "../../../../components/toast/Toast";
import { useFollow } from "../../../../hooks/useFollow";
const ReceivedOrgRequest = () => {
  const { orgReqs, setOrgReqs } = useFollow();
  const [orgId, setOrgId] = useState(null);
  const [fetching, setFetching] = useState(false);
  const [confirmIds, setConfirmIds] = useState([]);
  const [rejectIds, setRejectIds] = useState([]);
  const [users, setUsers] = useState([]);
  const [showClose, setShowClose] = useState(false);
  useEffect(() => {
    getOrganizations();
    if (users.length === 0) {
      setShowClose(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetching, users]);

  const getOrganizations = async () => {
    try {
      const response = await FollowService.fetchFollowReqsforMyOrgs(
        "status=pending"
      );
      setOrgReqs(response?.organizations);
    } catch (err) {
      console.log(err);
    }
  };
  const handleView = (users) => {
    setUsers(users);
    setShowClose(!showClose);
  };
  const confirmRejectRequest = async (userId, status, index) => {
    try {
      setFetching(true);
      status !== "rejected"
        ? setConfirmIds([...confirmIds, index])
        : setRejectIds([...rejectIds, index]);

      await FollowService.approveVolunteer(
        orgId,
        `volunteer_id=${userId}&status=${status}`
      );

      setTimeout(() => {
        setUsers([...users.filter((ele) => ele.user.id !== userId)]);
      }, 600);
    } catch (err) {
      notifyFailed("Oops sorry!,try again later");
    } finally {
      setRejectIds(rejectIds.filter((i) => i !== index));
      setConfirmIds(confirmIds.filter((i) => i !== index));
      setFetching(false);
    }
  };
  return (
    <Row>
      <Col sm="12">
        <Card>
          <Card.Header className="d-flex justify-content-between">
            <div className="header-title">
              <h4 className="card-title">Received Requests</h4>
            </div>
          </Card.Header>
          <Card.Body>
            <Row>
              {orgReqs?.length > 0 ? (
                <>
                  {orgReqs?.map((ele, index) => (
                    <Col sm="6" md="3" key={index}>
                      <Friendscard className="friendscard">
                        <div className="user-img img-fluid flex-shrink-0">
                          {/* <img
                            src={ele.display_picture_media.img_url}
                            alt="story-img"
                            className="rounded-circle avatar-90"
                          /> */}
                        </div>
                        <div className="card-content text-center">
                          <h5 className="card-title">Org Id :{ele.org_id}</h5>
                          {/* <p className="card-text mt-2 text-capitalize badge rounded-pill bg-info">
                            {ele.organization_sector.name}
                          </p> */}
                          <p className="card-text">
                            {/* {truncateText(ele.short_intro, 8)} */}
                          </p>
                        </div>
                        <div className="button-row d-flex justify-content-between">
                          <button
                            type="submit"
                            className="btn btn-primary flex-grow-1 me-2"
                            onClick={() => {
                              handleView(ele.users);
                              setOrgId(ele.org_id);
                            }}
                          >
                            View
                          </button>
                        </div>
                      </Friendscard>
                    </Col>
                  ))}
                </>
              ) : (
                "Not New Requests"
              )}
            </Row>
          </Card.Body>
        </Card>
        {showClose && (
          <Modal
            show={showClose}
            onHide={() => setShowClose(!showClose)}
            size="md"
          >
            <Modal.Header closeButton>
              <Modal.Title>Users </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <ul className="request-list m-0 p-0">
                {users.map((usr, index) => (
                  <li
                    className="d-flex align-items-center  flex-wrap"
                    key={index}
                  >
                    <div className="user-img img-fluid flex-shrink-0">
                      <img
                        src={usr.user.display_picture.src}
                        alt="story-img"
                        className="rounded-circle avatar-40"
                      />
                    </div>
                    <div className="flex-grow-1 ms-3">
                      <h6>{usr.user.name}</h6>
                      {/* <p className="mb-0">4 friends</p> */}
                    </div>
                    <div className="d-flex align-items-center mt-2 mt-md-0">
                      <button
                        type="button"
                        className="btn btn-primary"
                        onClick={() =>
                          confirmRejectRequest(usr.user.id, "approved", index)
                        }
                      >
                        {confirmIds.includes(index) ? (
                          <Spinner animation="border" size="sm" />
                        ) : (
                          "Confirm"
                        )}
                      </button>
                      <button
                        type="submit"
                        className="btn btn-secondary"
                        onClick={() =>
                          confirmRejectRequest(usr.user.id, "rejected", index)
                        }
                        disabled={
                          rejectIds.includes(index) ||
                          confirmIds.includes(index)
                        }
                      >
                        {rejectIds.includes(index) ? (
                          <Spinner animation="border" size="sm" />
                        ) : (
                          "Reject"
                        )}
                      </button>
                    </div>
                  </li>
                ))}
              </ul>
            </Modal.Body>
          </Modal>
        )}
      </Col>
    </Row>
  );
};

export default ReceivedOrgRequest;
