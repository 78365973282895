import React from "react";
import DynamicTitleTranslation from "../../DynamicTitleTranslation";
import Media from "../../FileUpload/Media";

const DeceaseDetails = () => {
  return (
    <>
      <div className="d-flex justify-content-center">
        <h4 className="text-dark mt-4 ">Deceased Details</h4>
      </div>
      <div className="row">
        <Media
          label="Display Picture"
          style={`col-xs-12`}
          description="Recommended image size 330x360 px"
        />

        <div className="form-group ">
          <label htmlFor="title">Name in English</label>
          <input
            type="text"
            className="form-control"
            id="title"
            name="title"
            defaultValue=""
            placeholder="Name in English"
          />
        </div>

        <div className="form-group ">
          <label htmlFor="name">Title Translations</label>
          <DynamicTitleTranslation />
        </div>
        <div className="form-group col-md-6 col-xs-12">
          <label htmlFor="deadline">Date of birth</label>
          <input
            type="date"
            className="form-control"
            id="deadline"
            name="deadline"
            defaultValue=""
          />
        </div>
        <div className="form-group col-md-6 col-xs-12">
          <label htmlFor="deadline">Date of death</label>
          <input
            type="date"
            className="form-control"
            id="deadline"
            name="deadline"
            defaultValue=""
          />
        </div>
        <div className="form-group col-md-6 col-xs-12">
          <label htmlFor="deadline">Birth place</label>
          <input
            type="text"
            className="form-control"
            id="deadline"
            name="deadline"
            defaultValue=""
          />
        </div>
        <div className="form-group col-md-6 col-xs-12">
          <label htmlFor="deadline">Death place</label>
          <input
            type="text"
            className="form-control"
            id="deadline"
            name="deadline"
            defaultValue=""
          />
        </div>
        <div className="form-group col-md-6 col-xs-12">
          <label htmlFor="deadline">Lived places</label>
          <input
            type="text"
            className="form-control"
            id="deadline"
            name="deadline"
            defaultValue=""
            placeholder="Type and enter"
          />
        </div>
        <div className="form-group col-md-6 col-xs-12">
          <label htmlFor="religion">Religion</label>
          <select className="form-control" id="categories_id" name="religion">
            <option value="">Select Religion</option>
            <option value={2}>Hindhu</option>
            <option value={3}>Buddhist</option>
            <option value={4}>Christian</option>
            <option value={5}>Sikh</option>
            <option value={6}>Other</option>
          </select>
        </div>
        <div className="form-group col-md-6 col-xs-12">
          <label htmlFor="deadline">Positions</label>
          <input
            type="text"
            className="form-control"
            id="deadline"
            name="deadline"
            defaultValue=""
            placeholder="Type and enter"
          />
        </div>
      </div>
    </>
  );
};

export default DeceaseDetails;
