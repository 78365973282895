import { post } from 'jquery'
import React, { useState } from 'react'
import { Button, Col, Modal, Row } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import { useGlobal } from '../../hooks/useGlobal'
import { usePost } from '../../hooks/usePost'
import DynamicVideoDeatil from '../dashboard/components/DynamicVideoDeatil'

export default function VideoLinkModal({show,handleClose}) {
    const {handleDynamicDetails,post} =usePost()
    const{setMediaState}=useGlobal()
    const handleCancel=()=>{
      handleDynamicDetails("videos", []);
      handleClose()
      setMediaState({
        show: false,
        field: "",
        model: "organization",
        values: [],
      })
    }
  return (
    <Modal
        size="xl"
        className="fade"
        id="post-modal"
        onHide={handleClose}
        show={show}
      >
        <Modal.Header className="d-flex justify-content-between">
          <Modal.Title id="post-modalLabel">Attach Video Link</Modal.Title>
          <Link to="#" className="lh-1" onClick={handleClose}>
            <span className="material-symbols-outlined">close</span>
          </Link>
        </Modal.Header>
        <Modal.Body>
          <Row className='d-flex justify-content-center'>
            <Col className="col-8">
            <DynamicVideoDeatil videos={post.videos} handleVideoDetails={handleDynamicDetails}/>
            </Col>
            <Col className="col-8 d-flex justify-content-end">
            <Button onClick={handleCancel}>Cancel</Button>
            <Button onClick={handleClose}>Save</Button>
            </Col>
          </Row>
        </Modal.Body>
      </Modal>
  )
}
