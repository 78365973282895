import React from "react";
import { useOrganization } from "../../../../../hooks/useOrganization";
import DynamicOtherDetail from "../../DynamicOtherDetail";

export default function OtherDetails(props) {
  const {organization,handleDynamicDetails} =useOrganization()
  if (props.currentStep !== 4) {
    return null;
  }
  return (
    <>
      <div className="d-flex justify-content-center">
        <h4 className="text-dark mt-4 ">Other Details</h4>
      </div>
      {/* <label for="other-details">Enter here more details as many as you want</label> */}
      <DynamicOtherDetail otherDetails={organization.other_details} handleOtherDetails={handleDynamicDetails}/>
    </>
  );
}
