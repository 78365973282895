import React, { useState } from 'react'
import './style.css';
export default function TagInputField({tags, handleTags,className}) {
const [isKeyReleased, setIsKeyReleased] = useState(false);
const [input, setInput] = useState('');
const onKeyDown = (e) => {
    const { key } = e;
    const trimmedInput = input.trim();
  
    if (key === ',' && trimmedInput.length && !tags.includes(trimmedInput)) {
      e.preventDefault();
      handleTags([...tags,trimmedInput]);
      setInput('');
    }
  
    if (key === "Backspace" && !input.length && tags.length && isKeyReleased) {
      const tagsCopy = [...tags];
      const poppedTag = tagsCopy.pop();
      e.preventDefault();
      handleTags(tagsCopy);
      setInput(poppedTag);
    }
  
    setIsKeyReleased(false);
  };
  const onChange = (e) => {
    const { value } = e.target;
    setInput(value);
  };
  const onKeyUp = () => {
    setIsKeyReleased(true);
  }
  const deleteTag = (index) => {
    handleTags(tags.filter((tag, i) => i !== index))
  }
  return (
    <div className="tag-container">
        {tags.map((tag, index) => (
        <div className="tag">
            {tag}
            <button onClick={() => deleteTag(index)}>x</button>
        </div>
        ))}
    <input
        value={input}
        placeholder="Enter a tag"
        onKeyDown={onKeyDown}
        onKeyUp={onKeyUp}
        className={className}
        onChange={onChange}
        />
    </div>
  )
}