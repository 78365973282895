import React from 'react'

const SmartText = ({ text, length = 100 }) => {
    const [showLess, setShowLess] = React.useState(true);
  
    if (text.length < length) {
      return <p dangerouslySetInnerHTML={{
        __html: text,
      }}></p>;
    }
  
    return (
      <>
        <div
          dangerouslySetInnerHTML={{
            __html: showLess ? `${text.slice(0, length)}...` : text,
          }}
        ></div>
        <a
        className='d-flex justify-content-end'
          style={{ color: "blue", cursor: "pointer" }}
          onClick={() => setShowLess(!showLess)}
        >
          &nbsp;View {showLess ? "More" : "Less"}
        </a>
      </>
    );
  };
  export default SmartText