import React from "react";
import { Editor } from "react-draft-wysiwyg";
import { useCampaign } from "../../../../../hooks/useCampaign";

const BasicDetails = ({campaignCategories}) => {
  const {campaign,handleChange,error,onEditorStateChange} = useCampaign()
  const { editorState } = campaign.campaign_content;
  return (
    <>
      <div className="d-flex justify-content-center">
        <h4 className="text-dark mt-4 ">Basic Details</h4>
      </div>
      <div className="form-group ">
        <label htmlFor="title">Title in English</label>
        <input
          type="text"
          className={`form-control ${error.title ? 'is-invalid':''}`}
          id="title"
          name="title"
          placeholder="Title in English"
          value={campaign.title}
          onChange={handleChange}
        />
         {error.title && <span className="text-danger">Please enter title</span>}
      </div>
      <div className="form-group" >
        <label className="text-dark">
          Permalink / Slug * :
        </label>
        <input
          type="text"
          className="form-control blog_slug mt-2"
          id="slug"
          name="slug"
          value={campaign.slug}
          onChange={handleChange}
          disabled
        />
      </div>
      <div className="row">
        <div className="form-group col-md-6 col-xs-12">
          <label htmlFor="categories_id">Category</label>
          <select
            className={`form-control ${error.category_id ? 'is-invalid':''}`}
            id="categories_id"
            name="category_id"
            value={campaign.category_id}
            onChange={handleChange}
          >
            <option value="">Select Category</option>
            {campaignCategories.map((category)=>(<option value={category.id} key={`category-${category.id}`}>{category.title}</option>))}
          </select>
          {error.category_id && <span className="text-danger">Please select category</span>}
        </div>
        <div className="form-group col-md-6 col-xs-12">
        <label htmlFor="amount">Amount</label>
        <input
          type="number"
          className={`form-control ${error.amount ? 'is-invalid':''}`}
          id="amount"
          name="amount"
          placeholder="amount"
          value={campaign.amount}
          onChange={handleChange}
        />
        {error.amount && <span className="text-danger">Please enter campaign amount</span>}
        </div>
      </div>
      <div className="col-sm-12 mb-3 ">
            <div
              className={`form-control ${
                error.campaign_content ? "is-invalid" : ""
              }`}
              style={{ minHeight: 250 }}
            >
              <Editor
                editorState={editorState}
                onEditorStateChange={onEditorStateChange}
              />
            </div>
            {error.campaign_content && (
              <span className="text-danger ">Please enter content.</span>
            )}
          </div>
      <div className="form-group ">
        <label htmlFor="excerpt">Excerpt</label>
        <textarea
           className={`form-control ${
            error.excerpt ? "is-invalid" : ""
          }`}
          id="excerpt"
          name="excerpt"
          rows={5}
          placeholder="excerpt"
          value={campaign.excerpt}
          onChange={handleChange}
        />
        {error.excerpt && (
              <span className="text-danger ">Please enter excerpt content.</span>
            )}<br/>
        <small className="info-text">a short brief about campaign</small>
      </div>
    </>
  );
};

export default BasicDetails;