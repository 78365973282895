
const calculateDuration =(date)=>{

}
function timeDiffCalc(date,from=new Date()) {
    let diffInMilliSeconds = Math.abs(from - date) / 1000;

    // calculate days
    const months = Math.floor(diffInMilliSeconds / (86400*30));
    const days = Math.floor(diffInMilliSeconds / 86400);
    diffInMilliSeconds -= days * 86400;

    // calculate hours
    const hours = Math.floor(diffInMilliSeconds / 3600) % 24;
    diffInMilliSeconds -= hours * 3600;

    // calculate minutes
    const minutes = Math.floor(diffInMilliSeconds / 60) % 60;
    diffInMilliSeconds -= minutes * 60;
    
    // calculate secounds
    var secd = ~~diffInMilliSeconds % 60;
    var seconds = Math.ceil(secd);

    let difference = '';
    if(months > 0){
        difference = months === 1 ? `${months} month ago ` : `${days} months ago `;
    }else if (days > 0) {
      difference = days === 1 ? `${days} day ago ` : `${days} days ago `;
    }else if (hours > 0) {
        difference = hours === 1 ? `${hours} hour ago ` : `${hours} hours ago`;
    }else if (minutes > 0) {
        difference = minutes === 1 ? `${minutes} minute ago ` : `${minutes} minutes ago`;
    }else if (seconds > 0) {
        difference = seconds === 1 ? `${seconds} second ago ` : `${seconds} seconds ago`;
    }else{
        difference = 'now';
    }
    return difference;
  }
export {
 calculateDuration ,
 timeDiffCalc
} 