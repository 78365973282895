import React from "react";
import TagInputField from "../../../../../components/tag/TagInputField";
import { useCampaign } from "../../../../../hooks/useCampaign";
import Media from "../../FileUpload/Media";

const MetaDetails = () => {
  const {campaign,handleChange,handleDynamicDetails,error} = useCampaign()
  const handleTags=(value)=>{
    console.log(value)
    handleDynamicDetails('meta_tags',value)
  }
  return (
    <>
      <div className="d-flex justify-content-center">
        <h4 className="text-dark mt-4">Meta Details</h4>
      </div>
      <div className="row mt-2">
      <div className="form-group col-md-12 col-xs-12">
          <label htmlFor="meta_title">Meta Title</label>
          <input
            type="text"
            className="form-control"
            id="meta_title"
            name="meta_title"
            placeholder="Meta Title"
            value={campaign.meta_title}
            onChange={handleChange}
          />
      </div>
      <div className="form-group  col-12">
        <label htmlFor="seo_meta_keywords">Meta Tags</label>
        <div className="bootstrap-tagsinput">
        </div>
        <TagInputField 
          // className={`form-control ${error.seo_meta_keywords ? 'is-invalid':''}`}
          tags={campaign.meta_tags} 
          handleTags={handleTags}/>
          {/* {error.seo_meta_keywords && <div class="text-danger">
            Please enter meta keywords.
          </div>} */}
      </div>
      <div className="form-group">
        <label htmlFor="meta_description">Meta Description</label>
        <textarea
          className="form-control"
          id="meta_description"
          name="meta_description"
          rows={5}
          value={campaign.meta_description}
          onChange={handleChange}
        />
      </div>
      <div className="form-group col-md-12 col-xs-12">
          <label htmlFor="og_meta_title">Og Meta Title</label>
          <input
            type="text"
            className="form-control"
            id="og_meta_title"
            name="og_meta_title"
            value={campaign.og_meta_title}
            onChange={handleChange}
          />
        </div>
        <div className="form-group col-md-12 col-xs-12">
          <label htmlFor="og_meta_description">Og Meta Description</label>
          <textarea
            className="form-control"
            id="og_meta_description"
            name="og_meta_description"
            value={campaign.og_meta_description}
            onChange={handleChange}
            rows={5}
          />
        </div>
        <Media style="col-12" label="OG Meta Image" isSingleImg={true} selectedPhotoes={campaign.og_meta_image} fieldName={'og_meta_image'} model="campaign"/>
      </div>
    </>
  );
};

export default MetaDetails;