import React, { useEffect, useState } from "react";
import { profileService } from "../../../../services/user.service";
import { Dropdown, Modal, Spinner } from "react-bootstrap";
import { Link } from "react-router-dom";
import { DATE_OPTIONS } from "../../../../utilities/constants";
import { IoMdAddCircleOutline } from "react-icons/io";
import {
  notifyFailed,
  notifySuccess,
} from "../../../../components/toast/Toast";
import { useAuth } from "../../../../hooks/useAuth";

const WorkPlaces = () => {
  const { user } = useAuth();
  const [workPlaceLists, setWorkPlaceLists] = useState([]);
  const [error, setShowError] = useState(null);

  const [clickedIndex, setClickedIndex] = useState(-1);
  const [showClose, setShowClose] = useState(false);
  const [loading, setLoading] = useState(false);
  const [workPlace, setWorkPlace] = useState({
    companyName: "",
    position: "",
    currentlyWorking: false,
    from: "",
    to: "",
    city: "",
    description: "",
  });
  const fetchWorkPlaces = async () => {
    try {
      const res = await profileService.fetchProfileData();
      if (res?.profile_data?._id) {
        setWorkPlaceLists(res?.profile_data?.workPlacesInfo);
      } else {
        setShowError("Please Complete Personal Information");
      }
    } catch (err) {
      return err;
    }
  };
  useEffect(() => {
    fetchWorkPlaces();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleShowClose = () => {
    setShowClose(!showClose);
    setWorkPlace({});
    setClickedIndex(-1);
  };
  const handleSetWorkPlace = (index) => {
    handleShowClose();
    setClickedIndex(index);
    const clikedWorkPlace = workPlaceLists[index];
    setWorkPlace(clikedWorkPlace);
  };
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (name === "currentlyWorking") {
      setWorkPlace((previousState) => ({
        ...previousState,
        [name]: !workPlace.currentlyWorking,
      }));
    } else {
      setWorkPlace((previousState) => ({ ...previousState, [name]: value }));
    }
  };
  const handleSend = async (e) => {
    e.preventDefault();
    setLoading(true);
    if (clickedIndex >= 0) {
      try {
        const tempWorkPlaceLists = [...workPlaceLists];
        tempWorkPlaceLists[clickedIndex] = workPlace;
        const res = await profileService.updateProfileData(
          {
            workPlacesInfo: [...tempWorkPlaceLists],
          },
          user.id
        );
        setWorkPlaceLists(res?.profile_data?.workPlacesInfo);
        notifySuccess("Your information updated successfully");
      } catch (err) {
        notifyFailed(err.message ?? "Failed Occur");
        return err;
      } finally {
        setLoading(false);
        handleShowClose();
      }
    } else {
      try {
        const res = await profileService.updateProfileData(
          {
            workPlacesInfo: [workPlace, ...workPlaceLists],
          },
          user.id
        );
        setWorkPlaceLists(res?.profile_data?.workPlacesInfo);
        notifySuccess("Your information created successfully");
      } catch (err) {
        notifyFailed(err.message ?? "Failed Occur");
        return err;
      } finally {
        setLoading(false);
        handleShowClose();
      }
    }
  };
  const handleDelete = async (index) => {
    try {
      const filteredWorkPlaces = workPlaceLists.filter(
        (element, i) => i !== index
      );
      const res = await profileService.updateProfileData(
        {
          workPlacesInfo: filteredWorkPlaces,
        },
        user.id
      );
      setWorkPlaceLists(res?.profile_data?.workPlacesInfo);
      notifySuccess("Your work place deleted successfully");
    } catch (err) {
      notifyFailed("Cannot delete it, try it later on");
      return err;
    } finally {
    }
  };
  return (
    <div className="m-2 p-2">
      <div className="row d-flex justify-content-left">
        <h4 className="text-dark">{error ? error : "Work Places"}</h4>
      </div>
      {!error && (
        <>
          {workPlaceLists?.map((ele, index) => (
            <div
              key={index}
              className="d-flex justify-content-between align-items-center p-2"
            >
              <div className="col-md-10 col-xs-12">
                <div className="d-flex">
                  <div>
                    <h5>
                      {ele?.position} at {ele?.companyName}
                    </h5>
                    <small className="text-muted">
                      {ele?.currentlyWorking ? (
                        <>
                          {new Date(ele.from).toLocaleDateString(
                            "en-US",
                            DATE_OPTIONS
                          )}
                          - present
                        </>
                      ) : (
                        <>
                          {new Date(ele.from).toLocaleDateString(
                            "en-US",
                            DATE_OPTIONS
                          )}
                          -
                          {new Date(ele.to).toLocaleDateString(
                            "en-US",
                            DATE_OPTIONS
                          )}
                        </>
                      )}
                    </small>
                  </div>
                </div>
              </div>

              <div className="col-md-2 col-xs-12">
                <div className="card-header-toolbar  d-flex justify-content-end">
                  <Dropdown>
                    <Link to="#">
                      <Dropdown.Toggle as="span">
                        <i
                          className="material-symbols-outlined md-18 action-button"
                          style={{ fontSize: "28px" }}
                        >
                          more_horiz
                        </i>
                      </Dropdown.Toggle>
                    </Link>
                    <Dropdown.Menu className="dropdown-menu-right">
                      <Dropdown.Item
                        href="#"
                        className="d-flex align-items-center"
                        onClick={() => handleSetWorkPlace(index)}
                      >
                        <i className="material-symbols-outlined me-2 md-18">
                          edit
                        </i>
                        Edit
                      </Dropdown.Item>
                      <Dropdown.Item
                        href="#"
                        className="d-flex align-items-center"
                        onClick={() => handleDelete(index)}
                      >
                        <i className="material-symbols-outlined me-2 md-18">
                          delete
                        </i>
                        Delete
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
              </div>
            </div>
          ))}
          <div className="d-flex  justify-content-left mb-3 mt-2">
            <div
              className="col-md-4 col-xs-12 my-button"
              onClick={handleShowClose}
            >
              <IoMdAddCircleOutline className="m-1" size={25} />
              <span>Add a Workplace</span>
            </div>
          </div>
          {showClose && (
            <Modal show={showClose} onHide={handleShowClose} size="xl">
              <Modal.Header closeButton>
                <Modal.Title>Edit Work Place</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <form onSubmit={handleSend}>
                  <div className="row p-2  d-flex align-items-center">
                    <div className="form-group col-md-6 col-xs-12">
                      <label htmlFor="other_detail_label">Company Name</label>
                      <input
                        type="text"
                        name="companyName"
                        className="form-control"
                        id="other_detail_label"
                        required
                        value={workPlace.companyName}
                        onChange={handleInputChange}
                      />
                    </div>
                    <div className="form-group col-md-6 col-xs-12">
                      <label htmlFor="url">Position</label>
                      <textarea
                        className="form-control"
                        id="other_detail_value"
                        name="position"
                        value={workPlace.position}
                        rows={1}
                        required
                        data-container-id={2}
                        onChange={handleInputChange}
                      />
                    </div>
                    <div className="form-group col-xs-12">
                      <label htmlFor="city">City</label>
                      <input
                        type="text"
                        name="city"
                        required
                        className="form-control"
                        id="other_detail_label"
                        value={workPlace.city}
                        onChange={handleInputChange}
                      />
                    </div>
                    <div className="form-group col-xs-12">
                      <label htmlFor="description">Description</label>
                      <textarea
                        name="description"
                        className="form-control"
                        id="other_detail_value"
                        value={workPlace.description}
                        rows={2}
                        required
                        data-container-id={2}
                        onChange={handleInputChange}
                      />
                    </div>
                    <div className="form-check col-xs-12">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        name="currentlyWorking"
                        value="zoneCeylon"
                        onChange={handleInputChange}
                        id="checkbox"
                        checked={workPlace.currentlyWorking}
                      />
                      <label
                        className="form-check-label"
                        for="flexCheckDefault"
                      >
                        I am currently working here
                      </label>
                    </div>
                    <div className="form-group col-md-6 col-xs-12">
                      <label htmlFor="from">From</label>
                      <input
                        type="date"
                        name="from"
                        className="form-control"
                        id="other_detail_label"
                        required
                        value={workPlace.from}
                        onChange={handleInputChange}
                        placeholder="Select"
                      />
                    </div>
                    {!workPlace.currentlyWorking && (
                      <div className="form-group col-md-6 col-xs-12">
                        <label htmlFor="to">To</label>
                        <input
                          type="date"
                          name="to"
                          required={!workPlace.currentlyWorking ? true : false}
                          className="form-control"
                          id="other_detail_label"
                          value={workPlace.to}
                          onChange={handleInputChange}
                          placeholder="Select"
                        />
                      </div>
                    )}
                  </div>
                  <div className="d-flex">
                    <div className="col-md-2 col-xs-6">
                      <button className="btn btn-danger" type="submit">
                        {loading ? (
                          <Spinner animation="border" size="sm" />
                        ) : (
                          "Send"
                        )}
                      </button>
                    </div>
                  </div>
                </form>
              </Modal.Body>
            </Modal>
          )}
        </>
      )}
    </div>
  );
};

export default WorkPlaces;
