import React from "react";
import Media from "../../FileUpload/Media";
import { Editor } from "react-draft-wysiwyg";
import "../../../../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

const ObituaryDetails = () => {
  return (
    <>
      <div className="d-flex justify-content-center">
        <h4 className="text-dark mt-4 ">Obituary Details</h4>
      </div>
      <div className="row">
        <div className="form-group col-md-6 col-xs-12">
          <label htmlFor="type">Type</label>
          <select className="form-control" id="categories_id" name="type">
            <option value="">Select Type</option>
            <option value={2}>Obituary</option>
            <option value={3}>Remembrance</option>
            <option value={4}>Thanks note</option>
            <option value={5}>Remembrance and thanks note</option>
          </select>
        </div>
        <div className="form-group col-md-6 col-xs-12">
          <label htmlFor="customeTitle">Custome Title</label>
          <input
            type="text"
            className="form-control"
            id="title"
            name="title"
            defaultValue=""
            placeholder="Title"
          />
        </div>
        <div className="form-group col-xs-12">
          <label htmlFor="customeTitle">Content</label>
          <div style={{ minHeight: 250 }}>
            <Editor />
          </div>
        </div>
        <div className="form-group col-md-6 col-xs-12">
          <label htmlFor="customeTitle">Video URL</label>
          <input
            type="url"
            className="form-control"
            id="title"
            name="title"
            defaultValue=""
            placeholder="Video URL"
          />
        </div>
        <div className="form-group col-md-6 col-xs-12">
          <label htmlFor="publishDate">Publish Date</label>
          <input
            type="date"
            className="form-control"
            id="title"
            name="title"
            defaultValue=""
          />
        </div>
        <Media
          label="Gallery Photos"
          style={`col-xs-12`}
          description="Recommended image size 1920x1280 px"
        />
      </div>
    </>
  );
};

export default ObituaryDetails;
