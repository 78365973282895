import React from "react";
import { Card, Col } from "react-bootstrap";
import { Link } from "react-router-dom";
import "./imageCard.css";
const ImageCard = ({ size, img, onImageClick, type }) => {
  return (
    <Col sm={size ? size : "4"} xs={6}>
      <Card>
        <Card.Body>
          <div className={`event-images position-relative `}>
            <Link to="#" onClick={onImageClick}>
              <div className="image-wrapper">
                <img
                  loading="lazy"
                  src={img}
                  className="img-fluid"
                  alt="media"
                />
                {type && (
                  <div className="play-icon">
                    <i className="fa fa-play"></i>
                  </div>
                )}
              </div>
            </Link>
          </div>
        </Card.Body>
      </Card>
    </Col>
  );
};

export default ImageCard;
