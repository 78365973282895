import React, { Fragment, useEffect, useState } from "react";
import { Button, Form } from "react-bootstrap";
import { useGlobal } from "../../../hooks/useGlobal";

export default function DynamicVideoDeatil({ videos, handleVideoDetails }) {
  const [inputFields, setInputFields] = useState([
    { video_url: "", preview_picture: "" },
  ]);
  const { mediaState } = useGlobal();
  useEffect(() => {
    // console.log(mediaState.model && mediaState.model.includes('video_detail'), mediaState.model)
    if (mediaState.model && mediaState.model.includes("video_detail")) {
      let index = mediaState.model.split("-")[1];
      handlePreviewImage(index, mediaState.values);
    }
  }, [mediaState.values]);
  useEffect(() => {
    videos?.length > 0 && setInputFields(videos);
  }, [videos]);

  const handleAddFields = () => {
    console.log("click ");
    const values = [...inputFields];
    values.push({ video_url: "", preview_picture: "", video_title: "" });
    handleVideoDetails("videos", values);
    // setInputFields(values);
  };
  const handlePreviewImage = (index, images) => {
    const values = [...inputFields];
    values[index].preview_picture = images;
    handleVideoDetails("videos", values);
    // setInputFields(values);
  };
  const handleRemoveFields = (index) => {
    const values = [...inputFields];
    values.splice(index, 1);
    handleVideoDetails("videos", values);
    // setInputFields(values);
  };

  const handleInputChange = (index, event) => {
    const values = [...inputFields];
    const { name } = event.target;
    values[index][name] = event.target.value;
    // setInputFields(values);
    handleVideoDetails("videos", values);
  };

  const { toggleMedia,setSelectSingleImage } = useGlobal();

  return (
    <>
      <div className="form-row">
        {inputFields.map((inputField, index) => (
          <Fragment key={`${inputField}~${index}`}>
            <div className="row p-3 mb-3 d-flex align-items-center">
              <div className="col-12">
                <div className="card border border-primary">
                  <div className="card-body">
                    <h5 className="card-title"> Preview Picture</h5>
                    {inputField.preview_picture?.length > 0 && (
                      <>
                        {inputField.preview_picture.map((img) => (
                          <div className="img-wrapper">
                            <img
                              src={img.src}
                              className="select-img"
                              alt="video_preview_picture"
                            />
                          </div>
                        ))}
                      </>
                    )}
                    <small>
                      Recommended image size 330x360 px. Acceptable image
                      formats: "jpg", "jpeg", "png", "gif", "webp"
                    </small>
                    <div className="col-6 mt-2">
                      <Button
                        variant="primary"
                        className="btn-style1"
                        onClick={() =>
                          {
                            setSelectSingleImage(true)
                            toggleMedia(
                            "preview_picture",
                            null,
                            `video_detail-${index}`
                          )}
                        }
                      >
                        {inputField.preview_picture?.length > 0
                          ? "Change Image"
                          : "Attach Files"}
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
              <div className="form-group col-12">
                <label htmlFor="video_title">Title</label>
                <input
                  type="text"
                  name="video_title"
                  className="form-control"
                  id="title"
                  value={inputField.video_title}
                  placeholder="Title"
                  onChange={(event) => handleInputChange(index, event)}
                />
              </div>
              <div className="form-group col-12">
                <label htmlFor="video_url">Video URL</label>
                <input
                  type="url"
                  name="video_url"
                  className="form-control"
                  id="video_url"
                  value={inputField.video_url}
                  placeholder="Video URL"
                  onChange={(event) => handleInputChange(index, event)}
                />
              </div>

              {index > 0 ? (
                <div className="col-2 col-xs-4">
                  <button
                    className="btn btn-danger"
                    type="button"
                    disabled={index === 0}
                    onClick={() => handleRemoveFields(index)}
                  >
                    Remove
                  </button>
                </div>
              ) : null}
            </div>
          </Fragment>
        ))}
      </div>
      <div className="row d-flex align-items-center justify-content-start">
        <div
          className="col-md-2 col-xs-12 btn btn-success"
          onClick={() => handleAddFields()}
        >
          Add More
        </div>
      </div>

      {/* <br />
      <pre>
              {JSON.stringify(inputFields, null, 2)}
            </pre> */}
    </>
  );
}
