import React from "react";
import { useEffect } from "react";
import { donationCompaignService } from "../../../../services/donationCompaign.service";
import { useState } from "react";
import CampaignCard from "../../../../components/Campaign/CampaignCard";

const CampaignTab = ({ orgId }) => {
  const [data, setData] = useState([]);
  const fetchCompaignsforOrg = async () => {
    try {
      const result = await donationCompaignService.fetchIndividualCampaigns(
        `campaigns?owner_type=organization&owner_id=${orgId}`
      );

      console.log(result);
      setData(result);
    } catch (err) {
      return err;
    }
  };
  useEffect(() => {
    orgId && fetchCompaignsforOrg();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orgId]);
  return <CampaignCard campaign={data} />;
};

export default CampaignTab;
