import React, { useState } from "react";
import { Link, useParams } from "react-router-dom";
import { Container, Row, Col, Nav, Tab, Button } from "react-bootstrap";
import Card from "../../../components/Card";
import imgp08 from "../../../assets/images/icon/08.png";
import imgp09 from "../../../assets/images/icon/09.png";
import imgp10 from "../../../assets/images/icon/10.png";
import imgp13 from "../../../assets/images/icon/13.png";
import "./donationCompaign.css";
import { useEffect } from "react";
import { donationCompaignService } from "../../../services/donationCompaign.service";
import ActivityTab from "./ActivityTab";
import ImageCard from "../../../components/Image/ImageCard";
import LightBox from "../../../components/lightbox/lightBox";
import { useGlobal } from "../../../hooks/useGlobal";
import "../../../assets/css/style.css";
import { RxActivityLog } from "react-icons/rx";
import { FaQuestion } from "react-icons/fa";
import { MdOutlineDescription } from "react-icons/md";
import { VscFileMedia } from "react-icons/vsc";
import Spiner from "../../../components/spinner/Spiner";
import DonorInfo from "./DonorInfo";
import { useAuth } from "../../../hooks/useAuth";
const SIngleDonationView = () => {
  const {user} = useAuth()
  const params = useParams();
  const { openLightbox } = useGlobal();
  const [activeTab, setActiveTab] = useState("first"); // State to track the active tab
  const [singleCampaign, setSingleCampaign] = useState({});
  const [expirationDate, setExpirationDate] = useState(null);
  const [remainingTime, setRemainingTime] = useState(null);
  const [expired, setExpired] = useState(false);
  const [mediaLists, setMediaLists] = useState([]);
  console.log(mediaLists);
  const handleTabClick = (tabKey) => {
    setActiveTab(tabKey);
  };
  useEffect(() => {
    if (expirationDate) {
      const timer = setInterval(() => {
        const timeRemaining = getRemainingTime();
        setRemainingTime(timeRemaining);
        if (timeRemaining <= 0) {
          setExpired(true);
          clearInterval(timer);
        }
      }, 1000);

      return () => {
        clearInterval(timer);
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [expirationDate]);
  function getRemainingTime() {
    const currentTime = new Date().getTime();
    const remaining = expirationDate - currentTime;
    return Math.max(remaining, 0);
  }
  function formatTime(time) {
    const days = Math.floor(time / (1000 * 60 * 60 * 24));
    const hours = Math.floor((time % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    const minutes = Math.floor((time % (1000 * 60 * 60)) / (1000 * 60));
    const seconds = Math.floor((time % (1000 * 60)) / 1000);

    return (
      <>
        {time ? (
          <div className="d-flex">
            <div className="single-count-down-item">
              <span className="time">{days}</span>
              <span className="label"> days</span>
            </div>
            <div className="single-count-down-item">
              <span className="time">{hours}</span>
              <span className="label">hours</span>
            </div>
            <div className="single-count-down-item">
              <span className="time">{minutes}</span>
              <span className="label">min</span>
            </div>
            <div className="single-count-down-item">
              <span className="time">{seconds}</span>
              <span className="label">sec</span>
            </div>
          </div>
        ) : (
          <>
            {expired ? (
              <div className="expired-container">Expired</div>
            ) : (
              <Spiner />
            )}
          </>
        )}
      </>
    );
  }
  const fetchSingleCampaign = async () => {
    try {
      const result = await donationCompaignService.fetchSingleCompaign(
        params.slug
      );
      setSingleCampaign(result);
      setExpirationDate(new Date(result.deadline));
      setMediaLists(result?.gallery_images);
    } catch (err) {
      return err;
    }
  };
  useEffect(() => {
    fetchSingleCampaign();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Container>
      <Row>
        <Col lg="4">
          <Row>
            <Card>
              <div className="top-bg-image">
                <img
                  loading="lazy"
                  src={singleCampaign?.image?.large_img_url}
                  className="img-fluid"
                  alt="Responsive"
                />
              </div>
              <Card.Body className="">
               
                <div className="status mt-1 ms-3">
                  <div className={`d-flex justify-content-end `}>
                    <span className={` badge-label badge-container camp-status ${singleCampaign.status}`} style={{lineHeight:0.75}}>
                      {singleCampaign.status ? <>{singleCampaign.status}</> : "not specified"}
                    </span>
                  </div>
                </div>
                <h2 className="text-center">{singleCampaign?.title}</h2>
              </Card.Body>
            </Card>
          </Row>
          <Row>
            <Card>
              <Card.Body className="text-center">
                <div>
                  <ul className=" d-flex ms-4 list-inline justify-content-center">
                    <li className="pe-3">
                      <Link to="#">
                        <img
                          loading="lazy"
                          src={imgp08}
                          className="img-fluid rounded"
                          alt="facebook"
                        />
                      </Link>
                    </li>
                    <li className="pe-3">
                      <Link to="#">
                        <img
                          loading="lazy"
                          src={imgp09}
                          className="img-fluid rounded"
                          alt="Twitter"
                        />
                      </Link>
                    </li>
                    <li className=" pe-3">
                      <Link to="#">
                        <img
                          loading="lazy"
                          src={imgp10}
                          className="img-fluid rounded"
                          alt="Instagram"
                        />
                      </Link>
                    </li>
                    <li className="pe-3">
                      <Link to="#">
                        <img
                          loading="lazy"
                          src={imgp13}
                          className="img-fluid rounded"
                          alt="linkedin"
                        />
                      </Link>
                    </li>
                  </ul>
                </div>

                <div className="d-flex d-flex justify-content-between">
                  Campaign Type:
                  <small>{singleCampaign?.campaign_type}</small>
                </div>
                {singleCampaign?.campaign_type === "Organization" && (
                  <div className="d-flex d-flex justify-content-between">
                    Organization Name:
                    <small>{singleCampaign?.organization?.name}</small>
                  </div>
                )}

                <div className="d-flex d-flex justify-content-between">
                  Deadline:
                  <small>
                    {singleCampaign.deadline &&
                      new Date(singleCampaign.deadline).toDateString()}
                  </small>
                </div>
                <div className="d-flex justify-content-center">
                  {formatTime(remainingTime)}
                </div>
                <div className="d-flex mt-2">
                  <div className="w-100">
                    <div className="progress" style={{ height: "15px" }}>
                      <div
                        className="progress-bar bg-success"
                        role="progressbar"
                        style={{
                          width: `${singleCampaign.raised_percentage}%`,
                        }}
                        aria-valuenow="75"
                        aria-valuemin="0"
                        aria-valuemax="100"
                      >
                        {singleCampaign.raised_percentage}%
                      </div>
                    </div>
                  </div>
                </div>
                <p>
                  $
                  {singleCampaign.raised_amount
                    ? singleCampaign.raised_amount
                    : 0}{" "}
                  Raised of ${singleCampaign.required_amount} Goal
                </p>
               { singleCampaign.user?.id === user?.id ? 
               (<div className="d-flex justify-content-center">
                 <Link to={`/campaigns/${singleCampaign.slug}/edit`} className="btn btn-success w-100">Edit</Link>
                </div>):
                (<div className="d-flex justify-content-center">
                  <Link className="btn btn-primary w-100">Donate Now</Link>
                </div>)}
              </Card.Body>
            </Card>
          </Row>
          <Row>
            <DonorInfo />
          </Row>
        </Col>
        <Col lg="8">
          <Tab.Container defaultActiveKey="f1">
            <Card>
              <div className="d-flex profile-feed-items  align-items-center justify-content-around">
                <Nav.Item
                  as="li"
                  className={`p-0 ${activeTab === "first" ? "active-tab" : ""}`}
                >
                  <Nav.Link
                    eventKey="f1"
                    href="#description"
                    onClick={() => handleTabClick("first")}
                  >
                    <MdOutlineDescription size={20} className="icon" />
                    <span className="mobile-text  ms-3 user-tabs ">
                      Description
                    </span>
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item
                  as="li"
                  className={`p-0 ${
                    activeTab === "second" ? "active-tab" : ""
                  }`}
                >
                  <Nav.Link
                    className=""
                    eventKey="f2"
                    href="#medias"
                    onClick={() => handleTabClick("second")}
                  >
                    <VscFileMedia size={20} className="icon" />
                    <span className="mobile-text  ms-3 user-tabs ">Medias</span>
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item
                  as="li"
                  className={`p-0 ${activeTab === "third" ? "active-tab" : ""}`}
                >
                  <Nav.Link
                    className=""
                    eventKey="f4"
                    href="#faqs"
                    onClick={() => handleTabClick("third")}
                  >
                    <FaQuestion size={20} className="icon" />
                    <span className="mobile-text  ms-3 user-tabs ">FAQ</span>
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item
                  as="li"
                  className={`p-0 ${
                    activeTab === "fourth" ? "active-tab" : ""
                  }`}
                >
                  <Nav.Link
                    className=""
                    eventKey="f5"
                    href="#activities"
                    onClick={() => handleTabClick("fourth")}
                  >
                    <RxActivityLog size={20} className="icon" />
                    <span className="mobile-text  ms-3 user-tabs ">
                      Activity
                    </span>
                  </Nav.Link>
                </Nav.Item>
              </div>
            </Card>
            <Tab.Content className="forum-content">
              <Tab.Pane eventKey="f1">
                <Card>
                  <Card.Header className="d-flex justify-content-between">
                    <div className="header-title">
                      <h4 className="card-title">Description</h4>
                    </div>
                  </Card.Header>
                  <Card.Body>
                    <p>{singleCampaign?.campaign_content}</p>
                  </Card.Body>
                </Card>
              </Tab.Pane>
              <Tab.Pane eventKey="f2">
                <Card>
                  <div className="d-flex justify-content-start p-2">
                    <Nav variant="pills" defaultActiveKey="gallery">
                      <Nav.Item
                        onClick={() => {
                          setMediaLists(singleCampaign?.gallery_images);
                        }}
                      >
                        <Nav.Link eventKey="gallery">Gallery</Nav.Link>
                      </Nav.Item>
                      <Nav.Item
                        onClick={() => {
                          setMediaLists(singleCampaign?.video_links);
                        }}
                      >
                        <Nav.Link eventKey="videos">Videos</Nav.Link>
                      </Nav.Item>
                      <Nav.Item
                        onClick={() => {
                          setMediaLists(singleCampaign?.medical_documents);
                        }}
                      >
                        <Nav.Link eventKey="docs">Documents</Nav.Link>
                      </Nav.Item>
                    </Nav>
                  </div>
                </Card>
                {mediaLists?.length > 0 ? (
                  <Row>
                    {mediaLists.map((gallery, index) => {
                      if (gallery.preview_picture_id) {
                        return (
                          <ImageCard
                            key={index}
                            img={gallery.preview_picture.large_img_url}
                            onImageClick={() => openLightbox(index)}
                            type="video"
                          />
                        );
                      }
                      return (
                        <ImageCard
                          key={index}
                          img={gallery.large_img_url}
                          onImageClick={() => openLightbox(index)}
                        />
                      );
                    })}
                    <LightBox images={mediaLists} />
                  </Row>
                ) : (
                  <Card className="p-2">
                    <h4>No Data Found</h4>
                  </Card>
                )}
              </Tab.Pane>
              <Tab.Pane eventKey="f4">
                <Card>
                  <Card.Header className="d-flex justify-content-between">
                    <div className="header-title">
                      <h4 className="card-title">
                        {singleCampaign.faq ? <>FAQs</> : <>No FAQs</>}
                      </h4>
                    </div>
                  </Card.Header>
                  {singleCampaign.faq && (
                    <Card.Body>
                      {singleCampaign.faq.map((faq, index) => (
                        <div key={index}>
                          <div className="align-items-center d-flex borderbox1 flex-wrap">
                            <h5>{faq.question}</h5>
                          </div>
                          <p>{faq.answer}</p>
                        </div>
                      ))}
                    </Card.Body>
                  )}
                </Card>
              </Tab.Pane>
              <Tab.Pane eventKey="f5">
                <ActivityTab />
              </Tab.Pane>
            </Tab.Content>
          </Tab.Container>
        </Col>
      </Row>
    </Container>
  );
};
export default SIngleDonationView;
