import React from "react";
import { Card, Col, Container, Row } from "react-bootstrap";
import ObituaryForm from "../dashboard/components/Obituary/Obituary";

export default function CreateObituaryPage() {
  return (
    <Container>
      <Row>
        <Col sm={12}>
          <Card>
            <Card.Header className="d-flex justify-content-between">
              <div className="header-title">
                <h4 className="card-title">Create Obituary</h4>
              </div>
            </Card.Header>
            <Card.Body>
              <div className="form1 form2">
                <ObituaryForm />
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
}
