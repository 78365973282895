import React from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import { useDocument } from "../../hooks/useDocument";
import "./documentWidget.css";
const UploadDocumentWidget = () => {
  const {
    file,
    handleChange,
    accessibility,
    handleRadioChange,
    handleUpload,
    setFile,
    setAccessibility,
    showClose,
    setShowClose,
  } = useDocument();
  return (
    <Row className="p-1 d-flex justify-content-center">
      <Col xs="12">
        <div className="card border border-primary">
          <div className="card-body">
            <div className="col-sm-12">
              <div class="mb-3">
                <label for="formFile" class="form-label">
                  Upload your Document
                </label>
                <input
                  class="form-control"
                  type="file"
                  id="formFile"
                  onChange={(event) => handleChange(event, "file")}
                  accept=".pdf,.doc,.docx,.ppt,.pptx"
                />
              </div>
            </div>
            {file?.name && (
              <div className="col-sm-6">
                <h4>{file?.name}</h4>
              </div>
            )}
            <p className="note mb-3">
              The file must be a file of type: pdf,.doc,.docx,.ppt or .pptx.
              File size should be 5mb or less than it
            </p>
            <div className="col-sm-4">
              <h6>Accessibility</h6>
            </div>
            <div className="col-sm-8">
              <div class="mb-3">
                <Form>
                  <Form.Group className="radio-button-group">
                    <Form.Check
                      type="radio"
                      label="Public"
                      value="public"
                      checked={accessibility === "public"}
                      onChange={handleRadioChange}
                    />
                    <Form.Check
                      type="radio"
                      label="Private"
                      value="private"
                      checked={accessibility === "private"}
                      onChange={handleRadioChange}
                    />
                    <Form.Check
                      type="radio"
                      label="Anyone with link"
                      value="anyone_with_link"
                      checked={accessibility === "anyone_with_link"}
                      onChange={handleRadioChange}
                    />
                  </Form.Group>
                </Form>
              </div>
            </div>
            <Col className="mt-2">
              <Button
                style={{ float: "right" }}
                disabled={!file?.name}
                onClick={handleUpload}
              >
                Save Changes
              </Button>
              <Button
                className="btn-info"
                style={{ float: "right" }}
                onClick={() => {
                  setShowClose(!showClose);
                  setFile(null);
                  setAccessibility("public");
                }}
              >
                Cancel
              </Button>
            </Col>
          </div>
        </div>
      </Col>
    </Row>
  );
};

export default UploadDocumentWidget;
