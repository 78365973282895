import React from "react";
import { Card, Dropdown, OverlayTrigger, Tooltip } from "react-bootstrap";
import { Link } from "react-router-dom";
import { BASE_URL, DATE_OPTIONS } from "../../utilities/constants";
import { useDocument } from "../../hooks/useDocument";
import BasicPostModal from "../../views/modals/BasicPostModal";
import { usePost } from "../../hooks/usePost";
import { useState } from "react";
import { useGlobal } from "../../hooks/useGlobal";
import htmlToDraft from "html-to-draftjs";
import { ContentState, EditorState } from "draft-js";
import { useAuth } from "../../hooks/useAuth";

const DocumentListsTable = ({orgAuthorizedUserId,orgId}) => {
  const {
    showClose,
    setShowClose,
    documents,
    handleDownload,
    deleteDocument,
    updateAccessibility,
  } = useDocument();
  const [showPost, setShowPost] = useState(false);
  const [showVideoDetail, setVideoDetailShow] = useState(false);
  const { toggleMedia } = useGlobal();
  const {user}=useAuth()
  const { post, setPost, initialPost } = usePost();
  const handlePostClose = () => {
    setShowPost(false);
    const { organization_id } = post;
    setPost({ ...initialPost, organization_id });
  };
  const toggleVideoDetailModal = () => {
    setVideoDetailShow(!showVideoDetail);
  };
  const handleShowPost = (docId) => {
    let link = `${BASE_URL}/document-uploads/${docId}`;
    const contentBlock = htmlToDraft(link);
    const contentState1 = ContentState.createFromBlockArray(
      contentBlock.contentBlocks
    );
    const editorState1 = EditorState.createWithContent(contentState1);
    setShowPost(true);
    setPost({
      ...post,
      content: { editorState: editorState1 },
    });
  };
  return (
    <Card>
      <Card.Header className="d-flex justify-content-between">
        <div className="header-title">
          <h4 className="card-title">Documents</h4>
        </div>
        {
          (user.id === orgAuthorizedUserId ||  orgId === null) && <div className="card-header-toolbar d-flex align-items-center">
          <button
            type="button"
            className="btn btn-secondary btn-sm d-flex align-items-center"
            onClick={() => setShowClose(!showClose)}
          >
            <span
              className="material-symbols-outlined  "
              style={{ fontSize: "14px" }}
            >
              edit
            </span>
            Upload
          </button>
        </div>}
      </Card.Header>
      <Card.Body>
        {documents.length > 0 ? (
          <div className="table-responsive">
            <table className="files-lists table table-striped mt-4">
              <thead>
                <tr>
                  <th scope="col">File Name</th>
                  <th scope="col">File Type</th>
                  <th scope="col">Date</th>
                  <th scope="col">Accessibility</th>
                  <th scope="col">Size</th>
                  <th scope="col">Author</th>
                  <th scope="col">Action</th>
                </tr>
              </thead>
              <tbody>
                {documents.map((document, index) => (
                  <tr key={index}>
                    <td>
                      <img
                        className="rounded-circle img-fluid avatar-40 me-2"
                        src={document.file_type?.logo}
                        alt="profile"
                      />
                      {document.title.toUpperCase()}
                    </td>
                    <td>{document.file_type.extension.toUpperCase()}</td>
                    <td>
                      {new Date(document.created_at).toLocaleDateString(
                        "en-US",
                        DATE_OPTIONS
                      )}{" "}
                    </td>
                    <td>
                      {document.accessibility === "anyone_with_link" ? (
                        <>ANYONE WITH LINK</>
                      ) : (
                        document.accessibility.toUpperCase()
                      )}
                    </td>
                    <td>{document.size}</td>
                    <td>{document.owner.name}</td>
                    <td>
                      <div className="flex align-items-center list-user-action">
                        {document.accessibility !== "private" &&  (user.id === orgAuthorizedUserId ||  orgId === null) && 
                          <OverlayTrigger
                            placement="top"
                            overlay={<Tooltip>Share</Tooltip>}
                          >
                            <Link
                              to="#"
                              onClick={() => handleShowPost(document.ulid)}
                            >
                              <i className="material-symbols-outlined">share</i>
                            </Link>
                          </OverlayTrigger>
                        }

                        {
                           (user.id === orgAuthorizedUserId ||  orgId === null) && <OverlayTrigger
                          placement="top"
                          overlay={<Tooltip>Accessibility</Tooltip>}
                        >
                          <div className="card-header-toolbar  d-flex justify-content-end">
                            <Dropdown>
                              <Link to="#">
                                <Dropdown.Toggle as="span">
                                  <i
                                    className="material-symbols-outlined md-18 action-button"
                                    style={{ fontSize: "28px" }}
                                  >
                                    more_horiz
                                  </i>
                                </Dropdown.Toggle>
                              </Link>
                              <Dropdown.Menu className="dropdown-menu-right">
                                <Dropdown.Item
                                  href="#"
                                  className="d-flex align-items-center"
                                  onClick={() =>
                                    updateAccessibility(document.ulid, "public")
                                  }
                                >
                                  Public
                                </Dropdown.Item>
                                <Dropdown.Item
                                  href="#"
                                  className="d-flex align-items-center"
                                  onClick={() =>
                                    updateAccessibility(
                                      document.ulid,
                                      "private"
                                    )
                                  }
                                >
                                  Private
                                </Dropdown.Item>
                                <Dropdown.Item
                                  href="#"
                                  onClick={() =>
                                    updateAccessibility(
                                      document.ulid,
                                      "anyone_with_link"
                                    )
                                  }
                                  className="d-flex align-items-center"
                                >
                                  Anyone with link
                                </Dropdown.Item>
                              </Dropdown.Menu>
                            </Dropdown>
                          </div>
                        </OverlayTrigger>}
                        <OverlayTrigger
                          placement="top"
                          overlay={<Tooltip>Download</Tooltip>}
                        >
                          <Link
                            to="#"
                            onClick={() => handleDownload(document.ulid)}
                          >
                            <i className="material-symbols-outlined">
                              file_download
                            </i>
                          </Link>
                        </OverlayTrigger>
                        { (user.id === orgAuthorizedUserId ||  orgId === null) && <OverlayTrigger
                          placement="top"
                          overlay={<Tooltip>Delete</Tooltip>}
                        >
                          <Link
                            to="#"
                            onClick={() => deleteDocument(document.ulid)}
                          >
                            <i className="material-symbols-outlined">delete</i>
                          </Link>
                        </OverlayTrigger>}
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        ) : null}
        <BasicPostModal
          // getPosts={getPosts}
          handleShow={true}
          handleClose={handlePostClose}
          show={showPost}
          toggleMedia={toggleMedia}
          toggleVideoDetailModal={toggleVideoDetailModal}
        />
      </Card.Body>
    </Card>
  );
};

export default DocumentListsTable;
