/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from "react";
import { useGlobal } from "./useGlobal";
import { EditorState } from "draft-js";

const OrganizationContext = React.createContext();
export const OrganizationProvider = ({ children }) => {
  const content = JSON.stringify({
    entityMap: {},
    blocks: [
      {
        key: "637gr",
        text: "",
        type: "unstyled",
        depth: 0,
        inlineStyleRanges: [],
        entityRanges: [],
        data: {},
      },
    ],
  });
  const onEditorStateChange = (editorState) => {
    setOrganization({
      ...organization,
      rich_content: { editorState },
    });
    setError({
      ...error,
      rich_content: !organization.rich_content.editorState
        .getCurrentContent()
        .hasText(),
    });
  };
  let initialOrganization = {
    // basic details
    logo: [],
    name: "",
    registration_number: "",
    short_intro: "",
    type_id: "",
    sector_id: "",
    sub_sector_id: "",
    gn_division_id: "",
    parent_organization_id: "",
    google_map_url: "",
    // profile details
    rich_content: {
      editorState: EditorState.createEmpty(),
      contentState: JSON.parse(content),
    },
    files: [],
    profile_picture: [],
    cover_picture: [],
    gallery_images: [],
    slider_images: [],
    videos: [],
    // contact details
    postal_address: "",
    latitude: "",
    longitude: "",
    postal_address_number: "",
    postal_address_street: "",
    postal_address_city: "",
    postal_address_province: "",
    postal_address_zipcode: "",
    postal_address_country: "",
    main_website: "",
    other_websites: [],
    main_email: "",
    other_emails: [],
    main_contact_number: "",
    other_contact_numbers: [],
    social_media_profiles: [],
    other_details: [],
    // seo details
    seo_meta_title: "",
    seo_meta_description: "",
    seo_meta_image: [],
    seo_meta_keywords: [],
    publish_at: "",
    verification_info: [],
    verification_documents: [],
  };
  const initialError = {
    // basic details
    logo: false,
    name: false,
    registration_number: false,
    short_intro: false,
    type_id: false,
    sector_id: false,
    sub_sector_id: false,
    gn_division_id: false,
    parent_organization_id: false,
    google_map_url: false,
    // profile details
    rich_content: false,
    files: false,
    profile_picture: false,
    cover_picture: false,
    gallery_images: false,
    slider_images: false,
    videos: false,
    // contact details
    postal_address: false,
    latitude: false,
    longitude: false,
    postal_address_number: false,
    postal_address_street: false,
    postal_address_city: false,
    postal_address_province: false,
    postal_address_zipcode: false,
    postal_address_country: false,
    main_website: false,
    other_websites: false,
    main_email: false,
    other_emails: false,
    main_contact_number: false,
    other_contact_numbers: false,
    social_media_profiles: false,
    other_details: false,
    // seo details
    seo_meta_title: false,
    seo_meta_description: false,
    seo_meta_image: false,
    seo_meta_keywords: false,
    publish_at: false,
    verification_info: false,
    verification_documents: false,
  };
  const [organization, setOrganization] = useState(initialOrganization);
  const [error, setError] = useState(initialError);
  const [action, setAction] = useState();
  const handleSelect = (field, selectedValue) => {
    setOrganization({
      ...organization,
      [field]: selectedValue,
    });
    setError({ ...error, [field]: checkFieldValidity(field, selectedValue) });
  };
  const checkFieldValidity = (field, value) => {
    if (field === "short_intro") {
      return value.length < 10;
    }
    if (typeof value == "string") {
      return value === "" || !(field in organization) ? true : false;
    } else {
      return value.length === 0;
    }
  };
  const handleError = (errObj) => {
    setError(errObj);
  };
  const handleDynamicDetails = (field, values) => {
    setOrganization({
      ...organization,
      [field]: values,
    });
    setError({ ...error, [field]: checkFieldValidity(field, values) });
  };
  const handleChange = (event) => {
    const { name, value } = event.target;
    setOrganization({
      ...organization,
      [name]: value,
    });
    setError({ ...error, [name]: checkFieldValidity(name, value) });
  };

  const { mediaState } = useGlobal();
  useEffect(() => {
    if (mediaState.model === "organization") {
      setOrganization({
        ...organization,
        [mediaState.field]: mediaState.values,
      });
      mediaState.field !== "" &&
        mediaState.values &&
        setError({
          ...error,
          [mediaState.field]: checkFieldValidity(
            mediaState.field,
            mediaState.values
          ),
        });
    }
  }, [mediaState.values]);
  useEffect(() => {
    setOrganization({ ...organization, sector_id: "", sub_sector_id: "" });
  }, [organization.type_id]);
  return (
    <OrganizationContext.Provider
      value={{
        organization,
        setOrganization,
        action,
        setAction,
        handleChange,
        handleSelect,
        handleDynamicDetails,
        onEditorStateChange,
        initialOrganization,
        initialError,
        error,
        handleError,
      }}
    >
      {children}
    </OrganizationContext.Provider>
  );
};
export const useOrganization = () => {
  return useContext(OrganizationContext);
};
