import React, { Fragment, useEffect, useState } from "react";

export default function DynamicOtherEmailDetail({otherEmails,handleOtherEmails}) {
  const [inputFields, setInputFields] = useState([]);
      useEffect(()=>{
        setInputFields(otherEmails)
      },[otherEmails])
      const handleAddFields = () => {
        const values = [...inputFields];
        values.push({ label: "", email: "" });
        handleOtherEmails('other_emails',values)
      };
    
      const handleRemoveFields = (index) => {
        const values = [...inputFields];
        values.splice(index, 1);
        handleOtherEmails('other_emails',values)
      };
      const handleInputChange = (index, event) => {
        const values = [...inputFields];
        const {name} = event.target
        values[index][name] = event.target.value;
        handleOtherEmails('other_emails',values)
      };
  return (
    <>
    {inputFields.map((inputField, index) => (
      <Fragment key={`${inputField}~${index}`}>
        <div
          className="row p-2 mb-3 d-flex align-items-center"
          style={{ backgroundColor: "#f5f2efc4" }}
        >
          <div className="form-group col-4">
                <label htmlFor="email_label">Email Label</label>
                <input
                type="text"
                name="label"
                className="form-control"
                id="email_label"
                onChange={(event) => handleInputChange(index, event)}
                placeholder="Email Label"
                />
            </div>
            <div className="form-group col-6">
                <label htmlFor="email_address">Email Address</label>
                <input
                type="email"
                name="email"
                className="form-control"
                id="email_address"
                onChange={(event) => handleInputChange(index, event)}
                placeholder="Email Address"
                />
            </div>

            <div className="col-2 col-xs-4">
              <button
                className="btn btn-danger"
                type="button"
                onClick={() => handleRemoveFields(index)}
              >
                Remove
              </button>
            </div>
        </div>
      </Fragment>
    ))}
    {inputFields.length >0 && <div className="row d-flex  justify-content-left ">
      <div
        className="col-xs-12 col-6 btn btn-success"
        onClick={handleAddFields}
      >
        Add More Email
      </div>
    </div>}
  </>
  )
}
