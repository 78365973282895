import React, { useState } from "react";
import { Col } from "react-bootstrap";
import CreatePostSection from "./Newsfeed/CreatePostSection";
import SinglePost from "./Newsfeed/SinglePost";
import { ADD, EDIT } from "../../../utilities/constants";
import { usePost } from "../../../hooks/usePost";
import htmlToDraft from "html-to-draftjs";
import { ContentState, EditorState } from "draft-js";
import loader from "../../../assets/images/page-img/page-load-loader.gif";

export default function Newsfeed({
  posts,
  getPosts,
  loading,
  error,
  postsRef,
}) {
  const [showPost, setPostShow] = useState(false);

  const { setPost, setAction } = usePost();
  const handlePostShow = (editObj = null, action = ADD) => {
    setPostShow(true);
    setAction(action);
    const contentBlock = editObj.content
      ? htmlToDraft(editObj.content)
      : htmlToDraft("<p></p>");
    const contentState1 = ContentState.createFromBlockArray(
      contentBlock.contentBlocks
    );
    if (action === EDIT) {
      const editorState1 = EditorState.createWithContent(contentState1);
      editObj != null &&
        setPost({
          ulid: editObj.ulid,
          organization_id:
            editObj.author_type === "organization" ? editObj.author_id : null,
          content: { editorState: editorState1 },
          post_status: editObj.post_status,
          post_visibility: editObj.post_visibility,
          comment_status: editObj.comment_status,
          title: editObj.title,
          photos: editObj.photos,
          videos: editObj.videos,
          author_type: editObj.author_type,
          author_id: editObj.author_id,
          publish_at: editObj.publish_at,
        });
    }
  };
  return (
    <>
      <Col sm={12}>
        <CreatePostSection
          getPosts={getPosts}
          handlePostShow={handlePostShow}
          showPost={showPost}
          setPostShow={setPostShow}
        />
      </Col>
      {posts && posts?.length > 0 ? (
        posts.map((post, i) => {
          const isLastElement = posts.length === i + 1;
          return isLastElement ? (
            <div key={i} ref={postsRef}>
              <Col sm={12} key={post.ulid}>
                <SinglePost
                  postDetails={post}
                  handlePostShow={handlePostShow}
                  getPosts={getPosts}
                />
              </Col>
            </div>
          ) : (
            <Col sm={12} key={post.ulid}>
              <SinglePost
                postDetails={post}
                handlePostShow={handlePostShow}
                getPosts={getPosts}
              />
            </Col>
          );
        })
      ) : (
        <Col className="d-flex justify-content-center align-items-center p-3">
          <h5>Posts Not Found.</h5>
        </Col>
      )}
      {loading && (
        <div className="col-sm-12 text-center">
          <img src={loader} alt="loader" style={{ height: "100px" }} />
        </div>
      )}
      {error && <p>{error}</p>}
    </>
  );
}
