import React, { useMemo, useState } from "react";
import { Row, Col, Container } from "react-bootstrap";
import { Link } from "react-router-dom";
import Card from "../../components/Card";
import "./app/css/newsfeed.css";
import Newsfeed from "./components/Newsfeed";
import { usePost } from "../../hooks/usePost";
import { postService } from "../../services/post.service";
import { donationCompaignService } from "../../services/donationCompaign.service";
import { timeDiffCalc } from "../../utilities/customFun";
import axios from "axios";
import { useRef } from "react";
import { useCallback } from "react";
import { useEffect } from "react";

const Index = () => {
  const [hasMorePages, setHasMorePages] = useState(false);
  const [nextPagesUrl, setNextPageUrl] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  const [posts, setPosts] = useState([]);
  const [recentDonations, setRecentDonations] = useState([]);
  const [recentPosts, setRecentPosts] = useState([]);
  const getPosts = async () => {
    try {
      const res = await postService.getAllPosts();
      setPosts(res.posts);
      setHasMorePages(res?.meta?.has_more_pages);
      if (res?.links?.next) setNextPageUrl(res.links.next);
    } catch (err) {
      setError("Something went wrong, try again later");
    }
  };
  const getNextPagePosts = async () => {
    setIsLoading(true);
    try {
      const res = await axios.get(nextPagesUrl);
      setPosts((prevPosts) => [
        ...new Set([...prevPosts, ...res?.data?.posts]),
      ]);
      setHasMorePages(res.data.meta.has_more_pages);
      if (res?.data?.links?.next) setNextPageUrl(res.data.links.next);
    } catch (err) {
      setError(err);
    } finally {
      setIsLoading(false);
    }
  };
  const getDonation = () => {
    donationCompaignService
      .fetchAllCompaigns(
        `?limit=3&sort[column]=created-date&sort[direction]=desc`
      )
      .then((res) => {
        setRecentDonations(res.campaigns);
      });
  };
  const getRecentPosts = () => {
    postService
      .getAllPosts(`?limit=3&sort[column]=created-date&sort[direction]=desc`)
      .then((res) => {
        setRecentPosts(res.posts);
      });
  };
  const { handleSelect } = usePost();
  useMemo(() => {
    handleSelect("organization_id", null);
    getPosts();
    getDonation();
    getRecentPosts();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  //method for infinite scrolling
  const observer = useRef();
  const lastPostElementRef = useCallback(
    (node) => {
      if (isLoading) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && hasMorePages) {
          getNextPagePosts();
        }
      });
      if (node) observer.current.observe(node);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isLoading, hasMorePages]
  );
  useEffect(() => {
    // 👇️ scroll to top on page load
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);
  return (
    <>
      <div id="content-page" className="content-page newsfeed-ui">
        <Container>
          <Row>
            <Col lg={8} className="row m-0 p-0">
              <Newsfeed
                posts={posts}
                getPosts={getPosts}
                loading={isLoading}
                error={error}
                postsRef={lastPostElementRef}
              />
            </Col>
            <Col lg={3} className="d-none d-sm-block">
              <Card>
                <div className="card-header d-flex justify-content-between">
                  <div className="header-title">
                    <h4 className="card-title">Recent Donations</h4>
                  </div>
                </div>
                <Card.Body>
                  <ul className="media-story list-inline m-0 p-0">
                    {recentDonations?.map((donation) => (
                      <li className="d-flex mb-3 align-items-center active">
                        {donation.user != null && (
                          <img
                            src={donation.user?.display_picture?.thumb_img_url}
                            alt="story-img"
                            className="rounded-circle img-fluid"
                          />
                        )}
                        {donation.organization != null && (
                          <img
                            src={
                              donation.organization.display_picture
                                ?.thumb_img_url
                            }
                            alt="story-img"
                            className="rounded-circle img-fluid"
                          />
                        )}
                        <div className="stories-data ms-3">
                          <h5>{donation.title}</h5>
                          <p className="mb-0">
                            {" "}
                            {timeDiffCalc(new Date(donation.created_at))}
                          </p>
                        </div>
                      </li>
                    ))}
                  </ul>
                  <Link
                    to="/donation-campaign/lists"
                    className="btn btn-primary d-block mt-3"
                  >
                    See All
                  </Link>
                </Card.Body>
              </Card>
              <Card>
                <div className="card-header d-flex justify-content-between">
                  <div className="header-title">
                    <h4 className="card-title">Recent Post</h4>
                  </div>
                </div>
                <Card.Body>
                  <ul className="media-story list-inline m-0 p-0">
                    {recentPosts.slice(0, 3).map((recentPost) => (
                      <li className="d-flex mb-4 align-items-center ">
                        <img
                          src={
                            recentPost.author.display_picture_media
                              ?.thumb_img_url
                          }
                          alt="story1"
                          className="rounded-circle img-fluid"
                        />
                        <div className="stories-data ms-3">
                          <h6>
                            {recentPost.author_type !== "admin"
                              ? recentPost.author.name
                              : "Admin"}
                          </h6>
                          <h5>{recentPost.title}</h5>
                          <p className="mb-0">
                            {timeDiffCalc(new Date(recentPost.publish_at))}
                          </p>
                        </div>
                      </li>
                    ))}
                  </ul>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default Index;
